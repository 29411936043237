import { Store } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";

const Providers: React.FC<{ children: ReactNode; store: Store }> = ({
  children,
  store,
}) => {
  return (
    <Provider store={store}>
      <SWRConfig>{children}</SWRConfig>
    </Provider>
  );
};

export default Providers;
