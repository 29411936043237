import { range } from "ramda";
import React from "react";
import { Skeleton } from "~/@/components/ui/skeleton";
import { cn } from "~/@/lib/utils";

type SkeletonContentProps = {
  isLoading: boolean;
  count?: number;
  children: React.ReactNode;
  Component: React.FC<Partial<{ className: string }>>;
};

export function SkeletonContent({
  children,
  count = 4,
  isLoading,
  Component,
}: SkeletonContentProps) {
  if (!isLoading) return <>{children}</>;

  return (
    <>
      {range(0, count).map((idx) => {
        return <Component key={idx} />;
      })}
    </>
  );
}

export function SkeletonText(props: {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string;
}) {
  const className = React.useMemo(
    () => cn("relative inline-block", props.className),
    [props.className],
  );

  return (
    <>
      {!props.isLoading ? (
        <span className={className}>{props.children}</span>
      ) : (
        <span className={className}>
          <Skeleton className={"absolute inset-0"} />
          <span
            style={{ opacity: "0" }}
            className="not-sr-only pointer-events-none relative"
          >
            Placeholder
          </span>
        </span>
      )}
    </>
  );
}
