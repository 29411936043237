import { WalletosContext } from "./walletosContext";
import {
  resolveFetchError,
  UnacceptedError,
  AuthMiddleware,
  resolveUrl,
} from "~/api/client";
import { FetchError, FetchOptions, ofetch } from "ofetch";
import { authSubject } from "~/libs/observables/auth";
import { destr } from "destr";

export type ErrorWrapper<TError> =
  | TError
  | { status: "unknown"; payload: string };

export type WalletosFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> =
  {
    url: string;
    method: string;
    body?: TBody;
    headers?: THeaders;
    queryParams?: TQueryParams;
    pathParams?: TPathParams;
    signal?: AbortSignal;
  } & WalletosContext["fetcherOptions"];

export async function liquidityFetch<
  TData,
  TError,
  TBody extends Record<string, unknown> | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {}
>(
  params: WalletosFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>
): Promise<TData> {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    ...params.headers,
  };

  try {
    return UnacceptedError.interceptResponse(
      await liquidityFetcher({
        ...params,
        query: params.queryParams,
        headers,
        url: resolveUrl(params.url, params.queryParams, params.pathParams),
      })
    );
  } catch (error) {
    return Promise.reject(resolveFetchError(error));
  }
}

export async function liquidityFetcher<TResponse>(
  params: { url: string } & FetchOptions
): Promise<TResponse> {
  try {
    const { url, ...rest } = params;
    const config = AuthMiddleware.setHeaders(rest) as FetchOptions<"json">;
    return liquidityApi<TResponse>(url, config);
  } catch (err) {
    const xhrErr = err as FetchError;
    if (xhrErr instanceof FetchError) {
      if (xhrErr?.response?.status === 401) {
        authSubject.next({ type: "unauthorized" });
      }
    }
    throw err;
  }
}

const liquidityApi = ofetch.create({
  baseURL: import.meta.env.VITE_LIQUIDITY_URL,
  parseResponse: destr,
});
