import React from "react";
import { DestinationCtx } from ".";
import CardDetails from "./CardDetails";
import BannkDetails from "./BankDetails";

const AccountDetail = ({
  currentMove,
  setCurrentMove,
  setCurrentStep,
  currentStep,
}) => {
  const { destinationState } = useContext(DestinationCtx);

  return (
    <div className="mx-auto w-full max-w-[613px]">
      {destinationState?.method?.label === "Card" && (
        <CardDetails
          setCurrentMove={setCurrentMove}
          currentMove={currentMove}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
      {["Bank", "Manual Bank"].includes(destinationState?.method?.label) && (
        <BannkDetails
          setCurrentMove={setCurrentMove}
          currentMove={currentMove}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
    </div>
  );
};

export default React.memo(AccountDetail);
