export function USDCIcon({ size }: { size?: string }) {
  return (
    <svg
      width={size || "21"}
      height={size || "21"}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4389_50655)">
        <path
          d="M20.5964 38.6107C31.1526 38.6107 39.6441 30.1193 39.6441 19.5631C39.6441 9.00693 31.1526 0.515503 20.5964 0.515503C10.0403 0.515503 1.54883 9.00693 1.54883 19.5631C1.54883 30.1193 10.0403 38.6107 20.5964 38.6107Z"
          fill="#2775CA"
        />
        <path
          d="M25.8345 22.5781C25.8345 19.801 24.1679 18.8486 20.8345 18.4505C18.4536 18.1324 17.9774 17.4981 17.9774 16.3876C17.9774 15.2771 18.7717 14.5629 20.3583 14.5629C21.7869 14.5629 22.5812 15.039 22.9774 16.2295C23.0574 16.4676 23.2955 16.6257 23.5336 16.6257H24.8041C25.1221 16.6257 25.3602 16.3876 25.3602 16.0695V15.9895C25.0421 14.2429 23.6136 12.8943 21.7888 12.7362V10.8314C21.7888 10.5133 21.5507 10.2752 21.1545 10.1971H19.9641C19.646 10.1971 19.4079 10.4352 19.3298 10.8314V12.6562C16.9488 12.9743 15.4402 14.561 15.4402 16.5457C15.4402 19.1648 17.0269 20.1971 20.3602 20.5933C22.5831 20.9895 23.2974 21.4657 23.2974 22.7362C23.2974 24.0067 22.1869 24.879 20.6783 24.879C18.6155 24.879 17.9012 24.0067 17.6631 22.8162C17.5831 22.4981 17.345 22.34 17.1069 22.34H15.7583C15.4402 22.34 15.2021 22.5781 15.2021 22.8962V22.9762C15.5202 24.961 16.7888 26.3895 19.4079 26.7857V28.6905C19.4079 29.0086 19.646 29.2467 20.0421 29.3248H21.2326C21.5507 29.3248 21.7888 29.0867 21.8669 28.6905V26.7857C24.246 26.3876 25.8345 24.721 25.8345 22.5781Z"
          fill="white"
        />
        <path
          d="M16.5469 30.9102C10.3564 28.6873 7.18118 21.7826 9.48404 15.6721C10.6745 12.3388 13.2936 9.79971 16.5469 8.60924C16.865 8.45114 17.0231 8.21305 17.0231 7.81495V6.70448C17.0231 6.38638 16.865 6.14829 16.5469 6.07019C16.4669 6.07019 16.3088 6.07019 16.2288 6.15019C8.68975 8.53114 4.56214 16.5464 6.94309 24.0873C8.37166 28.5311 11.785 31.9445 16.2288 33.373C16.5469 33.5311 16.8631 33.373 16.9431 33.055C17.0231 32.975 17.0231 32.8969 17.0231 32.7369V31.6264C17.0231 31.3864 16.785 31.0702 16.5469 30.9102ZM24.9583 6.14829C24.6402 5.99019 24.324 6.14829 24.244 6.46638C24.164 6.54638 24.164 6.62448 24.164 6.78448V7.89495C24.164 8.21305 24.4021 8.52924 24.6402 8.68924C30.8307 10.9121 34.0059 17.8169 31.7031 23.9273C30.5126 27.2607 27.8936 29.7997 24.6402 30.9902C24.3221 31.1483 24.164 31.3864 24.164 31.7845V32.895C24.164 33.213 24.3221 33.4511 24.6402 33.5292C24.7202 33.5292 24.8783 33.5292 24.9583 33.4492C32.4974 31.0683 36.625 23.053 34.244 15.5121C32.8155 10.9902 29.324 7.57686 24.9583 6.14829Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4389_50655">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5 0.324097)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
