import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { Button } from "~/@/components/ui/button";
import { Checkbox } from "~/@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/@/components/ui/form";
import { FormItemSplit } from "~/@/components/ui/form-layout";
import { Switch } from "~/@/components/ui/switch";
import { cn } from "~/@/lib/utils";
import { Divider } from "~/components/Divider";
import { FieldShowBalanceIn } from "~/components/Inputs/fields";
import { CardBody, CardFooter, CardRoot } from "~/components/layouts/Card";

export const Route = createFileRoute("/settings/wallets/user-wallet")({
  component: Component,
});

function Component() {
  const form = useForm();

  return (
    <Form {...form}>
      <CardRoot className="mt-6">
        <CardBody className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="show_balance_in"
            render={() => <FieldShowBalanceIn />}
          />

          <Divider />

          <FormItemSplit>
            <Label>
              Who can <b>Fund</b>?
            </Label>

            <div className={"flex flex-col gap-y-4"}>
              <FormField
                control={form.control}
                name="who_can_fund"
                render={() => {
                  return (
                    <FormItem>
                      <FormLabel
                        className={cn("flex items-center gap-x-2", {
                          "opacity-50": true,
                        })}
                      >
                        <FormControl>
                          <Checkbox checked={true} disabled />
                        </FormControl>
                        <span className={"font-normal"}>UserWallet Owner</span>
                      </FormLabel>
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="who_can_fund"
                render={() => {
                  return (
                    <FormItem>
                      <FormLabel className={"flex items-center gap-x-2"}>
                        <FormControl>
                          <Checkbox checked={true} />
                        </FormControl>
                        <span className={"font-normal"}>Business Owner</span>
                      </FormLabel>
                    </FormItem>
                  );
                }}
              />
            </div>
          </FormItemSplit>

          <Divider />

          <FormItemSplit>
            <Label>
              Who can <b>Withdraw</b>?
            </Label>

            <div className={"flex flex-col gap-y-4"}>
              <FormField
                control={form.control}
                name="who_can_withdraw"
                render={() => {
                  return (
                    <FormItem>
                      <FormLabel
                        className={cn("flex items-center gap-x-2", {
                          "opacity-50": true,
                        })}
                      >
                        <FormControl>
                          <Checkbox checked={true} disabled />
                        </FormControl>
                        <span className={"font-normal"}>UserWallet Owner</span>
                      </FormLabel>
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="who_can_fund"
                render={() => {
                  return (
                    <FormItem>
                      <FormLabel className={cn("flex items-center gap-x-2")}>
                        <FormControl>
                          <Checkbox checked={true} />
                        </FormControl>
                        <span className={"font-normal"}>Business Owner</span>
                      </FormLabel>
                    </FormItem>
                  );
                }}
              />
            </div>
          </FormItemSplit>

          <Divider />

          <FormItemSplit>
            <Label>Fund Wallet via</Label>
            <ul className="w-full space-y-2 font-body">
              <FormControl>
                <ul className="flex w-full flex-col gap-y-4">
                  {[
                    {
                      id: "subwallet",
                      title: "SubWallet/VBAccounts",
                      disabled: false,
                    },
                    {
                      id: "bank_transfer",
                      title: "Bank Transfer",
                      disabled: false,
                    },
                    {
                      id: "debit_credit_card",
                      title: "Debit/Credit Card",
                      disabled: false,
                    },
                    {
                      id: "crypto_transfer",
                      title: "Crypto Transfers",
                      disabled: false,
                    },
                    {
                      id: "option_wallet",
                      title: "Option selection",
                      disabled: false,
                    },
                  ].map((option) => {
                    return (
                      <FormField
                        key={option.title}
                        name={`allow_funding_in.${option.id}`}
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <FormItem
                              className={"flex items-center gap-x-2 text-sm"}
                            >
                              <FormControl>
                                <Checkbox
                                  name={field.name}
                                  disabled={option.disabled}
                                  checked={Boolean(field.value)}
                                  onCheckedChange={(value) => {
                                    form.setValue(field.name, value);
                                  }}
                                />
                              </FormControl>

                              <FormLabel className="!mt-0 font-normal text-gray-600">
                                {option.title}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    );
                  })}
                </ul>
              </FormControl>
            </ul>
          </FormItemSplit>

          <Divider />
          <FormItemSplit className={"pointer-events-none opacity-50"}>
            <Label>
              Allow Intra-Wallet Transfers <span>(Owned Subwallets)</span>
            </Label>

            <FormField
              control={form.control}
              name="intra_wallet_owner_subwallets"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={(v) => form.setValue(field.name, v)}
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          </FormItemSplit>
          <Divider />
          <FormItemSplit className={"pointer-events-none opacity-50"}>
            <Label>
              Allow Intra-Wallet Transfers{" "}
              <span className={"font-semibold"}>(Other UserWallets)</span>
            </Label>

            <FormField
              control={form.control}
              name="intra_wallet_user_wallets"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={(v) => form.setValue(field.name, v)}
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          </FormItemSplit>
        </CardBody>

        <CardFooter>
          <Button>Save changes</Button>
        </CardFooter>
      </CardRoot>
    </Form>
  );
}

function Label(props: { children: React.ReactNode }) {
  return <label className={"text-sm font-normal"}>{props.children}</label>;
}
