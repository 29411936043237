import { useGetBusinessId } from "~/hooks/use-business";
import { useGetDashboardMetrics } from "~/api/codegen/walletosComponents";
import { Metrics } from "~/general/interfaces";

export function useUserMetrics() {
  const businessId = useGetBusinessId();
  const res = useGetDashboardMetrics<Metrics>({
    headers: {},
    pathParams: { businessId },
  });

  return {
    ...res,
    data: res?.data?.data,
  };
}
