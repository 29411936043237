import { FooterBackArrow } from "~/assets/icons";

export function OnboardingBackButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type={"button"}
      onClick={() => onClick()}
      className="absolute left-[10px] mt-[-10px] cursor-pointer lg:left-[50px]"
    >
      <FooterBackArrow />
    </button>
  );
}
