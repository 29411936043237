import React, { ComponentProps } from "react";
import { TabsContent, TabsList, TabsTrigger } from "~/@/components/ui/tabs";
import { cn } from "~/@/lib/utils";
import { MainContainer } from "~/components/layouts/Shared";
import { TabButton } from "../Tabs";

export function SecondaryNav(props: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className="bg-[white]">
      <MainContainer
        className={`flex items-center justify-end px-8 py-4 ${props?.className}`}
      >
        {props.children}
      </MainContainer>
    </div>
  );
}

export const SecondaryRoot = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function SecondaryRoot(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div ref={ref} {...PROPS} className={cn("-mx-6", className)}>
      {children}
    </div>
  );
});

export const SecondaryContent = React.forwardRef<
  React.ElementRef<typeof TabsContent>,
  React.ComponentProps<typeof TabsContent>
>(function SecondaryContent(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <TabsContent ref={ref} {...PROPS}>
      <MainContainer className="flex flex-col">{children}</MainContainer>
    </TabsContent>
  );
});

type Tab = { key: string; label: string; isActive: boolean };

export function SecondaryTab(
  props:
    | { tabs: Tab[]; children?: never }
    | { tabs?: never; children: React.ReactNode },
) {
  const { tabs, children } = props;

  return (
    <TabsList asChild>
      <div className={"-mb-4 gap-x-6 bg-transparent !p-0"}>
        {tabs
          ? tabs.map((e) => {
              return (
                <TabsTrigger key={e.key} value={e.key} asChild>
                  <TabButton>{e.label}</TabButton>
                </TabsTrigger>
              );
            })
          : children}
      </div>
    </TabsList>
  );
}
