import React from "react";
import { Button } from "~/@/components/ui/button";
import { CardSeparator } from "~/components/layouts/Card";
import { useModal, useModalHandle } from "~/hooks/use-modal";
import { getDateFormat } from "~/libs/date.helpers";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { XIcon } from "~/assets/icons/XIcon";
import { AuditLog } from "~/libs/factories/audit-log";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { SectionHeading } from "~/components/atoms/heading";
import { truncateString } from "~/libs/string.helper";
import { notify } from "~/components/Toast";
import { CopyIcon } from "~/assets/icons";

const status_color_map = {
  critical: { bg: "#FFEBEB", text: "#F05050", border: "transparent" },
  important: { bg: "#FFF9EB", text: "#FFAB00", border: "transparent" },
  informational: { bg: "#FFFFFF", text: "#57584E", border: "#DEE5E7" },
  success: { bg: "#EDFDF0", text: "#3BB75E", border: "transparent" },
  get: { bg: "#FFFFFF", text: "#57584E", border: "#DEE5E7" },
};

export function AuditLogDetails({
  sourceTable,
  selectedData,
}: {
  sourceTable?: string;
  selectedData: AuditLog;
}) {
  const modal = useModal();
  const [selected, setSelected] = React.useState(null);
  const [source, setSource] = React.useState("");

  useEffect(() => {
    setSelected(selectedData);
    if (sourceTable === "Payment Link Details") {
      setSource(sourceTable);
    }
  }, [selectedData, sourceTable]);

  const match = status_color_map[selectedData?.action.toLowerCase()] ?? {};

  return (
    <DialogRemote
      id="create_modal"
      onOpenChange={(open) => {
        if (!open) {
          modal.hide("create_modal");
        }
      }}
    >
      <DialogContent showClose className="max-w-lg px-5">
        <DialogHeader className="space-y-0 px-5">
          <DialogTitle className="flex">
            <SectionHeading>
              <span className="text-lg text-[#0094FF]">Audit Details</span>
            </SectionHeading>
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <CardSeparator className="-mx-5" />
        <ScrollArea className="max-h-[40rem]">
          <>
            <div>
              <div className="flex min-h-[100px] w-[100%] flex-col items-center justify-center gap-1 rounded-lg bg-[#F2F6F8] px-5 py-1">
                <div className="text-sm font-normal text-[#005695]">
                  Narration
                </div>
                <span className="text-center font-body text-xl font-bold text-[#005695]">
                  {selectedData?.description}
                </span>
              </div>
              <div className="px-5 py-5">
                <div className="flex items-center justify-between pb-6 pt-2">
                  <h3 className="text-sm font-normal text-gray-600">Admin</h3>
                  <p className="text-sm font-semibold text-gray-800">{"-"}</p>
                </div>
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">Date</h3>
                  <p className="text-sm font-semibold text-gray-800">
                    {getDateFormat(new Date(selectedData?.createdAt))
                      .dateFormat ?? "-"}{" "}
                    <span className="font-normal">
                      {getDateFormat(new Date(selectedData?.createdAt))
                        .timeFormat ?? "-"}
                    </span>
                  </p>
                </div>
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">Source</h3>
                  <p className="text-sm text-gray-800">{"-"}</p>
                </div>
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">
                    IP Address
                  </h3>
                  <p className="text-sm font-semibold text-gray-800">{"-"}</p>
                </div>
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">
                    Category
                  </h3>
                  <p className="text-sm font-semibold text-gray-800">
                    {selectedData?.entity ?? "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">
                    Priority
                  </h3>
                  <div
                    style={{
                      backgroundColor: match.bg,
                      border: `1px solid ${match.border}`,
                    }}
                    className={
                      "inline-flex h-[30px] items-center gap-1 rounded-2xl px-3 py-[10px]"
                    }
                  >
                    <span
                      style={{ color: match.text }}
                      className="mx-[5px] text-center text-[12px] font-bold capitalize"
                    >
                      {selectedData?.action}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">Log ID</h3>
                  <div className="flex items-center space-x-2">
                    <span className="truncate text-sm font-semibold text-gray-800">
                      {truncateString(`${selectedData?.id}`, 8)}
                    </span>
                    <CopyToClipboard
                      text={`${selectedData?.id}`}
                      onCopy={() => notify("success", "Log ID copied")}
                    >
                      <button type="button" className={""}>
                        <CopyIcon h="14" w="14" color="#4084B5" />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div className="my-[20px] flex items-center justify-center">
                <Button
                  type={"button"}
                  variant="outline"
                  className="border-0 bg-[#F2F6F8] text-[#005695] hover:bg-[#005695]/10 hover:text-[#005695]"
                  size={"lg"}
                  onClick={() => {
                    modal.hide("create_modal");
                  }}
                >
                  <XIcon size="18" color="#005695" />
                  <span className="px-2">Close</span>
                </Button>
              </div>
            </div>
          </>
        </ScrollArea>
      </DialogContent>
    </DialogRemote>
  );
}
