import { cva } from "class-variance-authority";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/@/components/ui/accordion";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { TransactionKV } from "~/general/transaction";
import { useModalHandle } from "~/hooks/use-modal";
import { simpleCurrencyFormatter } from "~/libs/currency.helpers";
import { safeFormat } from "~/libs/date.helpers";
import {
  BankDetailsFactory,
  TrxFactory,
  TrxImpl,
  TrxLog,
  TxDestinationBusinessWallet,
  TxDestinationUserWallet,
} from "~/libs/factories/transaction";
import { StatusBadge } from "../Badges";
import { Divider } from "../Divider";
import { DialogModalHeader } from "../Modal";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const FALLBACK = TrxFactory({} as any);

export function WalletTransactionModal() {
  const modal = useModalHandle("transaction_details");

  const { data: transaction_data = FALLBACK } = modal;

  return (
    <DialogRemote id={"transaction_details"}>
      <DialogContent
        showClose={false}
        className="w-full overflow-hidden font-body"
      >
        <DialogHeader className="hidden">
          <DialogTitle />
          <DialogDescription />
        </DialogHeader>
        <div
          className={
            "fixed inset-x-0 top-0 z-10 bg-white bg-opacity-75 px-6 pb-0 pt-4 backdrop-blur-sm"
          }
        >
          <DialogModalHeader
            title={"Transaction Receipt"}
            onBackArrowClick={() => {
              modal.hide();
            }}
          />
          <Divider className={"-mx-6 mb-0"} />
        </div>

        <ScrollArea className={"-mx-6 max-h-[600px] px-6"}>
          <div className={"mt-16"} />
          <TransactionDisplay type={"success"} heading={"Total Amount Paid"}>
            <Currency
              value={transaction_data.amount.value}
              currency={transaction_data.amount.currency}
            />
          </TransactionDisplay>

          <Divider />

          <ListItem label="Status">
            <StatusBadge label={transaction_data.status} />
          </ListItem>

          <ListItem label="Type">
            <span className="text-base font-medium leading-normal">
              {transaction_data.flow_type}
            </span>
          </ListItem>

          <ListItem label="Date/Time">
            <span className="text-base font-medium leading-normal text-zinc-700">
              {safeFormat(transaction_data.created_at, "EEE dd, MM yyy")}{" "}
              <span className="text-sm font-normal leading-normal text-zinc-700">
                {safeFormat(transaction_data.created_at, "hh:mm:ssaaa")}
              </span>
            </span>
          </ListItem>

          <ListItem label="Method">
            <span className="text-base font-medium leading-normal text-[#002C3D]">
              {transaction_data.method_for_human}
            </span>
          </ListItem>

          <Divider className={"mb-0"} />

          <SenderReceiverInfo type={"source"} data={transaction_data} />

          <SenderReceiverInfo type={"destination"} data={transaction_data} />

          <ListItem label="Amount">
            <span className="text-base font-medium leading-normal text-[#002C3D]">
              {transaction_data.amount.formatted}
            </span>
          </ListItem>

          <ListItem label="Fee">
            <span className="flex items-center text-base font-medium leading-normal text-[#002C3D]">
              {transaction_data.fees.formatted}
              {/*<span className="ml-[5px] font-body text-sm font-normal">*/}
              {/*  0.5%*/}
              {/*</span>*/}
            </span>
          </ListItem>

          <ListItem label="Total">
            <span className="text-base font-medium leading-normal text-[#002C3D]">
              {transaction_data.total.formatted}
            </span>
          </ListItem>
        </ScrollArea>

        {/* <div className={"mt-8 flex justify-center gap-4"}>
          <Button size="lg" className="flex-1" variant={"default"}>
            <LoadingButtonContent Icon={<DownloadIcon color="white" />}>
              Download
            </LoadingButtonContent>
          </Button>

          <Button size="lg" className="flex-1" variant="outline">
            <LoadingButtonContent Icon={<Send size="1em" />}>
              Send Invoice
            </LoadingButtonContent>
          </Button>
        </div> */}
      </DialogContent>
    </DialogRemote>
  );
}

function Currency({ currency, value }: { currency: string; value: number }) {
  if (currency === "--") return false;
  if (!currency) return value;

  const formatter = simpleCurrencyFormatter(currency);

  return formatter.format(value);
}

function ListItem(props: { label: string; children?: React.ReactNode }) {
  const { label, children } = props;

  return (
    <div className="my-[20px] flex items-center justify-between">
      <span className="font-body text-base font-normal leading-tight text-zinc-700">
        {label}
      </span>

      {children}
    </div>
  );
}

const variant = cva(
  "flex gap-[10px] h-[90px] w-[100%] flex-col justify-center rounded-lg",
  {
    variants: {
      type: {
        success: "bg-[#EDFDF0]",
        error: "bg-red-50",
        info: "bg-[#EDFDF0]",
      },
    },
  },
);

function TransactionDisplay({
  heading,
  type,
  children,
}: {
  heading: string;
  type: "success";
  children: React.ReactNode;
}) {
  return (
    <div className={variant({ type })}>
      <span className="text-center text-sm font-normal leading-none text-[#226835]">
        {heading}
      </span>
      <span className="text-center font-body text-2xl font-bold text-[#226835]">
        {children}
      </span>
    </div>
  );
}

function SenderReceiverInfo({
  type,
  data,
}: {
  type: "source" | "destination";
  data?: TrxLog;
}) {
  const title =
    {
      source: "From",
      destination: "To",
    }[type] ?? "--";

  console.log("transaction_data", data);

  if (!data) return null;

  const render = React.useCallback((record: TransactionKV) => {
    const hasCopy = typeof record?.copyText !== "undefined";
    return (
      <ListItem key={record.title} label={record.title}>
        <div className="flex items-center gap-2 text-base font-medium leading-normal text-[#002C3D]">
          <span>{record.value}</span>

          {hasCopy ? (
            <CopyToClipboard text={record.copyText}>
              <button type="button" className={""}>
                <CopyIcon />{" "}
              </button>
            </CopyToClipboard>
          ) : null}
        </div>
      </ListItem>
    );
  }, []);

  const content = () => {
    if (type === "destination") {
      // console.log("hasOwner", data);
      const hasOwner =
        // @ts-expect-error
        typeof data?.$original?.destination?.owner === "undefined";

      if (hasOwner)
        // @ts-expect-error
        return BankDetailsFactory(data?.$original?.destination)
          .toArray()
          .map(render);

      if (TrxImpl.isDestination(data, "USER"))
        // @ts-expect-error
        return TxDestinationUserWallet(data?.$original?.destination)
          .toArray()
          .map(render);

      if (TrxImpl.isDestination(data, "BUSINESS")) {
        // @ts-expect-error
        return TxDestinationBusinessWallet(data?.$original?.destination)
          .toArray()
          .map(render);
      }
    }

    if (type === "source") {
      if (TrxImpl.isSource(data, "BANK_TRANSFER"))
        return BankDetailsFactory(data?.$original?.[type])
          .toArray()
          .map(render);
    }

    return <p className={"text-muted-foreground"}>No data</p>;
  };

  return (
    <Accordion type={"single"} collapsible defaultValue={type}>
      <AccordionItem value={type}>
        <AccordionTrigger>
          <span className="flex items-center justify-between font-normal text-zinc-700">
            {title}
          </span>
        </AccordionTrigger>
        <AccordionContent>{content()}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
