export function CloseIcon({ size }: { size?: string }) {
  return (
    <svg
      width={size || "18"}
      height={size || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.00002" cy="8.99984" r="8.33333" fill="#F05050" />
      <path
        d="M11.7083 6.5L6.5 11.7083M6.5 6.5L11.7083 11.7083"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
