import React from "react";
import { FormControl, FormItem, FormLabel } from "~/@/components/ui/form";
import { FormItemSplit } from "~/@/components/ui/form-layout";
import { FiatChipSelector } from "~/components/Inputs/Chip";
import { currencies } from "~/constants/currencies";
import { CountryI } from "~/general/interfaces";
import { useCountries } from "~/hooks";
import { CurrencyDropdown } from ".";

export function FieldShowBalanceIn() {
  const { data: countries } = useCountries();
  const [currency, setCurrency] = React.useState<CountryI>(
    countries.filter((val: CountryI) => val.iso2 === "US")[0],
  );
  return (
    <FormItem>
      <FormItemSplit>
        <FormLabel className={"font-normal"}>Show balance In</FormLabel>
        <FormControl>
          <div>
            <CurrencyDropdown
              value={currency}
              onValueChange={(val) => {
                setCurrency(val);
              }}
              clasNmae="w-full justify-between bg-white"
            />
          </div>
          {/* <FiatChipSelector
            options={currencies}
            values={[currencies[0]]}
            onSelectChange={console.info}
          /> */}
        </FormControl>
      </FormItemSplit>
    </FormItem>
  );
}
