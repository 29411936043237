export type PaginationMetadata = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
};

export function PaginationFactory<T = unknown>(
  res: unknown,
  factory: (a: unknown) => T = (a) => a as T,
): { data: T[]; meta: PaginationMetadata } {
  return {
    // @ts-expect-error
    data: safeArray(res?.data).map(factory),
    // @ts-expect-error
    meta: PaginationMeta(res?.meta),
  };
}

function PaginationMeta(meta: PaginationMetadata) {
  return {
    __proto__: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      total: 0,
    },
    ...safeObj(meta),
  } as PaginationMetadata;
}
