import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { formatNumber } from "~/libs/currency.helpers";
import { getDateFormat } from "~/libs/date.helpers";
import { MPLog } from "~/libs/factories/marketplace";
import { StatusBadge, StatusDot } from "../Badges";
import { EllipsisVertical } from "~/assets/icons";
import { CTADropdown } from "~/routes/dashboard/wallet/spend/marketplace";

const columns = createColumnHelper<MPLog>();

export const nameColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {data.date} <br />
        {/* {getDateFormat(data.created_at).dateFormat} <br /> */}
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {data.time}
          {/* {getDateFormat(data.created_at).timeFormat} */}
        </span>
      </span>
    );
  },
  {
    id: "name",
    header: tableHeader("Account Name", "Nick Name"),
    cell: (row) => row.getValue(),
  },
);

export const accountNumber = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.convertAmount, {
          decimal: true,
        })}{" "}
      </span>
    );
  },
  {
    id: "account-number",
    header: tableHeader("Account Number"),
    cell: (row) => row.getValue(),
  },
);

export const bankName = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.exchangeRate, {
          decimal: true,
        })}{" "}
        <br />
      </span>
    );
  },
  {
    id: "bank-name",
    header: tableHeader("Bank Name"),
    cell: (row) => row.getValue(),
  },
);

export const accountType = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.exchangeRate, {
          decimal: true,
        })}{" "}
        <br />
      </span>
    );
  },
  {
    id: "account-type",
    header: tableHeader("Account Type"),
    cell: (row) => row.getValue(),
  },
);

export const countryCurrency = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.receivingAmount, {
          decimal: true,
        })}{" "}
        <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {data?.receivingCurrency}
        </span>
      </span>
    );
  },
  {
    id: "country-currency",
    header: tableHeader("Country", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const statusColumn = columns.accessor(
  (data) => {
    return <StatusBadge label={data.status} />;
  },
  {
    id: "status",
    header: tableHeader("Status"),
    cell: (row) => row.getValue(),
  },
);
export const actionColumn = columns.accessor(
  (data) => {
    return <CTADropdown handleDelete={() => {}} handleEdit={() => {}} />;
  },
  {
    id: "cat",
    header: tableHeader(""),
    cell: (row) => row.getValue(),
  },
);

function tableHeader(
  mainHeading: string,
  subHeading?: string,
): () => React.JSX.Element {
  return () => {
    return (
      <div className="flex flex-col pl-5">
        <span
          className={`text-sm font-bold leading-tight text-gray-600 ${
            !subHeading ? "py-5" : ""
          }`}
        >
          {mainHeading}
        </span>
        {!subHeading ? null : (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              marginTop: "2px",
            }}
            className="font-body font-normal text-gray-400"
          >
            {subHeading}
          </span>
        )}
      </div>
    );
  };
}
