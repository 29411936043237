import React from "react";
import { ChevronRight } from "lucide-react";
import { ClickableCard } from "~/components/ClickableCard";
import { WithdrawCtx } from "..";
import { getFlagFromRegionCode } from "~/libs/currency.helpers";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { DestinationCtx } from ".";
import { Button } from "~/@/components/ui/button";
import { CardsIcon, BankFundingIcon } from "~/assets/icons";
import {
  useGetTokens,
  useOptionsMetadata,
} from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { ApplicationTokenTypes, PickOneOptionType } from "~/libs/types.helper";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { CardLikeButtonSkeleton } from "../StepAmount";

const PaymentMethod = ({ currentMove, setCurrentMove }) => {
  const { updateState, destinationState } = useContext(DestinationCtx);
  const businessId = useGetBusinessId();

  const { data: appTokens, isLoading } = useGetTokens(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        type: "ACCOUNT_WIDGET",
        page: 1,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const linkedToken = useMemo(() => {
    return (
      appTokens?.data?.filter(
        (token: ApplicationTokenTypes) =>
          token?.application?.externalId === businessId &&
          token?.application?.metadata?.currency ===
            destinationState?.country?.currency,
      ) ?? []
    );
  }, [appTokens]);

  const { data: options, isLoading: optionsLoading } = useOptionsMetadata(
    {
      pathParams: {
        token: linkedToken[0]?.application?.token,
        currency: linkedToken[0]?.application?.metadata?.currency,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!linkedToken[0]?.application?.token,
    },
  );

  const optionsMetadata = React.useMemo(() => {
    return options?.data?.options ?? [];
  }, [options?.data]);

  const optionIcon = (option: string) => {
    switch (option) {
      case "Bank":
      case "Manual Bank":
        return <BankFundingIcon />;
      case "Card":
        return <CardsIcon />;
      default:
        return <BankFundingIcon />;
    }
  };

  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10">
      <div className="mb-5 px-2 text-left">
        <h2 className="py-5 text-xl font-normal text-muted-foreground">
          Select Payment Method
        </h2>
      </div>
      <SkeletonContent
        isLoading={isLoading || optionsLoading}
        count={3}
        Component={CardLikeButtonSkeleton}
      >
        <ScrollArea className={"flex max-h-[300px] w-full flex-col px-2"}>
          {optionsMetadata?.map((method: PickOneOptionType) => (
            <ClickableCard
              key={method?.label}
              className={
                "mb-3 flex w-full items-center justify-between px-4 py-5"
              }
              onClick={() => {
                updateState("method", method);
                updateState("tokendetails", linkedToken[0]);
                setCurrentMove(currentMove + 1);
              }}
            >
              <div className="flex items-center">
                <div className="mr-5 flex">{optionIcon(method.label)}</div>
                <div className="flex flex-col text-left">
                  <span className="text-[15px] font-semibold text-gray-600">
                    {method.label}
                  </span>
                  <span className="text-[15px] font-normal text-gray-500">
                    {method.title}
                  </span>
                </div>
              </div>

              <div className="text-[32px] font-semibold text-[#002C3D]">
                <ChevronRight size={"1.5rem"} className="text-gray-400" />
              </div>
            </ClickableCard>
          ))}
        </ScrollArea>
      </SkeletonContent>
      <div className="mt-5 flex items-center justify-between px-2">
        <Button
          size="lg"
          variant="outline"
          className="mt-5 w-[15%]"
          onClick={() => {
            setCurrentMove(currentMove - 1);
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PaymentMethod);
