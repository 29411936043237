import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BusinessAPICredentials, BusinessMetadata } from "~/general/interfaces";

interface Role {
  id: number;
  BusinessId: string;
  AccountId: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface AssociatedBusiness {
  id: string;
  slug: string;
  website: string;
  description: string | null;
  contactEmail: string;
  contactPhoneNumber: string | null;
  address: string;
  country: string;
  zipCode: string | null;
  businessType: string | null;
  registrationNumber: string;
  industry: string;
  claimed: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  assignedRole: {
    role: string;
    updatedAt: string; // ISO String
  };
}

export interface UserAccountI {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  confirmed: boolean;
  mfaEnabled: boolean;
  backupCodes: string[] | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  associatedBusinesses: AssociatedBusiness[];
}

interface initialStateProps {
  userDetails: UserAccountI;
  businessDetails: BusinessMetadata | null;
  credentials: BusinessAPICredentials | null;
}

const initialState: initialStateProps = {
  userDetails: null,
  businessDetails: null,
  credentials: null,
};

const userDetailsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    _setUserDetails(state, action: PayloadAction<UserAccountI>) {
      state.userDetails = action.payload;
    },
    _setBusinessDetails(state, action: PayloadAction<BusinessMetadata>) {
      state.businessDetails = action.payload;
    },
    _setCredentials(state, action: PayloadAction<BusinessAPICredentials>) {
      state.credentials = action.payload;
    },
  },
});

export default userDetailsSlice.reducer;

export const { _setUserDetails, _setBusinessDetails, _setCredentials } =
  userDetailsSlice.actions;
