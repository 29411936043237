import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { CardIcon, BankFundingIcon } from "~/assets/icons";
import { WalletHolderImpl, useWallet, useWallets } from "~/hooks/use-wallet";
import { setCurrency } from "~/contexts/currency";
import { WalletCurrencyDropdown } from "~/components/Menu";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import {
  formatNumber,
  getFiatCurrency,
  getFlagFromRegionCode,
  InputFormatNumber,
} from "~/libs/currency.helpers";
import { Button } from "~/@/components/ui/button";
import { ClickableCard } from "~/components/ClickableCard";
import { Skeleton } from "~/@/components/ui/skeleton";
import {
  CardBody,
  CardFooter,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { Input } from "~/@/components/ui/input";
import { BankIcon } from "~/assets/icons";
import { PayoutCtx } from ".";
import { FiatCurrencyI } from "~/constants/currencies";
import { ChevronRight } from "lucide-react";
import { RecipientAccountTypes } from "~/libs/types.helper";
import { FlatImage } from "./StepRecipient";

const schema = z.object({
  amount: z.string(),
  receiverAmount: z.string(),
});

const StepAmount = ({ completeStep, setCurrentStep, currentStep }) => {
  const { payoutState, updateState } = useContext(PayoutCtx);

  const walletId = payoutState?.recipient?.wallet?.id;
  const { data: wallet } = useWallet(walletId);
  const [amount, setAmount] = React.useState<string | number>("");
  const [mim, setmin] = React.useState<string | number>("0");
  const [error, setError] = React.useState<boolean>(false);
  const [receiverAmount, setReceiverAmount] = React.useState<string | number>(
    "",
  );
  const form = useForm<z.infer<typeof schema>>();

  const formatWalletOption = useMemo(() => {
    if (wallet.kind === "CORPORATE") {
      return {
        symbol: wallet?.currency === "NGN" ? "₦" : "$",
        label: wallet?.currency,
        flagUrl:
          wallet?.currency === "NGN"
            ? getFlagFromRegionCode("NG")
            : getFlagFromRegionCode("US"),
        code: wallet?.currency,
        name: wallet?.currency,
        decimal: 2,
        flagName: wallet?.currency === "NGN" ? "ngn-flag" : "usd-flag",
        balance: wallet?.balance,
      };
    }
  }, [wallet]);

  const destinationOptions = useMemo(() => {
    const destination = payoutState?.recipient;
    return {
      symbol: destination?.currency === "NGN" ? "₦" : "$",
      label: destination?.currency,
      flagUrl:
        destination?.currency === "NGN"
          ? getFlagFromRegionCode("NG")
          : getFlagFromRegionCode("US"),
      code: destination?.currency,
      name: destination?.currency,
      decimal: 2,
      flagName: destination?.currency === "NGN" ? "ngn-flag" : "usd-flag",
      balance: destination?.wallet?.balance,
    };
  }, [payoutState]);

  useEffect(() => {
    if (payoutState?.payoutAmount?.amount) {
      const numericValue = (
        Number(payoutState?.payoutAmount?.amount) * 100
      ).toString();
      form.setValue("amount", numericValue as string);
      form.setValue("receiverAmount", numericValue as string);
      setmin(numericValue);
      setAmount(InputFormatNumber(numericValue));
      setReceiverAmount(InputFormatNumber(numericValue));
    }
  }, [payoutState]);

  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    form.setValue("amount", numericValue as string);
    form.setValue("receiverAmount", numericValue as string);
    setmin(numericValue);
    setAmount(InputFormatNumber(numericValue));
    setReceiverAmount(InputFormatNumber(numericValue));
  };

  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10 pt-5">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl font-normal text-muted-foreground">Amount</h2>
        <div className="w-[30%]">
          <Select value="cash" disabled>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Wallet Type" defaultValue={"cash"} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="cash">Cash</SelectItem>
              <SelectItem value="crypto">Crypto</SelectItem>
              <SelectItem value="collectibles">Collectibles</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <form
        className="mt-[20px] flex flex-col text-left"
        onSubmit={form.handleSubmit(async (data) => {
          try {
            if (!data.amount) {
              return;
            }
            const parseAmount = Number(data.amount) / 100;
            updateState("payoutAmount", {
              amount: parseAmount,
              fee: 0,
              currency: formatWalletOption?.code,
              recipientCurrency: destinationOptions?.code,
            });
            setCurrentStep(currentStep + 1);
            completeStep(currentStep);
          } catch (err) {
            console.error(err);
          }
        })}
      >
        <Form {...form}>
          <div className="flex w-full flex-col gap-4">
            <FormField
              control={form.control}
              name={"amount"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className="text-xs font-normal">
                      You're sending
                    </FormLabel>
                    <FormControl>
                      <div className="flex w-full items-center ">
                        <div
                          className={`mr-3 flex h-20 cursor-default items-center gap-2 rounded border border-gray-800 px-5 pr-7 pt-1.5 ${
                            (amount &&
                              Number(amount) >
                                Number(formatWalletOption?.balance)) ||
                            (amount &&
                              Number(amount) <
                                Number(
                                  formatWalletOption?.code === "NGN"
                                    ? 100.0
                                    : 2.0,
                                ))
                              ? "border-red-500"
                              : ""
                          }
                          `}
                        >
                          <WalletCurrencyDropdown
                            currency={formatWalletOption as FiatCurrencyI}
                            setCurrency={setCurrency}
                            disabled={false}
                          />
                        </div>

                        <Input
                          className={`flex h-20 border-gray-800 px-3 text-right text-3xl font-normal text-gray-800 outline-none placeholder:text-3xl placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]
                            ${
                              (amount &&
                                Number(amount) >
                                  Number(formatWalletOption?.balance)) ||
                              (amount &&
                                Number(amount) <
                                  Number(
                                    formatWalletOption?.code === "NGN"
                                      ? 100.0
                                      : 2.0,
                                  ))
                                ? "focus-visible:ring-ring-red-500 border-red-500 focus-visible:ring-red-500"
                                : ""
                            }`}
                          {...field}
                          value={amount}
                          placeholder="0.00"
                          inputMode={"numeric"}
                          onChange={(evt) => handleChange(evt.target.value)}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                    <FormDescription>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <span className="mr-[6px] text-xs text-gray-500">
                            Wallet Balance
                          </span>
                          <button
                            type="button"
                            className={
                              "inline-flex cursor-pointer items-center text-[#0094FF]"
                            }
                            disabled={true}
                          >
                            <span className="mr-[6px] text-xs">
                              <span className="text-xs font-semibold">
                                {formatNumber(formatWalletOption?.balance, {
                                  decimal: true,
                                })}
                              </span>
                            </span>
                            <span className=" text-xs text-gray-500">
                              {formatWalletOption?.code}
                            </span>
                          </button>
                        </div>
                        <div className="flex items-center">
                          {formatWalletOption?.code === "NGN" && (
                            <span className="mr-[6px] text-xs text-gray-500">
                              {mim && Number(mim) / 100 > 100.0
                                ? "Payout Limit"
                                : " Minimum Payout"}
                            </span>
                          )}
                          {formatWalletOption?.code === "USD" && (
                            <span className="mr-[6px] text-xs text-gray-500">
                              {mim && Number(mim) / 100 > 2.0
                                ? "Payout Limit"
                                : " Minimum Payout"}
                            </span>
                          )}
                          <button
                            type={"button"}
                            className={
                              "inline-flex cursor-pointer items-center text-[#0094FF]"
                            }
                            disabled={true}
                          >
                            {formatWalletOption?.code === "NGN" && (
                              <span className="text-xs">
                                <span className="mr-[5px] text-xs font-semibold">
                                  {mim && Number(mim) / 100 > 100.0
                                    ? formatNumber(
                                        formatWalletOption?.balance,
                                        {
                                          decimal: true,
                                        },
                                      )
                                    : formatNumber(100, {
                                        decimal: true,
                                      })}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {formatWalletOption?.code}
                                </span>
                              </span>
                            )}

                            {formatWalletOption?.code === "USD" && (
                              <span className="text-xs">
                                <span className="mr-[5px] text-xs font-semibold">
                                  {mim && Number(mim) / 100 > 2.0
                                    ? formatNumber(
                                        formatWalletOption?.balance,
                                        {
                                          decimal: true,
                                        },
                                      )
                                    : formatNumber(2, {
                                        decimal: true,
                                      })}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {formatWalletOption?.code}
                                </span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </FormDescription>
                  </FormItem>
                );
              }}
            />
            <CardSeparator className="my-2 border-dashed" />

            {formatWalletOption?.code !== destinationOptions?.code && (
              <FormField
                control={form.control}
                name={"receiverAmount"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel className="text-xs font-normal">
                        You get
                      </FormLabel>
                      <FormControl>
                        <div className="flex w-full items-center ">
                          <div className="mr-3 flex h-20 cursor-default items-center gap-2 rounded border border-gray-800 px-5 pr-7 pt-1.5">
                            <WalletCurrencyDropdown
                              currency={
                                destinationOptions as unknown as FiatCurrencyI
                              }
                              setCurrency={setCurrency}
                              disabled={false}
                            />
                          </div>

                          <Input
                            className="flex h-20 border-gray-800 px-3 text-right text-3xl font-normal text-gray-400 outline-none placeholder:text-3xl placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]"
                            {...field}
                            value={receiverAmount}
                            placeholder="0.00"
                            inputMode={"numeric"}
                            disabled={true}
                            onChange={(evt) => handleChange(evt.target.value)}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                      <FormDescription>
                        <div className="flex items-center">
                          <span className="mr-[6px] text-xs text-gray-800">
                            Rates
                          </span>
                          <div className="inline-flex cursor-pointer items-center text-[#226835]">
                            <span className="mr-1 text-xs font-semibold">
                              {formatNumber(1, {
                                decimal: true,
                              })}
                            </span>
                            <span className="text-xs font-normal">
                              {formatWalletOption?.code}
                            </span>
                            <span className="ml-0.5 text-xs font-semibold">
                              {"/  "}
                              {formatNumber(1300, {
                                decimal: true,
                              })}
                            </span>
                            <span className="ml-1 text-xs font-normal">
                              {formatWalletOption?.code}
                            </span>
                          </div>
                        </div>
                      </FormDescription>
                    </FormItem>
                  );
                }}
              />
            )}

            <div className="mt-3">
              <CardTitle className="mb-2 text-lg font-normal text-muted-foreground">
                Recipient Details
              </CardTitle>

              <AccountCard
                account={payoutState?.recipient}
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
                completeStep={completeStep}
              />
            </div>
          </div>
        </Form>
        <div className="mt-5 flex items-center justify-between">
          <Button
            size="lg"
            variant="outline"
            className="mt-5 w-[15%]"
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            Back
          </Button>
          <Button
            size="lg"
            variant="default"
            type="submit"
            className="mt-5 w-[15%]"
            disabled={
              !amount ||
              Number(amount) > Number(formatWalletOption?.balance) ||
              Number(amount) <
                Number(formatWalletOption?.code === "NGN" ? 100.0 : 2.0)
            }
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(StepAmount);

export function CardLikeButtonSkeleton() {
  return (
    <ClickableCard className="my-2 w-full justify-between" disabled>
      <div className="flex basis-1/2 flex-col space-y-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-12" />
          <Skeleton className="h-4 basis-7/12 rounded" />
        </div>
        <Skeleton className="h-3 w-full rounded" />
      </div>

      <Skeleton className="h-7 w-24 basis-4/12 rounded" />
    </ClickableCard>
  );
}

export function AccountCard({
  account,
  setCurrentStep,
  currentStep,
  completeStep,
}: {
  account: RecipientAccountTypes;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  currentStep?: number;
  completeStep?: (value: number) => void;
}) {
  const currency = getFiatCurrency(account?.currency);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setCurrentStep(currentStep - 1);
    }
  };

  const optionIcon = (option: string) => {
    switch (option.toLowerCase()) {
      case "bank":
      case "manual bank":
        return <BankFundingIcon color="#CCD1D6" w="35" h="34" />;
      case "card":
        return <CardIcon color="#CCD1D6" w="35" h="34" />;
      default:
        return <BankFundingIcon color="#CCD1D6" w="35" h="34" />;
    }
  };

  return (
    <ClickableCard
      className={
        "flex w-full cursor-default items-center justify-between px-4 py-4 hover:border-gray-100"
      }
    >
      <div className="flex items-center">
        <div className="mr-5 flex">
          {optionIcon(account?.optionType)}
          <div className="-ml-5 flex items-end">
            {!!currency?.flagUrl && (
              <FlatImage
                alt={currency?.name}
                src={currency?.flagUrl}
                className="aspect-[20/13.3] w-[20px] object-contain"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col text-left">
          <span className="text-[18px] font-semibold text-gray-600">
            {account?.account?.label}
          </span>
          <div className="text-sm text-neutral-400">
            <span className="capitalize">{account?.optionType} - </span>
            <span>{`****${account?.account?.lastFourDigits}`}</span>
          </div>
        </div>
      </div>

      <div
        className="cursor-pointer rounded-full bg-gray-50 px-2.5 pb-1 pt-px text-sm font-semibold text-[#0094FF] "
        onKeyDown={(e) => handleKeyDown(e)}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
      >
        <span className="">Change</span>
      </div>
    </ClickableCard>
  );
}
