import {
  Link,
  createFileRoute,
  useParams,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { usePlaidLink } from "react-plaid-link";
import { Info } from "lucide-react";
import React from "react";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { Button } from "~/@/components/ui/button";
import { CardLikeButtonSkeleton } from "~/components/Buttons/CardLikeButtonSkeleton";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { empty_state_no_cards } from "~/assets";
import { SectionHeading } from "~/components/atoms/heading";
import { NumberBadge } from "~/components/Badges";
import { Divider } from "~/components/Divider";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import { TabButton } from "~/components/Tabs";
import { DefaultInputField, PhoneNumberInput } from "~/components/Inputs";
import { WalletTransactionModal } from "~/components/TransactionDetails";
import {
  CardFooter,
  CardBackButton,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import * as Balance from "~/components/organisms/BalanceCard";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import { AppTable } from "~/components/table/table";
import {
  amountColumn,
  createdAtColumn,
  statusDotColumn,
  receiverColumn,
  initiatorColumn,
  senderColumn,
  statusColumn,
  transactionTypeColumn,
} from "~/components/table/transaction";
import { BusinessProvider } from "~/contexts/corporate-wallet";
import { setCurrency } from "~/contexts/currency";
import {
  useBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/@/components/ui/tooltip";
import * as Address from "~/components/Inputs/CountryAddressFields";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import { Textarea } from "~/@/components/ui/textarea";
import { Checkbox } from "~/@/components/ui/checkbox";
import { useForm } from "react-hook-form";
import { ChipSelector } from "~/components/Inputs";
import { notify, notifyApiError } from "~/components/Toast";
import { useModal, useModalHandle } from "~/hooks/use-modal";
import { useWallet, WalletHolderImpl } from "~/hooks/use-wallet";
import { getSafeFiatCurrency } from "~/libs/currency.helpers";
import { WalletImpl } from "~/libs/factories/wallet-factory";
import { Alert } from "~/components/atoms/Alert";
import useSearch, { useCountries } from "~/hooks";
import { Download } from "../../overview";
import { CaretDown, CopyIcon, BankIcon, BankFundingIcon } from "~/assets/icons";
import {
  useRetrieveVirtualCollectionAccount,
  useCreateIavToken,
  useConsumeIavToken,
  useUpdateBusinessKycNgn,
  useCreateVirtualCollectionAccount,
} from "~/api/codegen/liquidityComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { Input } from "~/@/components/ui/input";
import { CountryI } from "~/general/interfaces";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { cons } from "effect/List";
import { IavToken } from "~/libs/types.helper";
import TransactionsFilters from "~/components/TransactionsFilters";

export const Route = createFileRoute("/dashboard/wallet/spend/subwallets/$id")({
  component: Page,
});

function Page() {
  return (
    <BusinessProvider>
      <section className="flex flex-col gap-2 py-8">
        <BalanceOverview />
        <Divider className="h-[0px] w-[100%] border border-zinc-200 opacity-50" />
        <QuickActions />
        <CollectionAccount />
        <Divider className="h-[0px] w-[100%] border border-zinc-200 opacity-50" />
        <WalletTransactions />
      </section>
    </BusinessProvider>
  );
}

function BalanceOverview() {
  const { id } = useParams({ strict: false });
  const businessId = useGetBusinessId();
  const { isLoading, data: wallet } = useWallet(id);
  const balance = WalletImpl.balance(wallet);
  const currency = getSafeFiatCurrency(
    wallet.kind === "CORPORATE" ? wallet.currency : "--",
  );

  const { data } = useRetrieveVirtualCollectionAccount(
    {
      pathParams: {
        businessId,
        walletId: id,
      },
    },
    {
      enabled: !!id,
    },
  );

  const collectionAccount = useMemo(() => {
    return data?.data?.collectionAccount ?? {};
  }, [data?.data]);

  return (
    <Balance.Card>
      <Balance.Header headingText="Total Balance" />

      <Balance.Content>
        <div>
          <Balance.CurrencySwitcher
            readOnly={true}
            currency={currency}
            setCurrency={setCurrency}
          />
          {/* <span
            className={
              "mt-2 flex transform select-none items-center gap-2 rounded-full bg-[#F4F5F6] px-4 py-1 text-sm filter transition-all duration-200 hover:bg-[#ECEDEE]"
            }
          >
            <BankIcon /> <span>****290</span> <ChevronRight size={16} />
          </span> */}
        </div>
        <Balance.Balance
          size="lg"
          balance={balance}
          isLoading={isLoading}
          currency={currency}
        />
      </Balance.Content>

      {Object.keys(collectionAccount).length === 0 && (
        <Alert type={"info"} className="mx-auto mt-5 w-[50%]">
          Your wallet limit is <span className="font-semibold">$2,000.00.</span>
          {"  "}
          Create a collection account to store more funds when you pay{" "}
        </Alert>
      )}
    </Balance.Card>
  );
}

function QuickActions() {
  const { id } = useParams({ strict: false });
  const businessId = useGetBusinessId();

  const mutation = useConsumeIavToken();
  const { data: wallet } = useWallet(id);
  const [token, setToken] = useState("");
  const [tokenDetails, setTokenDetails] = useState(null);
  const currency = getSafeFiatCurrency(
    wallet.kind === "CORPORATE" ? wallet.currency : "--",
  );

  const { data, refetch, isLoading } = useCreateIavToken(
    {
      pathParams: {
        businessId,
        walletId: id,
      },
    },
    {
      enabled: !!id && currency?.label === "USD",
    },
  );

  const onSuccess = async (metadata) => {
    // send public_token to server
    console.log(metadata);
    await mutation.mutateAsync({
      pathParams: { businessId, walletId: id },
      body: {
        processorToken: metadata,
        accountId: tokenDetails?.accountId,
      },
    });
  };

  const onExit = (err, metadata) => {
    console.log(err, metadata);
    notify("error", err?.error_message);
  };

  useEffect(() => {
    if (!token) {
      setToken(data?.data?.token);
      setTokenDetails(data?.data);
    }
  }, [data?.data, isLoading]);

  const config = {
    token: `${token}`,
    onSuccess,
    onExit,
  };
  const { open: openPlaid, ready, error } = usePlaidLink(config);

  const handlePaid = async () => {
    await refetch();
    openPlaid();
  };

  return (
    <>
      <SectionHeading>
        <span className="py-3 text-gray-500">Quick Actions</span>
      </SectionHeading>
      <Balance.Card>
        <div className="flex items-center justify-between gap-x-4 py-3">
          <Link to="/convert" className="w-full text-gray-800" disabled={true}>
            <Button className="w-full items-center" variant="outline" disabled>
              <span className="px-2">Convert</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="">
                    <Info size="0.9rem" stroke="#4084B5" />
                  </TooltipTrigger>
                  <TooltipContent className="w-48 text-wrap text-left">
                    <p>
                      Convert currencies effortlessly for seamless payments.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Button>
          </Link>
          <Button
            className="w-full cursor-not-allowed bg-[#FAFAFA] text-[#C1C1C1]"
            variant="outline"
            disabled
          >
            Payout
          </Button>

          <Link className="w-full  bg-[#FAFAFA] text-[#C1C1C1]">
            <Button className="w-full" variant="outline" disabled>
              Withdraw
            </Button>
          </Link>

          {currency?.label === "USD" && (
            <Button
              className="w-full"
              variant="outline"
              disabled={!data?.data?.token || !ready}
              onClick={() => handlePaid()}
            >
              Add Verified Bank
            </Button>
          )}
        </div>
      </Balance.Card>
    </>
  );
}

function CollectionAccount() {
  const { id } = useParams({ strict: false });
  const modal = useModal();
  const { data: wallet, isLoading: loading } = useWallet(id);
  const businessId = useGetBusinessId();

  const walletHolder = useMemo(() => {
    if (wallet?.kind === "CORPORATE") {
      return wallet;
    }
  }, [wallet, loading]);

  const mutation = useCreateVirtualCollectionAccount();

  const { data, isLoading, refetch } = useRetrieveVirtualCollectionAccount(
    {
      pathParams: {
        businessId,
        walletId: id,
      },
    },
    {
      enabled: !!id,
    },
  );

  const updateKyc = async () => {
    await mutation.mutateAsync({
      pathParams: { businessId, walletId: id },
      body: {},
    });
    await refetch();
    modal.hide("invite_user");
  };

  const collectionAccount = useMemo(() => {
    return data?.data?.collectionAccount ?? {};
  }, [data?.data, isLoading]);

  return (
    <>
      <SectionHeading>
        <span className="py-3 text-gray-500">Collection Account</span>
      </SectionHeading>
      <EmptyStateRoot
        isEmpty={Object.keys(collectionAccount).length === 0 || isLoading}
      >
        <EmptyStateContent>
          <div className="flex flex-col items-center">
            <img
              src={empty_state_no_cards}
              alt="Nope!"
              className="aspect-[3/2] max-w-[30ch] object-contain"
            />

            <div className="flex flex-col items-center gap-2">
              <EmptyStateDescription className="text-gray-200">
                Collection account details will appear here
              </EmptyStateDescription>

              {walletHolder?.currency === "USD" && !isLoading ? (
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={() => {
                    // updateKyc();
                    modal.show("invite_user");
                  }}
                >
                  Setup up collection account
                </Button>
              ) : walletHolder?.currency === "NGN" && !isLoading ? (
                <Button type="button" variant="link" size="sm">
                  <DialogRemoteTrigger
                    modalId={"add_web_hook"}
                    params={{
                      mode: "create",
                    }}
                    className=""
                  >
                    <span className="pr-2">Setup up collection account</span>
                  </DialogRemoteTrigger>
                </Button>
              ) : null}
            </div>
          </div>
        </EmptyStateContent>

        <EmptyStateConceal>
          <Balance.Card>
            <SkeletonContent
              isLoading={isLoading}
              Component={CardLikeButtonSkeleton}
            >
              <div className="flex items-center justify-between px-3">
                <SectionHeading className="w-full">
                  <BankFundingIcon />
                  <span className="font-semibold text-gray-800">
                    {collectionAccount?.currency} VIRTUAL ACCOUNT
                  </span>
                </SectionHeading>
                {walletHolder.balance === 0 && (
                  <Alert type={"info"} className="w-full">
                    {collectionAccount?.currency === "USD"
                      ? " Activate wallet by paying into this bank account."
                      : "Fund CentryOS NGN wallet by paying into this bank account."}
                  </Alert>
                )}
              </div>
              <Divider className="my-5" />
              <div className="flex w-full gap-28 px-3 pb-10">
                <div className="w-full">
                  {collectionAccount?.accountName && (
                    <>
                      <div className="w-full">
                        <p className="font-light text-gray-800">Account Name</p>
                        <div className="flex items-center justify-between">
                          <p className="font-medium text-gray-800">
                            {collectionAccount?.accountName}
                          </p>
                          {collectionAccount?.accountName && (
                            <CopyToClipboard
                              text={`${collectionAccount?.accountName}`}
                              onCopy={() => {
                                notify(
                                  "success",
                                  `${collectionAccount?.accountName} copied`,
                                );
                              }}
                            >
                              <button type="button" className={""}>
                                <CopyIcon h="16" w="16" color="#4084B5" />
                              </button>
                            </CopyToClipboard>
                          )}
                        </div>
                      </div>
                      <Divider className="my-5" />
                    </>
                  )}
                  {collectionAccount?.bankName && (
                    <div className="w-full">
                      <p className="font-light text-gray-800">Bank Name</p>
                      <div className="flex items-center justify-between">
                        <p className="font-medium text-gray-800">
                          {collectionAccount?.bankName}
                        </p>
                        {collectionAccount?.bankName && (
                          <CopyToClipboard
                            text={`${collectionAccount?.bankName}`}
                            onCopy={() => {
                              notify(
                                "success",
                                `${collectionAccount?.bankName} copied`,
                              );
                            }}
                          >
                            <button type="button" className={""}>
                              <CopyIcon h="16" w="16" color="#4084B5" />
                            </button>
                          </CopyToClipboard>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full">
                  {collectionAccount?.accountNumber && (
                    <>
                      <div className="w-full">
                        <p className="font-light text-gray-800">Account/No</p>
                        <div className="flex items-center justify-between">
                          <p className="font-medium text-gray-800">
                            {" "}
                            {collectionAccount?.accountNumber}
                          </p>
                          {collectionAccount?.accountNumber && (
                            <CopyToClipboard
                              text={`${collectionAccount?.accountNumber}`}
                              onCopy={() => {
                                notify(
                                  "success",
                                  `${collectionAccount?.accountNumber} copied`,
                                );
                              }}
                            >
                              <button type="button" className={""}>
                                <CopyIcon h="16" w="16" color="#4084B5" />
                              </button>
                            </CopyToClipboard>
                          )}
                        </div>
                      </div>
                      <Divider className="my-5" />
                    </>
                  )}
                  {collectionAccount?.routing &&
                    Object.keys(collectionAccount?.routing).length !== 0 && (
                      <div className="w-full">
                        <p className="font-light text-gray-800">Routing/No</p>
                        <div className="flex items-center justify-between">
                          <ul className="w-full">
                            {Object.keys(collectionAccount?.routing)?.map(
                              (key: string, i: number) => (
                                <li key={`${i}-${key}`} className="py-2">
                                  {collectionAccount?.routing?.[key]
                                    ?.routing && (
                                    <div className="flex items-center justify-between font-medium text-gray-800">
                                      <div className="flex">
                                        <p className="font-medium text-gray-800">
                                          {key}:
                                        </p>
                                        <p className="px-5 font-medium text-gray-800">
                                          {
                                            collectionAccount?.routing?.[key]
                                              ?.routing
                                          }
                                        </p>
                                      </div>
                                      <CopyToClipboard
                                        text={`${collectionAccount?.routing?.[key]?.routing}`}
                                        onCopy={() => {
                                          notify("success", `${key} copied`);
                                        }}
                                      >
                                        <button type="button" className={""}>
                                          <CopyIcon
                                            h="16"
                                            w="16"
                                            color="#4084B5"
                                          />
                                        </button>
                                      </CopyToClipboard>
                                    </div>
                                  )}
                                </li>
                              ),
                            )}
                          </ul>
                          <p className="font-medium text-gray-800">
                            {" "}
                            {/* {collectionAccount?.routing} */}
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </SkeletonContent>
          </Balance.Card>
        </EmptyStateConceal>
      </EmptyStateRoot>
      <CreateCollectionAccount updateKyc={updateKyc} />
      <CollectionAccountDetails />
    </>
  );
}

function WalletTransactions() {
  const { id } = useParams({ strict: false });
  const businessId = useGetBusinessId();
  const { data: countries } = useCountries();
  const modal = useModal();
  const [currentTab, setCurrentTab] = useState(undefined);
  const [page, setPage] = useState<number>(1);
  const [open, setOpen] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [currency, setCurrency] = React.useState<CountryI>(
    countries.filter((val: CountryI) => val.iso2 === "US")[0],
  );
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [transactionMethod, setTransactionMethod] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [amount, setAmount] = React.useState<string>("");
  const [amountRange, setAmountRange] = React.useState<string>("");
  const [showFilter, setShowFilter] = React.useState<boolean>(false);

  const clearFilter = () => {
    setOpen(false);
    setShowFilter(false);
    setTransactionMethod("");
    setTransactionStatus("");
    setAmount("");
    setAmountRange("");
    setFromDate("");
    setToDate("");
    setCurrency(countries.filter((val: CountryI) => val.iso2 === "US")[0]);
    setSelectedCurrency("");
    refetch();
  };

  const handleFilter = () => {
    setOpen(false);
    setShowFilter(true);
  };

  const columns = useRef([
    statusDotColumn,
    createdAtColumn,
    senderColumn,
    receiverColumn,
    initiatorColumn,
    transactionTypeColumn,
    amountColumn,
    statusColumn,
  ]).current;

  const filteredItems = useMemo(
    () => [
      {
        title: "StartDate",
        value: fromDate ? format(fromDate, "dd/MM/yy") : "",
      },
      {
        title: "EndDate",
        value: toDate ? format(toDate, "dd/MM/yy") : "",
      },
      { title: "Method", value: transactionMethod ?? "" },
      { title: "Status", value: transactionStatus ?? "" },
      { title: "Currency", value: "" },
      { title: "Amt", value: amount ? `${amountRange}${amount}` : "" },
    ],
    [transactionMethod, fromDate, toDate, transactionStatus, currency, amount],
  );

  const filteredTab = React.useMemo(() => {
    let tab = "";

    if (currentTab === "all") {
      tab = "";
    }

    if (currentTab === "funding") {
      tab = "funding";
    }
    if (currentTab === "payout") {
      tab = "payout";
    }
    if (currentTab === "earnings") {
      tab = "earnings";
    }
    if (currentTab === "withdrawal") {
      tab = "withdrawal";
    }

    return tab;
  }, [currentTab]);

  const handleCurrencyChange = (newCurrency: CountryI) => {
    if (currency !== newCurrency) {
      setSelectedCurrency(newCurrency.currency);
    }
  };

  const {
    isLoading,
    data: pagination,
    refetch,
  } = useBusinessTransactions(
    {
      businessId,
      walletId: id,
      page: page,
      currency: "",
      amount:
        open === false && showFilter && amount && amountRange
          ? `${amountRange}${amount}`
          : "",
      startDate:
        open === false && showFilter && fromDate
          ? `${fromDate?.split("/")?.reverse()?.join("-")}`
          : "",
      endDate:
        open === false && showFilter && toDate
          ? `${toDate?.split("/")?.reverse()?.join("-")}`
          : "",
      flowType:
        (open === false && showFilter && transactionMethod
          ? transactionMethod !== "all"
            ? transactionMethod.toUpperCase()
            : ""
          : "") ||
        (open === false && filteredTab ? filteredTab.toUpperCase() : undefined),
      status:
        open === false && showFilter && transactionStatus
          ? transactionStatus
          : "",
    },
    {
      enabled: !!id,
    },
  );

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(pagination.data, "flow_type", "method");

  const tabs = [
    { label: "All", value: "all", isActive: true },
    { label: "Funding", value: "funding", isActive: true },
    { label: "Payout", value: "payout", isActive: true },
    { label: "Withdrawal", value: "withdrawal", isActive: true },
    { label: "Earnings", value: "earnings", isActive: true },
  ];

  return (
    <Tabs defaultValue={"all"} className="">
      <SectionHeading>
        <span className="py-3 pr-3 text-gray-500">History</span>
        <NumberBadge label="pending" count={pagination.data.length ?? 0} />
      </SectionHeading>

      <CardRoot>
        <div className={"flex justify-between px-4 pb-2 pt-5"}>
          <TabsList className={"gap-x-5 bg-transparent"}>
            {tabs.map((e) => (
              <TabsTrigger
                key={e.label}
                value={e.value}
                disabled={!e.isActive}
                onClick={() => setCurrentTab(e.value)}
                asChild
              >
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>

          <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
            {showFilter && (
              <div className="-mr-3 flex pb-1 text-sm font-semibold text-[#3BB75E]">
                <h1>Filtered By:</h1>
                <p className="flex pl-1">
                  {filteredItems?.map((item) => (
                    <p key={`${item.title}-${item.value}`}>
                      {item.value && <p>{`${item.title}(${item.value}),`}</p>}
                    </p>
                  ))}
                </p>
              </div>
            )}
            <TransactionsFilters
              isHistory={pagination.data.length === 0}
              open={open}
              setOpen={setOpen}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              clearFilter={clearFilter}
              handleFilter={handleFilter}
              transactionMethod={transactionMethod}
              setTransactionMethod={setTransactionMethod}
              transactionStatus={transactionStatus}
              setTransactionStatus={setTransactionStatus}
              amount={amount}
              setAmount={setAmount}
              amountRange={amountRange}
              setAmountRange={setAmountRange}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              currency={currency}
              setCurrency={setCurrency}
              currentTab={currentTab}
              handleCurrencyChange={handleCurrencyChange}
            />
            {/* <div className="">
              <Download />
            </div> */}
            <DefaultInputField
              placeholder="Search"
              value={searchTerm}
              setValue={handleSearchChange}
              disabled={pagination.data.length === 0}
            />
          </div>
        </div>

        <Divider className="my-[5px]" />

        <EmptyStateOverlay
          isEmpty={pagination.data.length === 0 && !isLoading}
          content={
            <EmptyStateDescription>
              Your wallet logs will appear here
            </EmptyStateDescription>
          }
        >
          <ScrollArea className={"min-h-[400px] w-full"}>
            <TabsContent value={"all"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", pagination.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"funding"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", pagination.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"payout"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", pagination.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"withdrawal"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", pagination.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"earnings"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", pagination.data[row.index]);
                }}
              />
            </TabsContent>
          </ScrollArea>
        </EmptyStateOverlay>

        {pagination.meta.total !== 0 && (
          <CardFooter>
            <TablePagination
              data={{
                page: pagination.meta.page,
                pageCount: pagination.meta.pageCount,
                pageSize: pagination.meta.pageSize,
                total: pagination.meta.total,
                setPage: setPage,
              }}
            />
          </CardFooter>
        )}

        <WalletTransactionModal />
      </CardRoot>
    </Tabs>
  );
}

function CreateCollectionAccount({ updateKyc }: { updateKyc: () => void }) {
  const modal = useModal();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <DialogRemote
      id="invite_user"
      onOpenChange={(open) => {
        if (!open) {
          modal.hide("invite_user");
        }
      }}
    >
      <DialogContent showClose className="max-w-lg px-5">
        <DialogHeader className="space-y-0 px-5">
          <DialogTitle className="flex items-center justify-center">
            <SectionHeading>
              <span className="text-gray-600">Collection Account</span>
            </SectionHeading>
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <CardSeparator className="-mx-5" />
        <div className="py-5">
          <p className="text-center text-gray-600">
            Create USD virtual account to manage your funds.
          </p>
        </div>
        <CardSeparator className="-mx-5" />
        <div className="flex w-full items-center justify-between px-2">
          <Button
            type={"button"}
            variant="outline"
            size={"lg"}
            onClick={() => {
              modal.hide("invite_user");
            }}
          >
            Cancel
          </Button>
          <Button
            type={"button"}
            size={"lg"}
            className=""
            onClick={async () => {
              setLoading(true);
              await updateKyc();
              setLoading(false);
            }}
          >
            <LoadingButtonContent loading={loading}>
              Create
            </LoadingButtonContent>
          </Button>
        </div>
        {/* <AccountValidation /> */}
      </DialogContent>
    </DialogRemote>
  );
}

function CollectionAccountDetails() {
  const modal = useModalHandle("add_web_hook");
  const router = useRouter();

  return (
    <DialogRemote id="add_web_hook" callback={() => {}}>
      <DialogContent showClose className="max-w-xl px-5">
        <DialogHeader className="space-y-0 px-5">
          <DialogTitle className="flex items-center justify-between">
            {/* <CardBackButton
              onClick={() => {
                router.history.back();
                modal.hide();
              }}
            /> */}
            <SectionHeading>
              <span className="text-gray-800">Complete Verification</span>
            </SectionHeading>
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <CardSeparator className="-mx-5" />
        <AccountValidation />
      </DialogContent>
    </DialogRemote>
  );
}

const schema = z.object({
  bvn: z.string(),
  dob: z.string(),
  phoneNumber: z.string(),
  address: z.string(),
  state: z.string(),
  city: z.string(),
  zipCode: z.string(),
  selectedCountry: z.string(),
});

function AccountValidation() {
  const { id } = useParams({ strict: false });
  const businessId = useGetBusinessId();
  const mutation = useUpdateBusinessKycNgn();

  const { refetch } = useRetrieveVirtualCollectionAccount(
    {
      pathParams: {
        businessId,
        walletId: id,
      },
    },
    {
      enabled: !!id,
    },
  );
  const [country, setCountry] = React.useState<CountryI>();
  const modal = useModalHandle("add_web_hook");
  const form = useForm<z.infer<typeof schema>>();

  return (
    <>
      <form
        className="mx-auto flex w-full flex-col"
        onSubmit={form.handleSubmit(async (data) => {
          if (!country) {
            notify("error", "Select a country phone code");
            return;
          }
          await mutation.mutateAsync({
            pathParams: { businessId, tier: 1 },
            body: {
              bvn: data?.bvn,
              dateOfBirth: data?.dob,
              phone: {
                countryCode: `+${country?.phone_code}`,
                number: data?.phoneNumber,
              },
              address: {
                street: data?.address,
                city: data?.city,
                state: data?.state,
                country: data?.selectedCountry,
                postalCode: data?.zipCode,
              },
            },
          });
          await refetch();
          modal.hide();
        })}
      >
        <Form {...form}>
          <ScrollArea className={"flex max-h-[35rem] w-full flex-col"}>
            <div className="mt-[0px] flex w-full flex-col gap-4 px-5">
              <FormField
                control={form.control}
                name={"bvn"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>BVN</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={"dob"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Date of birth</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="07-27-1980" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={"phoneNumber"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Phone number</FormLabel>
                      <FormControl>
                        <PhoneNumberInput
                          // label="Phone number"
                          value={field.value}
                          country={country}
                          setCountry={(value) => {
                            setCountry(value);
                          }}
                          setValue={(value) => {
                            form.setValue(field.name, value as string);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                name={"address"}
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Address" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <Address.Root defaultValue={form.watch("selectedCountry")}>
                <FormField
                  name={"selectedCountry"}
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Country</FormLabel>
                        <FormControl>
                          <Address.CountryField {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />

                <FormField
                  name={"state"}
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>State</FormLabel>
                        <FormControl>
                          <Address.StateField {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />

                <FormField
                  name={"city"}
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>City</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Enter city" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </Address.Root>
              <FormField
                name={"zipCode"}
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Postal code</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Zip code" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </ScrollArea>
          <CardSeparator className="mb-5 mt-5" />
          <div className="flex w-full items-center justify-between px-5">
            <Button
              type={"reset"}
              variant="outline"
              size={"lg"}
              onClick={() => {
                modal.hide();
              }}
            >
              Cancel
            </Button>
            <Button
              type={"submit"}
              size={"lg"}
              className=""
              disabled={!form.formState.isDirty || !form.formState.isValid}
            >
              <LoadingButtonContent loading={false}>
                Submit
              </LoadingButtonContent>
            </Button>
          </div>
        </Form>
      </form>
    </>
  );
}
