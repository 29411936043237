export function AlertTriangleIcon() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.65642 1.13736C7.5916 1.02001 7.49651 0.922185 7.38105 0.854059C7.26559 0.785933 7.13398 0.75 6.99992 0.75C6.86586 0.75 6.73425 0.785933 6.61879 0.854059C6.50333 0.922185 6.40824 1.02001 6.34342 1.13736L1.46842 10.1374C1.40538 10.2515 1.37321 10.3802 1.37508 10.5106C1.37695 10.641 1.41279 10.7687 1.47908 10.881C1.54537 10.9934 1.6398 11.0865 1.75306 11.1511C1.86632 11.2158 1.99449 11.2498 2.12492 11.2499H11.8749C12.0054 11.2498 12.1335 11.2158 12.2468 11.1511C12.36 11.0865 12.4545 10.9934 12.5208 10.881C12.5871 10.7687 12.6229 10.641 12.6248 10.5106C12.6266 10.3802 12.5945 10.2515 12.5314 10.1374L7.65642 1.13736Z"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9C7.04973 9 7.09742 9.01975 7.13258 9.05492C7.16775 9.09008 7.1875 9.13777 7.1875 9.1875"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.8125 9.1875C6.8125 9.13777 6.83225 9.09008 6.86742 9.05492C6.90258 9.01975 6.95027 9 7 9"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.375C6.95027 9.375 6.90258 9.35525 6.86742 9.32008C6.83225 9.28492 6.8125 9.23723 6.8125 9.1875"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1875 9.1875C7.1875 9.23723 7.16775 9.28492 7.13258 9.32008C7.09742 9.35525 7.04973 9.375 7 9.375"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.5V4.125"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
