import { GlobalRevenueStateI, _updateGlobalRevenueStates } from ".";
import { useAppDispatch, useAppSelector } from "../hooks";

// hook to easily interact with products state
export const useRevenueStates = () => {
  const dispatch = useAppDispatch();

  const updateGlobalRevenueStates = (payload: GlobalRevenueStateI) =>
    dispatch(_updateGlobalRevenueStates(payload));

  const revenueStates = useAppSelector((state) => state.revenue);

  return { revenueStates, updateGlobalRevenueStates };
};
