import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StreamDetails } from "~/general/interfaces";

export interface GlobalRevenueStateI {
  revenue_streams?: StreamDetails[]; // React.JSX.Element[]
}

const initialState: GlobalRevenueStateI = {
  revenue_streams: [],
};

const revenueSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    _updateGlobalRevenueStates(
      state,
      action: PayloadAction<GlobalRevenueStateI>,
    ) {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
  },
});

export default revenueSlice.reducer;

export const { _updateGlobalRevenueStates } = revenueSlice.actions;
