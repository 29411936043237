import { useState } from "react";
import Account from "./Account";
import Password from "./Password";

import { useNavigate } from "@tanstack/react-router";
import { LinksFooter } from "~/components/Footer";
import { InitalScaleHelmet } from "~/components/Helmet";
import { AuthScreenNav } from "~/components/Navbars";

export function SuperAdminSignup() {
  const navigate = useNavigate();
  const [currentState, updateCurrentState] = useState(1);

  function prev() {
    if (currentState === 1) {
      return navigate({ to: "/signin" });
    }
    updateCurrentState(currentState - 1);
  }

  function next() {
    if (currentState === 3) {
      return;
    }
    updateCurrentState(currentState + 1);
  }

  return (
    <div>
      <InitalScaleHelmet />

      <AuthScreenNav />

      <main className="flex flex-col items-center px-4 py-12">
        {currentState === 1 && (
          <Account prev={() => prev()} next={() => next()} />
        )}
        {currentState === 2 && (
          <Password prev={() => prev()} next={() => next()} />
        )}
      </main>

      <LinksFooter />
    </div>
  );
}
