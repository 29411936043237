import { useState } from "react";
import Modal from ".";
import { WalletLog } from "../DashboardComponents/wallet-logs";
import { useModals } from "../Redux/state/modals/hooks";

import { ArrowLeft } from "~/assets/icons/ArrowLeft";

export function RevenueLogModal() {
  const { globalModalState, updateGlobalModalState } = useModals();
  const [searchVal, setSearchVal] = useState("");

  return (
    <Modal
      modal={globalModalState.revenueLogModal}
      disableBackdropClose
      renderHeader={
        <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type={"button"}
            onClick={() => updateGlobalModalState({ revenueLogModal: false })}
            className="flex cursor-pointer items-center"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
          </button>
          <span
            className={
              "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
            }
          />
        </div>
      }
    >
      <div className="w-[1000px] max-w-[85vw] rounded bg-[white]">
        <div className="p-[20px]">
          <WalletLog title="Transaction History" />
        </div>
      </div>
    </Modal>
  );
}
