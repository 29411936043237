import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";

export const SectionHeading = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function SectionHeading(props, ref) {
  const { children, className, ...PROPS } = props;
  return (
    <div
      ref={ref}
      {...PROPS}
      className={cn(
        "flex items-center gap-3 text-base text-gray-700",
        className,
      )}
    >
      {children}
    </div>
  );
});
