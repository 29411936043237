export function ExchangeArrow({
  w,
  h,
  color,
}: {
  w?: string;
  h?: string;
  color?: string;
}) {
  return (
    <svg
      width={w || "25"}
      height={h || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13973_155654)">
        <path
          d="M17.5 10H3.5L7.5 6"
          stroke={color || "#008EF4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 14H21.5L17.5 18"
          stroke={color || "#008EF4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13973_155654">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
