import { data } from "autoprefixer";
import { capitalize } from "lodash";
import { is, take } from "ramda";
import {
  BankDetails,
  RootTxLog,
  TransactionKV,
  TxWalletRecord,
} from "~/general/transaction";
import { formatNumber } from "~/libs/currency.helpers";
import { safeNum, safeStr } from "~/libs/data.helper";
import { shortenId } from "~/libs/string.helper";
import { safeParseISO } from "../date.helpers";

type CellMux = {
  main: string;
  sub: string;
};

type CellMux2 = {
  transactionId: string;
  owner: {
    id: string;
    email: string;
    externalIdentifier: string;
    entityType: string;
  };
  entry: string;
  walletId: string;
  balance: string;
  previousBalance: string;
  currency: string;
};

export interface BisLog {
  linkedTransactionId: string;
  status: string;
  flowType: string;
  timestamp: Date;
  method: string;
  source: CellMux2;
  destination: CellMux2;
  amount: string;
  currency: string;
  fees: string;
  total: string;
}
export interface TrxLog {
  kind: "Transaction";
  $original: unknown;
  status: "SUCCESS";
  created_at: Date;
  method: "BANK_TRANSFER" | "WALLET" | "UNKNOWN";
  method_for_human: string;
  flow_type: string;
  source: CellMux;
  destination: CellMux;
  trx_type: string;
  externalTransactionId: string;
  summary: string;
  fees: FiatValue;
  amount: FiatValue;
  total: FiatValue;
}

export function TrxFactory(data?: RootTxLog): TrxLog {
  const total =
    safeNum(parseFloat(data?.amount), 0) + safeNum(parseFloat(data?.fees), 0);
  const destination_currency = data?.destination?.currency ?? "--";

  return {
    $original: data,
    kind: "Transaction",
    status: data?.status as TrxLog["status"],
    created_at: safeParseISO(data?.timestamp),
    method: (data?.method as TrxLog["method"]) ?? "UNKNOWN",
    destination: guessDestination(data?.destination),
    source: guessSource(data?.source),
    trx_type: data?.destination?.entry,
    externalTransactionId: data?.destination?.transactionId ?? null,
    summary: "No Summary",
    fees: FiatValue({
      value: safeNum(data?.fees),
      currency: destination_currency,
    }),
    amount: FiatValue({
      value: safeNum(parseFloat(data?.amount), 0),
      currency: destination_currency,
    }),
    total: FiatValue({
      value: total,
      currency: destination_currency,
    }),
    get method_for_human() {
      return safeStr(data?.method).split("_").map(capitalize).join(" ");
    },
    get flow_type() {
      return capitalize(data?.flowType);
    },
  };
}

type FiatValue = {
  _tag: "FIAT_VALUE";
  value: number;
  currency: string;
  formatted: string;
};

function FiatValue(data: { value: number; currency: string }): FiatValue {
  return {
    _tag: "FIAT_VALUE",
    ...data,
    get formatted() {
      return `${this.currency} ${formatNumber(this.value, { decimal: true })}`;
    },
  };
}

function guessInitiator(data: unknown): CellMux {
  return {
    main: "--",
    sub: "--",
  };
}

function guessSource(data: unknown): CellMux {
  // console.log(data);
  const hasOwner =
    typeof data === "object" && data !== null && Object.hasOwn(data, "owner");
  if (!hasOwner) {
    return {
      // @ts-expect-error
      main: data?.accountName ? safeStr(data?.accountName) : "--",
      // @ts-expect-error
      sub: data?.accountName
        ? // @ts-expect-error
          `Bank • ${safeStr(data?.accountNumber)}`.trim()
        : "--",
    };
  }
  // @ts-expect-error
  if (data?.owner?.entityType === "USER") {
    return {
      // @ts-expect-error
      main: (safeStr(data?.owner?.email) ?? "--").split("@")[0],
      sub: "User Wallet",
    };
  }

  // @ts-expect-error
  if (data?.owner?.entityType === "BUSINESS") {
    return {
      main: "Business Wallet",
      // @ts-expect-error
      sub: `${safeStr(data?.currency)} • CentryOS`.trim(),
    };
  }

  return {
    main: "--",
    sub: "--",
  };
}

function guessDestination(data: unknown): CellMux {
  // console.log(data);
  const hasOwner =
    typeof data === "object" && data !== null && Object.hasOwn(data, "owner");
  if (!hasOwner) {
    return {
      // @ts-expect-error
      main: safeStr(data?.accountName) ?? "--",
      // @ts-expect-error
      sub: `Bank • ${safeStr(data?.accountNumber)}`.trim(),
    };
  }

  // @ts-expect-error
  if (data?.owner?.entityType === "USER") {
    return {
      // @ts-expect-error
      main: (safeStr(data?.owner?.email) ?? "--").split("@")[0],
      sub: "User Wallet",
    };
  }

  // @ts-expect-error
  if (data?.owner?.entityType === "BUSINESS") {
    return {
      main: "Business Wallet",
      // @ts-expect-error
      sub: `${safeStr(data?.currency)} • CentryOS`.trim(),
    };
  }

  return {
    main: "--",
    sub: "--",
  };
}

export const BankDetailsFactory = (data: BankDetails) => {
  const encrypt = (str) => `${take(5, safeStr(str))}***`;

  return {
    data,
    toArray() {
      return [
        { title: "Bank Name", value: data?.bankName },
        { title: "Account name", value: data?.accountName },
        {
          title: "Account No.",
          value: encrypt(data?.accountNumber),
          copyText: data?.accountNumber,
        },
      ] as const;
    },
  } as const;
};

export const TrxImpl = {
  isSource: (data: TrxLog, tx_type: TrxLog["method"]) => {
    return tx_type === data.method;
  },
  isDestination: (data: TrxLog, tx_type: "USER" | "BUSINESS") => {
    // @ts-expect-error
    const entityType = data?.$original?.destination?.owner?.entityType;
    return safeStr(entityType).includes(tx_type);
  },
};

export function TxDestinationUserWallet(data?: TxWalletRecord) {
  return {
    toArray() {
      return [
        { title: "Wallet type", value: "User Wallet" },
        { title: "Username", value: data?.owner?.email },
        {
          title: "User ID",
          value: shortenId(safeStr(data?.owner?.id)),
          copyText: data?.owner?.id ?? "--",
        },
      ];
    },
  };
}

export function TxDestinationBusinessWallet(data?: TxWalletRecord) {
  return {
    toArray(): TransactionKV[] {
      return [
        { title: "Wallet type", value: "Business Wallet" },
        {
          title: "Business ID",
          value: shortenId(safeStr(data?.owner?.id)),
          copyText: data?.owner?.id ?? "--",
        },
      ];
    },
  };
}
