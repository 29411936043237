export function UserRoleIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6.5C4.5 7.42826 4.86875 8.3185 5.52513 8.97487C6.1815 9.63125 7.07174 10 8 10C8.92826 10 9.8185 9.63125 10.4749 8.97487C11.1313 8.3185 11.5 7.42826 11.5 6.5C11.5 5.57174 11.1313 4.6815 10.4749 4.02513C9.8185 3.36875 8.92826 3 8 3C7.07174 3 6.1815 3.36875 5.52513 4.02513C4.86875 4.6815 4.5 5.57174 4.5 6.5V6.5Z"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6087 13.9173C12.0048 13.3097 11.2868 12.8274 10.4958 12.4984C9.7049 12.1693 8.85669 11.9999 8.00002 11.9999C7.14336 11.9999 6.29515 12.1693 5.50421 12.4984C4.71327 12.8274 3.99521 13.3097 3.39136 13.9173"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 8C0.5 9.98912 1.29018 11.8968 2.6967 13.3033C4.10322 14.7098 6.01088 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98912 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98912 0.5 8 0.5C6.01088 0.5 4.10322 1.29018 2.6967 2.6967C1.29018 4.10322 0.5 6.01088 0.5 8V8Z"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
