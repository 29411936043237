import { Menu, Transition } from "@headlessui/react";
import { useTheme } from "@mui/material/styles";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { Chart } from "react-google-charts";
import { displayMockUI } from "~/libs";

import { CaretDownFilled } from "~/assets/icons/CaretDownFilled";
import { EmptyStateOverlay } from "./molecules/empty-state";
import moment from "moment";

export function BaseOptionChart() {
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: "Total",
    color: theme.palette.text.secondary,
    ...theme.typography.subtitle2,
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    ...theme.typography.h3,
  };

  return {
    // Colors
    colors: ["#00FF00", "#FF0000", "#0000ff", "#7f00ff", "#00FF00", "#FF0000"],

    // Chart
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },

    // States
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88,
        },
      },
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round",
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },

    // Tooltip
    tooltip: {
      x: {
        show: false,
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: 13,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary,
      },
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        columnWidth: "28%",
        borderRadius: 4,
      },
      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
      },
      // Radialbar
      radialBar: {
        track: {
          strokeWidth: "100%",
          background: theme.palette.grey[500_16],
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },
      // Radar
      radar: {
        polygons: {
          fill: { colors: ["transparent"] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider,
        },
      },
      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider,
        },
        spokes: {
          connectorColors: theme.palette.divider,
        },
      },
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: "40%" } },
        },
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: "32%" } },
        },
      },
    ],
  };
}

const data = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7], // CSS-style declaration
];

export function DonutChart({
  title,
  data,
}: {
  title: string;
  data: (string | number)[][];
}) {
  return (
    <div className="relative h-[260px] w-[260px] flex-col items-center justify-center p-[0px]">
      <Chart
        chartType="PieChart"
        width="100%"
        height="100%"
        data={data}
        options={{
          pieHole: 0.9,
          is3D: false,
          pieSliceText: "none", // Hide slice labels
          legend: "none", // Hide legend
          colors: ["red", "green", "blue", "yellow", "grey"],
          chartArea: {
            left: 20,
            top: 20,
            bottom: 20,
            right: 20,
            width: "100%",
            height: "100%", // Adjust chart area to cover the entire space
            margin: 20, // Set margin to 0
            padding: 20, // Set padding to 0
          },
        }}
      />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <div className="inline-flex h-[72px] w-20 flex-col items-center justify-start">
          <span className="mt-[10px] w-20 text-center text-base font-bold leading-normal text-gray-600">
            Total
          </span>
          <span className="text-center text-2xl font-bold text-gray-800">
            0.00
          </span>
        </div>
      </div>
    </div>
  );
}

TransactionPerformance.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideManage: PropTypes.bool,
};

enum positions {
  bottom = "bottom",
}

export default function TransactionPerformance({
  title,
  subheader,
  chartLabels,
  chartData,
  hideManage,
  ...other
}) {
  const chartOptions: unknown = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
    legend: {
      position: positions.bottom,
      horizontalAlign: "right",
      offsetY: 5,
    },
  });

  return (
    <div className="rounded-lg bg-[white]/[30%] p-[20px] shadow">
      <span className="text-lg font-bold leading-7 text-gray-800">
        {title || "Transaction Performance"}
      </span>
      {/* <div className="flex items-center justify-between">
        <span className="text-sm font-normal leading-snug text-gray-600">
          (0%) than last Week
        </span>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="">
            <div className="flex items-center justify-center gap-2 rounded-[16px] border border-[#DEE5E7] px-3 py-1.5">
              <span className="flex items-center text-[12px] font-bold leading-tight text-gray-600">
                All Time
              </span>
              <CaretDownFilled />
            </div>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex max-h-[300px] flex-col py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type={"button"}
                      onClick={() => {}}
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      className={`cursor-pointer px-[5px] font-normal ${
                        active ? "bg-gray-200" : ""
                      }`}
                    >
                      Last week
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type={"button"}
                      onClick={() => {}}
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      className={`cursor-pointer px-[5px] font-normal ${
                        active ? "bg-gray-200" : ""
                      }`}
                    >
                      Last month
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div> */}
      <ReactApexChart
        type="line"
        series={chartData}
        options={chartOptions}
        height={364}
      />
      {!hideManage && (
        <div className="mt-[-30px] flex w-[100%] items-center justify-end">
          <div className="flex h-[30px] w-[166px] cursor-pointer items-center justify-center gap-2 rounded-lg bg-[#F4F6F8] px-4 py-2">
            <span className="text-sm font-normal leading-tight text-[black]">
              Manage Streams
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export const OverviewPerformanceChart = () => {
  // Sample data for the bar chart
  const chartData = [
    {
      name: "Category 1",
      data: displayMockUI()
        ? [23, 45, 67, 23, 56, 87, 98, 12, 45, 76, 87, 34]
        : [23, 45, 67, 23, 56, 87, 98, 12, 45, 76, 87, 34],
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false, // Hide the menu icon
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "28%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    fill: {
      opacity: 1,
      colors: ["#226835"],
    },
  };

  return (
    <div className="flex flex-col justify-between gap-2 rounded-lg  p-[15px]">
      <span className="text-lg font-bold leading-7 text-gray-800">
        Transaction Performance
      </span>

      <EmptyStateOverlay isEmpty={false} className="aspect-[3/2]">
        <ReactApexChart
          options={chartOptions}
          series={chartData}
          type="bar"
          width={"100%"}
          height={"100%"}
        />
      </EmptyStateOverlay>
    </div>
  );
};
export const OverviewPerformanceChart2 = () => {
  const dates = [];
  const today = moment();

  dates.push(today.format("MM/DD/YYYY"));
  Array(12)
    .fill("1")
    .map(() => dates.push(today.subtract(1, "M").format("MM/DD/YYYY")));

  return (
    <div className="flex flex-col justify-between gap-2 rounded-lg">
      <EmptyStateOverlay isEmpty={false} className="aspect-[3/2]">
        <TransactionPerformance
          title="Transaction Performance"
          hideManage={true}
          subheader=""
          chartLabels={dates.reverse()}
          chartData={
            displayMockUI()
              ? [
                  {
                    name: "Stream 1",
                    type: "line",
                    fill: "solid",
                    data: [14, 23, 23, 35, 28, 19, 26, 34, 46, 54],
                  },
                ]
              : []
          }
        />
      </EmptyStateOverlay>
    </div>
  );
};
