import React from "react";
import { Link } from "@tanstack/react-router";
import { PlusIcon, Info } from "lucide-react";
import * as Balance from "~/components/organisms/BalanceCard";
import { setCurrency, useSharedCurrency } from "~/contexts/currency";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import { PeopleIcon } from "~/assets/icons";
import { SectionHeading } from "~/components/atoms/heading";
import { Button } from "~/@/components/ui/button";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { OverviewPerformanceChart } from "~/components/Charts";
import {
  OverviewBalanceCard,
  OverviewMetrics,
  PendingAdminActions,
} from "~/components/DashboardComponents/overview";
import { Divider } from "~/components/Divider";
import { DefaultInputField } from "~/components/Inputs";
import { TabButton } from "~/components/Tabs";
import { WalletTransactionModal } from "~/components/TransactionDetails";
import { Header5Medium } from "~/components/Typography";
import { NumberBadge } from "~/components/Badges";
import {
  CardFooter,
  CardHeader,
  CardRoot,
  CardTitle,
} from "~/components/layouts/Card";
import { MetricsCard } from "~/components/molecules/MetricsCard";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import { AppTable } from "~/components/table/table";
import { CaretDown } from "~/assets/icons";
import {
  amountColumn,
  createdAtColumn,
  receiverColumn,
  senderColumn,
  initiatorColumn,
  statusColumn,
  statusDotColumn,
  transactionTypeColumn,
} from "~/components/table/transaction";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import {
  useAllBusinessTransactions,
  useBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import { useConvert } from "~/hooks/use-currency-rates";
import { useModal } from "~/hooks/use-modal";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import useSearch from "~/hooks";

export function RevenueBalanceOverview() {
  const currency = useSharedCurrency();
  const { data: wallet_holder } = useWallets();
  const balances = WalletHolderImpl.calculateTotal(
    wallet_holder,
    (wallet) => wallet.kind === "CORPORATE",
  );
  const {
    isLoading,
    data: { amount: estimatedBalance },
  } = useConvert(currency.code, balances);

  return (
    <Balance.Card>
      <Balance.Header headingText="Aggregated Balance" />
      <Balance.Content>
        <Balance.CurrencySwitcher
          currency={currency}
          setCurrency={setCurrency}
          readOnly={true}
        />
        <Balance.Balance
          isLoading={isLoading}
          balance={estimatedBalance}
          size="lg"
          currency={currency}
        />
      </Balance.Content>
    </Balance.Card>
  );
}

export function RevenueQuickActions() {
  return (
    <>
      <SectionHeading>
        <span className="py-3 text-gray-500">Quick Actions</span>
      </SectionHeading>
      <Balance.Card>
        <div className="flex flex-wrap items-center justify-between gap-3 gap-x-4 py-3 md:flex-nowrap">
          <Button
            className="w-full cursor-not-allowed bg-[#FAFAFA] text-[#C1C1C1]"
            variant="outline"
            disabled
          >
            <span className="px-2">Manage Stream</span>
            <Info size="0.9rem" />
          </Button>
          <Button
            className="w-full cursor-not-allowed bg-[#FAFAFA] text-[#C1C1C1]"
            variant="outline"
            disabled
          >
            Edit Payout Date
          </Button>

          <Link
            to="/dashboard/wallet/revenue/requestPayout"
            className="w-full"
            disabled
          >
            <Button
              variant="outline"
              className="w-full bg-[#FAFAFA] text-[#C1C1C1]"
              disabled
            >
              Request Payout
            </Button>
          </Link>
        </div>
      </Balance.Card>
    </>
  );
}

export function RevenueWalletLog({ title }: { title?: string }) {
  const table_columns = useMemo(
    () => [
      statusDotColumn,
      createdAtColumn,
      senderColumn,
      receiverColumn,
      initiatorColumn,
      transactionTypeColumn,
      amountColumn,
      statusColumn,
    ],
    [],
  );

  const [currentTab, setCurrentTab] = useState("");
  const businessId = useGetBusinessId();
  const [page, setPage] = useState<number>(1);
  const modal = useModal();

  const filteredTab = useMemo(() => {
    // setPage(1);
    let tab = "";

    if (currentTab === "transactions") {
      tab = "";
    }
    if (currentTab === "admin") {
      tab = "admin";
    }

    return tab;
  }, [currentTab]);

  const { isLoading, data } = useAllBusinessTransactions(
    {
      businessId,
      page: page,
      flowType: filteredTab ? filteredTab.toUpperCase() : undefined,
    },
    {
      enabled: !!businessId,
    },
  );

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(data.data, "flow_type", "method");

  const tabs = [
    { label: "Transactions", value: "transactions", isActive: true },
    { label: "Admin Activity", value: "admin", isActive: true },
  ];

  return (
    <Tabs defaultValue={"transactions"} className="-mt-5">
      <CardHeader className="mb-[0px] flex px-[20px]">
        <CardTitle className="pr-3 font-normal text-gray-500">
          History
        </CardTitle>
        <NumberBadge label="pending" count={data.meta.total ?? 0} />
      </CardHeader>
      <CardRoot>
        <div
          className={
            "no-scrollbar flex flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
          }
        >
          <TabsList className={"gap-x-5 bg-transparent"}>
            {tabs.map((e) => (
              <TabsTrigger
                key={e.label}
                value={e.value}
                onClick={() => setCurrentTab(e.value)}
                asChild
              >
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>

          <div className="flex items-center">
            <Filters isHistory={data.data.length === 0} />
            <DefaultInputField
              placeholder="Search"
              value={searchTerm}
              setValue={handleSearchChange}
              disabled={data.data.length === 0}
            />
          </div>
        </div>

        <Divider className="my-[5px]" />

        <EmptyStateOverlay
          isEmpty={data.data.length === 0 && !isLoading}
          content={
            <EmptyStateDescription>
              Actions and Transactions will appear here
            </EmptyStateDescription>
          }
        >
          <div className="no-scrollbar overflow-auto">
            <TabsContent value={"transactions"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"admin"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
          </div>
        </EmptyStateOverlay>

        {data.meta.total !== 0 && (
          <CardFooter className="py-3">
            <TablePagination
              data={{
                page: data.meta.page,
                pageCount: data.meta.pageCount,
                pageSize: data.meta.pageSize,
                total: data.meta.total,
                setPage: setPage,
              }}
            />
          </CardFooter>
        )}

        <WalletTransactionModal />
      </CardRoot>
    </Tabs>
  );
}

function Filters({ isHistory = false }: { isHistory?: boolean }) {
  const filterItems = React.useMemo(
    () => [
      {
        label: "Recent",
        icon: <></>,
        link: "",
      },
    ],
    [],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="px-5" disabled={isHistory}>
        <div className="flex cursor-pointer items-center text-sm text-[#909090]">
          Filters
          <div className="ml-[5px]">
            <CaretDown />
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={10}
        align="end"
        className="min-w-[150px]"
      >
        <DropdownMenuGroup>
          {filterItems.map((item, i) => (
            <DropdownMenuItem
              key={item.label}
              className="space-x-2 text-gray-700"
              onClick={() => {}}
            >
              {item.icon}
              <span className={"text-base font-normal"}>{item.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        {/* <hr className="-mx-2 my-1  border border-[#F4F5F6]" /> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
