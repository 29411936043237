import { zodResolver } from "@hookform/resolvers/zod";
import { Match } from "effect";
import { Link } from "@tanstack/react-router";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import {
  fetchLogin,
  fetchVerifyMfa,
  fetchGetAccountMetadata,
} from "~/api/codegen/walletosComponents";
import { ArrowTopRight } from "~/assets/icons/ArrowTopRight";
import { XIcon } from "~/assets/icons";
import { DefaultButton, DefaultButtonOutline } from "~/components/Buttons";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { InitalScaleHelmet } from "~/components/Helmet";
import { OTPInput } from "~/components/Inputs";
import { PasswordInput } from "~/components/Inputs/password";
import Modal from "~/components/Modal";
import { AuthScreenNav } from "~/components/Navbars";
import { notify, notifyApiError } from "~/components/Toast";
import { Header4Medium } from "~/components/Typography";
import {
  TitleRoot,
  TitleSubHeading,
} from "~/containers/Dashboard/Pages/Settings/Title";
import { useAuth, useAuthSession } from "~/hooks/use-auth";
import { useAccountData } from "~/hooks/use-business";
import { X } from "lucide-react";

export interface IAuthentication {
  id: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
}

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "Password required" }),
});

export function SignInBase(props: {
  onSuccess: () => void;
  children?: React.ReactNode;
}) {
  const [otp, setOtp] = useState("");
  const [loading, showLoading] = useState(false);
  const [twoFAModal, showTwoFA] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [, setAuthentication] = useAuthSession();
  const { destroySession } = useAuth();

  const form = useForm<z.infer<typeof schema>>({
    mode: "onSubmit",
    resolver: zodResolver(schema),
  });

  async function verifyTOTP() {
    if (otp.length !== 6) {
      notify("error", "Invalid OTP");
    } else {
      showLoading(true);
      try {
        const res = await fetchVerifyMfa({
          body: {
            email: email,
            code: otp,
          },
        });
        if (res.status === "SUCCESS") {
          showLoading(false);
          props.onSuccess();
        } else {
          notify("error", res.message);
          showLoading(false);
        }
      } catch (err) {
        notifyApiError(err);
        showLoading(false);
      }
    }
  }

  return (
    <>
      <InitalScaleHelmet />

      <AuthScreenNav />

      <form
        className="mx-auto flex flex-col items-center px-4 pb-[100px] lg:w-[430px]"
        onSubmit={form.handleSubmit(async (data) => {
          try {
            showLoading(true);

            const res = await fetchLogin({ body: data });
            setAuthentication(res.authentication);

            const user = await fetchGetAccountMetadata({
              queryParams: {},
            });
            setEmail(user.account.email);
            if (user.account.mfaEnabled === false) {
              props.onSuccess();
            } else {
              setShowModal(true);
              showTwoFA(true);
            }
          } catch (err) {
            if (!err._tag || err._tag !== "ApiError") {
              notify("error", "Something went wrong");
              return;
            }
            notifyApiError(err);
          } finally {
            showLoading(false);
          }
        })}
      >
        <TitleRoot className={"text-center"}>
          <Header4Medium>Welcome Back!</Header4Medium>
          <TitleSubHeading className={"text-muted-foreground"}>
            Sign into your dashboard to continue using CentryOS
          </TitleSubHeading>
        </TitleRoot>

        <Form {...form}>
          <div className="mt-[20px] flex w-full flex-col gap-4">
            <FormField
              control={form.control}
              name={"email"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Business email</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type={"email"}
                        placeholder="johndoe@gmail.com"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name={"password"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Enter password</FormLabel>
                    <FormControl>
                      <PasswordInput {...field} />
                    </FormControl>
                    <FormMessage />
                    <div className="flex items-center justify-end gap-0.5">
                      <Link
                        to={"/auth/forgot-password"}
                        className="text-right font-body text-xs font-bold leading-tight text-[#0094FF]"
                      >
                        Recover password
                      </Link>
                      <span className="flex h-3 w-3 items-center justify-center pb-[1.50px] pl-[2.25px] pr-[1.79px] pt-[2.54px]">
                        <ArrowTopRight />
                      </span>
                    </div>
                  </FormItem>
                );
              }}
            />
          </div>

          <Button type={"submit"} size={"lg"} className="mt-[70px] w-full">
            <LoadingButtonContent loading={loading}>
              Log in
            </LoadingButtonContent>
          </Button>
        </Form>
      </form>

      {showModal && (
        <Modal modal={twoFAModal} showModal={showTwoFA}>
          <div className="inline-flex h-[313px] w-[90%] flex-col items-center justify-start overflow-y-hidden rounded-lg border border-zinc-200 bg-white shadow lg:w-[612px]">
            <div className="relative h-[52px] w-full">
              <div className="left-0 inline-flex w-full items-center justify-between p-4">
                <span className="text-md text-center font-bold leading-loose text-stone-600 lg:text-xl">
                  Two Factor Authentication
                </span>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    destroySession();
                    showTwoFA(false);
                  }}
                >
                  <XIcon />
                </span>
              </div>
              <div className="absolute left-0 top-[52px] h-[0px] w-full border border-zinc-200" />
            </div>
            <div className="flex h-48 flex-col items-center justify-start gap-6 self-stretch px-4 py-8">
              <div className="text-center text-base font-normal leading-normal text-black">
                Enter 6 digit code from your auth app
              </div>
              <div className="flex flex-col items-center justify-start gap-2">
                <OTPInput value={otp} setValue={setOtp} />
              </div>
            </div>
            <div className="flex h-[69px] flex-col items-center justify-center gap-4 self-stretch border-t border-zinc-200 bg-white">
              <div className="inline-flex h-[90%] w-full items-center justify-between px-4">
                <DefaultButtonOutline
                  label="Didn’t get a code?"
                  className="h-[36px] w-[160px]"
                  onClick={() => {
                    showTwoFA(false);
                  }}
                />
                <DefaultButton
                  label="Continue"
                  className="h-[36px] w-[108px]"
                  onClick={() => {
                    verifyTOTP();
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
