import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import React from "react";
import { cn } from "~/@/lib/utils";
import { AccountBriefInfo } from "~/components/layouts/AccountBriefInfo";
import { useNavbar } from "~/contexts/NavbarContext";

export function SharedSidebar(props: {
  heading?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { isOpen, toggleNavbar } = useNavbar();
  return (
    <>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div
        onClick={() => toggleNavbar()}
        className={`fixed cursor-pointer rounded-r-full bg-white p-2.5 ${
          isOpen ? "left-[240px] md:left-[240px]" : "left-0 md:left-[64px]"
        } top-[70px] z-10`}
      >
        {isOpen ? (
          <ChevronLeftIcon size={20} color="#4084B5" />
        ) : (
          <ChevronRightIcon size={20} color="#4084B5" />
        )}
      </div>
      <div
        className={`${
          isOpen ? "fixed flex h-full w-[240px] bg-white" : "hidden"
        } z-10 flex-1 flex-col md:flex`}
      >
        <AccountBriefInfo isOpen={isOpen} />

        <div className={"flex w-full flex-1 flex-col"}>
          {props?.heading ? (
            <div
              className={`flex ${
                isOpen ? "h-[50px] pl-[15px]" : "h-[50px] pl-[22px]"
              }  w-full cursor-pointer flex-col items-stretch justify-center  hover:bg-[#E2F1FC]`}
            >
              {props.heading}
            </div>
          ) : null}

          <nav className={"flex flex-1 flex-col"}>{props.children}</nav>
        </div>
      </div>
    </>
  );
}

export function MainContainer(props: React.ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={cn("mx-auto max-w-[1400px] px-6", props.className)}
    >
      {props.children}
    </div>
  );
}
