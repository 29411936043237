export function BankFundingIcon({
  w,
  h,
  color,
}: {
  w?: string;
  h?: string;
  color?: string;
}) {
  return (
    <svg
      width={w || "25"}
      height={h || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0449 1.65549C12.3144 1.44817 12.6898 1.44817 12.9593 1.65549L22.7113 9.15549C22.9659 9.35127 23.0676 9.68742 22.9642 9.99147C22.8607 10.2955 22.5753 10.5 22.2541 10.5H2.75005C2.4289 10.5 2.14337 10.2955 2.03998 9.99147C1.93658 9.68742 2.03825 9.35127 2.29282 9.15549L12.0449 1.65549ZM12.5001 7.5C13.1214 7.5 13.6251 6.99631 13.6251 6.375C13.6251 5.75368 13.1214 5.25 12.5001 5.25C11.8788 5.25 11.3751 5.75368 11.3751 6.375C11.3751 6.99631 11.8788 7.5 12.5001 7.5ZM17.0001 12H20.0001V18H17.0001V12ZM14.0001 18V12H11.0001V18H14.0001ZM8.00009 18V12H5.00009V18H8.00009ZM3.30949 19.5C3.12557 19.5592 2.96839 19.6884 2.87561 19.8646L2.08636 21.3646C1.96404 21.5971 1.97227 21.8767 2.10805 22.1016C2.24384 22.3264 2.4874 22.464 2.75009 22.464H22.2541C22.5168 22.464 22.7602 22.3266 22.896 22.1019C23.0319 21.8772 23.0403 21.5979 22.9183 21.3654L22.131 19.8654C22.0383 19.6887 21.8809 19.5592 21.6969 19.5H3.30949Z"
        fill={color || "#002C3D"}
      />
    </svg>
  );
}
