import { destr } from "destr";
import { is } from "ramda";
import { AuthCredentials } from "~/general/interfaces";
import { safeStr } from "~/libs/data.helper";

export function truncateString(str: string, maxLength: number | undefined) {
  if (!maxLength) {
    return str;
  }
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
}

export function createSlug(words: string) {
  return words
    .toLowerCase()
    .replace(/[\s]/g, "-")
    .replace(/[^\w-]/g, "");
}

export function getAuthData(): AuthCredentials | undefined {
  const auth_data = destr(localStorage.getItem("authentication"));

  if (!is(Object, auth_data)) return undefined;

  return auth_data;
}

export function getInitials(string: string) {
  try {
    return safeStr(string)
      .split(" ")
      .map((e) => e?.[0] ?? "")
      .join("");
  } catch {
    return "--";
  }
}

// checks if business has credentials
export function isBusinessAPIKeyValid(
  obj?: Partial<{
    BusinessAPIKey: { apiKey: string; secret: string };
  }>,
) {
  // Check if the object has a "BusinessAPIKey" property
  if (!obj || !obj.BusinessAPIKey) {
    return false;
  }

  const { apiKey, secret } = obj.BusinessAPIKey;

  // Check if apiKey and secret properties exist and are non-empty strings
  if (
    apiKey &&
    typeof apiKey === "string" &&
    apiKey.trim() !== "" &&
    secret &&
    typeof secret === "string" &&
    secret.trim() !== ""
  ) {
    return true;
  }

  return false;
}

export const shouldDisable = () => {
  if (import.meta.env.VITE_USE_MOCK_SYSTEM === "true") return false;
  return true;
};

export const displayMockUI = () => {
  if (import.meta.env.VITE_USE_MOCK_SYSTEM === "true") return true;
  return false;
};

export function generateRandomAmount(
  min: number,
  max: number,
  decimalPlaces: number,
) {
  const randomValue = Math.random() * (max - min) + min;
  return Number(randomValue.toFixed(decimalPlaces));
}

export function safeArray<T>(a: T[]): T[] {
  return Array.isArray(a) ? a : [];
}

export const safeJsonParse = <T>(str: string, fallback = null): T => {
  try {
    const value = destr(str);
    if (value === null) return fallback;
    return value as T;
  } catch (e) {
    return fallback;
  }
};

export const isDevelopment = () => import.meta.env.NODE_ENV === "development";

export const serialNo = (num: number): string => {
  if (num === 0) return "00";

  if (Math.abs(num) < 10) {
    if (num < 0) {
      return `-0${Math.abs(num)}`;
    }
    return `0${num}`;
  }
  return String(num);
};
