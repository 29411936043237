export function ErrorIcon({ size, color }: { size?: string; color?: string }) {
  return (
    <svg
      width={size || "14"}
      height={size || "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.00016" cy="6.99998" r="6.66667" fill="#F05050" />
      <path
        d="M9.16667 5L5 9.16667M5 5L9.16667 9.16667"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
