import React from "react";
import Layout from "~/containers/Payout/Layout";
import SideNav from "./SideNav";
import StepMethod from "./StepMethod";
import StepRecipient from "./StepRecipient";
import StepAmount from "./StepAmount";
import StepReview from "./StepReview";
import StepAuthorize from "./StepAuthorize";
import StepSuccess from "./StepSuccess";
import { RecipientAccountTypes } from "~/libs/types.helper";
import { ApplicationTokenTypes, PickOneOptionType } from "~/libs/types.helper";
import { CountryI } from "~/general/interfaces";

interface PayoutDefaultCtx {
  payoutState: {
    paymentMethod: PickOneOptionType;
    tokendetails: ApplicationTokenTypes;
    recipient: RecipientAccountTypes;
    country: CountryI;
    reason: string;
    payoutAmount: {
      amount: number;
      fee: number;
      currency: string;
      recipientCurrency: string;
    };
  };
  updateState: (name: string, newState: unknown) => void;
  resetState: () => void;
}

export const PayoutCtx = React.createContext({} as PayoutDefaultCtx);

export default function PayoutLayout() {
  const [payoutState, setPayoutState] = React.useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const completeStep = (index: number) => {
    const newCompletedSteps = [...completedSteps];
    newCompletedSteps[index] = true;
    setCompletedSteps(newCompletedSteps);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepMethod
            completeStep={() => completeStep(0)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 1:
        return (
          <StepRecipient
            completeStep={() => completeStep(1)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 2:
        return (
          <StepAmount
            completeStep={() => completeStep(2)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 3:
        return (
          <StepReview
            completeStep={() => completeStep(3)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 4:
        return (
          <StepAuthorize
            completeStep={() => completeStep(3)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 5:
        return (
          <StepSuccess
            completeStep={() => completeStep(4)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      default:
        return (
          <StepMethod
            completeStep={() => completeStep(0)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
    }
  };

  const updateState = (name: string, newState: unknown) => {
    setPayoutState((e) => ({ ...e, [name]: newState }));
  };
  const resetState = () => {
    setPayoutState({});
  };

  const ctxValue = {
    payoutState,
    updateState,
    resetState,
  };
  return (
    <Layout>
      <PayoutCtx.Provider value={ctxValue as null}>
        <div className={"container mx-auto flex flex-col px-12 pt-10"}>
          <div className="flex">
            <div className="">
              <SideNav
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                completedSteps={completedSteps}
              />
            </div>
            <div className="w-full grow px-[20px] text-center">
              {renderStepContent()}
            </div>
          </div>
        </div>
      </PayoutCtx.Provider>
    </Layout>
  );
}
