export function CryptoFundingIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5273 13.8281C19.4023 12.9375 18.9648 12.25 18.1835 11.7969C17.8241 11.5938 17.4335 11.4531 17.0272 11.2812C17.0741 11.25 17.1054 11.2188 17.1366 11.2031C19.0898 10.2031 18.9179 7.1875 17.496 6.15625C16.9179 5.73438 16.2772 5.46875 15.6053 5.3125C15.121 5.20313 14.6366 5.125 14.1366 5.04688C14.1366 4.17188 14.1522 2.96875 14.1522 2H12.3397C12.3241 2.95312 12.3241 4.125 12.3084 4.98438H10.8397C10.8397 4.125 10.8553 2.95312 10.8553 2H9.02714C9.01152 2.96875 9.01152 4.17188 8.99589 5.03125C7.74588 5.01562 6.54274 5.01563 5.29273 5C5.29273 5.67188 5.2771 6.29688 5.2771 6.95312C5.54273 6.95312 5.77711 6.95312 5.99586 6.96875C6.23024 6.96875 6.46461 6.96875 6.68337 6.98438C7.324 7.03125 7.65213 7.39062 7.65213 8.01562C7.6365 10.625 7.60525 13.25 7.574 15.8594C7.574 16.4375 7.324 16.6562 6.76149 16.6562H5.54273C5.41773 17.3906 5.29273 18.0938 5.16772 18.8281C6.40211 18.8438 7.62088 18.8594 8.87089 18.875C8.87089 19.8125 8.85527 21 8.85527 21.9844H10.6834C10.699 21 10.699 19.8438 10.7147 18.9219C11.2303 18.9375 11.6991 18.9531 12.1834 18.9688C12.1834 19.9062 12.1678 21.0469 12.1678 22H13.996C14.0116 21.0156 14.0116 19.8594 14.0272 18.9531C14.0897 18.9375 14.1366 18.9375 14.1835 18.9219C15.0585 18.7812 15.9491 18.7031 16.7929 18.5C17.9022 18.2188 18.7773 17.5781 19.246 16.4844C19.5898 15.6406 19.6679 14.75 19.5273 13.8281ZM10.8553 7.14062C11.9959 7.14062 13.1053 7.04688 14.1366 7.51562C14.8241 7.8125 15.1678 8.375 15.121 9.04688C15.0741 9.76562 14.6678 10.2812 13.9335 10.5156C12.9334 10.8438 11.8866 10.8281 10.8084 10.7812C10.8397 9.59375 10.8397 8.40625 10.8553 7.14062ZM14.8397 16.2656C13.8241 16.7188 12.7616 16.7188 11.6834 16.7344C11.3865 16.7344 11.0897 16.7188 10.7615 16.7188L10.8084 12.6719C12.1991 12.6719 13.5741 12.5625 14.871 13.1406C15.5428 13.4375 15.9335 13.9531 15.9335 14.7188C15.9335 15.4844 15.5116 15.9688 14.8397 16.2656Z"
        fill="black"
      />
    </svg>
  );
}
