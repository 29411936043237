import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import PayoutLayout from "~/containers/Payout";

export const Route = createFileRoute("/payout")({
  component: PayoutLayoutComponent,
});

function PayoutLayoutComponent() {
  const navigate = useNavigate();
  return (
    <ProtectedRoute
      onAuthStateChange={(state) => {
        if (state.kind === "error") {
          return navigate({ to: "/signin" });
        }
      }}
    >
      <PayoutLayout />
    </ProtectedRoute>
  );
}
