import React, { ComponentProps, useCallback } from "react";
import useEffectEvent from "react-use-event-hook";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/@/components/ui/popover";
import { cn } from "~/@/lib/utils";
import { CaretDown, XIcon } from "~/assets/icons";
import { FiatCurrencyI } from "~/constants/currencies";

import { Check } from "lucide-react";
import { useChecklist } from "~/hooks/use-checklist";

export type Chip = {
  image: { url: string; alt: string };
  value: string;
  altText?: React.ReactNode;
};

interface ChipValuesProps extends ComponentProps<"div"> {
  values: Chip[];
  onValueChange: (value: Chip) => void;
  placeholder?: string;
}

export const ChipValues = React.forwardRef<HTMLDivElement, ChipValuesProps>(
  function ChipValues(props, ref) {
    const { values, className, onValueChange } = props;

    const handleRemove = React.useCallback(
      (item: Chip) =>
        (
          evt:
            | React.MouseEvent<HTMLSpanElement>
            | React.KeyboardEvent<HTMLSpanElement>,
        ) => {
          evt.preventDefault();
          onValueChange(item);
        },
      [onValueChange],
    );

    return (
      <div
        ref={ref}
        className={cn(
          "flex min-h-[36px] w-[100%] items-start rounded-[4px] border-[1px] border-zinc-200 pr-[8px] font-body text-sm",
          className,
        )}
      >
        {values.length > 0 ? (
          <div className="mt-[2px] flex h-[100%] w-[100%] flex-wrap items-center px-[5px] pb-[2px]">
            {values.map((item) => (
              <span
                key={item.value}
                role={"button"}
                onClick={handleRemove(item)}
                onKeyDown={handleRemove(item)}
                className="mr-[3px] mt-[3px] flex min-h-[24px] w-[82px] cursor-pointer items-center justify-evenly rounded-[4px] border-[1px] border-zinc-300 py-[4px]"
              >
                <img
                  src={item.image.url}
                  alt={item.image.alt}
                  className="h-[15px] w-[16px] object-cover"
                />
                <span className="text-xs font-normal text-stone-600">
                  {item.value}
                </span>
                <XIcon size="8" color="grey" />
              </span>
            ))}
          </div>
        ) : (
          <span
            className={
              "inline-flex flex-1 items-center self-stretch px-2 text-start text-muted-foreground"
            }
          >
            {props.placeholder ?? "Select options"}
          </span>
        )}
        <div className="flex h-[36px] items-center">
          <CaretDown />
        </div>
      </div>
    );
  },
);

const ChipOption = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div"> & { value: Chip; selected: boolean }
>(function ChipOption(props, ref) {
  const { children, value, className, ...PROPS } = props;

  return (
    <div
      ref={ref}
      role={"button"}
      className={cn(
        "group flex cursor-pointer items-center justify-between border-b border-zinc-200 px-[5px] py-[8px] text-[13px] hover:bg-gray-100",
        className,
      )}
      {...PROPS}
    >
      <div className="flex cursor-pointer items-center">
        <span className={"min-w-[20px]"}>
          {props.selected ? <Check size={"0.9rem"} /> : null}
        </span>
        <img
          src={value.image.url}
          alt={value?.image.alt}
          style={{
            height: "20px",
            width: "25px",
            marginRight: "5px",
          }}
          className="rounded"
        />
        <span className="text-xs font-normal leading-tight text-stone-600">
          {value.value}
        </span>
      </div>

      {!value?.altText ? null : (
        <span className="text-right text-xs font-normal leading-tight text-neutral-400">
          {value.altText}
        </span>
      )}
    </div>
  );
});

function useChip(chips: Chip[], onChange: (values: Chip[]) => void) {
  return useChecklist({ length: chips.length });
}

/** Introduce a Context for Statement **/
function ChipRoot(props: { children?: React.ReactNode }) {
  return <>{props.children}</>;
}

export function FiatChipSelector(props: {
  options: FiatCurrencyI[];
  values: FiatCurrencyI[];
  onSelectChange: (values: FiatCurrencyI[]) => void;
}) {
  const fromFiatToChip = useCallback((e: FiatCurrencyI): Chip => {
    return {
      image: { url: e.flagUrl, alt: e.name },
      value: e.code,
      altText: e.name,
    };
  }, []);

  const onOptionChange = useEffectEvent((values: Chip[]) => {
    const set = new Set(values.map((opt) => opt.value));
    props.onSelectChange(props.options.filter((e) => set.has(e.code)));
  });

  const chip_values = props.values.map(fromFiatToChip);

  const { toggle, getList, checks } = useChip(chip_values, onOptionChange);

  const data = props.options.map(fromFiatToChip);
  const selected = getList()
    .map((i) => data.filter((option) => option.value === i))
    .flatMap((e) => e);

  return (
    <Popover>
      <ChipRoot>
        <PopoverTrigger>
          <ChipValues
            values={selected}
            onValueChange={(option) => {
              toggle(option.value);
            }}
          />
        </PopoverTrigger>

        <PopoverContent align={"start"}>
          <div className={"-mx-2.5 -my-2.5 flex flex-col gap-y-0.5"}>
            {data.map((option) => {
              return (
                <ChipOption
                  key={option.value}
                  value={option}
                  selected={checks.has(option.value)}
                  onClick={() => toggle(option.value)}
                  onKeyDown={() => toggle(option.value)}
                  className={"rounded-sm border-b-0 px-3"}
                />
              );
            })}
          </div>
        </PopoverContent>
      </ChipRoot>
    </Popover>
  );
}
