export function ArrowDown() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8267 5.44099L9.11309 11.5209C9.0001 11.6666 8.83778 11.7876 8.64242 11.8717C8.44705 11.9557 8.22551 12 8 12C7.77449 12 7.55295 11.9557 7.35758 11.8717C7.16222 11.7876 6.9999 11.6666 6.88691 11.5209L2.17331 5.44099C2.06004 5.2951 2.00027 5.12949 2 4.96086C1.99974 4.79223 2.05899 4.62653 2.1718 4.48043C2.28461 4.33434 2.44699 4.21301 2.64259 4.12867C2.83819 4.04433 3.0601 3.99995 3.28599 4H12.714C12.9399 3.99995 13.1618 4.04433 13.3574 4.12867C13.553 4.21301 13.7154 4.33434 13.8282 4.48043C13.941 4.62653 14.0003 4.79223 14 4.96086C13.9997 5.12949 13.94 5.2951 13.8267 5.44099Z"
        fill="#3BB75E"
      />
    </svg>
  );
}
