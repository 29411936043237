import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import {
  DayPicker,
  DayModifiers,
  Modifier,
  ModifiersClassNames,
} from "react-day-picker";
import { Divider } from "~/components/Divider";
import { Button } from "~/@/components/ui/button";

import { buttonVariants } from "~/@/components//ui/button";
import { cn } from "~/@/lib/utils";
interface DatePickerTypes {
  handleThisMonthClick: () => void;
  handleLastMonthClick: () => void;
  handleLast7DaysClick: () => void;
  handleLast6MonthsClick: () => void;
  // modifiers: Modifier;
  modifiersClassNames: ModifiersClassNames;
}

export type CalendarProps = React.ComponentProps<typeof DayPicker> &
  DatePickerTypes;

function Calendar({
  className,
  // modifiers,
  modifiersClassNames,
  handleThisMonthClick,
  handleLastMonthClick,
  handleLast7DaysClick,
  handleLast6MonthsClick,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const buttons = [
    {
      title: "This Month",
      onClick: handleThisMonthClick,
    },
    {
      title: "Last Month",
      onClick: handleLastMonthClick,
    },
    {
      title: "Last 7 Days",
      onClick: handleLast7DaysClick,
    },
    {
      title: "Last 6 Months",
      onClick: handleLast6MonthsClick,
    },
  ];
  const [activeButton, setActiveButton] = useState(0);

  const handleClick = (index: number, buttonFunction) => {
    setActiveButton(index);
    buttonFunction();
  };
  return (
    <div className="">
      <DayPicker
        showOutsideDays={showOutsideDays}
        className={cn("p-3", className)}
        classNames={{
          months:
            "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
          month: "space-y-4",
          caption: "flex justify-center pt-1 relative items-center",
          caption_label: "text-sm font-medium",
          nav: "space-x-1 flex items-center",
          nav_button: cn(
            buttonVariants({ variant: "outline" }),
            "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
          ),
          nav_button_previous: "absolute left-1",
          nav_button_next: "absolute right-1",
          table: "w-full border-collapse space-y-1",
          head_row: "flex",
          head_cell:
            "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
          row: "flex w-full mt-2",
          cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
          day: cn(
            buttonVariants({ variant: "ghost" }),
            "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
          ),
          day_range_end: "day-range-end",
          day_selected:
            "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
          day_today: "bg-accent text-accent-foreground",
          day_outside:
            "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
          day_disabled: "text-muted-foreground opacity-50",
          day_range_middle:
            "aria-selected:bg-accent aria-selected:text-accent-foreground",
          day_hidden: "invisible",
          ...classNames,
        }}
        components={{
          IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
          IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        }}
        // modifiers={modifiers}
        modifiersClassNames={modifiersClassNames}
        {...props}
      />
      <Divider className="-mt-1 mb-5" />
      <div className="mt-4 grid grid-cols-2 gap-4">
        {buttons.map((button, index) => (
          <Button
            key={`${index}-${button.title}`}
            variant={activeButton === index ? "default" : "outline"}
            className="h-8"
            onClick={() => handleClick(index, button.onClick)}
          >
            {button.title}
          </Button>
        ))}
      </div>
    </div>
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
