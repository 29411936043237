import React from "react";
import { CountryI } from "~/general/interfaces";
import { ApplicationTokenTypes, PickOneOptionType } from "~/libs/types.helper";
import { Wallet } from "~/libs/factories/wallet-factory";
import StepSource from "./StepSource";
import Countries from "./Countries";
import RecipientDetails, { RecipientDetailsType } from "./RecipientDetails";
import AccountDetail from "./AccountDetail";

interface RecipientDefaultCtx {
  recipientState: {
    wallet: Wallet;
    country: CountryI;
    recipientDetails: RecipientDetailsType;
  };
  updateState: (name: string, newState: unknown) => void;
  resetState: () => void;
}

export const RecipientCtx = React.createContext({} as RecipientDefaultCtx);

const AddRecipient = ({ setCurrentStep, currentStep }) => {
  const [recipientState, setRecipientState] = React.useState({});
  const [currentMove, setCurrentMove] = useState(0);

  const updateState = (name: string, newState: unknown) => {
    setRecipientState((e) => ({ ...e, [name]: newState }));
  };
  const resetState = () => {
    setRecipientState({});
  };

  const renderStepContent = () => {
    switch (currentMove) {
      // case 0:
      //   return (
      //     <StepSource
      //       setCurrentMove={setCurrentMove}
      //       currentMove={currentMove}
      //     />
      //   );
      // case 1:
      //   return (
      //     <Countries
      //       setCurrentMove={setCurrentMove}
      //       currentMove={currentMove}
      //     />
      //   );
      case 0:
        return (
          <RecipientDetails
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
          />
        );
      case 1:
        return (
          <AccountDetail
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      default:
        return (
          <RecipientDetails
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
          />
        );
    }
  };

  const ctxValue = {
    recipientState,
    updateState,
    resetState,
  };
  return (
    <RecipientCtx.Provider value={ctxValue as null}>
      <div className="mx-auto w-full">
        <div className="w-full grow">{renderStepContent()}</div>
      </div>
    </RecipientCtx.Provider>
  );
};

export default React.memo(AddRecipient);
