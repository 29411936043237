import { Dispatch, SetStateAction, useState } from "react";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
  EmptyStateTitle,
} from "~/@/components/custom/empty-state";
import { PeopleIcon } from "~/assets/icons";
import { PlatformIcon } from "~/assets/icons";
import { ArrowLeft } from "~/assets/icons/ArrowLeft";
import { CaretDown } from "~/assets/icons/CaretDown";
import { CaretRight } from "~/assets/icons/CaretRight";
import { setCurrency, useSharedCurrency } from "~/contexts/currency";
import styles from "~/general/styles.module.css";
import { useConvert } from "~/hooks/use-currency-rates";
import { useUserMetrics } from "~/hooks/use-user-metrics";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import CurrencyFormatter from "~/libs/CurrencyFormatter";
import { abbrevCurrencyValue } from "~/libs/currency.helpers";
import { getDateFormat } from "~/libs/date.helpers";
import {
  BalanceMap,
  getOptionsFromValueMap,
} from "~/libs/factories/balance-map";
import { StatusBadge, NumberBadge } from "../Badges";
import { DefaultButton } from "../Buttons";
import { Divider } from "../Divider";
import Modal from "../Modal";
import { CardBody, CardHeader, CardRoot, CardTitle } from "../layouts/Card";
import { MetricsCard } from "../molecules/MetricsCard";
import { EmptyStateDescription } from "../molecules/empty-state";
import * as BalanceCard from "../organisms/BalanceCard";
import emptyState from "~/assets/images/empty-state.png";

export function OverviewBalanceCard() {
  const currency = useSharedCurrency();
  const { data: wallets } = useWallets();
  const { data: metrics } = useUserMetrics();

  const balances = useMemo(() => {
    const totalBalance = BalanceMap.create(metrics?.totalBalance?.volume);
    return BalanceMap.reduce(
      totalBalance,
      WalletHolderImpl.calculateTotal(wallets),
    );
  }, [wallets, metrics?.totalBalance?.volume]);

  const {
    isLoading,
    data: { amount: estimatedBalance },
  } = useConvert(currency.code, balances);
  return (
    <BalanceCard.Card>
      <BalanceCard.Header headingText={"Aggregate Balance"} />
      <BalanceCard.Content>
        <BalanceCard.CurrencySwitcher
          currency={currency}
          options={getOptionsFromValueMap(balances)}
          setCurrency={setCurrency}
          readOnly={true}
        />
        <BalanceCard.Balance
          size={"lg"}
          isLoading={isLoading}
          balance={estimatedBalance}
          currency={currency}
        />
      </BalanceCard.Content>
    </BalanceCard.Card>
  );
}

function OverviewBusinessWallet() {
  const currency = useSharedCurrency();
  const { data: walletHolder } = useWallets();

  const corporate_wallet_balance = useConvert(
    currency.code,
    // @ts-expect-error
    WalletHolderImpl.getBalance(walletHolder, "CORPORATE"),
  );

  const revenue_wallet_balance = useConvert(
    currency.code,
    // @ts-expect-error
    WalletHolderImpl.getBalance(walletHolder, "REVENUE"),
  );
  const formatForCurrency = abbrevCurrencyValue(currency.code);

  return (
    <div className="w-full flex-1">
      <MetricsCard
        Icon={<PlatformIcon />}
        caption={"Platform Accounts"}
        data={[
          {
            title: "Spend Balance",
            value: formatForCurrency(corporate_wallet_balance.data.amount),
          },
          {
            title: "Revenue",
            value: formatForCurrency(revenue_wallet_balance.data.amount),
          },
        ]}
      />
    </div>
  );
}

export function OverviewMetrics() {
  const { data: metrics } = useUserMetrics();
  const currency = useSharedCurrency();

  const user_wallet_volume = useConvert(
    currency.code,
    BalanceMap.create(metrics?.totalBalance?.volume),
  );

  const vault_wallet_volume = useConvert(
    currency.code,
    BalanceMap.create(metrics?.vaultsWallet?.volume),
  );
  const formatForCurrency = abbrevCurrencyValue(currency.code);

  return (
    <div className="flex w-full flex-col flex-wrap items-center gap-4 text-base md:flex-row">
      <OverviewBusinessWallet />

      <div className="w-full flex-1">
        <MetricsCard
          variant="darkshade"
          Icon={<PeopleIcon />}
          caption={"User Accounts"}
          data={[
            {
              title: "Account Balance",
              value: formatForCurrency(user_wallet_volume.data.amount),
            },
            {
              title: "In Vault",
              value: formatForCurrency(vault_wallet_volume.data.amount),
            },
          ]}
        />
      </div>
    </div>
  );
}

function PendingActionDetails({
  details,
  modal,
  showModal,
}: {
  details: {
    firstname: string;
    lastname: string;
    amount: number;
  };
  modal: boolean;
  showModal: Dispatch<SetStateAction<boolean>>;
}) {
  function ListItem({
    label,
    element,
  }: {
    label: string;
    element?: React.JSX.Element;
  }) {
    return (
      <div className="my-[20px] flex items-center justify-between">
        <span className="font-body text-sm font-normal leading-tight text-zinc-700">
          {label}
        </span>
        {element}
      </div>
    );
  }

  return (
    <Modal
      modal={modal}
      showModal={showModal}
      renderHeader={
        <div className="flex h-[54px] w-full items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type="button"
            onClick={() => showModal(false)}
            className="flex cursor-pointer items-center"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
          </button>
          <span
            className={
              "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
            }
          >
            Activity Log
          </span>
        </div>
      }
    >
      <div className="h-[80vh] w-[600px] max-w-[90vw] rounded bg-[white]">
        <div
          className={`${styles.custom_scrollbar2} h-[85%] overflow-y-scroll p-[15px]`}
        >
          <div className="flex h-[90px] w-full flex-col justify-center rounded bg-[#FFF9EB]">
            <span className="text-center text-sm font-normal leading-none text-[#996700]">
              Total Amount
            </span>
            <span className="mt-[10px] text-center text-2xl font-bold text-[#996700]">
              ₦{CurrencyFormatter.formatCurrency(details.amount)}
            </span>
          </div>
          <Divider />
          <ListItem label="Status" element={<StatusBadge label="pending" />} />
          <ListItem
            label="Type"
            element={
              <span className="text-base font-bold leading-normal text-red-600">
                Withdrawal
              </span>
            }
          />
          <ListItem
            label="Initiated by"
            element={
              <span className="text-base font-bold leading-normal text-[#002C3D]">
                {details?.firstname} {details?.lastname}
              </span>
            }
          />
          <ListItem
            label="Time initiated"
            element={
              <span className="text-base font-bold leading-normal text-zinc-700">
                Wed 12, Nov 2023{" "}
                <span className="text-sm font-normal leading-normal text-zinc-700">
                  4:00pm
                </span>
              </span>
            }
          />
          <ListItem
            label="From"
            element={
              <span className="text-base font-bold leading-normal text-[#002C3D]">
                Corporate Wallet
              </span>
            }
          />
          <ListItem
            label="Method"
            element={
              <span className="text-base font-bold leading-normal text-[#002C3D]">
                Bank Transfer
              </span>
            }
          />
          <Divider />
          <div>
            <ListItem
              label="Amount"
              element={
                <span className="text-base font-bold leading-normal text-[#002C3D]">
                  ₦{CurrencyFormatter.formatCurrency(details.amount)}
                </span>
              }
            />
            <ListItem
              label="Fee"
              element={
                <span className="flex items-center text-base font-bold leading-normal text-[#002C3D]">
                  ₦
                  {CurrencyFormatter.formatCurrency(
                    (details.amount * 0.5) / 100,
                  )}
                  <span className="ml-[5px] font-body text-sm font-normal">
                    0.5%
                  </span>
                </span>
              }
            />
            <ListItem
              label="Total"
              element={
                <span className="text-base font-bold leading-normal text-[#002C3D]">
                  ₦{CurrencyFormatter.formatCurrency(details.amount)}
                </span>
              }
            />
          </div>
          <Divider />
          <div>
            <div className="flex items-center justify-between">
              <div className="text-base font-normal leading-tight text-zinc-500">
                To
              </div>
              <CaretDown color="black" />
            </div>
            <ListItem
              label="Bank name"
              element={
                <span className="text-base font-bold leading-normal text-[#002C3D]">
                  GT Bank
                </span>
              }
            />
            <ListItem
              label="Account name"
              element={
                <span className="mr-[8px] text-base font-bold leading-normal text-[#002C3D]">
                  Gamr Africa
                </span>
              }
            />
            <ListItem
              label="Account number"
              element={
                <span className="mr-[8px] text-base font-bold leading-normal text-[#002C3D]">
                  00947***
                </span>
              }
            />
          </div>
          <Divider />

          <ListItem
            label="Amount"
            element={
              <span className="text-base font-bold leading-normal text-[#002C3D]">
                ₦{CurrencyFormatter.formatCurrency(details.amount)}
              </span>
            }
          />
          <ListItem
            label="Fee"
            element={
              <span className="flex items-center text-base font-bold leading-normal text-[#002C3D]">
                ₦
                {CurrencyFormatter.formatCurrency((details.amount * 0.5) / 100)}
                <span className="ml-[5px] font-body text-sm font-normal">
                  0.5%
                </span>
              </span>
            }
          />
          <ListItem
            label="Total"
            element={
              <span className="text-base font-bold leading-normal text-[#002C3D]">
                ₦{CurrencyFormatter.formatCurrency(details.amount)}
              </span>
            }
          />
          <ListItem
            label="Time initiated"
            element={
              <span className="text-base font-bold leading-normal text-zinc-700">
                Wed 12, Nov 2023{" "}
                <span className="text-sm font-normal leading-normal text-zinc-700">
                  4:00pm
                </span>
              </span>
            }
          />
          <Divider />
        </div>

        <div className="item-center flex h-[auto] justify-center rounded bg-[white] p-[20px]">
          <DefaultButton
            label="Cancel Request"
            onClick={() => {}}
            textClassName="text-[#005695]"
            styles={{
              backgroundColor: "#F05050",
              width: "135px",
              height: "36px",
            }}
          />
          <DefaultButton
            label="Prompt Admin"
            onClick={() => {}}
            styles={{
              marginLeft: "15px",
              backgroundColor: "#3BB75E",
              width: "145px",
              height: "36px",
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export function PendingAdminActions() {
  const [detailsModal, showDetailsModal] = useState(false);
  const [details, setDetails] = useState(null);

  // {
  //   transactionType: "funding",
  //   transactionId: "GMR34fgse",
  //   revenueStream: "Stream 1",
  //   amount: 10000,
  //   date: new Date("2023-01-01"),
  //   approvedBy: "John Doe",
  //   initiatedBy: "Alice Smith",
  //   firstname: "Alice",
  //   lastname: "Smith",
  //   status: "active",
  //   adminRole: "admin",
  // },
  const logs = [];

  return (
    <>
      <CardRoot className="flex-1">
        <CardHeader>
          <div className="flex items-center gap-3">
            <CardTitle className="font-normal text-gray-500">
              Awaiting Approvals
            </CardTitle>
            <NumberBadge label="pending" count={logs.length} />
            {/* <CountBadge count={logs.length} /> */}
          </div>
        </CardHeader>

        <Divider />

        <CardBody className={`${styles.custom_scrollbar} -mx-6`}>
          <EmptyStateRoot isEmpty={!logs.length}>
            <EmptyStateContent className="px-6">
              <img
                src={emptyState}
                height={200}
                width={200}
                className="object-contain py-16"
                alt={"Empty state"}
              />
              <EmptyStateDescription className="pt-14 text-center text-xs">
                Transactions pending approvals will appear here
              </EmptyStateDescription>
            </EmptyStateContent>

            <EmptyStateConceal>
              <table className="w-full">
                <thead className="h-[56px] bg-[#F2F6F8]">
                  <tr>
                    <th className="w-[44px] ">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="h-[100%] px-[30px]"
                      >
                        <div className="h-2 w-2 rounded-lg bg-[#3BB75E]" />
                      </div>
                    </th>
                    <th className="w-[154px] text-left ">
                      <div className="flex flex-col">
                        <span className="text-sm font-bold leading-tight text-gray-600">
                          Date
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                            marginTop: "2px",
                          }}
                          className="font-body font-normal text-gray-400"
                        >
                          Time
                        </span>
                      </div>
                    </th>
                    <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                      <div className="flex flex-col">
                        <span className="text-sm font-bold leading-tight text-gray-600">
                          Type
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                            marginTop: "2px",
                          }}
                          className="font-body font-normal text-gray-400"
                        >
                          Initiator
                        </span>
                      </div>
                    </th>
                    <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                      <div className="flex flex-col">
                        <span className="text-sm font-bold leading-tight text-gray-600">
                          Amount
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                            marginTop: "2px",
                          }}
                          className="font-body font-normal text-gray-400"
                        >
                          Currency
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="">
                  {logs.map((log) => {
                    return (
                      // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                      <tr
                        key={log.transactionId}
                        onClick={() => {
                          setDetails(log);
                          showDetailsModal(true);
                        }}
                        className={
                          "h-[63px] cursor-pointer border-b-[.5px] border-zinc-100 hover:bg-zinc-100"
                        }
                      >
                        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                        <td onClick={(e) => e.stopPropagation()}>
                          <div className="flex h-[100%] w-full items-center justify-center">
                            <span className="h-[8px] w-[8px] rounded-[8px] bg-[#FFAA22]" />
                          </div>
                        </td>
                        <td>
                          <span
                            style={{ fontSize: "14px", fontWeight: "bolder" }}
                            className="font-body font-bold text-gray-600"
                          >
                            {getDateFormat(log.date).dateFormat}
                            <br />
                            <span
                              style={{ fontSize: "12px", fontWeight: "normal" }}
                              className="font-body font-normal text-gray-400"
                            >
                              {getDateFormat(log.date).timeFormat}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span
                            style={{ fontSize: "14px", fontWeight: "bolder" }}
                            className="font-body font-bold capitalize text-gray-600"
                          >
                            {log.transactionType}
                            <br />
                            <span
                              style={{ fontSize: "12px", fontWeight: "normal" }}
                              className="font-body font-normal text-gray-400"
                            >
                              {log.initiatedBy}
                            </span>
                          </span>
                        </td>
                        <td>
                          <div className="flex w-full items-center justify-between pr-[20px]">
                            <span
                              style={{ fontSize: "14px", fontWeight: "bolder" }}
                              className="font-body font-bold text-gray-600"
                            >
                              {CurrencyFormatter.formatCurrency(log.amount)}
                              <br />
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                                className="font-body font-normal text-gray-400"
                              >
                                NGN
                              </span>
                            </span>
                            <CaretRight color="black" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </EmptyStateConceal>
          </EmptyStateRoot>
        </CardBody>
      </CardRoot>

      {details && (
        <PendingActionDetails
          modal={detailsModal}
          details={details}
          showModal={showDetailsModal}
        />
      )}
    </>
  );
}
