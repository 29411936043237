import { useNavigate, useParams } from "@tanstack/react-router";
import { useState } from "react";
import { acceptBusinessInvite, updateTempPassword } from "~/api/auth";
import { AlertTriangleIcon } from "~/assets/icons/AlertTriangleIcon";
import { EyeCloseIcon } from "~/assets/icons/EyeCloseIcon";
import { EyeOpenIcon } from "~/assets/icons/EyeOpenIcon";
import { DefaultButton } from "~/components/Buttons";
import { OnboardingBackButton } from "~/components/Buttons/back-buttons";
import { DefaultInputField } from "~/components/Inputs";
import { useVerifyCredentials } from "~/components/Modal/VerifyAccountModal";
import {
  Header4Medium,
  SubTextRegular,
  TitleRegular,
} from "~/components/Typography";
import { useAuthSession } from "~/hooks/use-auth";
import ShowToast, { notifyApiError } from "../../components/Toast";
import UserProgress from "../../components/UserProgress";

export default function Password({
  prev,
}: {
  prev: () => void;
  next: () => void;
}) {
  const [{ email, pass }] = useVerifyCredentials();
  const [, setAuthentication] = useAuthSession();

  const navigate = useNavigate();
  const { sessionId: session } = useParams({ strict: false });
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  function verifyPassword(password: string) {
    const errors = [];

    // Check minimum length
    if (password.length < 8) {
      errors.push("Min 8 characters long");
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      errors.push("At least one UPPERCASE letter");
    }

    // Check for at least one special character
    if (!/[!@#$%^&*?]/.test(password)) {
      errors.push("One unique character (e.g: !@#$%^&*?)");
    }

    if (!/\d/.test(password)) {
      errors.push("At least one number");
    }

    if (password !== password2) {
      errors.push("Password does not match");
    }

    return errors;
  }

  async function handleSubmit() {
    if (verifyPassword(password).length > 0) {
      ShowToast(verifyPassword(password).join(", "), "error");
      return;
    }
    setLoading(true);
    try {
      const res = await updateTempPassword(email, pass, password);
      if (res.status === 200) {
        setAuthentication(res.authentication);
        const inviteRes = await acceptBusinessInvite(
          session,
          res.authentication.accessToken,
        );
        if (inviteRes.status === 200) {
          ShowToast(inviteRes.message, "success");
          navigate({ to: "/dashboard" });
        } else {
          throw new Error(inviteRes.message);
        }
      } else {
        ShowToast(res.message, "error");
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="flex h-[auto] w-[90%] flex-col items-center pb-[100px] lg:w-[430px]">
        <UserProgress stages={2} progress={2} />
        <OnboardingBackButton onClick={() => prev()} />

        <div className="mt-[40px] text-center">
          <Header4Medium>Set a Password</Header4Medium>
          <TitleRegular>Create an admin account for your business</TitleRegular>
        </div>
        <div className="mt-[20px] w-[100%]">
          <DefaultInputField
            label="Business email"
            value={email}
            setValue={() => {}}
            disabled
          />
          <DefaultInputField
            label="New password"
            isPasswordInput={passwordVisible}
            value={password}
            setValue={setPassword}
            rtl
            icon={
              <button
                type={"button"}
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="cursor-pointer"
              >
                {passwordVisible ? <EyeCloseIcon /> : <EyeOpenIcon />}
              </button>
            }
            containerStyle={{ marginBottom: "10px", marginTop: "25px" }}
          />
          {password.length > 0 && (
            <>
              {verifyPassword(password).map((error: string, i: number) => (
                <span className="mb-[4px] flex items-center">
                  <AlertTriangleIcon />
                  <SubTextRegular className="ml-[10px]">{error}</SubTextRegular>
                </span>
              ))}
            </>
          )}
          <DefaultInputField
            label="Re-enter password"
            isPasswordInput={passwordVisible}
            value={password2}
            setValue={setPassword2}
            containerStyle={{ marginTop: "15px" }}
            rtl
            icon={
              <button
                type={"button"}
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="cursor-pointer"
              >
                {passwordVisible ? <EyeCloseIcon /> : <EyeOpenIcon />}
              </button>
            }
          />

          {/* <div className="flex items-center justify-between mt-[50px]">
            <span className="flex items-center">
              <AlertTriangleIcon />
              <SubTextMedium>Two factor Authentication</SubTextMedium>
            </span>
            <ToggleInput
              isToggled={use2fa}
              setIsToggled={setUse2fa}
              onToggle={() => {}}
            />
          </div> */}
          {/* <p
            style={{ color: "#4576C2", lineHeight: "21px" }}
            className={`text-zinc-700 text-xs font-bold leading-tight mt-[15px]`}
          >
            Add an extra layer of security to your account.
          </p> */}
        </div>

        <DefaultButton
          onClick={handleSubmit}
          className="mt-[70px] w-[100%] lg:w-[390px]"
          label="Continue"
          loading={loading}
        />
      </div>
    </>
  );
}
