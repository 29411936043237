import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from ".";
import styles from "../../general/styles.module.css";
import { useModals } from "../Redux/state/modals/hooks";

import { Checkbox } from "@mui/material";
import { transferFromCorporateWallet } from "~/api/wallets";
import { ArrowLeft } from "~/assets/icons/ArrowLeft";
import { ArrowRight } from "~/assets/icons/ArrowRight";
import { ArrowTopRight } from "~/assets/icons/ArrowTopRight";
import { GridIcon } from "~/assets/icons/GridIcon";
import { PlusIcon } from "~/assets/icons/PlusIcon";
import { UserRoleIcon } from "~/assets/icons/UserRoleIcon";
import { FiatCurrencyI, currencies } from "~/constants/currencies";
import { Recipient } from "~/general/interfaces";
import { displayMockUI } from "~/libs";
import CurrencyFormatter from "~/libs/CurrencyFormatter";
import { formatNumber, getFlagFromRegionCode } from "~/libs/currency.helpers";
import { DefaultButton } from "../Buttons";
import { Divider } from "../Divider";
import {
  CurrencySelectorInputLg,
  DefaultInputField,
  EscrowPayoutInput,
} from "../Inputs";
import { useLoaders } from "../Redux/state/loader/hooks";
import { useUserData } from "../Redux/state/user/hooks";
import { useWallets } from "../Redux/state/wallets/hooks";
import ShowToast, { notifyApiError } from "../Toast";
import Authenticator from "./2FaAuth";

function PayoutIntro({
  close,
  setDisplay,
  narration,
  setNarration,
  recipients,
  setRecipients,
  amountInt,
  setAmountInt,
  currency,
  setCurrency,
  amountMask,
  setAmountMask,
}: {
  close: () => void;
  setDisplay: Dispatch<SetStateAction<unknown>>;
  narration: string;
  setNarration: Dispatch<SetStateAction<string>>;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
  amountInt: number;
  setAmountInt: Dispatch<SetStateAction<number>>;
  currency: FiatCurrencyI;
  setCurrency: Dispatch<SetStateAction<FiatCurrencyI>>;
  amountMask: string;
  setAmountMask: Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="w-[610px] rounded-lg bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type="button"
          onClick={() => close()}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Transfer
        </span>
      </div>
      <div className="p-[15px]">
        <div className="p-[20px]">
          <div className="mb-[7px]">
            <span className="h-5 w-[528px] text-2xl font-bold leading-tight text-zinc-700">
              Amount
            </span>
          </div>
          <CurrencySelectorInputLg
            currencies={currencies}
            currency={currency}
            setCurrency={setCurrency}
            value={amountMask}
            setValue={setAmountMask}
            setIntValue={setAmountInt}
          />
        </div>

        <div
          className={`${styles.custom_scrollbar2} max-h-[270px] overflow-y-scroll p-[20px]`}
        >
          <div className="mb-[20px] flex items-center">
            <span className="text-base font-normal leading-tight text-zinc-500">
              Recipients
            </span>
            <div className="ml-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-[32px] bg-[#FFF9EB]">
              <span className="text-center text-xs font-bold leading-tight text-amber-500">
                {recipients.length}
              </span>
            </div>
          </div>
          {recipients.length > 0 ? (
            recipients.map((recipient, i) => (
              <div key={recipient.id}>
                <UserItem
                  user={recipient}
                  users={recipients}
                  setUsers={setRecipients}
                  showAmount
                />
              </div>
            ))
          ) : (
            <button
              type="button"
              onClick={() => setDisplay("add-recipients")}
              className="flex h-[110px] w-[100%] cursor-pointer items-center justify-between rounded-lg border-[1px] border-[#7F8C95] px-[25px]"
            >
              <div className="flex items-center">
                <GridIcon size="40" />
                <div className="ml-[15px] flex flex-col">
                  <span className="text-2xl font-normal leading-tight text-stone-600">
                    Add Recipients
                  </span>
                  <span className="text-sm font-normal leading-tight text-stone-600">
                    Connect and send USDC to a crypto wallet
                  </span>
                </div>
              </div>
              <ArrowRight color="grey" size="24" />
            </button>
          )}
        </div>

        <Divider />
        <div className="flex items-center justify-end">
          <button
            type="button"
            onClick={() => setDisplay("edit-amount")}
            className="flex cursor-pointer items-center"
          >
            <span className="mr-[5px] mt-[3px] text-xs font-bold leading-tight text-[#0094FF]">
              Customize Amount
            </span>
            <ArrowTopRight />
          </button>
        </div>
        <div className="mb-[30px] flex-col px-[20px]">
          <span className="h-5 text-2xl font-bold leading-tight text-zinc-700">
            Narration
          </span>
          <input
            placeholder="Narration"
            value={narration}
            onChange={(e) => setNarration(e.target.value)}
            className="mt-[10px] inline-flex h-[72px] w-[100%] items-center justify-center gap-2 rounded border border-slate-500 bg-white px-4 py-2"
          />
        </div>
        {recipients.length > 0 && (
          <div className="flex w-[100] flex-col items-center">
            <DefaultButton
              onClick={() => setDisplay("summary")}
              styles={{ width: "420px" }}
              disabled={amountInt === 0}
              label="Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

function UserItem({
  user,
  users,
  setUsers,
  showAmount,
}: {
  user: {
    id: number;
    firstname: string;
    lastname: string;
    username: string;
    amount: number;
  };
  users: {
    id: number;
    firstname: string;
    lastname: string;
    username: string;
    amount: number;
  }[];
  setUsers: Dispatch<
    SetStateAction<
      {
        id: number;
        firstname: string;
        lastname: string;
        username: string;
        amount: number;
      }[]
    >
  >;
  showAmount?: boolean;
}) {
  function isSelected() {
    const _ = users.find((s) => s.id === user.id);
    if (_) return true;
    return false;
  }

  function selectedUser() {
    if (isSelected()) {
      const filtered = users.filter((s) => s.id !== user.id);
      setUsers(filtered);
    } else {
      setUsers((prev) => {
        return [...prev, user];
      });
    }
  }

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      onClick={() => selectedUser()}
      className="my-[10px] flex cursor-pointer items-center justify-between py-[10px] pr-[10px] hover:bg-zinc-100"
    >
      <div className="flex items-center">
        <Checkbox checked={isSelected()} />
        <div className="ml-[4px] flex items-center">
          <img src="/user.png" alt="Anonymous user" />
          <div className="ml-[6px] flex flex-col">
            <span className="text-sm font-normal leading-tight text-gray-600">
              {user.firstname} {user.lastname}
            </span>
            <span className="mt-[5px] text-sm font-bold leading-tight text-gray-600">
              @{user.username}
            </span>
          </div>
        </div>
      </div>
      <div>
        {showAmount && (
          <span className="text-right text-2xl font-bold leading-loose text-zinc-500">
            {CurrencyFormatter.formatCurrency(user.amount)} USD
          </span>
        )}
      </div>
    </div>
  );
}

function AddEscrowPayoutRecipients({
  setDisplay,
  recipients,
  setRecipients,
}: {
  setDisplay: Dispatch<SetStateAction<unknown>>;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
  currency: FiatCurrencyI;
}) {
  const [searchVal, setSearchVal] = useState("");

  const users = displayMockUI()
    ? [
        {
          id: 1,
          firstname: "Chijioke",
          lastname: "Okonkwo",
          username: "chijioke_okonkwo",
          amount: 700,
        },
        {
          id: 2,
          firstname: "Ngozi",
          lastname: "Adekunle",
          username: "ngozi_adekunle",
          amount: 1200,
        },
        {
          id: 3,
          firstname: "Yusuf",
          lastname: "Ahmed",
          username: "yusuf_ahmed",
          amount: 1650,
        },
      ]
    : [];

  const filtered = React.useMemo(
    () =>
      users.filter((user) => {
        return (
          `${user.firstname} ${user.lastname}`
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) !== -1
        );
      }),
    [searchVal, users],
  );

  const result = filtered.length > 0 ? filtered : users;

  function isSelected(user) {
    const _ = recipients.find((s) => s.id === user.id);
    if (_) return true;
    return false;
  }

  function selectedUser(user) {
    if (isSelected(user)) {
      const filtered = recipients.filter((s) => s.id !== user.id);
      setRecipients(filtered);
    } else {
      setRecipients((prev) => {
        return [...prev, user];
      });
    }
  }

  return (
    <div className="w-[610px] rounded-lg bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type="button"
          onClick={() => setDisplay("intro")}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Add Recipients
        </span>
      </div>
      <div className="p-[15px]">
        <DefaultInputField
          placeholder="Search users"
          value={searchVal}
          setValue={setSearchVal}
        />
        <Divider className="my-[15px]" />
        <div
          className={`${styles.custom_scrollbar2} h-[290px] overflow-y-scroll`}
        >
          {result.map((user) => (
            <div key={user.id}>
              <UserItem
                user={user}
                users={recipients}
                setUsers={setRecipients}
              />
            </div>
          ))}
        </div>
        <div className="flex w-[100%] items-center justify-center">
          <DefaultButton
            label="Continue"
            disabled={recipients.length === 0}
            onClick={() => setDisplay("intro")}
            className="w-[80%]"
          />
        </div>
      </div>
    </div>
  );
}

function EditableUserItem({
  recipient,
  recipients,
  setRecipients,
  index,
}: {
  index: number;
  recipient: Recipient;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
}) {
  const [amount, setAmount] = useState(recipient.amount);
  const [mask, setMask] = useState(formatNumber(recipient.amount.toString()));

  function updateAmounts(val: number) {
    const updated = recipients.map((item) => {
      if (item.id === recipient.id) {
        return { ...recipient, amount: mask ? val : 0 };
      }
      return item;
    });
    if (!updated.some((item) => item.id === recipient.id)) {
      updated.push({ ...recipient });
    }
    setRecipients(updated);
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (amount) {
      if (amount === 0) {
      }
      updateAmounts(amount);
    }
  }, [mask, amount]);

  return (
    <div
      className={`my-[10px] flex cursor-pointer items-center justify-between py-[10px] pr-[10px] ${
        recipients.length !== index + 1 && "border-b-[1px] border-[#DEE5E7]"
      }`}
    >
      <div className="flex items-center">
        <div className="ml-[4px] flex items-center">
          <img src="/user.png" alt="Anonymous user" />
          <div className="ml-[6px] flex flex-col">
            <span className="text-sm font-normal leading-tight text-gray-600">
              {recipient.firstname} {recipient.lastname}
            </span>
            <span className="mt-[5px] text-sm font-bold leading-tight text-gray-600">
              @{recipient.username}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <EscrowPayoutInput
          value={mask}
          setValue={setMask}
          setIntValue={setAmount}
        />
        <span className="ml-[10px] text-right text-2xl font-normal leading-loose text-gray-700">
          USD
        </span>
      </div>
    </div>
  );
}

function CustomizeEscrowPayout({
  setDisplay,
  recipients,
  setRecipients,
  amount,
  currency,
}: {
  setDisplay: Dispatch<SetStateAction<unknown>>;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
  currency: FiatCurrencyI;
  amount: number;
}) {
  return (
    <div className="w-[610px] rounded-lg bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type="button"
          onClick={() => setDisplay("intro")}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Customize Amount
        </span>
      </div>
      <div className="p-[15px]">
        <div className="mb-[20px]">
          <span className="text-xl font-bold leading-loose text-stone-600">
            How much should each recipient get?
          </span>
        </div>
        <div className="mb-[20px] flex items-center">
          <span className="text-base font-normal leading-tight text-zinc-500">
            Recipients
          </span>
          <div className="ml-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-[32px] bg-[#FFF9EB]">
            <span className="text-center text-xs font-bold leading-tight text-amber-500">
              {recipients.length}
            </span>
          </div>
        </div>

        <div className="rounded p-[10px] shadow">
          {recipients.map((recipient, i) => (
            <div>
              <EditableUserItem
                index={i}
                recipient={recipient}
                recipients={recipients}
                setRecipients={setRecipients}
              />
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={() => setDisplay("add-recipients")}
          className="my-[20px] flex cursor-pointer items-center justify-center"
        >
          <span className="mr-[5px] text-xs font-bold leading-tight text-[#0094FF]">
            Add another Recipient
          </span>
          <PlusIcon color="#0094FF" size="15" />
        </button>
        <Divider className="my-[20px]" />
        <div className="mt-[20px] p-[20px]">
          <span className="text-center text-xl font-bold leading-loose text-stone-600">
            Transfer Total
          </span>
          <div className="flex items-center justify-between">
            <span className="flex items-center">
              <img src={getFlagFromRegionCode("US")} alt="usd_flag" />
              <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                USD
              </span>
            </span>
            <span className="text-[32px] font-bold leading-10 text-cyan-950">
              {CurrencyFormatter.formatCurrency(amount)}
            </span>
          </div>
        </div>
        <div className="flex w-[100%] items-center justify-center">
          <DefaultButton
            label="Continue"
            onClick={() => setDisplay("intro")}
            className="w-[80%]"
          />
        </div>
      </div>
    </div>
  );
}

function CorporateWalletPayoutSummary({
  setDisplay,
  recipients,
  narration,
  setNarration,
  setRecipients,
  close,
  amount,
  currency,
}: {
  setDisplay: Dispatch<SetStateAction<unknown>>;
  recipients: Recipient[];
  narration: string;
  setNarration: Dispatch<SetStateAction<string>>;
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
  close: () => void;
  amount: number;
  currency: FiatCurrencyI;
}) {
  const [auth, setAuth] = useState(false);
  const { userDetails, businessDetails } = useUserData();
  const { showEllipsisLoader, closeEllipsisLoader } = useLoaders();
  const { corporateWalletLogs, setCorporateWalletLogs } = useWallets();

  function success() {
    setDisplay("intro");
    setRecipients([]);
    setNarration("");
    close();
  }

  const sum = recipients.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.amount;
  }, 0);

  async function initiatePayout() {
    try {
      showEllipsisLoader();
      const initiator = `${userDetails?.firstName} ${userDetails?.lastName}`;
      const res = await transferFromCorporateWallet(
        businessDetails?.id,
        amount,
        initiator,
      );
      if (res.status === 200) {
        success();
        setCorporateWalletLogs([res.transaction, ...corporateWalletLogs]);
        ShowToast(res.message, "success");
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      closeEllipsisLoader();
    }
  }

  return (
    <>
      <div className="w-[610px] rounded-lg bg-[white]">
        <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type="button"
            onClick={() => setDisplay("intro")}
            className="flex cursor-pointer items-center"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
          </button>
          <span
            className={
              "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
            }
          >
            Summary
          </span>
        </div>
        <div className="p-[15px]">
          <div className="mt-[20px] p-[10px]">
            <span className="text-center text-base font-normal leading-loose text-stone-600">
              You Transfer
            </span>
            <div className="flex items-center justify-between ">
              <span className="flex items-center">
                <img src={getFlagFromRegionCode("NG")} alt="Nigerian flag" />
                <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                  {currency.code}
                </span>
              </span>
              <span className="text-[32px] font-bold leading-10 text-cyan-950">
                {CurrencyFormatter.formatCurrency(sum)}
              </span>
            </div>
            <span className="mb-[20px] mt-[10px] inline-flex w-[100%] items-center justify-start gap-1">
              <span className="text-xs font-light leading-tight text-[#F05050]">
                Fees Applied
              </span>
              <span className="text-xs font-bold leading-tight text-[#CA1111]">
                {(amount * 0.25) / 100} USDC /
              </span>
              <span className="text-xs font-light leading-tight text-[#CA1111]">
                0.25 %
              </span>
            </span>
          </div>
          <div className="mb-[20px] flex items-center pl-[10px]">
            <span className="text-base font-normal leading-tight text-zinc-500">
              Recipients
            </span>
            <div className="ml-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-[32px] bg-[#FFF9EB]">
              <span className="text-center text-xs font-bold leading-tight text-amber-500">
                {recipients.length}
              </span>
            </div>
          </div>

          <div
            className={`${styles.custom_scrollbar2} max-h-[220px] overflow-y-scroll rounded p-[10px]`}
          >
            {recipients.map((recipient, i) => (
              <div
                key={recipient.id}
                className={
                  "my-[10px] flex items-center justify-between py-[10px] pr-[10px]"
                }
              >
                <div className="flex items-center">
                  <div className="ml-[4px] flex items-center">
                    <img src="/user.png" alt="Anonymous user" />
                    <div className="ml-[6px] flex flex-col">
                      <span className="text-sm font-normal leading-tight text-gray-600">
                        {recipient.firstname} {recipient.lastname}
                      </span>
                      <span className="mt-[5px] text-sm font-bold leading-tight text-gray-600">
                        @{recipient.username}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="text-right text-2xl font-bold leading-loose text-zinc-500">
                    {CurrencyFormatter.formatCurrency(recipient.amount)}
                  </span>
                  <span className="ml-[10px] text-right text-2xl font-normal leading-loose text-gray-700">
                    {currency.code}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="my-[30px] flex-col px-[20px]">
            <span className="h-5 text-2xl font-bold leading-tight text-zinc-700">
              Narration
            </span>
            <input
              placeholder="Narration"
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
              className="mb-[15px] mt-[10px] inline-flex h-[72px] w-[100%] items-center justify-center gap-2 rounded border border-slate-500 bg-white px-4 py-2"
            />
            <DefaultInputField
              label="Admin"
              value={`${userDetails?.firstName} ${userDetails?.lastName}`}
              setValue={setNarration}
              disabled
              icon={<UserRoleIcon />}
            />
          </div>
          <Divider className="my-[20px]" />
          <div className="mb-[20px] flex w-[100%] items-center justify-center">
            <DefaultButton
              label="Continue"
              onClick={() => setAuth(true)}
              className="w-[80%]"
            />
          </div>
        </div>
      </div>
      <Authenticator
        modal={auth}
        showModal={setAuth}
        onAuthSuccess={() => initiatePayout()}
      />
    </>
  );
}

export function CorporateWalletPayoutModal() {
  const [view, setView] = useState<"wallets" | "view-wallets" | "payout">(
    "wallets",
  );
  const { globalModalState, updateGlobalModalState } = useModals();
  const [walletDetails, setWalletDetails] = useState(null);
  const [amountInt, setAmountInt] = useState(0);
  const [amountMask, setAmountMask] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [narration, setNarration] = useState("");
  const [display, setDisplay] = useState<
    "intro" | "add-recipients" | "edit-amount" | "summary"
  >("intro");
  const [currency, setCurrency] = useState(currencies[0]);

  function reset() {
    setAmountInt(0);
    setAmountMask("");
    setRecipients([]);
  }

  return (
    <Modal modal={globalModalState.corporatePayoutModal} disableBackdropClose>
      {/* {view === "wallets" && <Wallets setWalletDetails={setWalletDetails} />}
      {view === "view-wallets" && <ViewWallet goBack={goBack} walletDetails={walletDetails} />} */}
      {display === "intro" && (
        <PayoutIntro
          close={() => updateGlobalModalState({ corporatePayoutModal: false })}
          setDisplay={setDisplay}
          narration={narration}
          setNarration={setNarration}
          recipients={recipients}
          setRecipients={setRecipients}
          amountInt={amountInt}
          setAmountInt={setAmountInt}
          currency={currency}
          setCurrency={setCurrency}
          amountMask={amountMask}
          setAmountMask={setAmountMask}
        />
      )}
      {display === "add-recipients" && (
        <AddEscrowPayoutRecipients
          setDisplay={setDisplay}
          recipients={recipients}
          setRecipients={setRecipients}
          currency={currency}
        />
      )}
      {display === "edit-amount" && (
        <CustomizeEscrowPayout
          setDisplay={setDisplay}
          recipients={recipients}
          setRecipients={setRecipients}
          amount={amountInt}
          currency={currency}
        />
      )}
      {display === "summary" && (
        <CorporateWalletPayoutSummary
          amount={amountInt}
          currency={currency}
          setDisplay={setDisplay}
          recipients={recipients}
          narration={narration}
          setNarration={setNarration}
          setRecipients={setRecipients}
          close={() => {
            updateGlobalModalState({ corporatePayoutModal: false });
            reset();
          }}
        />
      )}
    </Modal>
  );
}
