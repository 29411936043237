import React from "react";
import { createAccount } from "~/api/auth";
import { DefaultButton } from "~/components/Buttons";
import { DefaultInputField } from "~/components/Inputs";
import VerifyAccountModal from "~/components/Modal/VerifyAccountModal";
import ShowToast, { notifyApiError } from "~/components/Toast";
import { Header4Medium, TitleRegular } from "~/components/Typography";
import UserProgress from "~/components/UserProgress";

import { useNavigate, useParams } from "@tanstack/react-router";
import { useGetInviteSessionMetadata } from "~/api/codegen/walletosComponents";
import { UserRoleIcon } from "~/assets/icons/UserRoleIcon";
import { FullscreenLoader } from "~/components/Modal";

export default function Account({
  next,
}: {
  prev: () => void;
  next: () => void;
}) {
  const navigate = useNavigate();
  const { sessionId } = useParams({ strict: false });

  const [loading, showLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [role, setRole] = React.useState("Super-Admin");
  const [openModal, setOpenModal] = React.useState(false);

  const handleVerify = async () => {
    try {
      showLoading(true);
      const res = await createAccount(firstName, lastName, email);
      if (res.status === 200) {
        ShowToast(res.message, "success");
        setOpenModal(true);
      } else {
        ShowToast(res.message, "error");
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      showLoading(false);
    }
  };

  const {
    status,
    isLoading,
    data: inviteMetadata,
    error,
  } = useGetInviteSessionMetadata({
    pathParams: { session: sessionId },
  });

  React.useEffect(() => {
    if (status === "error") {
      navigate({ to: "/signin" })
        // @ts-expect-error
        .then(() => notifyApiError(error));
    }

    if (status === "success" && inviteMetadata?.invitation?.email) {
      setEmail(inviteMetadata?.invitation?.email);
    }
  }, [status, error, navigate, inviteMetadata]);

  return (
    <>
      {isLoading ? <FullscreenLoader /> : null}

      <div className="flex h-[auto] w-[90%] flex-col items-center pb-[100px] lg:w-[auto]">
        {/* <OnboardingProgess progress={1} /> */}
        <UserProgress stages={2} progress={1} />

        <div className="mt-[40px] text-center">
          <Header4Medium>Get Started</Header4Medium>
          <TitleRegular>Create an admin account for your business</TitleRegular>
        </div>

        <div className="mt-[20px] w-[100%]">
          <div className="mt-[15px] flex w-[100%] justify-between gap-x-6">
            <DefaultInputField
              label="First name"
              placeholder="John"
              value={firstName}
              setValue={setFirstName}
            />
            <DefaultInputField
              label="Last name"
              value={lastName}
              placeholder="Doe"
              setValue={setLastName}
            />
          </div>
          <DefaultInputField
            label="Business email"
            value={email}
            disabled
            setValue={setEmail}
            containerStyle={{ marginTop: "25px" }}
          />
          <DefaultInputField
            label="Role"
            value={role}
            setValue={setRole}
            disabled
            icon={<UserRoleIcon />}
            containerStyle={{ marginTop: "35px" }}
          />
        </div>

        <DefaultButton
          onClick={handleVerify}
          className="mt-[70px] w-[100%]"
          label="Continue"
          loading={loading}
        />
      </div>

      <VerifyAccountModal
        open={openModal}
        setOpen={setOpenModal}
        email={email}
        onSuccess={next}
      />
    </>
  );
}
