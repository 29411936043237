import { createFileRoute, useSearch } from "@tanstack/react-router";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Avatar, AvatarFallback, AvatarImage } from "~/@/components/ui/avatar";
import Cube from "~/assets/icons/cube.svg?react";
import { CopyButton } from "~/components/Buttons/copy-button";
import * as BalanceCard from "~/components/organisms/BalanceCard";
import { CentralUserWalletVaultBalanceCard } from "~/components/DashboardComponents/centraluserwallet";
import { UserWalletLog } from "~/components/DashboardComponents/shared";
import { Divider } from "~/components/Divider";
import * as Balance from "~/components/organisms/BalanceCard";
import { Badge } from "~/components/atoms/Badge";
import { CardBody, CardRoot } from "~/components/layouts/Card";
import { ArrowTopRight, ArrowDown } from "~/assets/icons";
import { addDays } from "date-fns";
import {
  ArrowDownToLine,
  Ban,
  Flag,
  Info,
  Octagon,
  ChevronRight,
} from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import {
  useEnforceActionType,
  useGetUserAccountMetadata,
} from "~/api/codegen/walletosComponents";
import { CountBadge, NumberBadge } from "~/components/Badges";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { RequestPayout } from "~/components/Modal/RequestPayout";
import {
  RequestBanModal,
  RequestBlockModal,
} from "~/components/Modal/UserDetails";
import { TabButton } from "~/components/Tabs";
import { notify, notifyApiError, notifySuccess } from "~/components/Toast";
import * as DropdownButton from "~/components/molecules/DropdownButton";
import { useGetBusinessId } from "~/hooks/use-business";
import { useModal } from "~/hooks/use-modal";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { safeFormat } from "~/libs/date.helpers";
import {
  BasicUser,
  CentralUserStatus,
  UserFactoryImpl,
  userBadgeStatus,
} from "~/libs/factories/user";

import {
  BalanceContent,
  BalanceCurrencyOptions,
  BalanceHeader,
  BalanceRoot,
  BalanceValue,
} from "~/components/organisms/BalanceCard";
import { setCurrency, useSharedCurrency } from "~/contexts/currency";
import { WalletHolderImpl, useUserWallets } from "~/hooks/use-wallet";

import { inRange } from "lodash";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { Button } from "~/@/components/ui/button";
import { empty_state_no_cards } from "~/assets";
import { SubwalletCard } from "~/components/subwallet-card";
import { useConvert } from "~/hooks/use-currency-rates";
import {
  BalanceMap,
  getOptionsFromValueMap,
} from "~/libs/factories/balance-map";
import { WalletFactory } from "~/libs/factories/wallet-factory";
import { SectionHeading } from "~/components/atoms/heading";
import { useUserMetrics } from "~/hooks/use-user-metrics";
import { useRetrieveVirtualCollectionAccount } from "~/api/codegen/liquidityComponents";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { CardLikeButtonSkeleton } from "~/components/Buttons/CardLikeButtonSkeleton";
import { BankFundingIcon, CopyIcon } from "~/assets/icons";
import { Alert } from "~/components/atoms/Alert";
import { DefaultInputField } from "~/components/Inputs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";

export const Route = createFileRoute("/dashboard/wallet/users/$user_id")({
  component: UserDetails,
});

function UserDetails() {
  const [currentIndex, setWalletIndex] = React.useState(0);
  const [activeCard, setActiveCard] = React.useState<string>("User Account");
  const [showDetails, setShowDetails] = React.useState<boolean>(false);

  const modal = useModal();
  const { user_id } = useSearch({ from: Route.fullPath });

  const businessId = useGetBusinessId();
  const { data: wallets } = useUserWallets(user_id);

  const changeStatusMutate = useEnforceActionType();

  const { data: user_res, refetch } = useGetUserAccountMetadata({
    pathParams: { accountId: user_id, businessId },
  });

  // console.log(user_res);

  const user = BasicUser({
    id: user_res?.account?.id ?? null,
    external_id: user_res?.externalId,
    firstname: user_res?.account?.firstName ?? "",
    lastname: user_res?.account?.lastName ?? "",
    status: user_res?.account?.status,
    role: user_res?.association?.user_id,
    email: user_res?.account?.email,
  });

  const current_wallet = WalletFactory(wallets[currentIndex]);
  // console.log(current_wallet);

  return (
    <div className={"mt-6 flex flex-col gap-y-2"}>
      <CardRoot>
        <CardBody className={"py-8"}>
          <div className="flex items-center justify-between">
            <div className={"flex items-center justify-start gap-4"}>
              <Avatar className={"h-[50px] w-[50px]"}>
                <AvatarImage src={undefined} alt="@shadcn" />
                <AvatarFallback>
                  {user.full_name ? user.firstname[0] : user.email[0]}
                </AvatarFallback>
              </Avatar>

              <div className={"flex flex-col"}>
                <div className="flex gap-4">
                  <p className={"text-[24px] font-bold text-[#303940]"}>
                    {user.full_name}
                  </p>
                  {/* <Badge type={userBadgeStatus(user.status)} size={"sm"}>
                    {user.status}
                  </Badge> */}
                  <UserStatus
                    status={user.status}
                    user_id={user_id}
                    refetch={refetch}
                  />
                </div>

                <div className={"flex items-center gap-2 text-sm"}>
                  <span className={"text-[#9BA8B5]"}>
                    {UserFactoryImpl.shortId(user)}
                  </span>

                  <CopyToClipboard
                    text={user.user_id}
                    onCopy={() => {
                      notify("success", "User ID copied");
                    }}
                  >
                    <CopyButton>
                      <span className={"font-semibold"}>Copy ID</span>
                    </CopyButton>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            <Button
              variant="outline"
              type="button"
              className="-mb-12 space-x-2 border-none"
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            >
              <ArrowDown />
            </Button>
          </div>

          {showDetails && <UserDetailsFields user={user} />}
        </CardBody>
      </CardRoot>

      <div className={"mt-4 flex flex-col gap-y-4"}>
        <div className="flex gap-x-4 rounded-md border p-4">
          <UserWalletBalance
            userId={user.external_id}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />
          <UserVaultBalance
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />
        </div>

        <div className={"mt-2 gap-4 rounded-md border p-4 py-4"}>
          <UserSubWallets
            userId={user.external_id}
            currentIndex={currentIndex}
            onSwitch={(new_index) => {
              if (inRange(new_index, 0, wallets.length)) {
                setWalletIndex(new_index);
              }
            }}
          />
          {/* <VaultWallets /> */}
        </div>

        <div className={"mt-2 gap-4 rounded-md border p-4 py-4"}>
          {pipe(
            Match.value(current_wallet),
            Match.when({ kind: "USER" }, (data) => {
              return (
                <UserCollectionAccount
                  userId={user.external_id}
                  walletId={data.id}
                />
              );
            }),
            Match.orElse(() => null),
          )}
        </div>
      </div>

      {/* <Divider /> */}

      {pipe(
        Match.value(current_wallet),
        Match.when({ kind: "USER" }, (data) => {
          return (
            <div className={"mt-4 gap-4 rounded-md border p-4 py-4"}>
              <UserWalletLog walletId={data.id} />
            </div>
          );
        }),
        Match.orElse(() => {
          return (
            <div className={"mt-4 gap-4 rounded-md border p-4 py-4"}>
              <UserWalletLog />
            </div>
          );
        }),
      )}

      <RequestBlockModal onSuccess={() => refetch()} />
      <RequestBanModal onSuccess={() => refetch()} />
      <RequestPayout />
    </div>
  );
}

function UserWalletBalance(props: {
  userId: string;
  className?: string;
  activeCard?: string;
  setActiveCard?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const currency = useSharedCurrency();

  const res = useUserWallets(props.userId);
  // console.log(res.data);
  const holder = WalletHolderImpl.create(res.data);
  const balances = WalletHolderImpl.calculateTotal(holder);
  const { isLoading, data } = useConvert(currency.code, balances);

  return (
    <BalanceRoot
      className={`cursor-pointer ${
        props.activeCard === "User Account" ? "bg-primary/10" : ""
      }  ${props.className}`}
      onClick={() =>
        props.setActiveCard ? props.setActiveCard("User Account") : {}
      }
    >
      <BalanceHeader headingText={"Aggregate Balance"} />
      <BalanceContent className="py-5">
        <BalanceCurrencyOptions
          currency={currency}
          readOnly={true}
          options={getOptionsFromValueMap(balances)}
          setCurrency={(val) => setCurrency(val)}
        />
        <BalanceValue
          isLoading={isLoading}
          balance={data.amount}
          currency={currency}
        />
      </BalanceContent>
    </BalanceRoot>
  );
}

function UserVaultBalance(props: {
  title?: string;
  className?: string;
  activeCard?: string;
  setActiveCard?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const currency = useSharedCurrency();
  const { data: metrics } = useUserMetrics();

  const { isLoading, data } = useConvert(
    currency.code,
    BalanceMap.create(metrics?.vaultsWallet?.volume),
  );

  return (
    <BalanceCard.Card
      className={`cursor-pointer ${
        props?.activeCard === "User Vault" ? "bg-primary/10" : ""
      }  ${props.className}`}
      onClick={() =>
        props?.setActiveCard ? props?.setActiveCard("User Account") : {}
      }
    >
      <BalanceCard.Header headingText={props.title ?? "Vault Balance"} />
      <BalanceCard.Content className="py-5">
        <BalanceCard.CurrencySwitcher
          currency={currency}
          readOnly={true}
          options={getOptionsFromValueMap(metrics?.vaultsWallet?.volume)}
          setCurrency={setCurrency}
        />
        <BalanceCard.Balance
          isLoading={isLoading}
          balance={data.amount}
          currency={currency}
          showIcon={false}
        />
      </BalanceCard.Content>
    </BalanceCard.Card>
  );
}

function VaultCard(props: {
  data: { vault_name: string; end_date: Date; status: string };
  balance: { symbol: string; value: number };
}) {
  const { data, balance } = props;

  return (
    <CardRoot>
      <CardBody className={"py-4"}>
        <div className={"mt-1 flex justify-between"}>
          <div className={"flex items-center gap-x-2"}>
            <Cube />
            <span className={"font-semibold"}>{data.vault_name}</span>
          </div>

          <span className={"-mr-2 rounded-full border p-1 px-2 text-xs"}>
            {data.status}
          </span>
        </div>

        <p className={"pb-3 pt-4 text-lg font-semibold text-neutral-500"}>
          {balance.symbol}{" "}
          {formatNumber(String(balance.value), { decimal: true })}
        </p>

        <p className={"flex items-center space-x-1 text-sm text-neutral-500"}>
          <span className={"text-muted-foreground"}>End date - </span>
          <span className={"text-foreground"}>
            {safeFormat(data.end_date, "io MMM, yyyy")}
          </span>
        </p>
      </CardBody>
    </CardRoot>
  );
}

function UserDetailsFields(props: { user: ReturnType<typeof BasicUser> }) {
  const {
    external_id,
    firstname,
    full_name,
    lastname,
    status,
    email,
    user_id,
    role,
    $original,
  } = props.user;

  return (
    <>
      <CardRoot className="mt-6 border-0  shadow-md">
        <CardBody className={"px-6 py-6"}>
          <div className="my-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              First Name
            </span>
            <DefaultInputField
              disabled
              value={firstname}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
            />
          </div>
          <div className="my-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Last Name
            </span>
            <DefaultInputField
              disabled
              value={lastname}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              User Email
            </span>
            <DefaultInputField
              disabled
              value={email}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Complince Tier
            </span>
            <DefaultInputField
              disabled
              value={""}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Account ID
            </span>
            <DefaultInputField
              disabled
              value={""}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
              icon={<CopyIcon color="#909090" h="16" w="16" />}
              rtl
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Centry ID
            </span>
            <DefaultInputField
              disabled
              value={""}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
              icon={<CopyIcon color="#909090" h="16" w="16" />}
              rtl
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Country of Residence
            </span>
            <DefaultInputField
              disabled
              value={""}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
              icon={<CopyIcon color="#909090" h="16" w="16" />}
              rtl
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Phone Number
            </span>
            <DefaultInputField
              disabled
              value={""}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
              icon={<CopyIcon color="#909090" h="16" w="16" />}
              rtl
            />
          </div>
          <div className="mb-[20px] flex items-center justify-between">
            <span className="text-sm font-bold leading-tight text-stone-600">
              Wallet Address
            </span>
            <DefaultInputField
              disabled
              value={""}
              setValue={() => {}}
              containerStyle={{ width: "400px" }}
              icon={<CopyIcon color="#909090" h="16" w="16" />}
              rtl
            />
          </div>
          {/* <div className="flex w-[100%] items-center justify-center">
        <DefaultButton
          onClick={() => showRequestPayoutModal(true)}
          label="Request Payout to User"
          className="h-[32px] w-[208px] bg-[#0094FF]"
          styles={{ height: "32px" }}
          icon={<PlusIcon color="white" />}
          disabled={displayMockUI() ? false : true}
        />
        <DefaultButtonOutline
          onClick={() => showRequestBan(true)}
          label="Suspend"
          textStyle={{ color: "#57584E" }}
          className="mx-[30px] h-[32px] w-[114px] bg-[#f7f7f7]"
          styles={{ height: "32px" }}
          icon={<DisputesIcon />}
          rtl
        />
        <DefaultButton
          onClick={() => showRequestBlock(true)}
          label="Block"
          className="h-[32px] w-[93px] bg-[#F05051]"
          styles={{ height: "32px" }}
          icon={<HelpIcon />}
          rtl
        />
      </div> */}
        </CardBody>
      </CardRoot>
    </>
  );
}

function UserSubWallets({
  userId,
  currentIndex,
  onSwitch,
}: {
  userId: string;
  currentIndex: number;
  onSwitch: (a: number) => void;
}) {
  const res = useUserWallets(userId);
  // console.log(res.data);

  const wallet_holder = WalletHolderImpl.create(res.data);
  const wallets = Array.from(WalletHolderImpl.toList(wallet_holder));

  const tabs = [
    { label: "Cash", value: "cash", isActive: true },
    { label: "Crypto", value: "crypto", isActive: false },
    { label: "Collectibles", value: "collectibles", isActive: false },
  ];

  return (
    <Tabs defaultValue={"cash"} className="w-full">
      <SectionHeading className="mb-4 flex items-center justify-between">
        <div className={"flex gap-x-3 py-1"}>
          <span className={"text-[#797D81]"}>Wallets</span>
          <NumberBadge label="pending" count={wallets.length ?? 0} />
        </div>
        <TabsList className={"gap-x-5 bg-transparent"}>
          {tabs.map((e) => (
            <TabsTrigger
              key={e.label}
              value={e.value}
              asChild
              disabled={!e.isActive}
            >
              <TabButton>{e.label}</TabButton>
            </TabsTrigger>
          ))}
        </TabsList>
      </SectionHeading>

      <TabsContent value={"cash"}>
        <EmptyStateRoot isEmpty={wallets.length === 0}>
          <EmptyStateContent className="">
            <div className="flex flex-col items-center">
              <img
                src={empty_state_no_cards}
                alt="Two Floating Cards"
                className="aspect-[3/2] max-w-[30ch] object-contain"
              />

              <div className="flex flex-col items-center gap-2 pb-8">
                <EmptyStateDescription>
                  All cash wallets will appear here
                </EmptyStateDescription>
              </div>
            </div>
          </EmptyStateContent>

          <EmptyStateConceal>
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
              {wallets.map((e, index) => {
                return (
                  <SubwalletCard
                    type="button"
                    key={e.id}
                    currency={getFiatCurrency(e.currency)}
                    balance={e.balance}
                    // data-active={index === currentIndex}
                    onClick={() => onSwitch(index)}
                    className={index === currentIndex ? "bg-primary/10" : ""}
                  />
                );
              })}
            </div>
          </EmptyStateConceal>
        </EmptyStateRoot>
      </TabsContent>
    </Tabs>
  );
}

function VaultWallets() {
  return (
    <>
      <Tabs className={"col-span-3"}>
        <div className={"flex items-center justify-between"}>
          <div className={"flex gap-x-3 py-1"}>
            <span className={"text-[#797D81]"}>Vaults</span>
            <CountBadge size="sm" count={3} />
          </div>

          <TabsList asChild>
            <div className={"gap-x-2 bg-transparent !p-0"}>
              {[
                { label: "All", key: "all" },
                { label: "Completed", key: "completed" },
                { label: "Suspended", key: "suspended" },
                { label: "Closed", key: "closed" },
              ].map((e) => {
                return (
                  <TabsTrigger key={e.key} value={e.key} asChild>
                    <TabButton className={"!pb-1"}>{e.label}</TabButton>
                  </TabsTrigger>
                );
              })}
            </div>
          </TabsList>
        </div>
      </Tabs>

      <VaultCard
        data={{
          vault_name: "Tournament 1",
          end_date: addDays(new Date(), 1),
          status: "closed",
        }}
        balance={{ value: 102944, symbol: "$" }}
      />

      <VaultCard
        data={{
          vault_name: "Tournament 2",
          end_date: addDays(new Date(), 4),
          status: "active",
        }}
        balance={{ value: 12944, symbol: "$" }}
      />
      <VaultCard
        data={{
          vault_name: "Tournament 3",
          end_date: addDays(new Date(), 12),
          status: "completed",
        }}
        balance={{ value: 1200, symbol: "$" }}
      />
    </>
  );
}

function UserCollectionAccount({
  userId,
  walletId,
}: {
  userId: string;
  walletId?: string;
}) {
  const res = useUserWallets(userId);
  const businessId = useGetBusinessId();

  const { data, isLoading, refetch } = useRetrieveVirtualCollectionAccount(
    {
      pathParams: {
        businessId,
        walletId: walletId,
      },
    },
    {
      enabled: !!walletId,
    },
  );

  const collectionAccount = useMemo(() => {
    return data?.data?.collectionAccount ?? {};
  }, [data?.data?.collectionAccount]);

  const tabs = [
    { label: "Local", value: "local", isActive: true },
    { label: "International/Swift", value: "swift", isActive: false },
  ];
  return (
    <Tabs defaultValue={"local"} className="w-full">
      <SectionHeading className="mb-4 flex items-center justify-between">
        <div className={"flex gap-x-3 py-1"}>
          <span className={"text-[#797D81]"}>Collection Account</span>
        </div>
        <TabsList className={"gap-x-5 bg-transparent"}>
          {tabs.map((e) => (
            <TabsTrigger
              key={e.label}
              value={e.value}
              asChild
              disabled={!e.isActive}
            >
              <TabButton>{e.label}</TabButton>
            </TabsTrigger>
          ))}
        </TabsList>
      </SectionHeading>

      <TabsContent value={"local"}>
        <EmptyStateRoot
          // isEmpty={true}
          isEmpty={Object.keys(collectionAccount).length === 0 || isLoading}
        >
          <EmptyStateContent>
            <div className="flex flex-col items-center">
              <img
                src={empty_state_no_cards}
                alt="Nope!"
                className="aspect-[3/2] max-w-[30ch] object-contain"
              />

              <div className="flex flex-col items-center gap-2">
                <EmptyStateDescription className="text-gray-200">
                  Collection account details will appear here
                </EmptyStateDescription>

                {collectionAccount?.currency === "USD" ? (
                  <Button
                    type="button"
                    variant="link"
                    size="sm"
                    onClick={async () => {
                      await refetch();
                    }}
                  >
                    Collection account will appear here
                  </Button>
                ) : // <DialogRemoteTrigger
                //   modalId={"add_web_hook"}
                //   params={{
                //     mode: "create",
                //   }}
                //   className=""
                //   disabled={true}
                // >
                //   <Button type="button" variant="link" size="sm">
                //     Setup up collection account
                //   </Button>
                // </DialogRemoteTrigger>
                null}
              </div>
            </div>
          </EmptyStateContent>

          <EmptyStateConceal>
            <Balance.Card>
              <SkeletonContent
                isLoading={isLoading}
                Component={CardLikeButtonSkeleton}
              >
                <div className="flex items-center justify-between px-3">
                  <SectionHeading className="w-full">
                    <BankFundingIcon />
                    <span className="font-semibold text-gray-800">
                      {collectionAccount?.currency} ACCOUNT
                    </span>
                  </SectionHeading>
                </div>
                <Divider className="my-5" />
                <div className="flex w-full gap-28 px-3 pb-10">
                  <div className="w-full">
                    {collectionAccount?.accountName && (
                      <>
                        <div className="w-full">
                          <p className="font-light text-gray-800">
                            Account Name
                          </p>
                          <div className="flex items-center justify-between">
                            <p className="font-medium text-gray-800">
                              {collectionAccount?.accountName}
                            </p>
                            {collectionAccount?.accountName && (
                              <CopyToClipboard
                                text={`${collectionAccount?.accountName}`}
                                onCopy={() => {
                                  notify(
                                    "success",
                                    `${collectionAccount?.accountName} copied`,
                                  );
                                }}
                              >
                                <button type="button" className={""}>
                                  <CopyIcon h="16" w="16" color="#4084B5" />
                                </button>
                              </CopyToClipboard>
                            )}
                          </div>
                        </div>
                        <Divider className="my-5" />
                      </>
                    )}
                    {collectionAccount?.bankName && (
                      <div className="w-full">
                        <p className="font-light text-gray-800">Bank Name</p>
                        <div className="flex items-center justify-between">
                          <p className="font-medium text-gray-800">
                            {collectionAccount?.bankName}
                          </p>
                          {collectionAccount?.bankName && (
                            <CopyToClipboard
                              text={`${collectionAccount?.bankName}`}
                              onCopy={() => {
                                notify(
                                  "success",
                                  `${collectionAccount?.bankName} copied`,
                                );
                              }}
                            >
                              <button type="button" className={""}>
                                <CopyIcon h="16" w="16" color="#4084B5" />
                              </button>
                            </CopyToClipboard>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    {collectionAccount?.accountNumber && (
                      <>
                        <div className="w-full">
                          <p className="font-light text-gray-800">Account/No</p>
                          <div className="flex items-center justify-between">
                            <p className="font-medium text-gray-800">
                              {" "}
                              {collectionAccount?.accountNumber}
                            </p>
                            {collectionAccount?.accountNumber && (
                              <CopyToClipboard
                                text={`${collectionAccount?.accountNumber}`}
                                onCopy={() => {
                                  notify(
                                    "success",
                                    `${collectionAccount?.accountNumber} copied`,
                                  );
                                }}
                              >
                                <button type="button" className={""}>
                                  <CopyIcon h="16" w="16" color="#4084B5" />
                                </button>
                              </CopyToClipboard>
                            )}
                          </div>
                        </div>
                        <Divider className="my-5" />
                      </>
                    )}
                    {collectionAccount?.routing &&
                      Object.keys(collectionAccount?.routing).length !== 0 && (
                        <div className="w-full">
                          <p className="font-light text-gray-800">Routing/No</p>
                          <div className="flex items-center justify-between">
                            <ul className="w-full">
                              {Object.keys(collectionAccount?.routing)?.map(
                                (key: string, i: number) => (
                                  <li key={`${i}-${key}`} className="py-2">
                                    {collectionAccount?.routing?.[key]
                                      ?.routing && (
                                      <div className="flex items-center justify-between font-medium text-gray-800">
                                        <div className="flex">
                                          <p className="font-medium text-gray-800">
                                            {key}:
                                          </p>
                                          <p className="px-5 font-medium text-gray-800">
                                            {
                                              collectionAccount?.routing?.[key]
                                                ?.routing
                                            }
                                          </p>
                                        </div>
                                        <CopyToClipboard
                                          text={`${collectionAccount?.routing?.[key]?.routing}`}
                                          onCopy={() => {
                                            notify("success", `${key} copied`);
                                          }}
                                        >
                                          <button type="button" className={""}>
                                            <CopyIcon
                                              h="16"
                                              w="16"
                                              color="#4084B5"
                                            />
                                          </button>
                                        </CopyToClipboard>
                                      </div>
                                    )}
                                  </li>
                                ),
                              )}
                            </ul>
                            <p className="font-medium text-gray-800">
                              {" "}
                              {/* {collectionAccount?.routing} */}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </SkeletonContent>
            </Balance.Card>
          </EmptyStateConceal>
        </EmptyStateRoot>
      </TabsContent>
    </Tabs>
  );
}

function UserStatus(props: {
  status: CentralUserStatus;
  user_id: string;
  refetch: () => void;
}) {
  const businessId = useGetBusinessId();
  const modal = useModal();
  const changeStatusMutate = useEnforceActionType();

  const getColors = (status: string) => {
    switch (status.toLowerCase()) {
      case "warn":
        return "#FFAB00";
      case "success":
      case "active":
        return "#3BB75E";
      case "default":
      case "dormant":
      case "suspend":
        return "#57584E";
      case "error":
      case "disabled":
      case "unblock":
      case "block":
      case "blocked":
        return "#F05050";
      case "info":
        return "#3B82F6";
      default:
        return "#57584E";
    }
  };

  const blockUser = [
    {
      label: "Unblock",
      modal: () => {
        changeStatusMutate
          .mutateAsync({
            pathParams: { businessId, accountId: props.user_id },
            queryParams: {
              action: "UNBLOCK",
              actionType: "STATUS_CHANGE",
            },
          })
          .then(props.refetch)
          .catch(notifyApiError);
      },

      icon: <Ban size={"0.9rem"} color="#57584E" />,
    },
  ];

  const suspendUser = [
    {
      label: "Un-Suspend",
      modal: () => {
        changeStatusMutate
          .mutateAsync({
            pathParams: { businessId, accountId: props.user_id },
            queryParams: {
              action: "UNSUSPEND",
              actionType: "STATUS_CHANGE",
            },
          })
          .then(props.refetch)
          .catch(notifyApiError);
      },

      icon: <Info size={"0.9rem"} color="#57584E" />,
    },
  ];

  const disabledUser = [
    {
      label: "Enable",
      modal: () => {
        changeStatusMutate
          .mutateAsync({
            pathParams: { businessId, accountId: props.user_id },
            queryParams: {
              action: "UNBAN",
              actionType: "STATUS_CHANGE",
            },
          })
          .then(props.refetch)
          .catch(notifyApiError);
      },
      icon: <Ban size={"0.9rem"} color="#57584E" />,
    },
  ];

  const filterItems = React.useMemo(() => {
    if (props.status === "BLOCKED") {
      return blockUser;
    }
    if (props.status === "DISABLED") {
      return disabledUser;
    }
    return [
      {
        label: "Suspend",
        modal: "suspend_user",
        icon: <Info size={"0.9rem"} color="#57584E" />,
      },
      // {
      //   label: "Block",
      //   modal: "ban_user",
      //   icon: <Ban size={"0.874rem"} color="#57584E" />,
      // },
    ];
  }, [props.status, blockUser, disabledUser]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center text-sm text-[#57584E]">
          <Badge
            type={userBadgeStatus(props.status)}
            size={"sm"}
            className="pr-7"
          >
            {props?.status}
          </Badge>
          <ChevronRight
            size={"0.874rem"}
            // color={"#3BB75E"}
            color={getColors(props.status)}
            className="-ml-5"
          />
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={10}
        align="start"
        className="min-w-[150px]"
      >
        <DropdownMenuGroup>
          {filterItems.map((item, i) => (
            <DropdownMenuItem
              key={item.label}
              className="space-x-2 text-gray-700 "
              onClick={() => {
                if (typeof item.modal === "function") {
                  item.modal();
                  return;
                }
                modal.show(item.modal, { user_id: props.user_id });
              }}
            >
              {item.icon}
              <span className={"text-sm font-normal text-[#57584E]"}>
                {item.label}
              </span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
