import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import { useInviteMember } from "~/api/codegen/walletosComponents";
import { UserRoleIcon } from "~/assets/icons";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { DropdownSelectorInput } from "~/components/Inputs";
import { notify } from "~/components/Toast";
import { useAuthSession } from "~/hooks/use-auth";
import { useBusinessMetadata, useGetBusinessId } from "~/hooks/use-business";
import { useModal } from "~/hooks/use-modal";

const inviteSchema = z.object({
  email: z.string().email({ message: "Please provide a valid email address" }),
  role: z.object({
    value: z.string(),
    label: z.string(),
  }),
});

export function InviteModal() {
  const modal = useModal();
  const { refetch } = useBusinessMetadata();
  const businessId = useGetBusinessId();
  const { mutateAsync, isPending } = useInviteMember();
  const [{ accessToken }] = useAuthSession();

  const roles = React.useRef([
    { label: "Admin", value: "ADMIN" },
    { label: "Business Owner", value: "OWNER" },
    { label: "Developer", value: "DEVELOPER" },
    { label: "Billing", value: "BILLING" },
    { label: "Support", value: "SUPPORT" },
  ]).current;

  const form = useForm<z.infer<typeof inviteSchema>>({
    defaultValues: { email: "", role: roles[0] },
    resolver: zodResolver(inviteSchema),
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        pathParams: { businessId },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: {
          email: data.email,
          role: data.role.value,
        },
      });

      notify("success", response.message);
      await refetch();
      modal.hide("invite_user");
    } catch (err) {
      notify("error", err.message, { autoClose: 5000 });
    }
  });

  return (
    <DialogRemote
      id={"invite_user"}
      onOpenChange={(open) => {
        if (!open) {
          form.reset();
          modal.hide("invite_user");
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Send Invite</DialogTitle>
          <DialogDescription>
            Invite other members of your team
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <FormField
            control={form.control}
            name={"email"}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input placeholder={"someone@domain.com"} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            name={"role"}
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel className="mb-[8px] text-sm font-bold leading-tight text-gray-800">
                    Role
                  </FormLabel>
                  <FormControl>
                    <DropdownSelectorInput
                      icon={<UserRoleIcon />}
                      list={roles}
                      // @ts-expect-error Zod incorrect type
                      value={form.watch(field.name)}
                      setValue={(data) => {
                        form.setValue(field.name, data, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className={"mt-8"} />

          <Button size={"lg"} onClick={() => handleSubmit()}>
            <LoadingButtonContent loading={isPending}>
              Send invite
            </LoadingButtonContent>
          </Button>
        </Form>
      </DialogContent>
    </DialogRemote>
  );
}
