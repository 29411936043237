import {
  Link,
  Outlet,
  createFileRoute,
  useMatchRoute,
  useNavigate,
} from "@tanstack/react-router";
import { ArrowLeft } from "lucide-react";
import { SettingsNavLinks } from "~/components/Navbars";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import {
  RootContent,
  RootLayout,
  RootSidebar,
  RootTopBar,
} from "~/components/layouts/RootLayout";
import { SharedSidebar } from "~/components/layouts/Shared";
import {
  AccountDropdownMenu,
  DashboardNavbar,
  NotificationtDropdownMenu,
} from "~/components/layouts/TopBar";
import { useNavbar } from "~/contexts/NavbarContext";

export const Route = createFileRoute("/settings")({
  component: LayoutComponent,
});

function LayoutComponent() {
  const navigate = useNavigate();
  const matches = useMatchRoute();
  const { isOpen } = useNavbar();

  return (
    <ProtectedRoute
      onAuthStateChange={(state) => {
        if (state.kind === "error") {
          return navigate({ to: "/signin" });
        }
      }}
    >
      <RootLayout>
        <RootTopBar>
          <DashboardNavbar>
            <NotificationtDropdownMenu />
            <AccountDropdownMenu />
          </DashboardNavbar>
        </RootTopBar>

        <RootSidebar>
          <SharedSidebar
            heading={
              <Link to={"/dashboard"} className="flex items-center">
                <ArrowLeft
                  strokeWidth={1.8}
                  size={"1.2rem"}
                  stroke={"#005695"}
                  absoluteStrokeWidth={false}
                />
                {isOpen && (
                  <span className="ml-[10px] font-body text-lg font-normal leading-normal text-gray-800">
                    Settings
                  </span>
                )}
              </Link>
            }
          >
            <SettingsNavLinks
              isActive={(item) => {
                return matches({ to: item.link });
              }}
              onNavigate={(item) => {
                navigate({ to: item.link });
              }}
            />
          </SharedSidebar>
        </RootSidebar>
        <RootContent>
          <Outlet />
        </RootContent>
      </RootLayout>
    </ProtectedRoute>
  );
}
