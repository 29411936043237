import { map } from "effect/Array";
import { fromPairs, lensPath, set, toPairs } from "ramda";
import React from "react";
import {
  useGetAnEntityMultiCurrencyWalletsUsdNgn,
  useGetBusinessWallets,
} from "~/api/codegen/liquidityComponents";
import { ValueMap } from "~/general/interfaces";
import { useGetBusinessId } from "~/hooks/use-business";
import { safeArray, safeNum } from "~/libs/data.helper";
import { BalanceMap } from "~/libs/factories/balance-map";
import {
  SafeWallet,
  Wallet,
  WalletFactory,
  WalletImpl,
  WalletType,
} from "~/libs/factories/wallet-factory";

export function useWallets() {
  const businessId = useGetBusinessId();

  return useGetBusinessWallets(
    {
      pathParams: { businessId },
    },
    {
      placeholderData: [],
      select: useCallback((data) => {
        return WalletHolderImpl.create(safeArray(data?.wallets));
      }, []),
    },
  );
}

type WalletHolder = {
  [key in WalletType]: {
    [key: string]: SafeWallet;
  };
};

export const WalletHolderImpl = {
  create(wallets: unknown[]): WalletHolder {
    let record: WalletHolder = {
      CORPORATE: {},
      REVENUE: {},
      USER: {},
      VAULT: {},
    };
    const items = wallets.map(WalletFactory);

    for (const item of items) {
      if (item.kind === "NONE") continue;
      const path = [item.kind, item.currency];
      record = set(lensPath(path), item, record);
    }

    return record;
  },
  getBalance(record: WalletHolder, key: WalletType) {
    if (record === undefined || record === null) return WalletImpl.empty();
    return BalanceMap.create(
      pipe(
        record[key],
        toPairs,
        map(([key, wallet]) => [key, WalletImpl.balance(wallet)]),
        // @ts-expect-error
        fromPairs,
      ),
    );
  },
  calculateTotal(
    record: WalletHolder,
    predicate?: (safe_wallet: SafeWallet) => boolean,
  ): ValueMap {
    const balancesMap = BalanceMap.create({});

    for (const wallet of entries(record, predicate)) {
      const last_balance = safeNum(balancesMap[wallet.currency], 0);
      balancesMap[wallet.currency] = last_balance + WalletImpl.balance(wallet);
    }

    return balancesMap;
  },

  count(record: WalletHolder, key: WalletType) {
    return Object.keys(record[key]).length;
  },

  toList: entries,

  /**
   * Gets the default wallet for a group
   */
  getDefaultWallet(record: WalletHolder, key: WalletType): SafeWallet {
    // ATM wallet defaults at this time is NGN
    return safeObj(record?.[key]).NGN ?? WalletImpl.empty();
  },
};

function* entries(
  record: WalletHolder,
  predicate: (safe_wallet: SafeWallet) => boolean = () => true,
) {
  if (record === undefined || record === null) return WalletImpl.empty();
  for (const kind of Object.keys(record)) {
    for (const wallet of Object.values(record[kind])) {
      const item = wallet as SafeWallet;

      if (!predicate(item)) continue;
      if (item.kind === "NONE") continue;

      yield item as Wallet;
    }
  }
}

export function useWallet(id: string) {
  const { data: wallet_holder, isLoading } = useWallets();
  const value = React.useMemo(() => {
    const iter = WalletHolderImpl.toList(
      wallet_holder,
      (safe_wallet) =>
        safe_wallet.kind === "CORPORATE" && safe_wallet.id === id,
    );
    return iter.next().value;
  }, [id, wallet_holder]);

  return { isLoading, data: value ? value : WalletImpl.empty() };
}

export function useUserWallets(user_id?: string) {
  const businessId = useGetBusinessId();

  const res = useGetAnEntityMultiCurrencyWalletsUsdNgn(
    {
      pathParams: { businessId, entityId: user_id },
    },
    {
      enabled: typeof user_id === "string",
    },
  );

  return { ...res, data: safeArray(res?.data?.wallets) };
}
