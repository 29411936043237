import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { destr } from "destr";
import { ArrowRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { useConfirmPasswordReset } from "~/api/codegen/walletosComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { PasswordInput } from "~/components/Inputs/password";
import { PasswordValidator } from "~/components/Inputs/password-validator";
import { AuthScreenNav } from "~/components/Navbars";
import { notify, notifyApiError } from "~/components/Toast";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
  CardTitle,
} from "~/components/layouts/Card";
import { isValidResetSession, useResetSession } from "~/hooks/use-auth";
import { password_validator } from "~/libs/validators";

export const Route = createFileRoute("/auth/new-password")({
  component: Page,
  beforeLoad: (opts) => {
    const meetsRequirements = isValidResetSession(
      destr(sessionStorage.getItem("reset_session")),
    );
    if (!meetsRequirements) {
      return opts.navigate({ to: "/", replace: true });
    }
  },
});

const schema = z
  .object({
    new_password: password_validator,
    confirm_password: z.string(),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Password doesn't match",
    path: ["confirm_password"],
  });

function Page() {
  const navigate = useNavigate();
  const [reset_session] = useResetSession();
  const form = useForm<z.infer<typeof schema>>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });
  const mutation = useConfirmPasswordReset();

  const onSubmit = form.handleSubmit((data) => {
    const body = {
      email: reset_session.email,
      password: data.new_password.trim(),
      code: reset_session.code,
    };
    mutation
      .mutateAsync({
        body,
      })
      .then(() => {
        notify("success", "Password updated. You can can login");
        return navigate({ to: "/", search: { email: body.email } });
      })
      .catch(notifyApiError);
  });

  return (
    <>
      <AuthScreenNav />

      <div className="container mx-auto flex flex-col items-center py-24">
        <CardRoot className="max-w-[612px]">
          <CardHeader>
            <CardTitle className="text-lg">Create New Password</CardTitle>
          </CardHeader>

          <CardBody className="space-y-6 border-t px-8">
            <div className="relative mx-auto flex flex-col justify-center gap-4 gap-x-4 text-sm">
              <Form {...form}>
                <FormField
                  name="new_password"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>New password</FormLabel>
                        <FormControl>
                          <PasswordInput {...field} />
                        </FormControl>
                        <PasswordValidator password={field.value} />
                      </FormItem>
                    );
                  }}
                />

                <FormField
                  name="confirm_password"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Re-enter password</FormLabel>
                        <FormControl>
                          <PasswordInput {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </Form>
            </div>
          </CardBody>

          <CardFooter className="flex justify-between">
            <Button
              size="sm"
              variant="outline"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
            <Button
              size="sm"
              variant="default"
              disabled={!form.formState.isValid}
              onClick={onSubmit}
            >
              <LoadingButtonContent loading={mutation.isPending}>
                <span className={"flex items-center gap-2"}>
                  <span>Recover Password</span>
                  <ArrowRight size="0.874rem" />
                </span>
              </LoadingButtonContent>
            </Button>
          </CardFooter>
        </CardRoot>
      </div>
    </>
  );
}
