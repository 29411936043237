export function ArrowTop() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.625V1.375"
        stroke="#2E8F49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 6.625L6 1.375L0.75 6.625"
        stroke="#2E8F49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
