import { pick } from "lodash";
import { CountryI } from "~/general/interfaces";
import { safeJsonParse } from "~/libs";

export async function getCountries(): Promise<CountryI[]> {
  const module = await import("~/data/countries.json");
  const excludes = [
    "Antigua And Barbuda",
    "Bosnia and Herzegovina",
    "Dominica",
    "Saint Vincent And The Grenadines",
    "Trinidad And Tobago",
  ];

  return (module.default as unknown[])
    .filter((data: CountryI) => !excludes.includes(data.name))
    .map((data) => {
      const props = [
        "name",
        "phone_code",
        "currency",
        "currency_symbol",
        "currency_name",
        "iso2",
        "states",
      ];
      return pick(data, props) as CountryI;
    });
}

export function cacheInLocalStorage<B>(
  key: string,
  validate: (data: unknown) => boolean,
  fn: (...args: unknown[]) => Promise<B>,
): (...args: unknown[]) => Promise<B> {
  return async (...args: unknown[]): Promise<B> => {
    if (!import.meta.env.SSR) {
      const data = safeJsonParse(localStorage.getItem(key));

      if (validate(data)) return data as B;

      localStorage.removeItem(key);

      const result = await fn(...args);

      localStorage.setItem(key, JSON.stringify(result));
      return result as B;
    }

    return (await fn(...args)) as B;
  };
}
