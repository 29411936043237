import Lottie from "lottie-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ellipsisAnimation from "~/assets/animations/ellipsis.lottie.json";
import Modal from ".";
import { OTPInput } from "../Inputs";

import { ArrowLeft } from "~/assets/icons/ArrowLeft";

export default function Authenticator({
  modal,
  showModal,
  onAuthSuccess,
  onAuthFailure,
}: {
  modal: boolean;
  showModal: Dispatch<SetStateAction<boolean>>;
  onAuthSuccess?: () => void;
  onAuthFailure?: () => void;
}) {
  const [otp, setOtp] = useState("");
  const [loading, showLoading] = useState(false);

  function sumilateLoading() {
    showLoading(true);
    setTimeout(() => {
      showLoading(false);
      onAuthSuccess();
      showModal(false);
      setOtp("");
    }, 1500);
  }

  useEffect(() => {
    if (otp.length === 6) {
      sumilateLoading();
    }
  }, [otp]);

  function goBack() {
    setOtp("");
    showModal(false);
  }

  return (
    <Modal modal={modal} showModal={showModal} disableBackdropClose>
      <div className="inline-flex h-[313px] w-[90%] flex-col items-center justify-start rounded-lg border border-zinc-200 bg-white shadow lg:w-[612px]">
        <div className="relative h-[52px] w-[100%]">
          <div className="left-0 inline-flex w-[100%] items-center justify-between p-4">
            <button
              type={"button"}
              onClick={() => (loading ? null : goBack())}
              style={{ opacity: loading ? 0.5 : 1 }}
              className={!loading ? "cursor-pointer" : "cursor-not-allowed"}
            >
              <ArrowLeft color="#0094FF" h="25" w="26" />
            </button>
            <span className="text-md text-center font-bold leading-loose text-[#0094FF] lg:text-xl">
              Authorize Request
            </span>
          </div>
          <div className="absolute left-0 top-[52px] h-[0px] w-[100%] border border-zinc-200" />
        </div>
        {loading ? (
          <div className="flex h-48 flex-col items-center justify-center gap-6 self-stretch px-4 py-8">
            <Lottie
              animationData={ellipsisAnimation}
              loop={true}
              style={{ height: "100px", width: "100px" }}
            />
            <span className="font-normal text-[grey]">Loading...</span>
          </div>
        ) : (
          <div className="flex h-48 flex-col items-center justify-start gap-6 self-stretch px-4 py-8">
            <div className="text-center text-base font-normal leading-normal text-black">
              Enter 6 digit code from your authenticatior app
            </div>
            <div className="flex flex-col items-center justify-start gap-2">
              <OTPInput value={otp} setValue={setOtp} />
            </div>
          </div>
        )}
        <div className="flex h-[69px] flex-col items-center justify-center gap-4 self-stretch border-t border-zinc-200 bg-white">
          <span
            className={`text-[14px] font-bold text-[#333333] ${
              !loading ? "cursor-pointer" : "cursor-not-allowed"
            }`}
          >
            Can’t get code?
          </span>
        </div>
      </div>
    </Modal>
  );
}
