import { mergeDeepRight } from "ramda";
import { BusinessMetadata } from "~/general/interfaces";

export function BusinessMetaFactory(data: unknown): {
  business: BusinessMetadata;
} {
  return mergeDeepRight(
    {
      business: {
        members: [],
        invitations: [],
        credentials: {},
      },
    } as { business: BusinessMetadata },
    safeObj(data),
  );
}
