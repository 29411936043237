import { Tabs } from "@radix-ui/react-tabs";
import {
  Link,
  Outlet,
  createFileRoute,
  useMatchRoute,
} from "@tanstack/react-router";
import { TabButton } from "~/components/Tabs";
import {
  SecondaryContent,
  SecondaryNav,
  SecondaryRoot,
  SecondaryTab,
} from "~/components/layouts/SecondaryNav";

export const Route = createFileRoute("/settings/wallets")({
  component: LayoutComponent,
});

function LayoutComponent() {
  const matches = useMatchRoute();
  const isActive = (...params: Parameters<typeof matches>) =>
    matches(...params) ? "active" : "inactive";

  return (
    <Tabs defaultValue="default">
      <SecondaryRoot>
        <SecondaryNav>
          <SecondaryTab>
            <Link to={"/settings/wallets"}>
              <TabButton
                data-state={isActive({
                  to: "/settings/wallets",
                })}
              >
                Spend Wallet
              </TabButton>
            </Link>
            <Link to={"/settings/wallets/revenue-wallet"}>
              <TabButton
                data-state={isActive({
                  to: "/settings/wallets/revenue-wallet",
                })}
              >
                Revenue Wallet
              </TabButton>
            </Link>
            <Link to={"/settings/wallets/user-wallet"}>
              <TabButton
                data-state={isActive({ to: "/settings/wallets/user-wallet" })}
              >
                User Wallet
              </TabButton>
            </Link>
            <Link to={"/settings/wallets/vault-wallet"}>
              <TabButton
                data-state={isActive({ to: "/settings/wallets/vault-wallet" })}
              >
                Vault Wallet
              </TabButton>
            </Link>
          </SecondaryTab>
        </SecondaryNav>

        <SecondaryContent value="default" className="font-body">
          <Outlet />
        </SecondaryContent>
      </SecondaryRoot>
    </Tabs>
  );
}
