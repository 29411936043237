import React from "react";
import Layout from "~/containers/CurrencySwap/Layout";
import SideNav from "./SideNav";
import StepAmount from "./StepAmount";
import StepReview from "./StepReview";
import StepAuthorize from "./StepAuthorize";
// import StepSuccess from "./StepSuccess";
import { RecipientAccountTypes } from "~/libs/types.helper";
import { ApplicationTokenTypes, PickOneOptionType } from "~/libs/types.helper";
import { CountryI } from "~/general/interfaces";
import { FiatCurrencyI } from "~/constants/currencies";

interface SwapDefaultCtx {
  swapState: {
    paymentMethod: PickOneOptionType;
    tokendetails: ApplicationTokenTypes;
    reason: string;
    swapDetails: {
      sourceWallet: FiatCurrencyI;
      sourceAmount: number;
      customeRate: number;
      isCustomeRate: boolean;
      receiverWallet: FiatCurrencyI;
      receiverAmount: number;
    };
  };
  updateState: (name: string, newState: unknown) => void;
  resetState: () => void;
}

export const ConvertCtx = React.createContext({} as SwapDefaultCtx);

export default function PayoutLayout() {
  const [swapState, setSwapState] = React.useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([false, false, false]);

  const completeStep = (index: number) => {
    const newCompletedSteps = [...completedSteps];
    newCompletedSteps[index] = true;
    setCompletedSteps(newCompletedSteps);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepAmount
            completeStep={() => completeStep(0)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 1:
        return (
          <StepReview
            completeStep={() => completeStep(1)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 2:
        return (
          <StepAuthorize
            completeStep={() => completeStep(2)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      //   case 5:
      //     return (
      //       <StepSuccess
      //         completeStep={() => completeStep(4)}
      //         setCurrentStep={setCurrentStep}
      //         currentStep={currentStep}
      //       />
      //     );
      default:
        return (
          <StepAmount
            completeStep={() => completeStep(0)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
    }
  };

  const updateState = (name: string, newState: unknown) => {
    setSwapState((e) => ({ ...e, [name]: newState }));
  };
  const resetState = () => {
    setSwapState({});
  };

  const ctxValue = {
    swapState,
    updateState,
    resetState,
  };
  return (
    <Layout>
      <ConvertCtx.Provider value={ctxValue as null}>
        <div className={"container mx-auto flex flex-col px-12 pt-10"}>
          <div className="flex">
            <div className="">
              <SideNav
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                completedSteps={completedSteps}
              />
            </div>
            <div className="w-full grow px-[20px] text-center">
              {renderStepContent()}
            </div>
          </div>
        </div>
      </ConvertCtx.Provider>
    </Layout>
  );
}
