import React from "react";
import { ArrowRight } from "lucide-react";

const SideNav = ({ currentStep, setCurrentStep, completedSteps }) => {
  const steps = ["Destination", "Amount", "Review", "Authorize"];

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLIElement>,
    index: number,
  ) => {
    if (event.key === "Enter") {
      setCurrentStep(index);
    }
  };

  return (
    <nav className="w-[200px] p-[20px]">
      <ul className="p-0">
        {steps.map((step, index) => (
          <li
            key={`${step}-${index}`}
            className={`flex cursor-pointer items-center py-[10px] ${
              currentStep === index
                ? "font-bold text-[#303940]"
                : completedSteps[index]
                  ? "font-normal text-[#303940]"
                  : "text-[#57584E]/50"
            }`}
            onClick={() => {
              if (completedSteps[index] && currentStep < steps.length) {
                setCurrentStep(index);
              }
            }}
            onKeyDown={(e) => handleKeyDown(e, index)}
          >
            <span className="mr-8 inline-flex text-[20px]">
              <ArrowRight
                absoluteStrokeWidth={false}
                size={"1.2rem"}
                stroke={
                  currentStep === index
                    ? "#303940"
                    : completedSteps[index]
                      ? "#303940"
                      : "#57584EA0"
                }
                strokeWidth={1.8}
              />
            </span>{" "}
            {step}
            {/* {completedSteps[index] && (
              <span className="ml-auto text-[#28a745]">✔️</span>
            )} */}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default React.memo(SideNav);
