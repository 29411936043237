import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";
import { useNavbar } from "~/contexts/NavbarContext";

const IS_DEBUG_MODE = false;

const debug = (className: string) => {
  if (!IS_DEBUG_MODE) return undefined;
  return className;
};

export const RootLayout = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function RootLayout(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div
      ref={ref}
      {...PROPS}
      className={cn(
        "grid h-screen w-full bg-[#F2F6F8]",
        className,
        debug("bg-blue-500"),
      )}
      style={{
        gridTemplate: `
        "aside header header" 65px
        "aside content content" auto / auto 1fr
        `,
      }}
    >
      {children}
    </div>
  );
});

export const RootTopBar = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function DropdownMenuButton(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div ref={ref} className={cn(className, debug("bg-purple-600"))} {...PROPS}>
      {children}
    </div>
  );
});

export const RootSidebar = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function RootSidebar(props, ref) {
  const { children, className, ...PROPS } = props;
  const { isOpen } = useNavbar();

  return (
    <div
      ref={ref}
      style={{ gridArea: "aside" }}
      {...PROPS}
      className={cn(
        `flex flex-col bg-white ${
          isOpen ? "z-10 md:w-[240px]" : "w-0 md:w-max"
        }`,

        className,
        debug("bg-red-400"),
      )}
    >
      {children}
    </div>
  );
});

export const RootContent = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function RootContent(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div
      ref={ref}
      style={{ gridArea: "content" }}
      {...PROPS}
      className={cn("overflow-y-scroll px-6", className, debug("bg-red-400"))}
    >
      {children}
    </div>
  );
});
