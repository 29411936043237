import { useSharedCurrency } from "~/contexts/currency";

import { BalanceMapFormatter } from "~/libs/factories/balance-map";

export function useSharedFormatter() {
  const currency = useSharedCurrency();
  const formatter = BalanceMapFormatter(["USD", "NGN"], currency.code);

  return { formatter, currency };
}
