import { RouterProvider, createRouter } from "@tanstack/react-router";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { ModalProvider } from "~/hooks/use-modal";
import { routeTree } from "~/routeTree.gen";
import translationsCh from "./assets/translations/ch.json";
import translationsEn from "./assets/translations/en.json";
import translationsFr from "./assets/translations/fr.json";
import { EllipsisLoader } from "./components/Modal";
import { CorporateWalletPayoutModal } from "./components/Modal/CorporateWalletPayout";
import { Escrow } from "./components/Modal/Escrow";
import { RevenueLogModal } from "./components/Modal/RevenueLog";
import { RevenueStreams } from "./components/Modal/RevenueStreams";
import Providers from "./components/Redux/Providers";
import store, { persistor } from "./components/Redux/state";
import { NotFound } from "./containers/Dashboard/Pages/404";
import { QueryProvider } from "./contexts/react-query";
import { NavbarProvider } from "./contexts/NavbarContext";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEn,
    },
    fr: {
      translation: translationsFr,
    },
    ch: {
      translation: translationsCh,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export function App() {
  return (
    <NavbarProvider>
      <QueryProvider>
        <ModalProvider>
          <PersistGate loading={null} persistor={persistor}>
            <Providers store={store}>
              <RouterProvider router={router} />
              <ToastContainer />
              <EllipsisLoader />
              <RevenueStreams />
              <Escrow />
              <CorporateWalletPayoutModal />
              <RevenueLogModal />
            </Providers>
          </PersistGate>
        </ModalProvider>
      </QueryProvider>
    </NavbarProvider>
  );
}

const router = createRouter({
  routeTree,
  // @ts-ignore
  globalNotFound: NotFound,
  defaultPreload: "intent",
});
