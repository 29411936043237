import { safeNum } from "~/libs/data.helper";

export type WalletType = "CORPORATE" | "REVENUE" | "USER" | "VAULT";

interface Application {
  id: string;
  token: string;
  tokenType: string;
  expiredAt: string;
  valid: boolean;
}

export interface AppToken {
  url: string;
  application: Application;
}
export interface Wallet {
  kind: WalletType;
  id: string;
  currency: string;
  balance: number;
  $original: unknown;
}

interface EmptyWallet {
  kind: "NONE";
}

export type SafeWallet = Wallet | EmptyWallet;

export function WalletFactory<T>(data: unknown): SafeWallet {
  return {
    // @ts-expect-error
    kind: data?.settings?.walletType,
    // @ts-expect-error
    id: data?.id,
    // @ts-expect-error
    currency: data?.currency ?? "--",
    // @ts-expect-error
    balance: safeNum(data?.balance, 0),
    $original: data,
  };
}

export const WalletImpl = {
  getType(wallet: SafeWallet) {
    return wallet.kind;
  },

  getId(wallet: SafeWallet): string | null {
    return pipe(
      Match.value(wallet),
      Match.when({ kind: "CORPORATE" }, (data) => data.id),
      Match.orElse(() => null),
    );
  },

  balance(wallet: SafeWallet): number {
    if (!wallet) return 0;
    if (wallet?.kind === "NONE") return 0;

    return wallet.balance;
  },

  empty(): EmptyWallet {
    return { kind: "NONE" };
  },
};
