import { WalletLog } from "~/general/interfaces";
import { _setCorporateWalletLogs } from ".";
import { useAppDispatch, useAppSelector } from "../hooks";

/**
 * hook to easily interact with products state
 * @deprecated useWallets from `hooks/use-wallet`
 */
export const useWallets = () => {
  const dispatch = useAppDispatch();

  const setCorporateWalletLogs = (transactions: WalletLog[]) =>
    dispatch(_setCorporateWalletLogs(transactions));

  const corporateWalletLogs = useAppSelector(
    (state) => state.wallets.corporateWalletLogs,
  );

  return { corporateWalletLogs, setCorporateWalletLogs };
};
