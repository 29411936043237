import { Button } from "~/@/components/ui/button";
import { serialNo } from "~/libs";
import { ChevronRight, ChevronLeft } from "lucide-react";
import { PaginationMetadata } from "~/libs/factories/pagination";

export function TablePagination(props: { data: PaginationMetadata }) {
  const meta = props.data;
  const totalPageNumber = useMemo(
    () => Math.ceil(meta?.total / meta?.pageSize),
    [meta?.total, meta?.pageSize],
  );

  const lastPage = meta.page === totalPageNumber;

  return (
    <div className="flex w-full items-center justify-between">
      <div
        className={
          "flex w-full items-center justify-between gap-x-4 text-sm text-foreground"
        }
      >
        <div className="flex items-center justify-between">
          {meta.page > 1 && (
            <Button
              type="button"
              size={"sm"}
              variant={"outline"}
              className="ml-0 border-0 py-0 pl-0 pr-1"
              onClick={() => {
                meta?.setPage?.(meta.page - 1);
              }}
            >
              <ChevronLeft
                absoluteStrokeWidth={false}
                size={"1.2rem"}
                stroke={"#005695"}
                strokeWidth={1.8}
              />
            </Button>
          )}
          <span className="font-body text-gray-600">
            {meta.page} - {meta.pageCount}
          </span>
          <Button
            type="button"
            size={"sm"}
            variant={"outline"}
            className="border-0 px-1 py-0"
            disabled={lastPage}
            onClick={() => {
              meta?.setPage?.(meta.page + 1);
            }}
          >
            <ChevronRight
              absoluteStrokeWidth={false}
              size={"1.2rem"}
              stroke={"#005695"}
              strokeWidth={1.8}
            />
          </Button>
        </div>

        {/* <Button
          size={"sm"}
          variant={"outline"}
          className="h-[34px] w-[106px] text-muted-foreground"
        >
          Load more
        </Button> */}

        {/* <span className={"text-muted-foreground"}>Load more</span> */}

        <span className="font-body text-gray-600">
          {meta.total} <span className={"text-muted-foreground"}>records</span>
        </span>
      </div>
    </div>
  );
}
