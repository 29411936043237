import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./assets/fonts/fonts.css";
import "./global.css";
import { displayMockUI } from "./libs";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://c29950574f51627ea73bf8d26bfc7a0d@o4507747167240192.ingest.us.sentry.io/4507761313185792",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENV,
  tracePropagationTargets: ["localhost", /^https:\/\/centryos\.xyz/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
  normalizeMaxBreadth: 10,
});

async function enableMocking() {
  if (!displayMockUI()) {
    return;
  }

  const { worker } = await import("./api/mock/msw");

  return worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

enableMocking().then(() =>
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  ),
);
