import { Menu, Transition } from "@headlessui/react";
import React, {
  ComponentProps,
  CSSProperties,
  Dispatch,
  Fragment,
  SetStateAction,
  useDeferredValue,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OtpInput from "react-otp-input";
import { CaretDown } from "~/assets/icons/CaretDown";
import { RadioSelected } from "~/assets/icons/RadioSelected";
import { RadioUnselected } from "~/assets/icons/RadioUnselected";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { Input } from "~/@/components/ui/input";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { cn } from "~/@/lib/utils";
import { XIcon } from "~/assets/icons/XIcon";
import { ChipValues } from "~/components/Inputs/Chip";
import { DEFAULT_COUNTRY } from "~/constants";
import {
  CryptoAssetI,
  FiatCurrencyI,
  currencies,
} from "~/constants/currencies";
import { CountryI } from "~/general/interfaces";
import { useCountries } from "~/hooks";
import { truncateString } from "~/libs";
import {
  formatNumber,
  getFlagFromRegionCode,
  InputFormatNumber,
  removeCommas,
} from "~/libs/currency.helpers";
import styles from "../../general/styles.module.css";

export function DefaultInputField({
  value,
  setValue,
  label,
  placeholder,
  style,
  containerStyle,
  footerElement,
  icon,
  disabled,
  rtl,
  isPasswordInput,
  inputStyle,
  forceNumbers,
}: {
  value: string;
  setValue: (a: string) => void;
  label?: string;
  placeholder?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  footerElement?: React.JSX.Element;
  icon?: React.JSX.Element;
  disabled?: boolean;
  rtl?: boolean;
  isPasswordInput?: boolean;
  inputStyle?: CSSProperties;
  forceNumbers?: boolean;
}) {
  const [isFocused, setIsFocused] = useState(false);

  const hasLabel = !!label;

  const handleChange = (val: string) => {
    if (forceNumbers) {
      const numericValue = val.replace(/[^0-9]/g, "");
      setValue(numericValue);
    } else {
      setValue(val);
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      setValue("");
    }
  };

  return (
    <div
      style={containerStyle}
      className={
        "inline-flex w-[100%] flex-col items-start justify-start gap-1"
      }
    >
      {hasLabel && (
        <span className="text-sm font-bold leading-tight text-gray-800">
          {label}
        </span>
      )}
      <div
        style={style}
        className={`inline-flex h-9 w-[100%] flex-row items-center justify-start gap-1 rounded-lg pl-0 ${
          isFocused ? "border border-blue-500" : "border border-zinc-200"
        } ${icon && !rtl && icon && "pl-[5px]"} ${
          icon && rtl && icon && "pr-[5px]"
        } ${disabled && "bg-[#F2F6F8]"}`}
      >
        <div className="ml-2">{icon && !rtl && icon}</div>
        <input
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          style={inputStyle}
          type={isPasswordInput ? "password" : ""}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`inline-flex h-[100%] w-[100%] flex-col items-start justify-start rounded-lg px-3 text-sm focus:outline-none ${
            disabled && "bg-[#F2F6F8]"
          }`}
        />
        {/* {icon && rtl && icon} */}
        {value && (
          <div
            onClick={() => setValue("")}
            onKeyDown={handleKeyDown}
            className="cursor-pointer"
          >
            {icon && rtl && icon}
          </div>
        )}
      </div>
      {footerElement && footerElement}
    </div>
  );
}

export function DefaultTextArea({
  value,
  setValue,
  label,
  placeholder,
  style,
  containerStyle,
  footerElement,
}: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  label?: string;
  placeholder?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  footerElement?: React.JSX.Element;
}) {
  const [isFocused, setIsFocused] = useState(false);

  const hasLabel = !!label;

  return (
    <div
      style={containerStyle}
      className={
        "inline-flex w-[100%] flex-col items-start justify-start gap-1"
      }
    >
      {hasLabel && (
        <span className="text-sm font-bold leading-tight text-gray-800">
          {label}
        </span>
      )}
      <div
        style={style}
        className={`inline-flex h-[120px] max-h-[120px] min-h-[120px] w-[100%] min-w-[100%] max-w-[100%] flex-row items-center justify-start gap-1 rounded pl-0 ${
          isFocused ? "border border-blue-500" : "border border-zinc-200"
        }`}
      >
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="inline-flex h-[100%] w-[100%] resize-none flex-col items-start justify-start rounded pl-3 text-sm focus:outline-none"
        />
      </div>
      {footerElement && footerElement}
    </div>
  );
}

export function CurrencySelectorInput({
  currencies,
  value,
  setValue,
  currency,
  setCurrency,
  setIntValue,
}: {
  currencies: FiatCurrencyI[];
  currency: FiatCurrencyI;
  setCurrency: Dispatch<SetStateAction<FiatCurrencyI>>;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  label?: string;
  setIntValue?: Dispatch<SetStateAction<number>>;
}) {
  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    if (val === "0") return null;
    if (setIntValue) {
      const plain_val = removeCommas(numericValue);
      if (!plain_val) {
        setIntValue(0);
      } else {
        setIntValue(parseFloat(plain_val));
      }
    }
    setValue(formatNumber(numericValue));
  };

  return (
    <>
      <div className="inline-flex h-9 w-[100%] items-start justify-start gap-1">
        <div className="flex h-9 shrink grow basis-0 items-start justify-start gap-2">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="">
                <div className="flex items-center justify-center gap-2 rounded border border-zinc-200 bg-neutral-50 px-4 py-2">
                  <img
                    src={currency.flagUrl}
                    className="h-[19px] w-[26px] rounded-[2px]"
                    alt={currency?.flagName}
                  />
                  <span className="text-sm font-normal leading-tight text-neutral-400">
                    {currency.label}
                  </span>
                  <div className="flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
                    <CaretDown />
                  </div>
                </div>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="flex flex-col py-3">
                  {currencies.map((currency) => (
                    <Menu.Item key={currency.code}>
                      {({ active }) => (
                        <div
                          className={`flex items-center px-[10px] ${
                            active ? "bg-gray-200" : ""
                          }`}
                        >
                          <img
                            src={currency.flagUrl}
                            alt={currency?.flagName}
                            className="h-[18px] w-[24px] rounded-[2px]"
                          />

                          <button
                            type="button"
                            onClick={() => setCurrency(currency)}
                            style={{ textAlign: "center", cursor: "pointer" }}
                            className={"cursor-pointer text-[black] "}
                          >
                            {currency.label}
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <DefaultInputField
            value={value}
            placeholder="0"
            setValue={(val) => {
              handleChange(val);
            }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
}

export function CurrencySelectorInputLg({
  currency,
  setCurrency,
  currencies,
  value,
  setValue,
  setIntValue,
}: {
  currency: FiatCurrencyI;
  setCurrency: Dispatch<SetStateAction<FiatCurrencyI>>;
  currencies: FiatCurrencyI[];
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  setIntValue?: Dispatch<SetStateAction<number>>;
}) {
  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    if (val === "0") return null;
    if (setIntValue) {
      const plain_val = removeCommas(numericValue);
      if (!plain_val) {
        setIntValue(0);
      } else {
        setIntValue(parseFloat(plain_val));
      }
    }
    setValue(formatNumber(numericValue));
  };

  return (
    <>
      <div className="flex h-[auto] w-[100%] items-center justify-between">
        <Menu as="div" className="relative mr-[20px] inline-block text-left">
          <div>
            <Menu.Button className="w-[140px]">
              <div className="flex h-[60px] items-center justify-center gap-2 rounded border border-zinc-200 bg-[white] px-4 py-2">
                <span
                  style={{ fontSize: "24px" }}
                  className="flex items-center font-bold leading-tight text-gray-400"
                >
                  <img
                    src={currency.flagUrl}
                    alt={currency?.flagName}
                    className="mr-[3px] h-[32px] w-[32px]"
                  />

                  {currency.label}
                </span>
                <div className="flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
                  <CaretDown />
                </div>
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col py-1">
                {currencies.map((currency) => (
                  <Menu.Item key={currency.code}>
                    {({ active }) => (
                      <button
                        type={"button"}
                        onClick={() => setCurrency(currency)}
                        style={{ textAlign: "center", cursor: "pointer" }}
                        className={`flex cursor-pointer items-center px-[5px] text-[black] ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        <img
                          src={currency.flagUrl}
                          alt={currency?.flagName}
                          style={{
                            height: "20px",
                            width: "25px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {currency.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <DefaultInputField
          inputStyle={{ fontSize: "34px", textAlign: "right" }}
          value={value}
          placeholder="0"
          setValue={(val) => handleChange(val)}
          style={{ width: "100%", height: "60px" }}
        />
      </div>
    </>
  );
}

export function CryptoAssetSelectorInputLg({
  asset,
  setAsset,
  assets,
  value,
  setValue,
}: {
  asset: CryptoAssetI;
  setAsset: Dispatch<SetStateAction<CryptoAssetI>>;
  assets: CryptoAssetI[];
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}) {
  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9.]/g, "");
    setValue(numericValue);
  };

  return (
    <>
      <div className="flex h-[auto] w-[100%] items-center justify-between">
        <Menu as="div" className="relative mr-[20px] inline-block text-left">
          <div>
            <Menu.Button className="w-[140px]">
              <div className="flex h-[60px] items-center justify-center gap-2 rounded border border-zinc-200 bg-[white] px-4 py-2">
                <span
                  style={{ fontSize: "24px" }}
                  className="flex items-center font-bold leading-tight text-gray-400"
                >
                  <img
                    src={asset.logoUrl}
                    alt={asset?.name}
                    className="mr-[3px] h-[32px] w-[32px]"
                  />

                  {asset.code}
                </span>
                <div className="flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
                  <CaretDown />
                </div>
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col py-1">
                {assets.map((asset, i) => (
                  <Menu.Item key={asset.code}>
                    {({ active }) => (
                      /**  biome-ignore lint/a11y/useKeyWithClickEvents: REVISIT **/
                      <span
                        onClick={() => setAsset(asset)}
                        style={{ textAlign: "center", cursor: "pointer" }}
                        className={`flex cursor-pointer items-center px-[5px] text-[black] ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        <img
                          src={asset.logoUrl}
                          alt={asset?.name}
                          style={{
                            height: "20px",
                            width: "25px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {asset.code}
                      </span>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <DefaultInputField
          inputStyle={{ fontSize: "34px", textAlign: "right" }}
          value={value}
          placeholder="0"
          setValue={(val) => handleChange(val)}
          style={{ width: "100%", height: "60px" }}
        />
      </div>
    </>
  );
}

export function EscrowPayoutInput({
  value,
  setValue,
  setIntValue,
}: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  setIntValue: Dispatch<SetStateAction<number>>;
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    if (val === "") {
      setValue("");
      if (setIntValue) {
        setIntValue(0);
      }
      return;
    }

    if (setIntValue) {
      const plain_val = removeCommas(numericValue);
      if (!plain_val) {
        setIntValue(0);
      } else {
        setIntValue(parseFloat(plain_val));
      }
    }

    setValue(formatNumber(numericValue));
  };

  return (
    <div
      className={`${
        isFocused ? "border border-[#0094FF]" : "border border-zinc-200"
      } h-[70px] w-[200px] rounded-[4px] p-[10px]`}
    >
      <input
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => handleChange(e.target.value)}
        placeholder="0"
        className={
          "h-[100%] w-[100%] text-right text-2xl font-normal leading-loose text-[black] focus:outline-none"
        }
      />
    </div>
  );
}

type PhoneInputProps = {
  country?: CountryI;
  value: string;
  setCountry: (a: CountryI) => void;
  setValue: (value: string) => void;
  containerStyle?: CSSProperties;
  label?: string;
  disabled?: boolean;
};

export const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  PhoneInputProps
>(function PhoneNumberInput(props, ref) {
  const {
    country = DEFAULT_COUNTRY,
    setCountry,
    value,
    setValue,
    containerStyle,
    label,
    disabled,
  } = props;

  const hasLabel = !!label;
  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    setValue(numericValue);
  };

  return (
    <>
      <div
        style={containerStyle}
        className={
          "inline-flex w-full flex-col items-start justify-start gap-1"
        }
      >
        {hasLabel && (
          <span className="text-sm font-bold leading-tight text-gray-800">
            {label}
          </span>
        )}
        <div className="inline-flex h-9 w-[100%] items-start justify-start gap-1">
          <div className="flex h-9 shrink grow basis-0 items-start justify-between gap-2">
            <CountriesDropdown
              value={country}
              disabled={disabled}
              onValueChange={(country) => {
                setCountry(country);
              }}
            />

            <Input
              ref={ref}
              value={value}
              disabled={disabled}
              placeholder="e.g 12345678"
              inputMode={"numeric"}
              onChange={(evt) => handleChange(evt.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
});

type AmountInputProps = {
  country: CountryI;
  value: string;
  setCountry: (a: CountryI) => void;
  setValue: (value: string) => void;
  containerStyle?: CSSProperties;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showCurrency?: boolean;
};

export const AmountInput = React.forwardRef<HTMLInputElement, AmountInputProps>(
  function AmountInput(props, ref) {
    const {
      country = DEFAULT_COUNTRY,
      setCountry,
      value,
      setValue,
      containerStyle,
      label,
      placeholder,
      showCurrency = true,
      disabled = false,
    } = props;

    const hasLabel = !!label;

    const handleChange = (val: string) => {
      const numericValue = val.replace(/[^0-9]/g, "");

      setValue(InputFormatNumber(numericValue));
    };

    return (
      <>
        <div
          style={containerStyle}
          className={
            "inline-flex w-full flex-col items-start justify-start gap-1"
          }
        >
          {hasLabel && (
            <span className="text-sm font-bold leading-tight text-gray-800">
              {label}
            </span>
          )}
          <div className="inline-flex h-9 w-[100%] items-start justify-start gap-1">
            <div className="flex h-9 shrink grow basis-0 items-start justify-between gap-2">
              {showCurrency && (
                <CurrencyDropdown
                  value={country}
                  disabled={disabled}
                  onValueChange={(country) => {
                    setCountry(country);
                  }}
                />
              )}

              <Input
                ref={ref}
                value={value}
                placeholder={placeholder ?? "Amount"}
                inputMode={"numeric"}
                onChange={(evt) => handleChange(evt.target.value)}
              />
            </div>
          </div>
        </div>
      </>
    );
  },
);

export function CurrencyDropdown(props: {
  value?: CountryI;
  disabled?: boolean;
  onValueChange?: (a: CountryI) => void;
  clasNmae?: string;
}) {
  const [search_, setSearch] = useState("");
  const search = useDeferredValue(search_);

  const { data: countries } = useCountries();

  // const { data: countries } = useCountries({
  //   enabled: props.value?.name !== DEFAULT_COUNTRY?.name,
  // });

  const isMatch = React.useCallback(
    (country: CountryI) => {
      if (!search) {
        return true;
      }

      return (
        country.currency.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        country.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    [search],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        disabled={props.disabled}
        className={props.clasNmae}
      >
        <CurrencyPicker value={props.value} className={props.clasNmae} />
      </DropdownMenuTrigger>

      <DropdownMenuContent align={"start"}>
        <div className="mt-[5px] w-[100%] px-[5px]">
          <Input
            value={search}
            placeholder="Search"
            className={"h-[32px]"}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </div>

        <ScrollArea className={"flex max-h-[250px] flex-col"}>
          {countries.map((country: CountryI) => {
            if (!isMatch(country)) {
              return null;
            }

            return (
              <DropdownMenuItem
                key={country.iso2}
                className={
                  "flex cursor-pointer items-center justify-between border-b border-zinc-200 px-[5px] py-[8px] text-[13px]"
                }
                onClick={() => {
                  setSearch("");
                  props.onValueChange?.(country);
                }}
              >
                <div className="flex items-center">
                  <img
                    src={getFlagFromRegionCode(country.iso2)}
                    className="mr-[5px] h-[20px] w-[22px] rounded"
                    alt={`${country.name} flag`}
                  />
                  <span className="text-xs font-normal leading-tight text-stone-600">
                    {country.currency_name}
                  </span>
                </div>
                <span className="text-right font-body text-xs font-normal leading-tight text-neutral-400">
                  {country.currency}
                </span>
              </DropdownMenuItem>
            );
          })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function CountriesDropdown(props: {
  value?: CountryI;
  disabled: boolean;
  onValueChange: (a: CountryI) => void;
}) {
  const [search_, setSearch] = useState("");
  const search = useDeferredValue(search_);

  const { data: countries } = useCountries();
  // const { data: countries } = useCountries({
  //   enabled: props.value?.name !== DEFAULT_COUNTRY?.name,
  // });

  const isMatch = React.useCallback(
    (country: CountryI) => {
      if (!search) {
        return true;
      }

      return country.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    [search],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={props.disabled}>
        <CountryPhonePicker value={props.value} />
      </DropdownMenuTrigger>

      <DropdownMenuContent align={"start"}>
        <div className="mt-[5px] w-[100%] px-[5px]">
          <Input
            value={search}
            placeholder="Search"
            className={"h-[32px]"}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </div>

        <ScrollArea className={"flex max-h-[250px] flex-col"}>
          {countries.map((country: CountryI) => {
            if (!isMatch(country)) {
              return null;
            }

            return (
              <DropdownMenuItem
                key={country.iso2}
                className={
                  "flex cursor-pointer items-center justify-between border-b border-zinc-200 px-[5px] py-[8px] text-[13px]"
                }
                onClick={() => {
                  setSearch("");
                  props.onValueChange?.(country);
                }}
              >
                <div className="flex items-center">
                  <img
                    src={getFlagFromRegionCode(country.iso2)}
                    className="mr-[5px] h-[20px] w-[22px] rounded"
                    alt={`${country.name} flag`}
                  />
                  <span className="text-xs font-normal leading-tight text-stone-600">
                    {country.name}
                  </span>
                </div>
                <span className="text-right font-body text-xs font-normal leading-tight text-neutral-400">
                  {country.phone_code}
                </span>
              </DropdownMenuItem>
            );
          })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const CountryPhonePicker = React.forwardRef<
  HTMLButtonElement,
  {
    value: CountryI;
  }
>(function CountryPhonePicker(props, ref) {
  const { value, ...PROPS } = props;

  return (
    <button
      ref={ref}
      type={"button"}
      {...PROPS}
      className="flex items-center justify-center gap-5 rounded border border-zinc-200 bg-neutral-50 px-2 py-2"
    >
      <span className="flex items-center text-sm font-normal leading-tight text-black">
        <img
          src={getFlagFromRegionCode(value.iso2)}
          style={{
            height: "20px",
            width: "25px",
            marginRight: "5px",
          }}
          alt={value.name}
        />
        +{value.phone_code}
      </span>
      <div className="ml-[5px] flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
        <CaretDown />
      </div>
    </button>
  );
});

const CurrencyPicker = React.forwardRef<
  HTMLButtonElement,
  {
    value: CountryI;
    className?: string;
  }
>(function CountryPhonePicker(props, ref) {
  const { value, className, ...PROPS } = props;

  return (
    <button
      ref={ref}
      type={"button"}
      {...PROPS}
      className={`flex items-center justify-center gap-5 rounded border border-zinc-200 bg-neutral-50 px-2 py-2 ${className}`}
    >
      <span className="flex items-center text-sm font-normal leading-tight text-black">
        <img
          src={getFlagFromRegionCode(value.iso2)}
          style={{
            height: "20px",
            width: "25px",
            marginRight: "5px",
          }}
          alt={value.name}
        />
        {value.currency}
      </span>
      <div className="ml-[5px] flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
        <CaretDown />
      </div>
    </button>
  );
});

export function DropdownSelectorInput({
  list,
  value,
  setValue,
  label,
  maxValueLen,
  containerStyle,
  icon,
}: {
  list: { value: string; label: string }[];
  value: { value: string; label: string };
  setValue: (data: { value: string; label: string }) => void;
  containerStyle?: CSSProperties;
  maxValueLen?: number;
  label?: string;
  icon?: React.JSX.Element;
}) {
  const hasLabel = !!label;

  const _list: { value: string; label: string }[] = [];
  list.map((item) => {
    if (item.value !== "null") {
      _list.push(item);
    }
  });

  return (
    <>
      <div
        style={containerStyle}
        className={
          "inline-flex w-[100%] flex-col items-start justify-start gap-1"
        }
      >
        {hasLabel && (
          <span className="text-sm font-bold leading-tight text-gray-800">
            {label}
          </span>
        )}
        <Menu as="div" className="relative inline-block w-[100%] text-left">
          <Menu.Button className="w-[100%]">
            <div className="inline-flex h-9 w-[100%] flex-row items-center justify-between gap-1 rounded border border-zinc-200 px-2">
              <span
                className={`flex items-center ${
                  value.value === "null" ? "opacity-50" : "opacity-100"
                }`}
              >
                {icon && <span className="mr-[5px]">{icon}</span>}
                <span className="text-left text-sm font-normal leading-tight text-black">
                  {truncateString(value.label, maxValueLen)}
                </span>
              </span>
              <div className="flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
                <CaretDown />
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-[100%] origin-top-right rounded-md bg-white p-[10px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <ul
                style={{ overflowY: "scroll" }}
                className={`${styles.custom_scrollbar} flex max-h-[300px] flex-col`}
              >
                {_list.map((item) => (
                  <Menu.Item key={item.label}>
                    {({ active }) => (
                      <button
                        type={"button"}
                        onClick={() => setValue(item)}
                        style={{
                          cursor: "pointer",
                          fontSize: "13px",
                          color: "#57584E",
                        }}
                        className={`cursor-pointer border-b border-zinc-200 px-[5px] py-[5px] font-normal ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        {item.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </ul>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export function DropdownRadioSelectorInput({
  list,
  value,
  setValue,
  label,
  maxValueLen,
  containerStyle,
}: {
  list: { value: string; label: string }[];
  value: { value: string; label: string };
  setValue: Dispatch<SetStateAction<{ value: string; label: string }>>;
  containerStyle?: CSSProperties;
  maxValueLen?: number;
  label?: string;
}) {
  const hasLabel = !!label;

  const _list: { value: string; label: string }[] = [];
  list.map((item) => {
    if (item.value !== "null") {
      _list.push(item);
    }
  });

  return (
    <>
      <div
        style={containerStyle}
        className={
          "inline-flex w-[100%] flex-col items-start justify-start gap-1"
        }
      >
        {hasLabel && (
          <span className="text-sm font-bold leading-tight text-gray-800">
            {label}
          </span>
        )}
        <Menu as="div" className="relative inline-block w-[100%] text-left">
          <Menu.Button className="w-[100%]">
            <div className="inline-flex h-9 w-[100%] flex-row items-center justify-between gap-1 rounded border border-zinc-200 px-2">
              <span
                className={`${
                  value.value === "null" ? "text-[lightgrey]" : "text-black"
                } text-left text-sm font-normal leading-tight`}
              >
                {truncateString(value.label, maxValueLen)}
              </span>
              <div className="flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
                <CaretDown />
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-[200px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col p-[10px]">
                {_list.map((item) => (
                  <Menu.Item key={item.label}>
                    {({ active }) => (
                      // biome-ignore lint/a11y/useKeyWithClickEvents: REVISITING
                      <div
                        onClick={() => setValue(item)}
                        className={`flex items-center rounded px-[5px] py-[10px] ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        {item.value === value.value ? (
                          <RadioSelected />
                        ) : (
                          <RadioUnselected />
                        )}
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            color: "#57584E",
                          }}
                          className={
                            "ml-[8px] cursor-pointer px-[5px] font-normal"
                          }
                        >
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export function CountrySelectorInput({
  value,
  setValue,
  label,
  containerStyle,
}: {
  value: CountryI;
  setValue: Dispatch<SetStateAction<CountryI>>;
  containerStyle?: CSSProperties;
  label?: string;
}) {
  const hasLabel = !!label;
  const [searchVal, setSearchVal] = useState("");
  const { data: countries } = useCountries();

  const result = React.useMemo(
    () =>
      countries.filter((country) => {
        return (
          country.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1
        );
      }),
    [countries, searchVal],
  );

  return (
    <>
      <div
        style={containerStyle}
        className={
          "inline-flex w-[100%] flex-col items-start justify-start gap-1"
        }
      >
        {hasLabel && (
          <span className="text-sm font-bold leading-tight text-gray-800">
            {label}
          </span>
        )}
        <Menu as="div" className="relative inline-block w-[100%] text-left">
          <Menu.Button className="w-[100%]">
            <div className="inline-flex h-9 w-[100%] flex-row items-center justify-between gap-1 rounded border border-zinc-200 px-2">
              <span className="text-sm font-normal leading-tight text-black">
                {value.name}
              </span>
              <div className="flex h-2 w-2 items-center justify-center px-[0.25px] pb-[1.92px] pt-[2.44px]">
                <CaretDown />
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-[100%] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="mt-[7px] w-[100%] px-[7px]">
                <DefaultInputField
                  value={searchVal}
                  setValue={setSearchVal}
                  placeholder="Search"
                  containerStyle={{ width: "100%", height: "25px" }}
                />
              </div>

              <div
                style={{ overflowY: "scroll" }}
                className={`${styles.custom_scrollbar} flex max-h-[300px] flex-col px-2 py-1 pt-[7px]`}
              >
                {result.map((country: CountryI) => (
                  <Menu.Item key={country.iso2}>
                    {({ active }) => (
                      // biome-ignore lint/a11y/useKeyWithClickEvents: REVISITING
                      <div
                        onClick={() => {
                          setSearchVal("");
                          setValue(country);
                        }}
                        style={{ cursor: "pointer", fontSize: "13px" }}
                        className={`flex cursor-pointer items-center justify-between border-b border-zinc-200 px-[5px] py-[8px] ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        <div className="flex items-center">
                          <img
                            src={getFlagFromRegionCode(country.iso2)}
                            style={{
                              height: "20px",
                              width: "25px",
                              marginRight: "5px",
                            }}
                            className="rounded"
                            alt={country.name}
                          />{" "}
                          <span className="text-xs font-normal leading-tight text-stone-600">
                            {country.name}
                          </span>
                        </div>
                        <span className="text-right font-body text-xs font-normal leading-tight text-neutral-400">
                          {country.phone_code}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export const CountrySelector = React.forwardRef<
  HTMLSelectElement,
  {
    value: string;
    onChange: ComponentProps<typeof Select>["onValueChange"];
  }
>(function CountrySelector(props, ref) {
  const { data: countries, isLoading } = useCountries();

  return (
    <Select onValueChange={props.onChange} defaultValue={props.value}>
      <SelectTrigger className="w-[100%]">
        <SelectValue ref={ref} placeholder={"Choose country"} />
      </SelectTrigger>

      <SelectContent>
        {countries.map((country: CountryI) => (
          <SelectItem key={country.iso2} value={country.iso2}>
            <div className="flex items-center space-x-2">
              <img
                src={getFlagFromRegionCode(country.iso2)}
                className="aspect-[25/20] w-[25px] rounded object-contain"
                alt={country.name}
              />

              <span className="text-xs font-normal leading-tight text-stone-600">
                {country.name}
              </span>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});

export function ToggleInput({
  isToggled,
  setIsToggled,
  onToggle,
  isDisabled,
}: {
  isToggled: boolean;
  setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  onToggle: () => void;
  isDisabled?: boolean;
}) {
  function onClick() {
    if (!isDisabled) {
      onToggle();
      setIsToggled(!isToggled);
    }
  }

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: REVISITING
    <span
      onClick={() => onClick()}
      style={{
        cursor: isDisabled ? "not-allowed" : "pointer",
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      {isToggled ? (
        <div className="relative h-5 w-[37px]">
          <div className="absolute left-0 top-0 h-5 w-[37px] rounded-[15px] bg-green-500" />
          <div className="absolute left-[19px] top-[2px] h-4 w-4 rounded-full bg-white" />
        </div>
      ) : (
        <div className="relative h-5 w-[37px]">
          <div className="absolute left-0 top-0 h-5 w-[37px] rounded-[15px] bg-stone-300" />
          <div className="absolute left-[2px] top-[2px] h-4 w-4 rounded-full bg-white" />
        </div>
      )}
    </span>
  );
}

export function DatePickerInput({
  value,
  setValue,
  label,
  containerStyle,
}: {
  value: Date;
  setValue: Dispatch<SetStateAction<Date>>;
  label?: string;
  containerStyle?: CSSProperties;
}) {
  const hasLabel = !!label;

  return (
    <div
      style={{ ...containerStyle }}
      className={
        "inline-flex w-[100%] flex-col items-start justify-start gap-1"
      }
    >
      {hasLabel && (
        <span className="text-sm font-bold leading-tight text-gray-800">
          {label}
        </span>
      )}
      <div
        style={{ cursor: "pointer" }}
        className={
          "inline-flex h-9 w-[100%] flex-row items-center justify-start gap-1 rounded border border-zinc-200 pl-0"
        }
      >
        <DatePicker
          className="inline-flex h-8 w-[100%] cursor-pointer flex-col items-start justify-start rounded pl-3 text-sm focus:outline-none"
          dateFormat="dd/MM/yyyy"
          selected={value}
          onChange={(date) => setValue(date)}
        />
      </div>
    </div>
  );
}

export function TimePickerInput({
  value,
  setValue,
  label,
  containerStyle,
}: {
  value: Date;
  setValue: Dispatch<SetStateAction<Date>>;
  label?: string;
  containerStyle?: CSSProperties;
}) {
  const hasLabel = !!label;

  return (
    <div
      style={{ ...containerStyle }}
      className={
        "inline-flex w-[100%] flex-col items-start justify-start gap-1"
      }
    >
      {hasLabel && (
        <span className="text-sm font-bold leading-tight text-gray-800">
          {label}
        </span>
      )}
      <div
        style={{ cursor: "pointer" }}
        className={
          "inline-flex h-9 w-[100%] flex-row items-center justify-start gap-1 rounded border border-zinc-200 pl-0"
        }
      >
        <DatePicker
          className="inline-flex h-8 w-[100%] cursor-pointer flex-col items-start justify-start rounded pl-3 text-sm focus:outline-none"
          selected={value}
          onChange={(date) => setValue(date)}
          showTimeSelect
          showTimeSelectOnly
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
      </div>
    </div>
  );
}

export function OTPInput({
  value,
  setValue,
}: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}) {
  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    setValue(numericValue);
  };

  return (
    <OtpInput
      value={value}
      onChange={(val) => handleChange(val)}
      numInputs={6}
      renderSeparator={<span className="w-[8px]" />}
      renderInput={(props) => <input {...props} />}
      inputStyle={{
        width: "47px",
        height: "60px",
        border: "1px solid #000000",
        borderRadius: "10px",
      }}
    />
  );
}

/** @deprecated Use Chip.Root  **/
export function CurrencyChipSelector({
  selected,
  setSelected,
}: {
  selected: FiatCurrencyI[];
  setSelected: Dispatch<SetStateAction<FiatCurrencyI[]>>;
}) {
  function onSelect(currency: FiatCurrencyI) {
    const _ = selected.find((curr) => curr.code === currency.code);
    if (!_) {
      setSelected((prev) => {
        return [...prev, currency];
      });
    }
  }

  return (
    <div className="w-[300px]">
      <Menu as="div" className="relative inline-block w-[100%] text-left">
        <Menu.Button className="w-[100%]">
          <ChipValues
            values={selected.map((e) => {
              return {
                image: { url: e.flagUrl, alt: e.flagName },
                value: e.code,
              };
            })}
            onValueChange={(chip) => {
              const _ = selected.filter((curr) => curr.code !== chip.value);
              setSelected(_);
            }}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-[100%] origin-top-right rounded-md bg-white p-[10px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              style={{ overflowY: "scroll" }}
              className={`${styles.custom_scrollbar} flex max-h-[300px] flex-col`}
            >
              {currencies.map((currency) => (
                <Menu.Item key={currency.code}>
                  {({ active }) => (
                    <button
                      type={"button"}
                      style={{ cursor: "pointer", fontSize: "13px" }}
                      className={cn(
                        { "bg-gray-200": active },
                        "flex cursor-pointer items-center justify-between border-b border-zinc-200 px-[5px] py-[8px]",
                      )}
                      onClick={() => onSelect(currency)}
                    >
                      <div className="flex cursor-pointer items-center">
                        <img
                          src={currency.flagUrl}
                          alt={currency?.flagName}
                          style={{
                            height: "20px",
                            width: "25px",
                            marginRight: "5px",
                          }}
                          className="rounded"
                        />{" "}
                        <span className="text-xs font-normal leading-tight text-stone-600">
                          {currency.name}
                        </span>
                      </div>
                      <span className="text-right text-xs font-normal leading-tight text-neutral-400">
                        {currency.code}
                      </span>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export function ChipSelector({
  list,
  selected,
  setSelected,
  className,
  placeholder,
}: {
  list: string[];
  selected: string[];
  className?: string;
  placeholder?: string;
  setSelected: Dispatch<SetStateAction<string[]>>;
}) {
  function onSelect(value: string) {
    const _ = selected.find((val) => val === value);
    if (!_) {
      setSelected((prev) => {
        return [...prev, value];
      });
    }
  }

  function remove(value: string) {
    const _ = selected.filter((val) => val !== value);
    setSelected(_);
  }

  const _list: string[] = [];
  list.map((val) => {
    const isSelected = selected.find((v) => v === val);
    if (!isSelected) {
      _list.push(val);
    }
  });

  return (
    <div className={`w-[300px] ${className}`}>
      <Menu as="div" className="relative inline-block w-[100%] text-left">
        <Menu.Button className="w-[100%]">
          <div
            className={`flex min-h-[36px] w-[100%] items-start rounded-[4px] border-[1px]  border-zinc-200 pr-[8px] ${
              selected.length === 0 && "justify-end"
            }`}
          >
            {selected.length > 0 ? (
              <div className="mt-[2px] flex h-[100%] w-[100%] flex-wrap items-center px-[5px] pb-[2px]">
                {selected.map((val) => (
                  <button
                    type={"submit"}
                    key={val}
                    onClick={() => remove(val)}
                    className="mr-[3px] mt-[3px] flex min-h-[24px] w-[82px] cursor-pointer items-center justify-evenly rounded-[4px] border-[1px] border-zinc-300 py-[4px]"
                  >
                    <span className="text-xs font-normal text-stone-600">
                      {val}
                    </span>
                    <XIcon size="8" color="grey" />
                  </button>
                ))}
              </div>
            ) : (
              <div className="mt-[2px] flex h-[100%] w-full flex-wrap items-center px-[5px] pb-[2px]">
                <button
                  type="button"
                  className="ml-[5px] mt-[3px] flex min-h-[24px]  cursor-pointer items-center justify-evenly border-zinc-300 py-[4px]"
                >
                  <span className="text-xs font-normal text-gray-400">
                    {placeholder}
                  </span>
                </button>
              </div>
            )}

            <div className="flex h-[36px] items-center">
              <CaretDown />
            </div>
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-[100%] origin-top-right rounded-md bg-white p-[10px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              style={{ overflowY: "scroll" }}
              className={`${styles.custom_scrollbar} flex max-h-[300px] flex-col`}
            >
              {_list.length === 0 && (
                <div className="flex h-[35px] w-[100%] items-center justify-center">
                  <span className="text-xs font-normal leading-tight text-stone-400">
                    No options
                  </span>
                </div>
              )}
              {_list.map((val: string, i) => (
                <Menu.Item key={val}>
                  {({ active }) => (
                    <button
                      type={"button"}
                      onClick={() => {
                        onSelect(val);
                      }}
                      style={{ cursor: "pointer", fontSize: "13px" }}
                      className={`flex cursor-pointer items-center justify-between border-b px-[5px] py-[8px] ${
                        _list.length !== i + 1
                          ? "border-zinc-200"
                          : "border-[transparent]"
                      } ${active ? "bg-gray-200" : ""}`}
                    >
                      <div className="flex cursor-pointer items-center">
                        <span className="text-xs font-normal leading-tight text-stone-600">
                          {val}
                        </span>
                      </div>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
