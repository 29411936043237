import { useQuery } from "@tanstack/react-query";
import { ofetch } from "ofetch";
import { sum } from "ramda";
import { ValueMap } from "~/general/interfaces";
import { convertCurrencyFrom } from "~/libs/currency.helpers";

function getCurrencyRates(symbol: "USD" | "NGN", signal: AbortSignal) {
  return ofetch("https://min-api.cryptocompare.com/data/price", {
    signal,
    query: {
      fsym: symbol,
      tsyms: "NGN,USD,EUR",
    },
  });
}

export function useCurrencyRates() {
  return useQuery({
    queryKey: ["currency", "rates"],
    queryFn({ signal }) {
      return getCurrencyRates("USD", signal);
    },
    placeholderData: {},
  });
}

function convertToUSD(rates: Record<string, number>) {
  return ([currency_code, amount]) => {
    return convertCurrencyFrom(rates, {
      from: currency_code,
      to: "USD",
      fromAmount: amount,
    });
  };
}

export function useConvert(
  currency_code: string,
  currency_value_map: ValueMap,
) {
  const { data: rates } = useCurrencyRates();

  const convertToViewCurrency = useCallback(
    (total_balance_in_usd: number) =>
      convertCurrencyFrom(rates, {
        from: "USD",
        to: currency_code,
        fromAmount: total_balance_in_usd,
      }),
    [rates, currency_code],
  );

  const usd_balances = useMemo(() => {
    return Object.entries(currency_value_map ?? {}).map(convertToUSD(rates));
  }, [rates, currency_value_map]);

  return {
    isLoading: rates.isLoading,
    data: { amount: pipe(usd_balances, sum, convertToViewCurrency, safeNum) },
  };
}
