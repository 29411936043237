import {
  Outlet,
  createFileRoute,
  useMatchRoute,
  useNavigate,
} from "@tanstack/react-router";
import { DashboardNavLinks } from "~/components/Navbars";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import {
  RootContent,
  RootLayout,
  RootSidebar,
  RootTopBar,
} from "~/components/layouts/RootLayout";
import { SharedSidebar } from "~/components/layouts/Shared";
import {
  AccountDropdownMenu,
  NotificationtDropdownMenu,
  DashboardNavbar,
} from "~/components/layouts/TopBar";

export const Route = createFileRoute("/dashboard")({
  component: DashboardLayoutComponent,
});

function DashboardLayoutComponent() {
  const navigate = useNavigate();
  const matches = useMatchRoute();

  return (
    <ProtectedRoute
      onAuthStateChange={(state) => {
        if (state.kind === "error") {
          return navigate({ to: "/signin" });
        }
      }}
    >
      <RootLayout>
        <RootTopBar className={"border-l border-gray-120"}>
          <DashboardNavbar>
            <NotificationtDropdownMenu />
            <AccountDropdownMenu />
          </DashboardNavbar>
        </RootTopBar>
        <RootSidebar>
          <SharedSidebar>
            <DashboardNavLinks
              isLinkMatch={(item) => {
                return matches({ to: item.link });
              }}
              onNavigate={(item) => {
                return navigate({ to: item.link });
              }}
            />
          </SharedSidebar>
        </RootSidebar>
        <RootContent className={"border-l border-gray-120"}>
          <Outlet />
        </RootContent>
      </RootLayout>
    </ProtectedRoute>
  );
}
