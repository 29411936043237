import CryptoJS from "crypto-js";
import moment from "moment";

const traceError = (err: unknown) => {
  try {
    const error = (typeof err === "string" ? new Error(err) : err) as Error;
    console.trace(error.message, { tag: "traceError" });
  } catch (e) {}
};

export const STAGING_SERVER_URL = import.meta.env.VITE_URL;
const ACCESS_KEY = import.meta.env.VITE_ACCESS_KEY;
const SECRET_KEY = import.meta.env.VITE_SECRET_KEY;

const handleResponse = async (endpoint, res) => {
  try {
    const json = await res.json();

    if (res.status >= 200 && res.status < 300) {
      return { ...json, status: 200, serverCode: res?.status };
    }

    return { ...json, status: 400, serverCode: res?.status };
  } catch {
    const msg = `${endpoint} ${res?.status}`;
    traceError(new Error(msg));

    if (res?.status === 403) {
      return {
        status: 403,
        serveCode: res?.status,
        message: "Request blocked. Please try again later.",
      };
    }
    return {
      status: 500,
      serveCode: res?.status,
      message: `Error ${res?.status}`,
    };
  }
};

export const request = async (
  endpoint: string,
  params: {
    body: Record<string, unknown>;
    token?: string;
    headers?: HeadersInit;
  },
  method?: "GET" | "PUT" | "POST" | "DELETE",
  ...config
) => {
  const { body, token, headers } = params;
  const requestBody: RequestInit = {
    method: method,
    mode: "cors",
    cache: "no-cache",
    body: method === "GET" ? undefined : JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
    ...config,
  };
  const request = await fetch(`${STAGING_SERVER_URL}${endpoint}`, requestBody);
  return handleResponse(endpoint, request);
};

export const mockRequest = async (
  endpoint: string,
  params: {
    body: Record<string, unknown>;
    token?: string;
    headers?: HeadersInit;
  },
  method?: "GET" | "PUT" | "POST" | "DELETE",
  ...config
) => {
  const { body, token, headers } = params;
  const requestBody: RequestInit = {
    method: method,
    mode: "cors",
    cache: "no-cache",
    body: method === "GET" ? undefined : JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
    ...config,
  };
  const request = await fetch(endpoint, requestBody);
  return handleResponse(endpoint, request);
};

export const AdminRequest = async (
  endpoint: string,
  params: {
    body: Record<string, unknown>;
    token?: string;
    headers?: HeadersInit;
  },
  method?: "GET" | "PUT" | "POST" | "DELETE",
  ...config
) => {
  const currentDate = moment().utc().format("YYYYMMDDTHHmmss[Z]"); // Use UTC time
  const canonicalRequest = `${method}\n/${endpoint}\n\nhost:${
    new URL(STAGING_SERVER_URL).hostname
  }\nx-amz-date:${currentDate}\n\nhost;x-amz-date\n${CryptoJS.SHA256(
    "",
  ).toString()}`;

  const stringToSign = `AWS4-HMAC-SHA256\n${currentDate}\n${currentDate.substring(
    0,
    8,
  )}/eu-north-1/execute-api/aws4_request\n${CryptoJS.SHA256(
    canonicalRequest,
  ).toString()}`;

  // Signing Key
  const signingKey = CryptoJS.HmacSHA256(
    "aws4_request",
    CryptoJS.HmacSHA256(
      "eu-north-1",
      CryptoJS.HmacSHA256(
        currentDate.substring(0, 8),
        CryptoJS.HmacSHA256(`AWS4${SECRET_KEY}`, `AWS4${SECRET_KEY}`),
      ),
    ),
  );

  // Signature
  const signature = CryptoJS.HmacSHA256(stringToSign, signingKey).toString(
    CryptoJS.enc.Hex,
  );
  const { body, token, headers } = params;
  const requestBody: RequestInit = {
    method: method,
    mode: "cors",
    cache: "no-cache",
    body: method === "GET" ? undefined : JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      AccessKey: ACCESS_KEY,
      // SecretKey: SECRET_KEY,
      // 'x-amz-date': currentDate,
      // Authorization: `AWS4-HMAC-SHA256 Credential=${ACCESS_KEY}/${currentDate.substring(0, 8)}/eu-north-1/execute-api/aws4_request, SignedHeaders=host;x-amz-date, Signature=${signature}`,
      ...headers,
    },
    ...config,
  };
  const request = await fetch(`${STAGING_SERVER_URL}${endpoint}`, requestBody);
  return handleResponse(endpoint, request);
};

export const fetcher = (url: string) => fetcher(url).then((res) => res.json());
