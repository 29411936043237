import { memo } from "react";
import { Skeleton } from "~/@/components/ui/skeleton";

export const TableSkeleton = memo(function TableSkeleton(props: {
  columns: number;
  rows?: number;
}) {
  const [columns, rows] = useMemo(() => {
    return [Array(props.columns).fill(0), Array(props.rows ?? 4).fill(0)];
  }, [props]);

  return (
    <tbody className="not-sr-only">
      {rows.map((_, idx) => {
        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <tr key={idx} className={"h-[63px]"}>
            {columns.map(renderSkeleton)}
          </tr>
        );
      })}
    </tbody>
  );
});

function renderSkeleton(_: unknown, i: number): React.JSX.Element {
  return (
    <td key={i} className="space-y-1 px-4 pr-8">
      <Skeleton className="h-3 w-full" />
      <Skeleton className="h-2 w-4/12" />
    </td>
  );
}
