import { Eye, EyeOff } from "lucide-react";
import React, { forwardRef, useState } from "react";
import { Button } from "~/@/components/ui/button";
import { Input } from "~/@/components/ui/input";
import { cn } from "~/@/lib/utils";

interface PasswordInputProps
  extends React.ComponentPropsWithoutRef<typeof Input> {
  hideToggle?: boolean;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput_({ hideToggle = false, className, ...props }, ref) {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
      <div className={"relative flex items-center gap-x-1"}>
        <Input
          {...props}
          ref={ref}
          type={showPassword ? "text" : "password"}
          className={"pr-12"}
        />
        {hideToggle ? null : (
          <Button
            size="icon"
            variant="link"
            data-state={showPassword ? "visible" : "hidden"}
            aria-label={showPassword ? "Hide password" : "Show password"}
            type={"button"}
            className="absolute right-0 inline-flex aspect-square !flex-shrink-0 items-center justify-center rounded p-1 text-[22px] text-[#35383F] outline-none hover:bg-blue-500/[0.2] focus:bg-blue-500/[0.2]"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff size="1.1rem" /> : <Eye size="1.1rem" />}
          </Button>
        )}
      </div>
    );
  },
);
