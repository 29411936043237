import React from "react";
import { PayoutCtx } from "..";
import CardDetails from "./CardDetails";
import BankDetails from "./BankDetails";

const AccountDetail = ({
  currentMove,
  setCurrentMove,
  setCurrentStep,
  currentStep,
}) => {
  const { payoutState } = useContext(PayoutCtx);

  return (
    <div className="mx-auto w-full max-w-[613px]">
      {["Card"].includes(payoutState?.paymentMethod?.label) && (
        <CardDetails
          setCurrentMove={setCurrentMove}
          currentMove={currentMove}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
      {["Bank", "Manual Bank"].includes(payoutState?.paymentMethod?.label) && (
        <BankDetails
          setCurrentMove={setCurrentMove}
          currentMove={currentMove}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
    </div>
  );
};

export default React.memo(AccountDetail);
