import { useBusinessMetadata } from "~/hooks/use-business";

export function AccountBriefInfo({ isOpen }: { isOpen: boolean }) {
  const [displayData, setDisplayData] = useState(false);
  const { data } = useBusinessMetadata();
  const business = data?.business;

  return (
    <>
      {isOpen ? (
        <div className="m-4 items-center justify-between gap-3 self-stretch rounded-lg bg-slate-100 px-3 py-[11px]">
          <div className="inline-flex flex-col items-start justify-start gap-1.5">
            <div className="font-body text-sm font-normal leading-[1] text-zinc-700">
              {business?.legalName || "Business Name"}
            </div>
            <div className="font-body text-xs leading-[1] text-zinc-700/[0.7]">
              {business?.registrationNumber || "Reg No."}
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setDisplayData(!displayData)}
          onKeyPress={() => {}}
          onMouseOver={() => setDisplayData(true)}
          onFocus={() => {}}
          onMouseOut={() => setDisplayData(false)}
          onBlur={() => {}}
        >
          <div className="m-2 w-12 cursor-pointer items-center justify-center gap-3 self-stretch rounded-lg bg-slate-100 px-2 py-2">
            <div className="items-center justify-center rounded-full bg-[#4084B5] p-1  text-center text-white">
              {business?.legalName?.slice(0, 1) || "N"}
            </div>
          </div>
          {displayData && (
            <div className="fixed left-[80px] top-[10px] z-20 rounded-lg bg-[#F2F6F8] p-2 pr-4 shadow-sm">
              <div className="inline-flex flex-col items-start justify-start gap-1.5">
                <div className="font-body text-sm font-normal leading-[1] text-zinc-700">
                  {business?.legalName || "Business Name"}
                </div>
                <div className="font-body text-xs leading-[1] text-zinc-700/[0.7]">
                  {business?.registrationNumber || "Reg No."}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
