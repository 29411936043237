import { createFileRoute, useRouter, Link } from "@tanstack/react-router";
import {
  CardBackButton,
  CardBody,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { useGetBusinessId } from "~/hooks/use-business";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { empty_state_no_cards } from "~/assets";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import { Wallet } from "~/libs/factories/wallet-factory";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { ClickableCard } from "~/components/ClickableCard";
import { Button } from "~/@/components/ui/button";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { useModalHandle } from "~/hooks/use-modal";
import { Skeleton } from "~/@/components/ui/skeleton";
import { EmptyStateDescription } from "~/components/molecules/empty-state";
import { Badge } from "~/components/atoms/Badge";
import { CardLikeButtonSkeleton } from "~/components/Buttons/CardLikeButtonSkeleton";

export const Route = createFileRoute("/dashboard/wallet/revenue/requestPayout")(
  {
    component: () => <Page />,
  },
);

function Page() {
  return (
    <section className="flex justify-center py-12">
      <PaymentRequest />
    </section>
  );
}

function PaymentRequest() {
  const router = useRouter();
  return (
    <>
      <CardRoot size="lg" className="px-2">
        <CardHeader className="flex justify-between gap-2 ">
          <Link className="flex items-center">
            <CardBackButton
              onClick={() => {
                router.history.back();
              }}
            />
          </Link>
          <CardTitle className="text-md">Select Wallet</CardTitle>
        </CardHeader>
        <CardSeparator className="-mx-2" />
        <ChooseWallet />
      </CardRoot>
    </>
  );
}

function ChooseWallet() {
  const { data, isLoading } = useWallets();
  const [selected, setSelected] = useState<Wallet>();

  const wallets = Array.from(
    WalletHolderImpl.toList(data, (wallet) => wallet.kind === "CORPORATE"),
  );

  const currency = getFiatCurrency(selected?.currency);

  return (
    <>
      <CardBody className="pt-5">
        <EmptyStateRoot isEmpty={wallets.length === 0 && !isLoading}>
          <EmptyStateContent>
            <div className="flex flex-col items-center">
              <img
                src={empty_state_no_cards}
                alt="Nope!"
                className="aspect-[3/2] max-w-[30ch] object-contain"
              />

              <div className="flex flex-col items-center gap-2">
                <EmptyStateDescription className="py-2 text-gray-200">
                  You have no wallet
                </EmptyStateDescription>

                <Link to="/dashboard/wallet/spend/setup">
                  <Badge type={"info"} size="sm" showIcon={false}>
                    Setup up a cash wallet
                  </Badge>
                </Link>
              </div>
            </div>
          </EmptyStateContent>
          <EmptyStateConceal>
            <div className="-mx-1 mt-[20px] flex flex-col gap-3">
              <SkeletonContent
                isLoading={isLoading}
                Component={CardLikeButtonSkeleton}
              >
                {wallets.map((item) => {
                  const currency = getFiatCurrency(item.currency);
                  return (
                    <ClickableCard
                      key={currency.code}
                      className={`items-center justify-between ${
                        selected?.currency === item?.currency &&
                        "border-[#0094FF]"
                      } px-4 py-4 `}
                      //   disabled={item.currency !== "NGN"}
                      onClick={() => {
                        setSelected(item);
                      }}
                    >
                      <div>
                        <div className="flex gap-2">
                          <img
                            alt={currency.name}
                            src={currency.flagUrl}
                            className="aspect-[20/13.3] w-[20px] object-contain"
                          />
                          <span className="text-[20px] font-semibold text-gray-600">
                            {currency.code}
                          </span>
                        </div>
                        <div className="text-neutral-500">{currency.name}</div>
                      </div>

                      <div className="text-[32px] font-semibold text-[#002C3D]">
                        {formatNumber(item.balance, { decimal: true })}
                      </div>
                    </ClickableCard>
                  );
                })}

                <Button
                  type="button"
                  size="lg"
                  variant="default"
                  className="mx-32 mt-5"
                  disabled={!currency}
                >
                  Continue
                </Button>
              </SkeletonContent>
            </div>
          </EmptyStateConceal>
        </EmptyStateRoot>
      </CardBody>
    </>
  );
}
