import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";

const ContextDefault = {
  isEmpty: true,
};

const EmptyStateCtx = React.createContext(ContextDefault);

function useContext() {
  return React.useContext(EmptyStateCtx) ?? ContextDefault;
}

const EmptyStateContent = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function Content(props, ref) {
  const { isEmpty } = useContext();
  if (!isEmpty) return null;

  return (
    <div
      {...props}
      ref={ref}
      className={cn("flex flex-col items-center gap-4", props.className)}
    />
  );
});

const EmptyStateTitle = React.forwardRef<
  HTMLHeadingElement,
  ComponentProps<"h3">
>(function ESTitle(props, ref) {
  return (
    <h6
      ref={ref}
      {...props}
      className={cn("font-500 text-center font-semibold", props.className)}
    />
  );
});

const EmptyStateDescription = React.forwardRef<
  HTMLParagraphElement,
  ComponentProps<"div">
>(function ESDescription(props, ref) {
  return <div ref={ref} {...props} />;
});

function EmptyStateConceal(props: { children?: React.ReactNode }) {
  const { isEmpty } = useContext();

  if (isEmpty) return null;

  return <>{props.children}</>;
}

function EmptyStateRoot(props: {
  isEmpty: boolean;
  children?: React.ReactNode;
}) {
  const { isEmpty = true, children } = props;

  return (
    <EmptyStateCtx.Provider value={{ isEmpty }}>
      {children}
    </EmptyStateCtx.Provider>
  );
}

export {
  EmptyStateRoot,
  EmptyStateConceal,
  EmptyStateDescription,
  EmptyStateTitle,
  EmptyStateContent,
};
