import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import {
  CardIcon,
  BankFundingIcon,
  ExchangeArrow,
  InfoIcon,
} from "~/assets/icons";
import { WalletHolderImpl, useWallet, useWallets } from "~/hooks/use-wallet";
import { WalletCurrencyDropdown } from "~/components/Menu";
import { useForm } from "react-hook-form";
import { MoveUp, MoveDown, Info } from "lucide-react";
import { Badge } from "~/components/atoms/Badge";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import {
  formatNumber,
  getFiatCurrency,
  getFlagFromRegionCode,
  InputFormatNumber,
} from "~/libs/currency.helpers";
import { Button } from "~/@/components/ui/button";
import { ClickableCard } from "~/components/ClickableCard";
import { Skeleton } from "~/@/components/ui/skeleton";
import {
  CardBody,
  CardFooter,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { Input } from "~/@/components/ui/input";
import { ConvertCtx } from ".";
import { FiatCurrencyI } from "~/constants/currencies";
import { RecipientAccountTypes } from "~/libs/types.helper";
import { Link } from "@tanstack/react-router";
import { Checkbox } from "~/@/components/ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/@/components/ui/tooltip";
import { FlatImage } from "../Withdrawal/StepDestination";
import { Alert } from "~/components/atoms/Alert";
import { use } from "i18next";

const schema = z.object({
  amount: z.string(),
  receiverAmount: z.string(),
  rateAmount: z.string(),
  customRate: z.boolean(),
});

const StepAmount = ({ completeStep, setCurrentStep, currentStep }) => {
  const { swapState, updateState } = useContext(ConvertCtx);
  const { data: wallet, isLoading } = useWallets();
  const [customRate, setCustomRate] = React.useState<boolean>(false);
  const [rateAmount, setRateAmount] = React.useState<string | number>("");
  const [amount, setAmount] = React.useState<string | number>("");
  const [receiverAmount, setReceiverAmount] = React.useState<string | number>(
    "",
  );
  const [mim, setmin] = React.useState<string | number>("0");
  const form = useForm<z.infer<typeof schema>>();

  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const [sourcetOption, setSourceOption] = useState<FiatCurrencyI>();
  const [destinationOption, setDestinationOption] = useState<FiatCurrencyI>();

  const sourceWalletOption = useMemo(() => {
    return wallets?.map((wallet) => {
      if (wallet.kind === "CORPORATE") {
        return {
          id: wallet?.id,
          symbol: wallet?.currency === "NGN" ? "₦" : "$",
          label: wallet?.currency,
          flagUrl:
            wallet?.currency === "NGN"
              ? getFlagFromRegionCode("NG")
              : getFlagFromRegionCode("US"),
          code: wallet?.currency,
          name: wallet?.currency,
          decimal: 2,
          flagName: wallet?.currency === "NGN" ? "ngn-flag" : "usd-flag",
          balance: wallet?.balance,
        };
      }
    });
  }, [wallet]);

  useEffect(() => {
    if (swapState?.swapDetails?.sourceWallet) {
      setSourceOption(swapState?.swapDetails?.sourceWallet as FiatCurrencyI);
      setDestinationOption(
        swapState?.swapDetails?.receiverWallet as FiatCurrencyI,
      );
    } else {
      setSourceOption(sourceWalletOption[0] as FiatCurrencyI);
      setDestinationOption(sourceWalletOption[1] as FiatCurrencyI);
    }
  }, [wallet]);

  useEffect(() => {
    if (swapState?.swapDetails?.sourceAmount) {
      const sourceValue = (
        Number(swapState?.swapDetails?.sourceAmount) * 100
      ).toString();
      const receiverValue = (
        Number(swapState?.swapDetails?.receiverAmount) * 100
      ).toString();
      const customRate = (
        Number(swapState?.swapDetails?.customeRate) * 100
      ).toString();
      form.setValue(
        "customRate",
        swapState?.swapDetails?.isCustomeRate as boolean,
      );
      form.setValue("rateAmount", customRate as string);
      form.setValue("amount", sourceValue as string);
      form.setValue("receiverAmount", receiverValue as string);
      setmin(sourceValue);
      setCustomRate(swapState?.swapDetails?.isCustomeRate as boolean);
      setRateAmount(InputFormatNumber(customRate));
      setAmount(InputFormatNumber(sourceValue));
      setReceiverAmount(InputFormatNumber(receiverValue));
    }
  }, []);

  const handleRateChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    form.setValue("rateAmount", numericValue as string);
    setRateAmount(InputFormatNumber(numericValue));
  };

  const handleChange = (val: string) => {
    const numericValue = val.replace(/[^0-9]/g, "");
    const convert = (Number(numericValue) * 1300).toString();
    form.setValue("amount", numericValue as string);
    form.setValue("receiverAmount", convert as string);
    setmin(numericValue);
    setAmount(InputFormatNumber(numericValue));
    setReceiverAmount(InputFormatNumber(convert));
  };

  // useEffect(() => {
  //   if (customRate && rateAmount) {
  //     const convert = (
  //       Number(amount) * (rateAmount ? Number(rateAmount) : 1300)
  //     ).toString();
  //     form.setValue("receiverAmount", convert as string);
  //     setReceiverAmount(InputFormatNumber(convert));
  //   }
  // }, [rateAmount]);

  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10 pt-5">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl font-normal text-gray-800">Amount</h2>
        <div className="w-[30%]">
          <Select value="cash" disabled>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Wallet Type" defaultValue={"cash"} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="cash">Cash</SelectItem>
              <SelectItem value="crypto">Crypto</SelectItem>
              <SelectItem value="collectibles">Collectibles</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <form
        className="mt-[20px] flex flex-col text-left"
        onSubmit={form.handleSubmit(async (data) => {
          try {
            if (!data.amount) {
              return;
            }
            const sourceAmount = Number(data.amount) / 100;
            const receiverAmount = Number(data.receiverAmount) / 100;
            const customeRate = Number(data.rateAmount) / 100;

            const swapDetails = {
              sourceWallet: sourcetOption,
              receiverWallet: destinationOption,
              sourceAmount: sourceAmount,
              customeRate: customeRate,
              isCustomeRate: data.customRate,
              receiverAmount: receiverAmount,
            };
            // console.log(swapDetails);
            updateState("swapDetails", { ...swapDetails });
            completeStep(currentStep);
            setCurrentStep(currentStep + 1);
          } catch (err) {
            console.error(err);
          }
        })}
      >
        <Form {...form}>
          <div className="flex w-full flex-col gap-4">
            <FormField
              control={form.control}
              name={"amount"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className="text-xs font-normal">
                      You're paying
                    </FormLabel>
                    <FormControl>
                      <div className="flex w-full items-center ">
                        <div
                          className={`mr-3 flex h-20 cursor-default items-center gap-2 rounded border border-gray-800 px-5 pr-10 pt-1.5
                          ${
                            (amount &&
                              Number(amount) >
                                Number(sourcetOption?.balance)) ||
                            (amount &&
                              Number(amount) <
                                Number(
                                  sourcetOption?.code === "NGN" ? 100.0 : 2.0,
                                ))
                              ? "border-red-500"
                              : ""
                          }
                          `}
                        >
                          <WalletCurrencyDropdown
                            currency={sourcetOption}
                            setCurrency={setSourceOption}
                            options={sourceWalletOption as FiatCurrencyI[]}
                          />
                        </div>

                        <Input
                          className={`flex h-20 border-gray-800 px-3 text-right text-3xl font-normal text-gray-800 outline-none placeholder:text-3xl placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]
                            ${
                              (amount &&
                                Number(amount) >
                                  Number(sourcetOption?.balance)) ||
                              (amount &&
                                Number(amount) <
                                  Number(
                                    sourcetOption?.code === "NGN" ? 100.0 : 2.0,
                                  ))
                                ? "focus-visible:ring-ring-red-500 border-red-500 focus-visible:ring-red-500"
                                : ""
                            }
                            `}
                          {...field}
                          value={amount}
                          placeholder="0.00"
                          inputMode={"numeric"}
                          onChange={(evt) => handleChange(evt.target.value)}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                    <FormDescription>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <span className="mr-[6px] text-xs text-gray-500">
                            Wallet Balance
                          </span>
                          <button
                            type="button"
                            className={
                              "inline-flex cursor-pointer items-center text-[#0094FF]"
                            }
                            disabled={true}
                          >
                            <span className="mr-[6px] text-xs">
                              <span className="text-xs font-semibold">
                                {formatNumber(sourcetOption?.balance, {
                                  decimal: true,
                                })}
                              </span>
                            </span>
                            <span className=" text-xs text-gray-500">
                              {sourcetOption?.code}
                            </span>
                          </button>
                        </div>
                        <div className="flex items-center">
                          {sourcetOption?.code === "NGN" && (
                            <span className="mr-[6px] text-xs text-gray-500">
                              {mim && Number(mim) / 100 > 100.0
                                ? "Convert Limit"
                                : " Minimum Convert"}
                            </span>
                          )}
                          {sourcetOption?.code === "USD" && (
                            <span className="mr-[6px] text-xs text-gray-500">
                              {mim && Number(mim) / 100 > 2.0
                                ? "Convert Limit"
                                : " Minimum Convert"}
                            </span>
                          )}
                          <button
                            type={"button"}
                            className={
                              "inline-flex cursor-pointer items-center text-[#0094FF]"
                            }
                            disabled={true}
                          >
                            {sourcetOption?.code === "NGN" && (
                              <span className="text-xs">
                                <span className="mr-[5px] text-xs font-semibold">
                                  {mim && Number(mim) / 100 > 100.0
                                    ? formatNumber(sourcetOption?.balance, {
                                        decimal: true,
                                      })
                                    : formatNumber(100, {
                                        decimal: true,
                                      })}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {sourcetOption?.code}
                                </span>
                              </span>
                            )}

                            {sourcetOption?.code === "USD" && (
                              <span className="text-xs">
                                <span className="mr-[5px] text-xs font-semibold">
                                  {mim && Number(mim) / 100 > 2.0
                                    ? formatNumber(sourcetOption?.balance, {
                                        decimal: true,
                                      })
                                    : formatNumber(2, {
                                        decimal: true,
                                      })}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {sourcetOption?.code}
                                </span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </FormDescription>
                  </FormItem>
                );
              }}
            />

            <div className="mx-auto my-5 flex">
              <MoveUp size="1.5rem" stroke="#008EF4" className="-mr-2" />
              <MoveDown size="1.5rem" stroke="#008EF4" />
            </div>

            <FormField
              control={form.control}
              name={"receiverAmount"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className="text-xs font-normal">
                      You're receiving
                    </FormLabel>
                    <FormControl>
                      <div className="flex w-full items-center ">
                        <div className="mr-3 flex h-20 cursor-default items-center gap-2 rounded border border-gray-800 px-5 pr-7 pt-1.5">
                          <WalletCurrencyDropdown
                            currency={destinationOption}
                            setCurrency={setDestinationOption}
                            options={sourceWalletOption as FiatCurrencyI[]}
                          />
                        </div>

                        <Input
                          className="flex h-20 border-gray-800 px-3 text-right text-3xl font-normal text-gray-400 outline-none placeholder:text-3xl placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]"
                          {...field}
                          value={receiverAmount}
                          placeholder="0.00"
                          inputMode={"numeric"}
                          disabled={true}
                          onChange={(evt) => handleChange(evt.target.value)}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                    <FormDescription>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <span className="mr-[6px] text-xs text-gray-800">
                            Rates
                          </span>
                          <div className="inline-flex cursor-pointer items-center text-[#226835]">
                            <span className="mr-1 text-xs font-semibold">
                              {formatNumber(1, {
                                decimal: true,
                              })}
                            </span>
                            <span className="text-xs font-normal">
                              {sourcetOption?.code}
                            </span>
                            <span className="ml-0.5 text-xs font-semibold">
                              {"  =  "}
                              {formatNumber(1300, {
                                decimal: true,
                              })}
                            </span>
                            <span className="ml-1 text-xs font-normal">
                              {destinationOption?.code}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Badge
                            type={"info"}
                            size="sm"
                            showIcon={false}
                            className="mt-1"
                          >
                            Best Rate
                          </Badge>
                        </div>
                      </div>
                    </FormDescription>
                  </FormItem>
                );
              }}
            />

            <CardSeparator className="my-2 border-dashed" />
            <FormField
              control={form.control}
              name={"customRate"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel
                      className={"flex items-center gap-x-2 text-gray-500"}
                    >
                      <FormControl>
                        <Checkbox
                          onCheckedChange={(value) => {
                            form.setValue(field.name, value as boolean);
                            setCustomRate(value as boolean);
                          }}
                          checked={field.value ?? false}
                          className="border-gray-300 data-[state=checked]:border-primary"
                        />
                      </FormControl>
                      <span className={"font-normal text-gray-600"}>
                        Use custom rate
                      </span>
                    </FormLabel>
                  </FormItem>
                );
              }}
            />
            {customRate && (
              <div className="">
                <div className="flex items-center">
                  <CardTitle className="pr-2 font-semibold text-gray-800">
                    Your rate
                  </CardTitle>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="">
                        <Info size="0.9rem" stroke="#008EF4" />
                      </TooltipTrigger>
                      <TooltipContent className="w-48 text-wrap text-left">
                        <p>Convert currencies</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <div className="flex items-center justify-between py-2">
                  <div className="flex items-center">
                    <FormField
                      control={form.control}
                      name={"rateAmount"}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel
                              className={
                                "flex items-center gap-x-2 text-gray-500"
                              }
                            >
                              <FormControl>
                                <Input
                                  className="flex h-10 w-28 border-gray-500 px-3 text-right text-lg font-normal text-gray-500 outline-none placeholder:text-lg placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]"
                                  {...field}
                                  value={rateAmount}
                                  placeholder="0.00"
                                  inputMode={"numeric"}
                                  onChange={(evt) =>
                                    handleRateChange(evt.target.value)
                                  }
                                />
                              </FormControl>
                            </FormLabel>
                          </FormItem>
                        );
                      }}
                    />

                    <div className="flex items-center pl-3">
                      {destinationOption?.flagUrl && (
                        <>
                          <FlatImage
                            alt={destinationOption?.code}
                            src={destinationOption.flagUrl}
                            className="aspect-[20/13.3] w-[20px] object-contain"
                          />
                          <p className="pl-2 text-sm font-semibold text-gray-800">
                            {destinationOption?.code}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <ExchangeArrow />
                  </div>
                  <div className="flex items-center">
                    <Input
                      className="flex h-10 w-28 border-gray-100 px-3 text-right text-lg font-normal text-gray-400 outline-none placeholder:text-lg placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]"
                      disabled={true}
                      value={"1.00"}
                      placeholder="0.00"
                      inputMode={"numeric"}
                      onChange={(evt) => handleChange(evt.target.value)}
                    />
                    <div className="flex items-center pl-3">
                      {sourcetOption?.flagUrl && (
                        <>
                          <FlatImage
                            alt={sourcetOption?.code}
                            src={sourcetOption.flagUrl}
                            className="aspect-[20/13.3] w-[20px] object-contain"
                          />
                          <p className="pl-2 text-sm font-semibold text-gray-800">
                            {sourcetOption?.code}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center py-1">
                  <InfoIcon size="1.3rem" color="#FFAA22" />
                  <p className="pl-2 text-sm font-normal text-gray-500">
                    <span className="px-.5 font-medium">
                      {rateAmount && rateAmount !== 0 ? rateAmount : "0.00"}
                    </span>{" "}
                    NGN will be exchanged for every{" "}
                    <span className="px-.5 font-medium">1.00</span>
                    <span className="pl-1">{sourcetOption?.code}</span>.
                  </p>
                </div>
              </div>
            )}
          </div>
        </Form>
        <div className="mt-5 items-center justify-between text-right">
          <Button
            size="lg"
            variant="default"
            type="submit"
            className="mt-5 w-[15%]"
            disabled={
              !amount ||
              Number(amount) > Number(sourcetOption?.balance) ||
              Number(amount) <
                Number(sourcetOption?.code === "NGN" ? 100.0 : 2.0)
            }
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(StepAmount);
