import * as React from "react";

import * as Popover from "@radix-ui/react-popover";

type Props = {
  open: boolean;
  variant?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  side?: "left" | "top" | "right" | "bottom";
  align?: "center" | "end" | "start";
  button: React.ReactElement;
  children: React.ReactNode;
};

const RadixPopover: React.FC<Props> = ({
  open,
  setOpen,
  button,
  side = "left",
  align = "end",
  variant,
  children,
}) => {
  return (
    <>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger className="">{button}</Popover.Trigger>
        <Popover.Content
          className={`z-[5] ${variant}`}
          side={side}
          align={align}
        >
          {children}
        </Popover.Content>
      </Popover.Root>
    </>
  );
};

export default RadixPopover;
