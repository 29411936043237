import React from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/@/components/ui/popover";
import { Button } from "~/@/components/ui/button";
import { AppEditIcon } from "~/assets/icons";
import { Calendar } from "~/@/components/ui/calendar";
import { span } from "effect/Array";

export interface DatePickerTypes {
  showIcone?: boolean;
  className?: string;
  placeholder?: string;
  date?: string | null;
  setDate?: React.Dispatch<React.SetStateAction<string | null>>;
}

function DatePicker({
  showIcone = true,
  placeholder = "Select date",
  className,
  date,
  setDate,
}: DatePickerTypes) {
  // const [date, setDate] = useState<string>("");

  const handleChange = (date: Date) => {
    const formateDate = format(date, "dd/MM/yyyy");
    setDate(formateDate);
  };

  // Modifiers to apply styles to selected days
  const modifiers = {
    selected: date,
  };

  const modifiersClassNames = {
    today:
      "bg-primary text-primary-foreground hover:bg-primary/80 hover:text-primary-foreground  ", // Style for the current day
    selected: "bg-primary text-primary-foreground", // Highlight the selected date
    hover: "hover:bg-primary text-primary-foreground", // Hover effect
  };

  const handleThisMonthClick = () => {
    const formateDate = format(new Date(), "dd/MM/yyyy");
    setDate(formateDate);
  };

  const handleLastMonthClick = () => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const formateDate = format(lastMonth, "dd/MM/yyyy");
    setDate(formateDate);
  };

  const handleLast7DaysClick = () => {
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7);
    const formateDate = format(last7Days, "dd/MM/yyyy");
    setDate(formateDate);
  };

  const handleLast6MonthsClick = () => {
    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    const formateDate = format(last6Months, "dd/MM/yyyy");
    setDate(formateDate);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={`flex w-full justify-between px-3 text-gray-700 ${className}`}
        >
          <span className={"text-sm font-normal text-gray-700"}>
            {date ? date : <span className="text-gray-500">{placeholder}</span>}
          </span>
          {showIcone && <AppEditIcon />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <Calendar
          mode="single"
          selected={new Date()}
          onSelect={handleChange}
          // modifiers={[modifiers]}
          modifiersClassNames={modifiersClassNames}
          handleThisMonthClick={handleThisMonthClick}
          handleLastMonthClick={handleLastMonthClick}
          handleLast7DaysClick={handleLast7DaysClick}
          handleLast6MonthsClick={handleLast6MonthsClick}
        />
      </PopoverContent>
    </Popover>
  );
}

export default DatePicker;
