import { Checkbox, capitalize } from "@mui/material";
import React from "react";
import { DefaultInputField } from "~/components/Inputs";
import { useUserData } from "~/components/Redux/state/user/hooks";
import useSearch from "~/hooks";

import { ArrowRight } from "~/assets/icons/ArrowRight";
import { CardBody, CardRoot } from "~/components/layouts/Card";

import useEffectEvent from "react-use-event-hook";
import { Button } from "~/@/components/ui/button";
import { Badge } from "~/components/atoms/Badge";
import { InviteModal } from "~/components/modals/InviteModal";
import { useBusinessMetadata } from "~/hooks/use-business";
import { useChecklist } from "~/hooks/use-checklist";
import { useModal } from "~/hooks/use-modal";
import { safeArray } from "~/libs/data.helper";
import { getDateFormat } from "~/libs/date.helpers";
import {
  InvitedMember,
  MemberFactory,
  TeamMember,
} from "~/libs/factories/team-members";

export function TeamMembers() {
  const { data } = useBusinessMetadata();
  const businessDetails = data.business;

  const modal = useModal();

  const members = React.useMemo(() => {
    const data = [
      ...safeArray(businessDetails?.members).map(TeamMember),
      ...safeArray(businessDetails?.invitations).map(InvitedMember),
    ];
    return data.map(MemberFactory);
  }, [businessDetails]);

  const checklist = useChecklist({ length: members.length });

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(members, "name", "email");

  const handleClick = useEffectEvent(() => {});

  return (
    <div className={"mb-10 pt-6"}>
      <CardRoot>
        <div className="mb-[0px] flex w-full flex-col items-center justify-between gap-2 px-2 py-[20px] md:flex-row md:px-[25px] ">
          <div className="flex w-full items-center md:w-[160px]">
            <DefaultInputField
              placeholder="Search"
              value={searchTerm}
              setValue={handleSearchChange}
              containerStyle={{ height: "36px" }}
            />
          </div>
          <div className="flex w-full items-start md:w-min md:items-end">
            <Button
              variant={"secondary"}
              className={"space-x-2"}
              onClick={() => modal.show("invite_user")}
            >
              <span>Send Invite</span>
              <ArrowRight size={"0.74rem"} />
            </Button>
          </div>
        </div>

        <CardBody
          className={
            "no-scrollbar flex flex-col items-stretch overflow-x-auto pt-0"
          }
        >
          <table className="-mx-6 w-full min-w-[800px] self-stretch">
            <thead className="h-[56px] bg-[#F2F6F8]">
              <tr>
                <th className="w-[44px] ">
                  <div className="flex h-[100%] items-center justify-center">
                    <Checkbox
                      size="small"
                      value={checklist.allChecked}
                      onChange={() => {
                        checklist.toggleAll(members, "email");
                      }}
                    />
                  </div>
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Name
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Role
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Last Login
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  2FA Status
                </th>
              </tr>
            </thead>
            <tbody className="">
              {filteredList.map((member) => {
                return (
                  <tr
                    role={"button"}
                    tabIndex={0}
                    className={
                      "h-[63px] cursor-pointer border-b-[.5px] border-zinc-100 hover:bg-zinc-100"
                    }
                    onClick={handleClick}
                    onKeyDown={handleClick}
                  >
                    <td>
                      <div className="flex h-[100%] w-[100%] items-center justify-center">
                        <Checkbox
                          size="small"
                          checked={checklist.checks.has(member.email)}
                          onChange={() => checklist.toggle(member.email)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col">
                        <span className={"text-[14px] text-gray-600"}>
                          {member.name}
                        </span>
                        <span className="text-xs lowercase text-gray-400">
                          {member.email}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="font-body text-[14px] font-bold text-gray-600">
                        {member.status === "active" ? (
                          capitalize(member.role.toLowerCase())
                        ) : (
                          <span className="text-primary">Invite pending</span>
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="flex flex-col font-body text-[14px] text-gray-600">
                        {member.status === "active" ? (
                          <>
                            {getDateFormat(member.lastLogin).dateFormat}
                            <span className="font-body text-xs text-gray-400">
                              {getDateFormat(member.lastLogin).timeFormat}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </td>
                    <td>
                      <div className="flex w-[100%] items-center justify-between pr-[15px]">
                        {member.status === "invited" ? (
                          <Badge type="warn">Not activated</Badge>
                        ) : member.status === "active" ? (
                          <>
                            {member.mfaEnabled === true ? (
                              <Badge type={"success"} showIcon={false}>
                                Enabled
                              </Badge>
                            ) : (
                              <Badge type={"error"} showIcon={false}>
                                Disabled
                              </Badge>
                            )}
                          </>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {searchTerm.length > 0 && filteredList.length === 0 ? (
            <div className="flex h-[60px] w-[100%] items-center justify-center">
              <span className="text-[grey]">
                No search result for{" "}
                <span className="font-bold italic">{searchTerm}</span>
              </span>
            </div>
          ) : null}
        </CardBody>
      </CardRoot>

      <InviteModal />
    </div>
  );
}
