import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { formatNumber } from "~/libs/currency.helpers";
import { getDateFormat } from "~/libs/date.helpers";
import { MPLog } from "~/libs/factories/marketplace";
import { StatusBadge, StatusDot } from "../Badges";
import { EllipsisVertical } from "~/assets/icons";
import { CTADropdown } from "~/routes/dashboard/wallet/spend/marketplace";

const columns = createColumnHelper<MPLog>();

export const statusDotColumn = columns.accessor(
  (log) => {
    return <StatusDot label={log.status} />;
  },
  {
    id: "tx-status-dot",
    header: () => (
      <div className={"flex items-center self-stretch"}>
        <StatusDot label={"success"} />
      </div>
    ),
    cell: (row) => row.getValue(),
  },
);

export const dateColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {data.date} <br />
        {/* {getDateFormat(data.created_at).dateFormat} <br /> */}
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {data.time}
          {/* {getDateFormat(data.created_at).timeFormat} */}
        </span>
      </span>
    );
  },
  {
    id: "date-time",
    header: tableHeader("Date", "Time"),
    cell: (row) => row.getValue(),
  },
);

export const sourceDetails = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.convertAmount, {
          decimal: true,
        })}{" "}
        <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {data?.convertCurrency}
        </span>
      </span>
    );
  },
  {
    id: "converting-details",
    header: tableHeader("Converting Amount", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const rateDetails = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.exchangeRate, {
          decimal: true,
        })}{" "}
        <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {data?.exchangeCurrency}
        </span>
      </span>
    );
  },
  {
    id: "rate-details",
    header: tableHeader("Exchange Rate", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const destinationDetails = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data?.receivingAmount, {
          decimal: true,
        })}{" "}
        <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {data?.receivingCurrency}
        </span>
      </span>
    );
  },
  {
    id: "receiver-details",
    header: tableHeader("Receiving Amount", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const statusColumn = columns.accessor(
  (data) => {
    return <StatusBadge label={data.status} />;
  },
  {
    id: "status",
    header: tableHeader("Status"),
    cell: (row) => row.getValue(),
  },
);
export const actionColumn = columns.accessor(
  (data) => {
    return <CTADropdown handleDelete={() => {}} handleEdit={() => {}} />;
  },
  {
    id: "cat",
    header: tableHeader(""),
    cell: (row) => row.getValue(),
  },
);

function tableHeader(
  mainHeading: string,
  subHeading?: string,
): () => React.JSX.Element {
  return () => {
    return (
      <div className="flex flex-col">
        <span
          className={`text-sm font-bold leading-tight text-gray-600 ${
            !subHeading ? "py-5" : ""
          }`}
        >
          {mainHeading}
        </span>
        {!subHeading ? null : (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              marginTop: "2px",
            }}
            className="font-body font-normal text-gray-400"
          >
            {subHeading}
          </span>
        )}
      </div>
    );
  };
}
