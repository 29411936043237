export function NotFound() {
  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center">
      <h1 className="text-[80px] font-bold">404</h1>
      <h1 className="text-[70px] font-bold">Page not found</h1>
      <h3 className="font-bold">
        The page you're looking for may have been moved or doesn't exist.
      </h3>
      <button
        type={"button"}
        onClick={() => window.history.back()}
        className="mt-[10px] cursor-pointer font-bold underline"
      >
        Go back
      </button>
    </div>
  );
}
