import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";
import { safeObj } from "~/libs/data.helper";

export const FormGrid = React.forwardRef(function FormGrid<
  TKey extends keyof React.JSX.IntrinsicElements = "div",
>(props: ComponentProps<TKey> & { as?: TKey }, ref: React.Ref<HTMLDivElement>) {
  const { children, as = "div", className, ...PROPS } = props;

  const Component = as;

  return (
    // @ts-expect-error Type error
    <Component
      // @ts-expect-error Type error
      ref={ref}
      {...PROPS}
      className={cn("grid w-full gap-x-8 gap-y-4", className)}
      style={{
        ...safeObj(PROPS.style),
        gridAutoFlow: "column",
        gridTemplate:
          "repeat(var(--rows, 5), 1fr) / repeat(var(--cols, 2), 1fr)",
        alignItems: "start",
      }}
    >
      {children}
    </Component>
  );
});
