import { getAuthData } from "~/libs";
import { request } from ".";

enum endpoints {
  BUSINESS = "/business",
  GET_CORPORATE_WALLET_TRANSACTIONS = "/business/corporate-wallet/transactions",
  FUND_CORPORATE_WALLET = "/business/corporate-wallet/fund",
  CORPORATE_WALLET_TRANSFER = "/business/corporate-wallet/transfer",
}

//
export const createBusinessWallet = async (businessId: string) => {
  const body = {
    walletType: "DEFAULT",
    threshold: 1,
    chain: "ETH",
  };
  const authData = getAuthData();
  return await request(
    `${endpoints.BUSINESS}/${businessId}/create-wallet`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};

//
export const getCorporateWalletTransactions = async (businessId: string) => {
  const body = {};
  const authData = getAuthData();
  return await request(
    `${endpoints.GET_CORPORATE_WALLET_TRANSACTIONS}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "GET",
  );
};

export const fundCorporateWallet = async (
  businessId: string,
  amount: number,
  initiatedBy: string,
  type: string,
) => {
  const body = {
    amount,
    transactionType: "funding",
    location: "Corporate wallet",
    revenueStream: "--",
    initiatedBy,
    type,
  };
  const authData = getAuthData();
  return await request(
    `${endpoints.FUND_CORPORATE_WALLET}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};

export const transferFromCorporateWallet = async (
  businessId: string,
  amount: number,
  initiatedBy: string,
) => {
  const body = {
    amount,
    transactionType: "Revenue",
    method: "Payout",
    initiatedBy,
  };
  const authData = getAuthData();
  return await request(
    `${endpoints.CORPORATE_WALLET_TRANSFER}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};
