import { ActivityLog } from "~/general/interfaces";
import { _setAdminActivities } from ".";
import { useAppDispatch, useAppSelector } from "../hooks";

// hook to easily interact with products state
export const useGeneralState = () => {
  const dispatch = useAppDispatch();

  const setAdminActivities = (activities: ActivityLog[]) =>
    dispatch(_setAdminActivities(activities));

  const adminActivities = useAppSelector(
    (state) => state.general.adminActivities,
  );

  return { adminActivities, setAdminActivities };
};
