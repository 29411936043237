import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { Button } from "~/@/components/ui/button";
import { ClickableCard } from "~/components/ClickableCard";
import { Skeleton } from "~/@/components/ui/skeleton";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { Wallet } from "~/libs/factories/wallet-factory";

const StepSource = ({ completeStep, setCurrentStep, currentStep }) => {
  const { data: wallet, isLoading } = useWallets();
  const [selected, setSelected] = useState<Wallet>();
  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const currency = getFiatCurrency(selected?.currency);
  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10 pt-5">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl font-normal text-muted-foreground">
          Select Wallet
        </h2>
        <div className="w-[30%]">
          <Select value="cash" disabled>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Wallet Type" defaultValue={"cash"} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="cash">Cash</SelectItem>
              <SelectItem value="crypto">Crypto</SelectItem>
              <SelectItem value="collectibles">Collectibles</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <EmptyStateRoot isEmpty={wallets.length === 0 && !isLoading}>
        <EmptyStateContent>
          <div className="flex flex-col items-center">You have no wallet</div>
        </EmptyStateContent>
        <EmptyStateConceal>
          <div className="-mx-1 mt-[20px] flex flex-col gap-3">
            <SkeletonContent
              isLoading={isLoading}
              Component={CardLikeButtonSkeleton}
            >
              {wallets.map((item) => {
                const currency = getFiatCurrency(item.currency);
                return (
                  <ClickableCard
                    key={currency.code}
                    className={`items-center justify-between ${
                      selected?.currency === item?.currency &&
                      "border-[#0094FF]"
                    } px-4 py-4 `}
                    //   disabled={item.currency !== "NGN"}
                    onClick={() => {
                      setSelected(item);
                    }}
                  >
                    <div>
                      <div className="flex gap-2">
                        <img
                          alt={currency.name}
                          src={currency.flagUrl}
                          className="aspect-[20/13.3] w-[20px] object-contain"
                        />
                        <span className="text-[20px] font-semibold text-gray-600">
                          {currency.code}
                        </span>
                      </div>
                      <div className="text-neutral-500">{currency.name}</div>
                    </div>

                    <div className="text-[32px] font-semibold text-[#002C3D]">
                      {formatNumber(item.balance, { decimal: true })}
                    </div>
                  </ClickableCard>
                );
              })}

              <div className="text-right">
                <Button
                  size="lg"
                  variant="default"
                  className="mt-5 w-[15%]"
                  disabled={!currency}
                  onClick={() => {
                    completeStep();
                    setCurrentStep(currentStep + 1);
                  }}
                >
                  Next
                </Button>
              </div>
            </SkeletonContent>
          </div>
        </EmptyStateConceal>
      </EmptyStateRoot>
    </div>
  );
};

export default React.memo(StepSource);

export function CardLikeButtonSkeleton() {
  return (
    <ClickableCard className="my-2 w-full justify-between" disabled>
      <div className="flex basis-1/2 flex-col space-y-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-12" />
          <Skeleton className="h-4 basis-7/12 rounded" />
        </div>
        <Skeleton className="h-3 w-full rounded" />
      </div>

      <Skeleton className="h-7 w-24 basis-4/12 rounded" />
    </ClickableCard>
  );
}
