export function InfoIcon({
  color = "currentColor",
  size = "16",
}: {
  color?: string;
  size?: string;
}) {
  return (
    <svg
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4761_6634"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4761_6634)">
        <path
          d="M8.00001 11.3333C8.1889 11.3333 8.34723 11.2694 8.47501 11.1416C8.60279 11.0138 8.66668 10.8555 8.66668 10.6666C8.66668 10.4777 8.60279 10.3194 8.47501 10.1916C8.34723 10.0638 8.1889 9.99992 8.00001 9.99992C7.81112 9.99992 7.65279 10.0638 7.52501 10.1916C7.39723 10.3194 7.33334 10.4777 7.33334 10.6666C7.33334 10.8555 7.39723 11.0138 7.52501 11.1416C7.65279 11.2694 7.81112 11.3333 8.00001 11.3333ZM8.00001 8.66659C8.1889 8.66659 8.34723 8.6027 8.47501 8.47492C8.60279 8.34714 8.66668 8.18881 8.66668 7.99992V5.33325C8.66668 5.14436 8.60279 4.98603 8.47501 4.85825C8.34723 4.73047 8.1889 4.66659 8.00001 4.66659C7.81112 4.66659 7.65279 4.73047 7.52501 4.85825C7.39723 4.98603 7.33334 5.14436 7.33334 5.33325V7.99992C7.33334 8.18881 7.39723 8.34714 7.52501 8.47492C7.65279 8.6027 7.81112 8.66659 8.00001 8.66659ZM8.00001 14.6666C7.07779 14.6666 6.21112 14.4916 5.40001 14.1416C4.5889 13.7916 3.88334 13.3166 3.28334 12.7166C2.68334 12.1166 2.20834 11.411 1.85834 10.5999C1.50834 9.78881 1.33334 8.92214 1.33334 7.99992C1.33334 7.0777 1.50834 6.21103 1.85834 5.39992C2.20834 4.58881 2.68334 3.88325 3.28334 3.28325C3.88334 2.68325 4.5889 2.20825 5.40001 1.85825C6.21112 1.50825 7.07779 1.33325 8.00001 1.33325C8.92223 1.33325 9.7889 1.50825 10.6 1.85825C11.4111 2.20825 12.1167 2.68325 12.7167 3.28325C13.3167 3.88325 13.7917 4.58881 14.1417 5.39992C14.4917 6.21103 14.6667 7.0777 14.6667 7.99992C14.6667 8.92214 14.4917 9.78881 14.1417 10.5999C13.7917 11.411 13.3167 12.1166 12.7167 12.7166C12.1167 13.3166 11.4111 13.7916 10.6 14.1416C9.7889 14.4916 8.92223 14.6666 8.00001 14.6666Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
