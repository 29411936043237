import { Link } from "@tanstack/react-router";
import React from "react";
import {
  APIConfigIcon,
  ActiveEscrowsIcon,
  AuditLogsIcon,
  CorporateWalletIcon,
  DashboardIcon,
  ComplianceIcon,
  LinkedAccountsIcon,
  UsersGroupIcon,
  RevenueBreakdownIcon,
  SettingsIcon,
  TeamManagementIcon,
  WalletsIcon,
  NavBellIcon,
} from "~/assets/navIcons";
import { Scrollbar } from "~/components/Scrollbar";
import { NavGroup, RouteGroup, RouteLink } from "../NavGroup";
import { SharedSidebar } from "../layouts/Shared";

function Brand() {
  return (
    <div className="inline-flex items-center justify-center rounded-lg bg-[#0094FF] px-4 py-2">
      <span className="text-[15px] font-black leading-tight text-white">
        CentryOS
      </span>
    </div>
  );
}

export function AuthScreenNav({ path }: { path?: string }) {
  return (
    <header className="inline-flex w-full items-center justify-between px-4 py-6 md:px-12">
      <div className="w-full">
        <Link to={path ? path : "/"}>
          <Brand />
        </Link>
      </div>
    </header>
  );
}

export function DashboardSideNav(props: { children: React.ReactNode }) {
  return <SharedSidebar>{props.children}</SharedSidebar>;
}

export function SettingsNavLinks(props: {
  isActive: (path: RouteLink) => boolean;
  onNavigate: (path: RouteLink) => void;
}) {
  const navItems = React.useMemo(
    () =>
      [
        {
          groupHeading: undefined,
          items: [
            {
              label: "Profile",
              icon: <SettingsIcon />,
              link: "/settings/profile",
              isDisabled: false,
            },
            // {
            //   label: "Notifications",
            //   icon: <NavBellIcon />,
            //   link: "/settings/notifications",
            //   isDisabled: false,
            // },
          ],
        },
        {
          groupHeading: undefined,
          items: [
            {
              label: "Team Management",
              icon: <TeamManagementIcon />,
              link: "/settings/team",
              isDisabled: false,
            },
            {
              label: "Wallets",
              icon: <WalletsIcon />,
              link: "/settings/wallets",
              isDisabled: false,
            },
            // {
            //   label: "Linked Accounts",
            //   icon: <LinkedAccountsIcon />,
            //   link: "/settings/accounts",
            //   isDisabled: false,
            // },
          ],
        },
        // {
        //   groupHeading: undefined,
        //   items: [
        //     {
        //       label: "API Configurations",
        //       icon: <APIConfigIcon />,
        //       link: "/settings/api",
        //       isDisabled: false,
        //     },
        //   ],
        // },
      ] satisfies RouteGroup[],
    [],
  );

  return navItems.map((group, index) => (
    <NavGroup
      key={group?.groupHeading ?? index}
      group={group}
      isMatch={props.isActive}
      onItemClick={(item) => {
        props.onNavigate(item);
      }}
    />
  ));
}

export function DashboardNavLinks(props: {
  isLinkMatch: (path: RouteLink) => boolean;
  onNavigate: (path: RouteLink) => void;
}) {
  const links = React.useMemo(
    () =>
      [
        {
          groupHeading: "",
          items: [
            {
              label: "Compliance",
              icon: <ComplianceIcon />,
              link: "/dashboard",
              isDisabled: true,
            },
            {
              label: "Dashboard",
              icon: <DashboardIcon />,
              link: "/dashboard/overview",
              isDisabled: false,
            },
          ],
        },
        {
          groupHeading: "Platform Accounts",
          items: [
            {
              label: "Spend",
              icon: <CorporateWalletIcon />,
              link: "/dashboard/wallet/spend/",
            },
            {
              label: "Revenue",
              icon: <RevenueBreakdownIcon />,
              link: "/dashboard/wallet/revenue/overview",
              isDisabled: true,
            },
          ],
        },
        // {
        //   groupHeading: "Revenue Wallet",
        //   items: [
        //     {
        //       label: "Overview",
        //       icon: <RevenueWalletIcon />,
        //       link: "/dashboard/wallet/revenue/overview",
        //       isDisabled: false,
        //     },
        //     {
        //       label: "Revenue Streams",
        //       icon: <RevenueStreamsIcon />,
        //       // @ts-expect-error No Destination
        //       link: "/dashboard/revenue/stream",
        //       isDisabled: true,
        //     },
        //     {
        //       label: "Revenue Logs",
        //       icon: <RevenueLogsIcon />,
        //       // @ts-expect-error No Destination
        //       link: "/dashboard/revenue/logs",
        //       isDisabled: true,
        //     },
        //   ],
        // },
        {
          groupHeading: "User Accounts",
          items: [
            {
              label: "Users",
              icon: <UsersGroupIcon />,
              link: "/dashboard/wallet/users/",
              isDisabled: false,
            },
            {
              label: "Vault",
              icon: <ActiveEscrowsIcon />,
              // @ts-expect-error No destination
              link: "/dashboard/wallet/vault",
              onClick: () => {},
              isDisabled: true,
            },
          ],
        },
        // {
        //   groupHeading: "DEVELOPERS",
        //   items: [
        //     {
        //       label: "API Keys",
        //       icon: <ApiKeysIcon />,
        //       link: "/settings/api",
        //       isDisabled: false,
        //     },
        //     {
        //       label: "Documentation",
        //       icon: <ActiveEscrowsIcon />,
        //       link: "",
        //       onClick: () =>
        //         window.open("https://docs.wallets.poket.finance/", "_blank"),
        //       isDisabled: true,
        //     },
        //   ],
        // },
      ] satisfies RouteGroup[],
    [],
  );
  const footerlinks = React.useMemo(
    () =>
      [
        {
          groupHeading: "",
          items: [
            {
              label: "Developer",
              icon: <APIConfigIcon />,
              link: "/dashboard/api",
              isDisabled: false,
            },
            {
              label: " Audit Logs",
              icon: <AuditLogsIcon />,
              link: "/dashboard/audit",
              isDisabled: false,
            },
            {
              label: "Settings",
              icon: <SettingsIcon />,
              link: "/settings/profile",
              isDisabled: false,
            },
          ],
        },
      ] satisfies RouteGroup[],
    [],
  );

  return (
    <div className={"flex flex-1 flex-col"}>
      <div className="h-full">
        <div className={"flex-1"}>
          {links.map((group) => (
            <NavGroup
              key={group.groupHeading}
              // @ts-expect-error
              group={group}
              isMatch={props.isLinkMatch}
              onItemClick={(item) => {
                props.onNavigate(item);
              }}
            />
          ))}
        </div>
      </div>

      <div className={"flex-1 bg-[#f1f5f9]"}>
        {footerlinks.map((group) => (
          <NavGroup
            key={group.groupHeading}
            group={group}
            isMatch={props.isLinkMatch}
            onItemClick={(item) => {
              props.onNavigate(item);
            }}
          />
        ))}
      </div>
    </div>
  );
}
