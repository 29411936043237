export function CaretDown({
  h,
  w,
  color,
}: {
  h?: string;
  w?: string;
  color?: string;
}) {
  return (
    <svg
      width={w || "10"}
      height={h || "6"}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 1.43701L5.17667 5.01001C5.15348 5.03323 5.12594 5.05166 5.09563 5.06422C5.06531 5.07679 5.03282 5.08326 5 5.08326C4.96718 5.08326 4.93469 5.07679 4.90437 5.06422C4.87406 5.05166 4.84652 5.03323 4.82333 5.01001L1.25 1.43701"
        stroke={color || "#919191"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
