export function NotificationIcon({ color }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.89 22 12 22ZM18 16V11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16Z"
        fill={color || "#002C3D"}
      />
      <path
        d="M18 6C18.1133 6 18.2083 5.96167 18.285 5.885C18.3617 5.80833 18.4 5.71333 18.4 5.6C18.4 5.48667 18.3617 5.39167 18.285 5.315C18.2083 5.23833 18.1133 5.2 18 5.2C17.8867 5.2 17.7917 5.23833 17.715 5.315C17.6383 5.39167 17.6 5.48667 17.6 5.6C17.6 5.71333 17.6383 5.80833 17.715 5.885C17.7917 5.96167 17.8867 6 18 6ZM18 4.4C18.1133 4.4 18.2083 4.36167 18.285 4.285C18.3617 4.20833 18.4 4.11333 18.4 4V2.4C18.4 2.28667 18.3617 2.19167 18.285 2.115C18.2083 2.03833 18.1133 2 18 2C17.8867 2 17.7917 2.03833 17.715 2.115C17.6383 2.19167 17.6 2.28667 17.6 2.4V4C17.6 4.11333 17.6383 4.20833 17.715 4.285C17.7917 4.36167 17.8867 4.4 18 4.4ZM18 8C17.4467 8 16.9267 7.895 16.44 7.685C15.9533 7.475 15.53 7.19 15.17 6.83C14.81 6.47 14.525 6.04667 14.315 5.56C14.105 5.07333 14 4.55333 14 4C14 3.44667 14.105 2.92667 14.315 2.44C14.525 1.95333 14.81 1.53 15.17 1.17C15.53 0.81 15.9533 0.525 16.44 0.315C16.9267 0.105 17.4467 0 18 0C18.5533 0 19.0733 0.105 19.56 0.315C20.0467 0.525 20.47 0.81 20.83 1.17C21.19 1.53 21.475 1.95333 21.685 2.44C21.895 2.92667 22 3.44667 22 4C22 4.55333 21.895 5.07333 21.685 5.56C21.475 6.04667 21.19 6.47 20.83 6.83C20.47 7.19 20.0467 7.475 19.56 7.685C19.0733 7.895 18.5533 8 18 8Z"
        fill={color || "#FFAA22"}
      />
    </svg>
  );
}
