import { useMemo, useState } from "react";
import { safeStr } from "~/libs/data.helper";

function useSearch<T>(
  initialList: T[],
  primarySearchKey = "",
  secondarySearchKey = "",
) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredList: T[] = useMemo(() => {
    return initialList.filter(
      (item) =>
        safeStr(item[primarySearchKey])
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (secondarySearchKey &&
          safeStr(item[secondarySearchKey])
            .toLowerCase()
            .includes(searchTerm.toLowerCase())),
    );
  }, [initialList, searchTerm, primarySearchKey, secondarySearchKey]);

  const handleSearchChange = (val: string) => {
    setSearchTerm(val);
  };

  const resetSearch = () => {
    setSearchTerm("");
  };

  return {
    searchTerm,
    handleSearchChange,
    resetSearch,
    filteredList,
  };
}

export default useSearch;
export { useCountries } from "~/hooks/use-countries";
