export function PlusIcon({ size, color }: { size?: string; color?: string }) {
  return (
    <svg
      width={size || "10"}
      height={size || "10"}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 5H8.25"
        stroke={color || "#919191"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 1.25V8.75"
        stroke={color || "#919191"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
