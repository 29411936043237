import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";

const TitleRoot = React.forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function TitleRoot(props, ref) {
    const { children, className, ...PROPS } = props;

    return (
      <hgroup
        ref={ref}
        className={cn("mb-4 mt-6 flex flex-col", className)}
        {...PROPS}
      >
        {children}
      </hgroup>
    );
  },
);

const TitleHeading = React.forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function DropdownMenuButton(props, ref) {
    const { children, className, ...PROPS } = props;

    return (
      <div
        ref={ref}
        className={cn("font-body text-2xl font-bold text-gray-800", className)}
        {...PROPS}
      >
        {children}
      </div>
    );
  },
);

const TitleSubHeading = React.forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function TitleSubHeading(props, ref) {
    const { children, className, ...PROPS } = props;

    return (
      <p
        ref={ref}
        className={cn("font-body text-base text-gray-500", className)}
        {...PROPS}
      >
        {children}
      </p>
    );
  },
);

export { TitleRoot, TitleHeading, TitleSubHeading };
