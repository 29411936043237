export function FooterBackArrow() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#F2F6F8" />
      <g clipPath="url(#clip0_4007_5680)">
        <path
          d="M33.334 22.6667H18.4406L24.9473 16.16C25.4673 15.64 25.4673 14.7867 24.9473 14.2667C24.4273 13.7467 23.5873 13.7467 23.0673 14.2667L14.2806 23.0534C13.7606 23.5734 13.7606 24.4134 14.2806 24.9334L23.0673 33.72C23.5873 34.24 24.4273 34.24 24.9473 33.72C25.4673 33.2 25.4673 32.36 24.9473 31.84L18.4406 25.3334H33.334C34.0673 25.3334 34.6673 24.7334 34.6673 24C34.6673 23.2667 34.0673 22.6667 33.334 22.6667Z"
          fill="#002C3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_4007_5680">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
