import React, { Dispatch, SetStateAction } from "react";
import Modal from ".";
import { DefaultButton, DefaultButtonOutline } from "../Buttons";
import { Divider } from "../Divider";

/** @deprecated Use Dialog **/
export function DefaultDialog({
  modal,
  showModal,
  title,
  message,
  leftButtonOptions,
  rightButtonOptions,
}: {
  modal: boolean;
  showModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  message: string;
  leftButtonOptions: { label: string; width?: string };
  rightButtonOptions: {
    label: string;
    onClick?: () => void;
    bgColor?: string;
    textColor?: string;
    width?: string;
  };
}) {
  return (
    <Modal modal={modal} showModal={showModal}>
      <div className="h-[auto] w-[450px] rounded bg-[white]">
        <div
          className="p-[20px]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span className="text-center text-sm font-bold leading-tight text-stone-600">
            {title}
          </span>
          <span className="mt-[15px]">
            <span className="text-sm font-normal leading-tight text-stone-600">
              {message}
            </span>
          </span>
        </div>
        <Divider className="my-[1px]" />
        <div className="flex items-center justify-between px-[20px] py-[15px]">
          <DefaultButtonOutline
            label={leftButtonOptions.label}
            onClick={() => showModal(false)}
            styles={{
              height: "36px",
              width: leftButtonOptions?.width || "80px",
            }}
          />
          <DefaultButton
            label={rightButtonOptions.label}
            onClick={() =>
              rightButtonOptions?.onClick ? rightButtonOptions.onClick() : null
            }
            styles={{
              height: "36px",
              width: rightButtonOptions?.width || "110px",
              backgroundColor: rightButtonOptions?.bgColor || "#3BB75E",
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
