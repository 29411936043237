import React from "react";
import { cn } from "~/@/lib/utils";

export function Header1({ children }: { children: React.ReactNode }) {
  return (
    <h1 className="text-8xl font-bold leading-[112px] text-gray-600">
      {children}
    </h1>
  );
}

export function Header2({ children }: { children: React.ReactNode }) {
  return (
    <h2 className="font-boldleading-[72px] text-6xl text-gray-600">
      {children}
    </h2>
  );
}

export function Header3({ children }: { children: React.ReactNode }) {
  return (
    <h3 className="text-5xl font-bold leading-[56px] text-gray-600">
      {children}
    </h3>
  );
}

export const Header4Medium = React.forwardRef<
  HTMLHeadingElement,
  React.ComponentProps<"h4">
>(function Header4Medium({ children, className, ...PROPS }, ref) {
  return (
    <h4
      ref={ref}
      {...PROPS}
      className={cn(
        "text-[34px] font-bold leading-[42px] text-gray-600",
        className,
      )}
    >
      {children}
    </h4>
  );
});

export function Header4Regular({ children }: { children: React.ReactNode }) {
  return (
    <h4 className="text-[34px] font-normal leading-[42px] text-gray-600">
      {children}
    </h4>
  );
}

export function Header5Medium({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h5 className={`text-2xl font-bold leading-10 text-gray-600 ${className}`}>
      {children}
    </h5>
  );
}

export function Header5Regular({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h5
      className={`text-2xl font-normal leading-10 text-gray-600 ${className}`}
    >
      {children}
    </h5>
  );
}

export function Header6Medium({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h6
      className={`text-xl font-bold leading-loose text-gray-600 ${className}`}
    >
      {children}
    </h6>
  );
}

export function Header6Regular({ children }: { children: React.ReactNode }) {
  return (
    <h6 className="text-xl font-normal leading-loose text-gray-600">
      {children}
    </h6>
  );
}

export function BodyMedium({ children }: { children: React.ReactNode }) {
  return (
    <p className="text-base font-normal leading-normal text-gray-600">
      {children}
    </p>
  );
}

export function BodyRegular({ children }: { children: React.ReactNode }) {
  return (
    <p className="text-base font-normal leading-normal text-gray-600">
      {children}
    </p>
  );
}

export function SubTextMedium({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <span className={`text-sm font-bold text-gray-600 ${className}`}>
      {children}
    </span>
  );
}

export function SubTextRegular({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <span className={`text-sm font-normal text-gray-600 ${className}`}>
      {children}
    </span>
  );
}

export function CaptionMedium({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p className={`text-xs font-bold leading-tight ${className}`}>{children}</p>
  );
}

export function TitleRegular({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={`text-base font-normal leading-normal text-gray-600 ${className}`}
    >
      {children}
    </p>
  );
}
