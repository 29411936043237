import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  isLoaderOpen: boolean;
}

const initialState: initialStateProps = {
  isLoaderOpen: false,
};

const loaderSlice = createSlice({
  name: "loaders",
  initialState,
  reducers: {
    _showEllipsisLoader(state) {
      state.isLoaderOpen = true;
    },
    _closeEllipsisLoader(state) {
      state.isLoaderOpen = false;
    },
  },
});

export default loaderSlice.reducer;

export const { _showEllipsisLoader, _closeEllipsisLoader } =
  loaderSlice.actions;
