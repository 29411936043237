import React from "react";
import { CardSeparator } from "~/components/layouts/Card";
import { Input } from "~/@/components/ui/input";
import { ConvertCtx } from ".";
import { OTPInput } from "~/components/Inputs";
import {
  useAccountData,
  useAllBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import { notify } from "~/components/Toast";
import { useLoaders } from "~/components/Redux/state/loader/hooks";
import { fetchVerifyMfa } from "~/api/codegen/walletosComponents";
import { useWithdrawal } from "~/api/codegen/liquidityComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { Button } from "~/@/components/ui/button";
import { useWallets, WalletHolderImpl } from "~/hooks/use-wallet";
import { useNavigate } from "@tanstack/react-router";

const StepAuthorize = ({ completeStep, setCurrentStep, currentStep }) => {
  const { swapState } = useContext(ConvertCtx);
  const { data: userDetails } = useAccountData();
  const mutation = useWithdrawal();
  const navigate = useNavigate();
  const businessId = useGetBusinessId();
  const { showEllipsisLoader, closeEllipsisLoader } = useLoaders();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [otp, setOtp] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const [view, setView] = useState<"otp" | "code">("otp");
  const { data: wallet } = useWallets();
  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );
  const { refetch } = useAllBusinessTransactions(
    {
      businessId,
      page: 1,
      wallets: wallets.map((w) => w.id).join(","),
    },
    {
      enabled: !!businessId,
    },
  );

  const handleAutorize = async () => {
    try {
      if (view === "otp" && otp.length === 6) {
        showEllipsisLoader();
        setLoading(true);
        const response = await fetchVerifyMfa({
          body: {
            email: userDetails.email,
            code: otp,
          },
        });

        if (response.status === "SUCCESS") {
          //   await mutation.mutateAsync({
          //     pathParams: {
          //       businessId,
          //       currency: amountState?.currency,
          //       linkedAccountId: recipientDetails?.id,
          //     },
          //     body: {
          //       reason: withdrawReason,
          //       amount: amountState?.amount,
          //       transactionType: "WITHDRAWAL",
          //     },
          //   });
          await refetch();
          closeEllipsisLoader();
          completeStep(currentStep);
          navigate({ to: "/tsuccess" });
          // setCurrentStep(currentStep + 1);
          setLoading(false);
        } else {
          closeEllipsisLoader();
          notify("error", response.message);
          setLoading(false);
        }
      }

      if (view === "code" && code.length === 10) {
        notify("success", `Backup code is ${code}`);
        return;
      }
    } catch (error) {
      setLoading(false);
      closeEllipsisLoader();
      notify("error", `${error?.message}`);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      return;
    }
  };

  return (
    <div className="mx-auto w-[600px] w-full max-w-[613px] rounded-lg bg-[white] px-10 pt-5">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl font-normal text-gray-800">
          Authorize Transaction
        </h2>
      </div>

      <div className="flex flex-col items-center justify-center gap-4 rounded-lg border py-10">
        {view === "otp" && (
          <div className="flex flex-col">
            <p className="mb-8 font-normal">
              Enter 6 digit code from your authenticator app
            </p>
            <OTPInput value={otp} setValue={setOtp} />
          </div>
        )}
        {view === "code" && (
          <div className="flex flex-col">
            <p className="mb-8 font-normal">Enter your 10 digit backup code</p>
            <Input
              className="h-10 w-full border-gray-800 text-xl font-normal text-gray-800 outline-none placeholder:text-lg placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]"
              placeholder="Backup code"
              value={code}
              onChange={(evt) => setCode(evt.target.value)}
            />
            <CardSeparator className="-mx-10 mt-14 border-dashed" />
            <div
              className="mt-5 cursor-pointer font-body text-xs font-normal leading-tight hover:text-[#0094FF]"
              onClick={() => {
                setView("otp");
              }}
              onKeyDown={(e) => handleKeyDown(e)}
            >
              <LoadingButtonContent loading={mutation.isPending}>
                Use OTP code?
              </LoadingButtonContent>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5 flex w-full items-center justify-between">
        <Button
          size="lg"
          variant="outline"
          className="mt-5 w-[15%]"
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          Back
        </Button>
        <Button
          size="lg"
          variant="default"
          disabled={otp.length !== 6 || loading}
          className="mt-5 w-[20%]"
          onClick={() => {
            handleAutorize();
          }}
        >
          <LoadingButtonContent loading={mutation.isPending || loading}>
            Authorize
          </LoadingButtonContent>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(StepAuthorize);
