import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { DEFAULT_COUNTRY } from "~/constants";
import { cacheInLocalStorage, getCountries } from "~/constants/countries";
import { CountryI } from "~/general/interfaces";

const fetcher = cacheInLocalStorage(
  "country_codes",
  (data) => Array.isArray(data) && data.length > 0,
  getCountries,
);

export function useCountries(
  config?: Pick<
    UndefinedInitialDataOptions<CountryI[], Error, CountryI[], string[]>,
    "enabled"
  >,
) {
  return useQuery({
    ...config,
    queryKey: ["countries"],
    placeholderData: [DEFAULT_COUNTRY],
    queryFn: () => fetcher(),
  });
}
