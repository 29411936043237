import { useLocalStorage, useSessionStorage } from "usehooks-ts";
import { z } from "zod";
import { invalidateAccessToken } from "~/api/auth";
import { IAuthentication } from "~/containers/Signin";
import { query_client } from "~/contexts/react-query";

// Default authentication state
const DEFAULT_AUTH_STATE: IAuthentication = {
  id: "",
  accessToken: "",
  refreshToken: "",
  expiresIn: 0,
  tokenType: "",
};

// Hook to manage authentication session using localStorage
export function useAuthSession() {
  return useLocalStorage<IAuthentication>("authentication", DEFAULT_AUTH_STATE);
}

// Hook to manage authentication logic
export function useAuth() {
  // Function to clear session data
  async function destroySession() {
    try {
      await query_client.invalidateQueries();
      await invalidateAccessToken();
    } catch (error) {
      console.error("Failed to invalidate access token:", error);
    } finally {
      localStorage.clear(); // Clear all localStorage
      sessionStorage.clear(); // Clear all sessionStorage
    }
  }

  // Function to handle logout process
  async function logOut() {
    await destroySession();
    window.location.reload(); // Reload the page to reset the app state
  }

  return {
    destroySession,
    logOut,
  };
}

// Zod schema for validating reset session data
export const resetSessionSchema = z.object({
  email: z.string().email(),
  code: z.string().length(6),
});

// Function to validate reset session payload
export function isValidResetSession(payload: unknown) {
  return resetSessionSchema.safeParse(payload).success;
}

// Hook to manage reset session using sessionStorage
export function useResetSession() {
  return useSessionStorage<z.infer<typeof resetSessionSchema>>(
    "reset_session",
    null,
  );
}
