export default function UserProgress({
  stages,
  progress,
}: {
  stages: number;
  progress: number;
}) {
  const arr = Array.from({ length: stages }, (_, index) => index + 1);
  const arr2 = Array.from({ length: stages - 1 }, (_, index) => index + 1);
  return (
    <div
      className="h-8 w-[auto]"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {arr.map((stage) => (
        <div key={stage} style={{ display: "flex", alignItems: "center" }}>
          <div className="h-8 w-8 ">
            <div
              className={`flex h-8 w-8 items-center justify-center rounded-full ${
                progress >= stage ? "bg-[#008EF4]" : "bg-gray-200"
              }`}
            >
              <span
                className={`text-center ${
                  progress >= stage ? "text-white" : "text-neutral-400"
                } font-body text-sm font-bold `}
              >
                {stage}
              </span>
            </div>
          </div>
          {stage < stages && (
            <div
              className={`left-[32px] h-[0px] w-[42px] border ${
                progress > stage ? "border-sky-500" : "border-green-50 "
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
}
