import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "@tanstack/react-router";
import { is } from "ramda";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import {
  useCreateAnAccount,
  useGetInviteSessionMetadata,
} from "~/api/codegen/walletosComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { FullscreenLoader } from "~/components/Modal";
import { VerifyAccountModalV2 } from "~/components/Modal/VerifyAccountModal";
import { notify, notifyApiError } from "~/components/Toast";
import { Header4Medium, TitleRegular } from "~/components/Typography";
import { useModal } from "~/hooks/use-modal";
import { safeStr } from "~/libs/data.helper";
import { isEmail } from "~/libs/validators";
import UserProgress from "../../components/UserProgress";

const schema = z.object({
  // email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
});

export default function Account({
  prev,
  next,
}: {
  prev: () => void;
  next: () => void;
}) {
  const navigate = useNavigate();
  const { sessionId: session_id } = useParams({ strict: false });
  const modal = useModal();

  const form = useForm<z.infer<typeof schema>>({
    mode: "onSubmit",
    resolver: zodResolver(schema),
  });

  const { isLoading, status, data, error } = useGetInviteSessionMetadata(
    {
      pathParams: { session: session_id },
    },
    { enabled: is(String, session_id) },
  );
  const { isPending, mutateAsync } = useCreateAnAccount();

  const email = isEmail(safeStr(data?.invitation?.email))
    ? data.invitation.email
    : "";

  useEffect(() => {
    if (status === "error") {
      // @ts-expect-error API Type mismatch
      notifyApiError(error);
      navigate({ to: "/signin" });
    }
  }, [status, error, navigate]);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await mutateAsync({
        body: {
          firstName: data.first_name,
          lastName: data.last_name,
          email: email,
        },
      });
      modal.show("verify_email", { email });
      notify(res.message, "success");
    } catch (err) {
      notifyApiError(err);
    }
  });

  return (
    <>
      {isLoading ? <FullscreenLoader /> : null}

      <div className="flex max-w-[600px] flex-col items-center gap-y-12 px-6 pb-[100px] pt-14 lg:w-[auto]">
        <UserProgress stages={3} progress={1} />

        <div className="text-center">
          <Header4Medium>Get Started</Header4Medium>
          <TitleRegular>Create an admin account for your business</TitleRegular>
        </div>

        <Form {...form}>
          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col gap-y-5"
          >
            <div className="flex gap-x-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>First name</FormLabel>
                      <FormControl>
                        <Input placeholder="John" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Last name</FormLabel>
                      <FormControl>
                        <Input placeholder="Doe" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            <FormItem>
              <FormLabel>Business email</FormLabel>
              <FormControl>
                <Input disabled readOnly value={email} />
              </FormControl>
              <FormMessage />
            </FormItem>

            <Button
              className="w-full"
              disabled={isLoading || isPending}
              size={"lg"}
            >
              <LoadingButtonContent loading={isPending}>
                Continue
              </LoadingButtonContent>
            </Button>
          </form>
        </Form>
      </div>

      <VerifyAccountModalV2
        onSuccess={() => {
          next();
        }}
      />
    </>
  );
}
