import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import React from "react";
import Lottie from "lottie-react";
import { CardSeparator } from "~/components/layouts/Card";
import { Button } from "~/@/components/ui/button";
import animationData from "~/assets/animations/animation-1723826246351.json";

export const Route = createFileRoute("/tsuccess")({
  component: SuccessComponent,
});

function SuccessComponent() {
  const navigate = useNavigate();
  return (
    <ProtectedRoute
      onAuthStateChange={(state) => {
        if (state.kind === "error") {
          return navigate({ to: "/signin" });
        }
      }}
    >
      <StepSuccess />
    </ProtectedRoute>
  );
}

const StepSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className={"container mx-auto flex flex-col px-12 pt-20"}>
      <div className="w-full grow px-[20px] text-center">
        <div className="mx-auto mt-5 flex w-full max-w-[613px] justify-center rounded-lg">
          <div className="flex w-full  items-center justify-center rounded-lg border py-5">
            <div className="flex w-full flex-col">
              <p className="pb-5 font-semibold text-primary">
                Processing Your Transaction!
              </p>
              <CardSeparator className="mb-10 w-full" />
              <div className="flex h-48 flex-col items-center justify-center gap-6 self-stretch px-4 py-8">
                <Lottie
                  animationData={animationData}
                  loop={true}
                  style={{ height: "100px", width: "100px" }}
                />
              </div>
              <div className="mb-5 mt-10">
                <Button
                  size="lg"
                  variant="default"
                  className="mt-5 w-[60%]"
                  onClick={() => {
                    navigate({ to: "/dashboard/wallet/spend" });
                  }}
                >
                  Return to Dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
