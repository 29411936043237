import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { formatNumber } from "~/libs/currency.helpers";
import { getDateFormat } from "~/libs/date.helpers";
import { TrxLog } from "~/libs/factories/transaction";
import { StatusBadge, StatusDot } from "../Badges";

const columns = createColumnHelper<TrxLog>();

export const statusColumn = columns.accessor(
  (data) => {
    return <StatusBadge label={data.status} />;
  },
  {
    header: tableHeader("Status", ""),
    id: "status",
    cell: (row) => row.getValue(),
  },
);

export const transactionTypeColumn = columns.accessor(
  (log) => {
    return tableHeader(log.flow_type, log.method_for_human)();
  },
  {
    id: "transaction_type",
    header: tableHeader("Transaction Type", "Method"),
    cell: (row) => row.getValue(),
  },
);

export const statusDotColumn = columns.accessor(
  (log) => {
    return <StatusDot label={log.status} />;
  },
  {
    id: "tx-status-dot",
    header: () => (
      <div className={"flex items-center self-stretch"}>
        <StatusDot label={"success"} />
      </div>
    ),
    cell: (row) => row.getValue(),
  },
);

export const createdAtColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {getDateFormat(data.created_at).dateFormat} <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {getDateFormat(data.created_at).timeFormat}
        </span>
      </span>
    );
  },
  {
    id: "date-time",
    header: tableHeader("Date", "Time"),
    cell: (row) => row.getValue(),
  },
);

export const amountColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {formatNumber(data.amount.value, { decimal: true })}
        <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body font-normal text-gray-400"
        >
          {data.amount.currency}
        </span>
      </span>
    );
  },
  {
    id: "amount",
    header: tableHeader("Amount", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const senderColumn = columns.accessor(
  (data) => {
    // console.log(data);
    return (
      <span className="flex flex-col text-[14px]  font-bold text-gray-600">
        <span>{data.source.main}</span>
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body font-normal text-gray-400"
        >
          {data.source.sub}
        </span>
      </span>
    );
  },
  {
    id: "source",
    header: tableHeader("From", "Account Type"),
    cell: (row) => row.getValue(),
  },
);

export const receiverColumn = columns.accessor(
  (data) => {
    return (
      <span className="flex flex-col text-[14px] font-bold text-gray-600">
        <span>{data.destination.main}</span>
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body font-normal text-gray-400"
        >
          {data.destination.sub}
        </span>
      </span>
    );
  },
  {
    id: "destination",
    header: tableHeader("To", "Account Type"),
    cell: (row) => row.getValue(),
  },
);

export const initiatorColumn = columns.accessor(
  (data) => {
    return (
      <span className="flex flex-col font-body text-[14px] text-gray-600">
        <span>{"--"}</span>
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body font-normal text-gray-400"
        >
          {"--"}
        </span>
      </span>
    );
  },
  {
    id: "initiator",
    header: tableHeader("Initiator", "Approver"),
    cell: (row) => row.getValue(),
  },
);

function tableHeader(
  mainHeading: string,
  subHeading: string,
): () => React.JSX.Element {
  return () => {
    return (
      <div className="flex flex-col">
        <span
          className={`text-sm font-bold leading-tight text-gray-600 ${
            !subHeading ? "py-5" : ""
          }`}
        >
          {mainHeading}
        </span>
        {!subHeading ? null : (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              marginTop: "2px",
            }}
            className="font-body font-normal text-gray-400"
          >
            {subHeading}
          </span>
        )}
      </div>
    );
  };
}
