import React from "react";
import { useGetPlatformMetadata } from "~/api/codegen/walletosComponents";
import { safeArray } from "~/libs/data.helper";

type Role = "OWNER" | "ADMIN" | "SUPPORT" | "BILLING" | "DEVELOPER";

export type RoleInfo = {
  role: Role;
  label: string;
  permissions: [];
};

export function useGetRoles() {
  return useGetPlatformMetadata(
    {},
    {
      select: React.useCallback(
        (data) => safeArray(data?.supportedBusinessRoles) as RoleInfo[],
        [],
      ),
    },
  );
}

export function useGetRole(params: { roleName: Role }) {
  const query = useGetRoles();

  return {
    ...query,
    get data() {
      return safeArray(query.data).find((e) => e?.role === params?.roleName);
    },
  };
}
