import { _closeEllipsisLoader, _showEllipsisLoader } from ".";
import { useAppDispatch, useAppSelector } from "../hooks";

// hook to easily interact with products state
export const useLoaders = () => {
  const dispatch = useAppDispatch();

  const showEllipsisLoader = () => dispatch(_showEllipsisLoader());
  const closeEllipsisLoader = () => dispatch(_closeEllipsisLoader());

  const isLoaderOpen = useAppSelector(
    (state) => state.ellipsisLoader.isLoaderOpen,
  );

  return { isLoaderOpen, showEllipsisLoader, closeEllipsisLoader };
};
