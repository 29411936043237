import { useState } from "react";
import { DialogContent, DialogRemote } from "~/@/components/ui/dialog";
import { requestRevenuePayout } from "~/api/admin";
import { USDCIcon } from "~/assets/assetIcons";
import { ArrowLeft } from "~/assets/icons/ArrowLeft";
import { ArrowTopRight } from "~/assets/icons/ArrowTopRight";
import { UserRoleIcon } from "~/assets/icons/UserRoleIcon";
import { mockValues } from "~/constants";
import { currencies } from "~/constants/currencies";
import { useModalHandle } from "~/hooks/use-modal";
import { displayMockUI, shouldDisable } from "~/libs";
import CurrencyFormatter from "~/libs/CurrencyFormatter";
import { DefaultButton, DefaultButtonOutline } from "../Buttons";
import { Divider } from "../Divider";
import { CurrencySelectorInputLg, DefaultInputField } from "../Inputs";
import { useGeneralState } from "../Redux/state/general/hooks";
import { useUserData } from "../Redux/state/user/hooks";
import ShowToast, { notifyApiError } from "../Toast";

export function RequestPayout() {
  const modal = useModalHandle("request_payout");

  const { userDetails, businessDetails } = useUserData();
  const [amount, setAmount] = useState("");
  const [amountInt, setAmountInt] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
  const [display, setDisplay] = useState<"main" | "summary">("main");
  const [owner, setOwner] = useState(
    `${userDetails?.firstName} ${userDetails?.lastName}`,
  );
  const { adminActivities, setAdminActivities } = useGeneralState();

  const balance = displayMockUI() ? mockValues.revenueBalance : 0;
  const usdcVal = amountInt * 1.13;

  async function createRequest() {
    try {
      const initiator = `${userDetails?.firstName} ${userDetails?.lastName}`;
      const res = await requestRevenuePayout(
        businessDetails?.id,
        initiator,
        amountInt,
      );
      if (res.status === 200) {
        modal.hide();
        setDisplay("main");
        setAmountInt(0);
        setAmount("");
        setAdminActivities([res.activity, ...adminActivities]);
        ShowToast(res.message, "success");
      } else {
      }
    } catch (err) {
      notifyApiError(err);
    }
  }

  function next() {
    if (display === "main") {
      if (amountInt === 0) {
        return ShowToast("Enter a valid amount to proceed", "error");
      }
      if (amountInt > balance) {
        return ShowToast(
          "Amount can not be greater than revenue balance",
          "error",
        );
      }
      setDisplay("summary");
    }
    if (display === "summary") {
      createRequest();
    }
  }

  function goBack() {
    if (display === "summary") {
      setDisplay("main");
    } else {
      modal.hide();
    }
  }

  return (
    <DialogRemote id={"request_payout"}>
      <DialogContent>
        <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type="button"
            onClick={() => goBack()}
            className="cursor-pointer"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
          </button>

          <span
            className={"text-[20px] font-bold leading-tight text-[#0094FF]"}
          >
            {display === "main" ? "Request Payout" : "Summary"}
          </span>
        </div>

        <div className="h-[auto] w-[550px] rounded bg-[white]">
          {display === "main" ? (
            <div className="h-[auto] w-[100%] bg-[white] px-[20px] py-[0px]">
              <div className="h-[95%] rounded p-[20px] shadow">
                <span className="text-center text-xl font-bold leading-loose text-stone-600">
                  Amount
                </span>
                <CurrencySelectorInputLg
                  currency={selectedCurrency}
                  setCurrency={setSelectedCurrency}
                  currencies={currencies}
                  value={amount}
                  setValue={setAmount}
                  setIntValue={setAmountInt}
                />
                <div className="mt-[10px] flex w-[100%] items-center justify-end">
                  <div className="inline-flex h-5 items-start justify-start gap-1">
                    <div className="text-xs font-normal leading-tight text-zinc-700">
                      Revenue Balance
                    </div>
                    <div className="text-xs font-bold leading-tight text-sky-500">
                      {CurrencyFormatter.formatCurrency(balance)}
                    </div>
                    <div className="text-xs font-normal leading-tight text-sky-800">
                      {selectedCurrency.label}
                    </div>
                  </div>
                </div>
                <Divider />
                <div>
                  <span className="text-center text-base font-normal leading-loose text-stone-600">
                    You Get
                  </span>
                  <div className="mt-[15px] flex items-center justify-between">
                    <span className="flex items-center">
                      <USDCIcon />
                      <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                        USDC
                      </span>
                    </span>

                    <span className="text-[32px] font-bold leading-10 text-cyan-950">
                      {CurrencyFormatter.formatCurrency(
                        parseFloat(usdcVal.toFixed(2)),
                      )}
                    </span>
                  </div>
                  <span className="mt-[20px] inline-flex h-5 w-[117px] items-start justify-start gap-1">
                    <span className="text-xs font-bold leading-tight text-green-800">
                      1
                    </span>
                    <span className="text-xs font-normal leading-tight text-green-800">
                      USDC
                    </span>
                    <span className="text-xs font-bold leading-tight text-green-800">
                      /
                    </span>
                    <span className="text-xs font-bold leading-tight text-green-800">
                      1
                    </span>
                    <span className="text-xs font-normal leading-tight text-green-800">
                      {selectedCurrency.code}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-[auto] w-[100%] bg-[white] px-[20px] py-[0px]">
              <div className="h-[95%] rounded p-[20px] shadow">
                <span className="mb-[25px] text-center text-base font-normal leading-loose text-stone-600">
                  You Send
                </span>
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-center gap-2 bg-[white]">
                    <span
                      style={{ fontSize: "24px" }}
                      className="flex items-center font-bold leading-tight text-gray-400"
                    >
                      <img
                        src={selectedCurrency.flagUrl}
                        alt={selectedCurrency?.flagName}
                        className="mr-[3px] h-[32px] w-[32px]"
                      />
                      {selectedCurrency.label}
                    </span>
                  </div>
                  <span style={{ fontSize: "34px" }} className="font-bold">
                    {CurrencyFormatter.formatCurrency(parseInt(amount))}
                  </span>
                </div>
                <div className="mt-[10px] flex w-[100%] items-center justify-start">
                  <div className="inline-flex h-5 items-start justify-start gap-1">
                    <div className="text-xs font-normal leading-tight text-zinc-700">
                      Revenue Balance
                    </div>
                    <div className="text-xs font-bold leading-tight text-sky-500">
                      {CurrencyFormatter.formatCurrency(balance)}
                    </div>
                    <div className="text-xs font-normal leading-tight text-sky-800">
                      {selectedCurrency.label}
                    </div>
                  </div>
                </div>
                <div className="mt-[20px]">
                  <span className="text-center text-base font-normal leading-loose text-stone-600">
                    You Get
                  </span>
                  <div className="mt-[15px] flex items-center justify-between">
                    <span className="flex items-center">
                      <USDCIcon />
                      <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                        USDC
                      </span>
                    </span>
                    <span className="text-[32px] font-bold leading-10 text-cyan-950">
                      {(amountInt * 1.13).toFixed(2)}
                    </span>
                  </div>
                  <Divider className="my-[0px] mt-[5px]" />
                  <span className="mt-[0px] inline-flex h-5 w-[117px] items-start justify-start gap-1">
                    <span className="text-xs font-bold leading-tight text-green-800">
                      1
                    </span>
                    <span className="text-xs font-normal leading-tight text-green-800">
                      USDC
                    </span>
                    <span className="text-xs font-bold leading-tight text-green-800">
                      /
                    </span>
                    <span className="text-xs font-bold leading-tight text-green-800">
                      1
                    </span>
                    <span className="text-xs font-normal leading-tight text-green-800">
                      {selectedCurrency.code}
                    </span>
                  </span>
                </div>
              </div>
              <div className="p-[20px]">
                <span className="text-xl font-bold leading-loose text-stone-600">
                  Role
                </span>
                <DefaultInputField
                  icon={<UserRoleIcon />}
                  label="Owner"
                  disabled
                  value={owner}
                  setValue={setOwner}
                  footerElement={
                    <div className="inline-flex h-5 w-[100%] items-start justify-between">
                      <div />
                      <div className="flex items-center justify-end gap-0.5">
                        <div className="text-right text-xs font-bold leading-tight text-[#0094FF]">
                          Switch Role
                        </div>
                        <span className="flex items-center justify-center pb-[1.50px] pl-[2.25px] pr-[1.79px] pt-[2.54px]">
                          <ArrowTopRight />
                        </span>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          )}

          <div className="h-[80px] w-[100%] border-t-[.5px] border-zinc-200 px-[20px]">
            <div className="mt-[20px] flex items-center justify-between">
              <DefaultButtonOutline
                label="Back"
                onClick={() => {}}
                className="h-[33px] w-[61px]"
              />
              <DefaultButton
                label="Request Payout"
                onClick={() => next()}
                disabled={shouldDisable()}
                className="h-[33px] w-[auto] bg-[#3BB75E] px-[10px]"
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogRemote>
  );
}
