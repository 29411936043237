export function VBankIcon({ size = "1rem" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14546_60058)">
        <path
          d="M16.6665 3.33337H3.33317C2.40817 3.33337 1.67484 4.07504 1.67484 5.00004L1.6665 15C1.6665 15.925 2.40817 16.6667 3.33317 16.6667H16.6665C17.5915 16.6667 18.3332 15.925 18.3332 15V5.00004C18.3332 4.07504 17.5915 3.33337 16.6665 3.33337ZM15.8332 15H4.1665C3.70817 15 3.33317 14.625 3.33317 14.1667V10H16.6665V14.1667C16.6665 14.625 16.2915 15 15.8332 15ZM16.6665 6.66671H3.33317V5.00004H16.6665V6.66671Z"
          fill="#002C3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_14546_60058">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
