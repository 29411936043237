import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { LinksFooter } from "~/components/Footer";
import { InitalScaleHelmet } from "~/components/Helmet";
import { AuthScreenNav } from "~/components/Navbars";
import { NotFound } from "~/containers/Dashboard/Pages/404";
import Account from "~/containers/Signup/Account";
import Password from "~/containers/Signup/Password";
import TwoFA from "~/containers/Signup/2FA";

export const Route = createFileRoute("/signup/$sessionId")({
  component: TeamMemberOnboarding,
});

export function TeamMemberOnboarding() {
  const [currentState, updateCurrentState] = React.useState(0);

  function prev() {
    if (currentState < 1) {
      return;
    }
    updateCurrentState(currentState - 1);
  }

  function next() {
    if (currentState === 3) {
      return;
    }
    updateCurrentState(currentState + 1);
  }

  const Component = React.useMemo(
    () => Components[currentState] ?? NotFound,
    [currentState],
  );

  return (
    <div>
      <InitalScaleHelmet />

      <AuthScreenNav />

      <main className="flex flex-col items-center px-4 py-12">
        <Component next={next} prev={prev} />
      </main>

      <LinksFooter />
    </div>
  );
}

const Components = [Account, Password, TwoFA];
