export function ZelleIcon({ size = "1rem" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5833 17.5H5.41667C3.80583 17.5 2.5 16.1942 2.5 14.5833V5.41667C2.5 3.80583 3.80583 2.5 5.41667 2.5H14.5833C16.1942 2.5 17.5 3.80583 17.5 5.41667V14.5833C17.5 16.1942 16.1942 17.5 14.5833 17.5Z"
        fill="#AA00FF"
      />
      <path
        d="M7.29167 7.70703H13.125C13.355 7.70703 13.5417 7.52036 13.5417 7.29036V6.2487C13.5417 6.0187 13.355 5.83203 13.125 5.83203H7.29167C7.06167 5.83203 6.875 6.0187 6.875 6.2487V7.29036C6.875 7.52036 7.06167 7.70703 7.29167 7.70703Z"
        fill="white"
      />
      <path
        d="M7.08366 14.375H13.1253C13.3553 14.375 13.542 14.1883 13.542 13.9583V12.9167C13.542 12.6867 13.3553 12.5 13.1253 12.5H7.08366C6.85366 12.5 6.66699 12.6867 6.66699 12.9167V13.9583C6.66699 14.1883 6.85366 14.375 7.08366 14.375Z"
        fill="white"
      />
      <path
        d="M9.27148 4.58333V7.08333C9.27148 7.19833 9.36482 7.29167 9.47982 7.29167H10.9382C11.0532 7.29167 11.1465 7.19833 11.1465 7.08333V4.58333C11.1465 4.46833 11.0532 4.375 10.9382 4.375H9.47982C9.36482 4.375 9.27148 4.46833 9.27148 4.58333Z"
        fill="white"
      />
      <path
        d="M9.27148 13.3333V15.8333C9.27148 15.9483 9.36482 16.0417 9.47982 16.0417H10.9382C11.0532 16.0417 11.1465 15.9483 11.1465 15.8333V13.3333C11.1465 13.2183 11.0532 13.125 10.9382 13.125H9.47982C9.36482 13.125 9.27148 13.2183 9.27148 13.3333Z"
        fill="white"
      />
      <path
        d="M6.90779 12.8901H9.16695L13.4561 7.54055C13.5303 7.44805 13.464 7.31055 13.3453 7.31055H11.042L6.78154 12.6235C6.69404 12.7297 6.76987 12.8901 6.90779 12.8901Z"
        fill="white"
      />
    </svg>
  );
}
