import { assign, setup } from "xstate";
import { FiatCurrencyI } from "~/constants/currencies";
import { CurrencyFactory } from "~/libs/factories/currency";
import { Wallet } from "../factories/wallet-factory";

export const addFundsMachine = setup({
  types: {
    context: {} as {
      walletId: string;
      currency: { code: string; flagUrl: string; name: string };
      amount_transferred: number;
    },
    events: {} as
      | { type: "NEXT" }
      | { type: "PROCESSING" }
      | {
          type: "WALLET_CHOSEN";
          data: { currency: FiatCurrencyI; walletId: string };
        }
      | { type: "BACK" }
      | { type: "BANK_TRANSFER"; data: { walletId: string } }
      | { type: "PAYMENT_MADE" }
      | { type: "RESET" },
  },
  schemas: {
    events: {
      NEXT: {
        type: "object",
        properties: {},
      },
      PROCESSING: {
        type: "object",
        properties: {},
      },
      WALLET_CHOSEN: {
        type: "object",
        properties: {},
      },
      BACK: {
        type: "object",
        properties: {},
      },
      BANK_TRANSFER: {
        type: "object",
        properties: {},
      },
      PAYMENT_MADE: {
        type: "object",
        properties: {},
      },
    },
    context: {
      walletId: {
        type: "string",
      },
      currency: {
        type: "object",
        properties: {
          code: {
            type: "string",
          },
          flagUrl: {
            type: "string",
          },
          name: {
            type: "string",
          },
        },
      },
      amount_transferred: {
        type: "number",
      },
    },
  },
}).createMachine({
  context: {
    walletId: null,
    currency: null,
    amount_transferred: 0,
  },
  id: "wallet_funding",
  initial: "choose_wallet",
  states: {
    choose_wallet: {
      on: {
        WALLET_CHOSEN: {
          target: "choose_funding_method",
          actions: assign({
            currency: (event) => CurrencyFactory(event.event.data.currency),
            walletId: (event) => String(event.event.data.walletId),
          }),
        },
      },
      description: "Select the Wallet you want to fund",
    },
    choose_funding_method: {
      on: {
        BANK_TRANSFER: {
          target: "view_transfer_details",
          actions: assign({
            walletId: (event) => String(event.event.data.walletId),
          }),
        },
        BACK: {
          target: "choose_wallet",
        },
      },
    },
    view_transfer_details: {
      on: {
        PAYMENT_MADE: {
          target: "FUNDING_SUCCESS",
        },
        BACK: {
          target: "choose_funding_method",
        },
      },
    },
    FUNDING_SUCCESS: {
      on: {
        RESET: {
          target: "choose_wallet",
          actions: assign({
            walletId: () => null,
            currency: () => null,
            amount_transferred: () => 0,
          }),
        },
      },
    },
  },
});
