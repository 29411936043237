import { Checkbox } from "@mui/material";
import React, { useId } from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import { Skeleton } from "~/@/components/ui/skeleton";
import { Textarea } from "~/@/components/ui/textarea";
import { ArrowRight, PlusIcon } from "~/assets/icons";
import { UsersGroupIcon } from "~/assets/navIcons";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { SkeletonContent } from "~/components/atoms/skeleton";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
} from "~/components/layouts/Card";
import {
  TitleHeading,
  TitleRoot,
  TitleSubHeading,
} from "~/containers/Dashboard/Pages/Settings/Title";
import { RoleInfo, useGetRoles } from "~/hooks/use-app-config";
import { useBusinessMetadata } from "~/hooks/use-business";
import { useModal } from "~/hooks/use-modal";
import { safeArray } from "~/libs/data.helper";
import {
  InvitedMember,
  MemberFactory,
  TeamMember,
} from "~/libs/factories/team-members";

interface Member {
  readonly _tag: "Member";
  name: string;
  status: "active" | "invited";
  role: string;
  email: string;
  lastLogin: Date;
  mfaEnabled: boolean;
  isChecked: boolean;
}

export function ManageRoles({ role, setRole }) {
  const modal = useModal();
  const { data: roles = [], refetch, isLoading } = useGetRoles();

  return (
    <div className={"mt-6 flex flex-col gap-6"}>
      {role ? (
        <PermissionManager roleData={role} onSave={() => setRole(undefined)} />
      ) : (
        <>
          <CardRoot>
            <CardHeader className={"flex justify-between"}>
              <TitleHeading>Manage Roles</TitleHeading>
              <Button
                variant={"secondary"}
                className={"hidden space-x-2"}
                onClick={() => {
                  modal.show("create_role");
                }}
              >
                <PlusIcon color={"currentColor"} size={"12px"} />
                <span>Add Role</span>
              </Button>
            </CardHeader>

            <CardBody className={"pt-6"}>
              <div className={"grid grid-cols-1 gap-6 md:grid-cols-2"}>
                <SkeletonContent isLoading={isLoading} Component={RoleSkeleton}>
                  {roles.map((role) => {
                    const roleName = role.label;

                    return (
                      <button
                        key={roleName}
                        type={"button"}
                        className={
                          "flex flex-col gap-[0.625rem] rounded-xl border p-[1rem] text-left hover:border-primary"
                        }
                        onClick={() => setRole(role)}
                      >
                        <div className={"flex w-full justify-between"}>
                          <div>
                            <p className={"text-sm text-gray-400"}>Role Name</p>
                            <p
                              className={
                                "text-[0.875rem] font-bold text-gray-600"
                              }
                            >
                              {roleName}
                            </p>
                          </div>
                          <div className={"text-[#828282]"}>
                            <ArrowRight
                              strokeWidth={0.5}
                              color={"currentColor"}
                              size={"24px"}
                            />
                          </div>
                        </div>
                        {/*<div className={"my-2  h-[1px] bg-[#DEE5E7]"} />*/}
                        {/*<div className={"flex space-x-2"}>*/}
                        {/*  <div*/}
                        {/*    className={*/}
                        {/*      "rounded-full border border-[#DEE5E7] p-1 px-3 text-[0.75rem] text-gray-600"*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    Johnny*/}
                        {/*  </div>*/}
                        {/*  <div*/}
                        {/*    className={*/}
                        {/*      "rounded-full border border-[#DEE5E7] p-1 px-3 text-[0.75rem] text-gray-600"*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    Kelvin*/}
                        {/*  </div>*/}
                        {/*  <div*/}
                        {/*    className={*/}
                        {/*      "rounded-full border border-[#DEE5E7] p-1 px-3 text-[0.75rem] text-gray-600"*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    Micheal*/}
                        {/*  </div>*/}
                        {/*  <div*/}
                        {/*    className={*/}
                        {/*      "rounded-full border border-[#DEE5E7] p-1 px-3 text-[0.75rem] text-gray-600"*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    +10*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </button>
                    );
                  })}
                </SkeletonContent>
              </div>
            </CardBody>
          </CardRoot>

          <AddRole onSuccess={refetch} />
        </>
      )}
    </div>
  );
}

function AddRole(props: { onSuccess: () => void }) {
  const form = useForm();

  const onSubmit = form.handleSubmit(() => {
    props.onSuccess;
  });

  return (
    <DialogRemote id={"create_role"}>
      <DialogContent>
        <DialogTitle>Create Role</DialogTitle>

        <Form {...form}>
          <div className={"pt-2"}>
            <FormField
              control={form.control}
              name="role_name"
              render={() => (
                <FormItem>
                  <FormLabel>Role name</FormLabel>
                  <FormControl>
                    <Input />
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={() => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea />
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </Form>

        <DialogFooter>
          <Button>Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRemote>
  );
}

function PermissionManager({
  roleData,
  onSave,
}: {
  roleData: RoleInfo;
  onSave: () => void;
}) {
  const { data } = useBusinessMetadata();
  const businessDetails = data.business;

  const members = React.useMemo(() => {
    const data = [
      ...safeArray(businessDetails?.members).map(TeamMember),
      ...safeArray(businessDetails?.invitations).map(InvitedMember),
    ];
    return data.map(MemberFactory);
  }, [businessDetails]);

  const { label: roleName } = roleData ?? {};
  const permissions = safeArray(roleData.permissions);

  function countUsersWithRole(
    members: Member[],
    roleData: RoleInfo | undefined,
  ) {
    if (roleData === undefined) {
      return 0;
    }
    if (members.length > 0) {
      const targetRole = roleData.role;
      return members.filter((user) => user.role === targetRole).length;
    }
    return 0;
  }

  return (
    <section>
      <div className="mb-4">
        <p className="text-gray-80 font-body text-2xl font-bold">{roleName}</p>
        {roleName === "Owner" ||
          (roleName === "Administrator" && (
            <p className="font-body text-base text-gray-500">
              This role grants users the permission to manage everything on the
              dashboard
            </p>
          ))}
      </div>

      <CardRoot>
        <CardHeader className="border-b border-zinc-200 ">
          <div className="flex items-center justify-start gap-1">
            <UsersGroupIcon />
            <span>
              Team mates with this role: (
              {countUsersWithRole(members, roleData)})
            </span>
          </div>
        </CardHeader>

        <CardBody className={"divide-y"}>
          <PermissionList permissions={permissions} roleName={roleName} />
        </CardBody>

        <CardFooter>
          <Button onClick={onSave}>
            <LoadingButtonContent>Save changes</LoadingButtonContent>
          </Button>
        </CardFooter>
      </CardRoot>
    </section>
  );
}

function transform_structure(
  permissions: typeof flat_permission_structure,
): Array<[string, Array<[string, Array<string>]>]> {
  const object = {};

  for (const item of permissions) {
    const [group_title, second] = item.path;
    const sub_group_title = second ?? "General";

    object[group_title] = {
      ...(object[group_title] ?? {}),
      [sub_group_title]: append(
        object[group_title]?.[sub_group_title],
        item.description,
      ),
    };
  }

  return Object.entries(object).map(([key, value]) => [
    key,
    Object.entries(value),
  ]);

  function append(list: Record<string, unknown>, data: string) {
    if (Array.isArray(list)) {
      return [...list, data];
    }
    return [data];
  }
}

function PermissionList({ permissions, roleName }) {
  return (
    <div>
      <h2 className={"pb-2 font-body text-xl font-semibold text-gray-600"}>
        Permissions
      </h2>

      <div className={"font-body"}>
        <ul className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-10">
          <li
            className={
              "rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg py-3 shadow-md"
            }
          >
            <h3
              className={
                " rounded-tl-lg  rounded-tr-lg bg-[#3BB75E]  p-2 pl-4 font-semibold text-[#E8EBEE]"
              }
            >
              What you can do
            </h3>

            <ul className={"flex flex-col pl-3 pr-3"}>
              {permissions.map((permission) => {
                return <Permission>{permission.description}</Permission>;
              })}
            </ul>
          </li>
          {roleName === "Owner" ||
            (roleName === "Administrator" && (
              <li
                className={
                  "h-min rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg py-3 shadow-md"
                }
              >
                <h3
                  className={
                    " rounded-tl-lg  rounded-tr-lg bg-[#F05050] p-2  pl-4 text-center font-semibold text-[#E8EBEE]"
                  }
                >
                  What you can do
                </h3>

                <div className="flex flex-col items-center justify-center gap-6 py-6">
                  <h4 className="font-semibold text-[#57584E]">
                    This role has full access!
                  </h4>
                  <div className="w-14 border-b border-b-[#DEE5E7]" />
                  <p className="max-w-80 text-center text-[#57584E]">
                    Any team member with this role can access all the sections
                    of the dashboard.
                  </p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

function Permission(props: { children?: React.ReactNode }) {
  const id = useId();

  return (
    <li className={"flex items-center justify-start border-b border-[#DEE5E7]"}>
      <label htmlFor={id}>
        <Checkbox
          id={id}
          checked={true}
          disabled={false}
          sx={{
            "& .MuiSvgIcon-root": {
              color: "#0094FF",
            },
          }}
        />
        <span>
          <span className={"font-medium text-[#0094FF]"}>Can </span>
          <span className={"text-[#4F5056]"}>{props.children}</span>
        </span>
      </label>
    </li>
  );
}

function RoleSkeleton() {
  return (
    <div
      className={
        "pointer-events-none min-h-[100px] w-full rounded-lg border border-zinc-200/[0.4] p-4"
      }
    >
      <div className={"flex flex-col gap-y-2"}>
        <Skeleton className={"h-2 w-[100px]"} />
        <Skeleton className={"h-5 w-[200px]"} />
      </div>
    </div>
  );
}

const flat_permission_structure: Array<{
  path: string[];
  description: string;
}> = [
  { description: "View wallet settings", path: ["Corporate Wallet"] },
  { description: "Edit wallet settings", path: ["Corporate Wallet"] },
  {
    description: "View Business Performance Metrics",
    path: ["Corporate Wallet", "Metrics"],
  },
  {
    description: "View Wallet Transaction Log",
    path: ["Corporate Wallet", "Metrics"],
  },
  {
    description: "Export Wallet Transaction Log",
    path: ["Corporate Wallet", "Metrics"],
  },
  {
    description: "View Wallet Activity Log",
    path: ["Corporate Wallet", "Metrics"],
  },
  {
    description: "Export Wallet Activity Log",
    path: ["Corporate Wallet", "Metrics"],
  },
  { description: "Add Funds", path: ["Corporate Wallet", "Transactions"] },
  {
    description: "Request Withdrawals",
    path: ["Corporate Wallet", "Transactions"],
  },
  {
    description: "Approve Withdrawals",
    path: ["Corporate Wallet", "Transactions"],
  },
  {
    description: "Request Transfers",
    path: ["Corporate Wallet", "Transactions"],
  },
  {
    description: "Approve Transfers",
    path: ["Corporate Wallet", "Transactions"],
  },

  {
    description: "View Business Performance Metrics",
    path: ["Revenue Wallet", "Metrics"],
  },
  {
    description: "View Wallet Transaction Log",
    path: ["Revenue Wallet", "Metrics"],
  },
  {
    description: "Export Wallet Transaction Log",
    path: ["Revenue Wallet", "Metrics"],
  },
  {
    description: "View Wallet Activity Log",
    path: ["Revenue Wallet", "Metrics"],
  },
  {
    description: "Export Wallet Activity Log",
    path: ["Revenue Wallet", "Metrics"],
  },
  { description: "Add Funds", path: ["Revenue Wallet", "Transactions"] },
  {
    description: "Request Withdrawals",
    path: ["Revenue Wallet", "Transactions"],
  },
  {
    description: "Approve Withdrawals",
    path: ["Revenue Wallet", "Transactions"],
  },
  {
    description: "Request Transfers",
    path: ["Revenue Wallet", "Transactions"],
  },
  {
    description: "Approve Transfers",
    path: ["Revenue Wallet", "Transactions"],
  },
];
