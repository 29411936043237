import React from "react";
import { CopyIcon } from "~/assets/icons/CopyIcon";

export const CopyButton = React.forwardRef(function CopyButton(
  props: { children?: React.ReactNode },
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <button
      ref={ref}
      {...props}
      type={"button"}
      className="inline-flex cursor-pointer items-center"
    >
      <span className="mr-[6px] text-xs text-[#0094FF]">
        {props.children ?? "Copy"}
      </span>
      <CopyIcon h="14" w="14" color="#4084B5" />
    </button>
  );
});
