import { Dispatch, SetStateAction, useState } from "react";
import { useSessionStorage } from "usehooks-ts";
import { XIcon } from "~/assets/icons/XIcon";
import Modal from ".";
import { DefaultButton, DefaultButtonOutline } from "../Buttons";
import { Divider } from "../Divider";
import { DefaultInputField } from "../Inputs";
import ShowToast, { notify } from "../Toast";
import { BodyRegular, Header6Medium, SubTextMedium } from "../Typography";

import { Balancer } from "react-wrap-balancer";
import { Button } from "~/@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { Input } from "~/@/components/ui/input";
import { ArrowRight } from "~/assets/icons/ArrowRight";
import { useModalHandle } from "~/hooks/use-modal";
import { LoadingButtonContent } from "../Buttons/LoadingButtonContent";

type VerifyAccountProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  email: string;
  onSuccess: () => void;
};

export function useVerifyCredentials() {
  return useSessionStorage("_temp_vc_", {
    pass: "",
    email: "",
  });
}

const VerifyAccountModal = ({
  open,
  setOpen,
  email,
  onSuccess: done,
}: VerifyAccountProps) => {
  const [otp, setOtp] = useState("");
  const [, setTempLogin] = useVerifyCredentials();

  const handleVerification = () => {
    if (otp.length > 6) {
      setTempLogin({
        pass: otp,
        email: email,
      });
      done();
      setOpen(false);
    } else {
      ShowToast("Invalid Password", "error");
    }
  };
  return (
    <Modal
      modal={open}
      showModal={setOpen}
      renderHeader={
        <div className="flex h-[50px] items-center justify-between border-b-[1px] border-zinc-200 px-[20px]">
          <Header6Medium>Verify your email</Header6Medium>
          <button type="button" onClick={() => setOpen(false)}>
            <XIcon />
          </button>
        </div>
      }
    >
      <div className="flex w-[100%] flex-col items-center">
        <div className="bg-white">
          <div className="grid gap-6 px-4 py-8">
            <BodyRegular>
              <p className="px-12 text-center">
                Enter the temporary password sent to your email address;
                <strong className="block text-blue-500">{email}</strong>
              </p>
            </BodyRegular>
            <Divider className="my-0" />
            <div className="w-full px-16">
              <DefaultInputField
                value={otp}
                setValue={setOtp}
                placeholder="Enter Password"
              />
            </div>
            <Divider className="my-0" />
            <SubTextMedium>
              <div className="flex items-center justify-center gap-1 text-blue-500">
                <p>Didn't get the code?</p>
                <div className="flex h-3 w-3 items-center justify-center rounded-full border  text-[8px] font-black">
                  ?
                </div>
              </div>
            </SubTextMedium>
          </div>

          <Divider className="my-0" />

          <div className="flex items-center justify-between p-4">
            <DefaultButtonOutline
              label="Back"
              onClick={() => setOpen(false)}
              className="w-max"
            />

            <DefaultButton
              label="Verify email"
              onClick={handleVerification}
              className="w-max"
              disabled={!otp}
              icon={<ArrowRight />}
              rtl
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export function VerifyAccountModalV2({ onSuccess }: { onSuccess: () => void }) {
  const [otp, setOtp] = useState("");
  const { data } = useModalHandle("verify_email");

  const email = data?.email;

  const [, saveCred] = useVerifyCredentials();

  const handleVerification = () => {
    if (otp.length > 6) {
      saveCred({
        pass: otp,
        email: email,
      });
      onSuccess();
    } else {
      notify("error", "Invalid Password");
    }
  };

  return (
    <DialogRemote id="verify_email">
      <DialogContent className="font-body">
        <DialogHeader>
          <DialogTitle>Verify your email</DialogTitle>
        </DialogHeader>

        <div className=" mt-4 ">
          <p className="text-center font-body text-muted-foreground">
            <Balancer>
              Enter the temporary password sent to your email address:
            </Balancer>
            <span className="text-black">{email}</span>
          </p>

          <Divider className="-mx-6" />

          <div>
            <Input
              value={otp}
              type="text"
              placeholder="Enter Password"
              onChange={(evt) => setOtp(evt.target.value)}
            />
          </div>

          <Divider className="-mx-6" />

          {/* <p className="flex items-center justify-center gap-1 text-sm font-semibold text-blue-500">
            Didn't get the code?
          </p> */}
        </div>

        <DialogFooter>
          <Button
            className="w-max"
            disabled={!otp}
            onClick={handleVerification}
          >
            <LoadingButtonContent>Verify email</LoadingButtonContent>
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRemote>
  );
}

export default VerifyAccountModal;
