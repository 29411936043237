import { hash } from "ohash";
import { has, is, memoizeWith, mergeDeepWith } from "ramda";
import { FiatCurrencyI, SymbolCurrencyMap } from "~/constants/currencies";
import { ValueMap } from "~/general/interfaces";
import { simpleCurrencyFormatter } from "~/libs/currency.helpers";
import { safeObj } from "~/libs/data.helper";

export const BalanceMap = {
  create: function ValueMap(values: Record<string, number>): ValueMap {
    return safeObj(values) as ValueMap;
  },

  reduce(a: ValueMap, b: ValueMap) {
    return pipe(
      mergeDeepWith(
        (value_a, value_b) => {
          return safeNum(value_a, 0) + safeNum(value_b, 0);
        },
        a,
        b,
      ),
      BalanceMap.create,
    );
  },

  format(currency: string, valueMap: ValueMap) {
    return BalanceMapFormatter(Object.keys(valueMap), currency).format(
      this.value,
    );
  },
};

export function BalanceMapFormatter(
  currencies: string[],
  selected_currency: string,
) {
  const set = new Set(currencies);
  const intl_num = simpleCurrencyFormatter(selected_currency);

  return {
    getValue(values: Record<string, number | string>): number {
      if (!set.has(selected_currency)) return 0;
      if (!has(selected_currency, values)) return 0;

      return safeNum(values[selected_currency], 0);
    },
    format(values: Record<string, number | string>) {
      return intl_num.format(this.getValue(values));
    },
  };
}

export const getOptionsFromValueMap = memoizeWith(
  (value) => hash(value, { excludeValues: true }),
  (value: ValueMap): FiatCurrencyI[] => {
    if (!is(Object, value)) return [];
    return Object.keys(value)
      .map((e) => SymbolCurrencyMap[e])
      .filter((e) => e);
  },
);
