import { getAuthData } from "~/libs";
import { request } from ".";

enum endpoints {
  ADMIN_ACTIVITIES = "/business/admin/activities",
  REQUEST_REVENUE_PAYOUT = "/business/admin/revenue/request-payout",
  APPROVE_REVENUE_PAYOUT = "/business/admin/revenue/approve-payout",
}

export const getAdminActivities = async (businessId: string) => {
  const body = {};
  const authData = getAuthData();
  return await request(
    `${endpoints.ADMIN_ACTIVITIES}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "GET",
  );
};

export const requestRevenuePayout = async (
  businessId: string,
  initiatedBy: string,
  amount: number,
) => {
  const body = { initiatedBy, amount };
  const authData = getAuthData();
  return await request(
    `${endpoints.REQUEST_REVENUE_PAYOUT}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};

export const approveAdminRequest = async (
  businessId: string,
  activityId: string,
  status: "approve" | "deny",
) => {
  const body = { id: activityId, status };
  const authData = getAuthData();
  return await request(
    `${endpoints.APPROVE_REVENUE_PAYOUT}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};
