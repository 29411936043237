import React, { ComponentProps } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { BusinessFields } from "~/containers/Onboarding/BusinessFields";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import { Input } from "~/@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { FormGrid } from "~/containers/Onboarding/FormGrid";
import {
  OnboardingHeading,
  OnboardingSubHeading,
} from "~/containers/Onboarding/Layout";
import {
  OnboardingFormProps,
  form_one_schema,
} from "~/containers/Onboarding/helpers";
import { isDevelopment } from "~/libs";

const mock_data = isDevelopment()
  ? {
      address: "27 Ada george street",
      city: "Port-Harcourt",
      business_email: "once@owonwo.33mail.com",
      business_name: "Once",
      selectedCountry: "NG",
      state: "Rivers",
      zipCode: "50300",
    }
  : {};

export function OnboardingOne(props: OnboardingFormProps) {
  const { next, initialFormState } = props;

  const form = useForm<z.infer<typeof form_one_schema>>({
    defaultValues: initialFormState ?? mock_data,
    resolver: zodResolver(form_one_schema),
    mode: "all",
  });

  const onSubmit = form.handleSubmit((data) => {
    next(data);
  });

  return (
    <form className="flex flex-col items-center" onSubmit={onSubmit}>
      <Form {...form}>
        <OnboardingHeading.Slot>
          Let’s verify your business
        </OnboardingHeading.Slot>
        <OnboardingSubHeading.Slot>
          This information will be used to verify your business
        </OnboardingSubHeading.Slot>

        <FormGrid style={{ "--rows": 4 }} className="gap-x-20">
          <FormField
            name={"business_name"}
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Legal business name *</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Business Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name={"business_structure"}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Business structure</FormLabel>
                  <FormControl>
                    <BusinessStructureField {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          {/* TODO: Re-enable when supported */}
          {/*<FormField*/}
          {/*  control={form.control}*/}
          {/*  name={"phone_country"}*/}
          {/*  render={() => {*/}
          {/*    return (*/}
          {/*      <FormItem>*/}
          {/*        <FormLabel>*/}
          {/*          Business phone number&nbsp;*/}
          {/*          <span className={"font-normal"}>(optional)</span>*/}
          {/*        </FormLabel>*/}

          {/*        <FormControl>*/}
          {/*          <PhoneNumberInput*/}
          {/*            country={form.watch("phone_country")}*/}
          {/*            setCountry={(val) => {*/}
          {/*              form.setValue("phone_country", val);*/}
          {/*            }}*/}
          {/*            value={form.watch("phone_number")}*/}
          {/*            setValue={(val) => {*/}
          {/*              form.setValue("phone_number", val as string);*/}
          {/*            }}*/}
          {/*          />*/}
          {/*        </FormControl>*/}

          {/*        <FormMessage />*/}
          {/*      </FormItem>*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}

          <FormField
            name={"business_email"}
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Business email*</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Business email address" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <BusinessFields />
        </FormGrid>

        <Button type={"submit"} size={"lg"} className={"mt-20 w-full md:w-1/2"}>
          Continue
        </Button>
      </Form>
    </form>
  );
}

const business_structures = [
  { value: "null", label: "Select option" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Partnership", label: "Partnership" },
  {
    value: "Limited Liability Company (LLC)",
    label: "Limited Liability Company (LLC)",
  },
  { value: "Corporation", label: "Corporation" },
  { value: "S Corporation", label: "S Corporation" },
  { value: "Nonprofit Organization", label: "Nonprofit Organization" },
  { value: "Cooperative", label: "Cooperative" },
  { value: "Limited Partnership (LP)", label: "Limited Partnership (LP)" },
  {
    value: "Limited Liability Partnership (LLP)",
    label: "Limited Liability Partnership (LLP)",
  },
  {
    value: "Professional Corporation (PC)",
    label: "Professional Corporation (PC)",
  },
  { value: "Joint Venture", label: "Joint Venture" },
  { value: "Franchise", label: "Franchise" },
  {
    value: "B Corporation (Benefit Corporation)",
    label: "B Corporation (Benefit Corporation)",
  },
  { value: "Trust", label: "Trust" },
  {
    value: "Community Interest Company (CIC)",
    label: "Community Interest Company (CIC)",
  },
];

const BusinessStructureField = React.forwardRef<
  React.ElementRef<typeof SelectValue>,
  {
    value: string;
    onChange: ComponentProps<typeof Select>["onValueChange"];
    onBlur: ComponentProps<"select">["onBlur"];
  }
>(function BusinessStructureField(props, ref) {
  return (
    <Select
      autoComplete={"true"}
      onValueChange={props.onChange}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue
          ref={ref}
          placeholder={"Select option"}
          onBlur={props.onBlur}
        />
      </SelectTrigger>

      <SelectContent>
        {business_structures.map((e) => {
          return (
            <SelectItem key={e.value} value={e.value}>
              {e.label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
});
