import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import React from "react";
import CurrencySwap from "~/containers/CurrencySwap";

export const Route = createFileRoute("/convert")({
  component: ConvertLayoutComponent,
});

function ConvertLayoutComponent() {
  const navigate = useNavigate();
  return (
    <ProtectedRoute
      onAuthStateChange={(state) => {
        if (state.kind === "error") {
          return navigate({ to: "/signin" });
        }
      }}
    >
      <CurrencySwap />
    </ProtectedRoute>
  );
}
