import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ArrowRight } from "lucide-react";
import { Button } from "~/@/components/ui/button";
import { cn } from "~/@/lib/utils";
import { useResetPassword } from "~/api/codegen/walletosComponents";
import { ApiError } from "~/api/response-types";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { AuthScreenNav } from "~/components/Navbars";
import { notify, notifyApiError } from "~/components/Toast";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { usePIN } from "~/hooks/pin";
import { isValidResetSession, useResetSession } from "~/hooks/use-auth";

export const Route = createFileRoute("/auth/reset-password")({
  component: Page,
});

function Page() {
  const [reset_session, setSession] = useResetSession();
  const navigate = useNavigate();
  const reset = useResetPassword();

  const pin = usePIN({
    length: 6,
  });

  return (
    <>
      <AuthScreenNav />

      <div className="container mx-auto flex flex-col items-center py-24">
        <CardRoot className="max-w-[612px]">
          <CardHeader>
            <CardTitle className="font-body text-lg font-bold">
              Verify Account
            </CardTitle>
          </CardHeader>

          <CardSeparator />

          <CardBody className="space-y-6">
            <p className="mx-auto  max-w-[40ch] text-balance text-center text-sm text-muted-foreground">
              Enter 6 digit code sent to your email address <br />
              <span className={"font-semibold text-foreground"}>
                {reset_session?.email}
              </span>
            </p>

            <div className="relative flex justify-center gap-x-4 text-sm">
              {pin.digits.map((digit, index) => {
                return (
                  <input
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    {...pin.register({ index: index })}
                    type="text"
                    defaultValue={digit ?? ""}
                    data-value={!!digit}
                    className={cn(
                      "inline-flex aspect-[1/1.2] w-12 items-center justify-center rounded-sm border text-center text-2xl font-bold ring-offset-1 focus:ring-2 data-[value=true]:border-primary",
                      {
                        "border-primary":
                          pin.focused && index === 0 && pin.position === 0,
                      },
                    )}
                  />
                );
              })}
            </div>

            <input
              ref={pin.mainInput}
              type="text"
              className="absolute left-0 top-0 w-[0.1px] opacity-0"
              maxLength={6}
              onKeyDown={(evt) => {
                const code = evt.nativeEvent.code;
                if (
                  !(
                    code === "Enter" ||
                    code === "Backspace" ||
                    code.includes("Digit")
                  )
                ) {
                  evt.preventDefault();
                }
              }}
              onChange={(evt) => {
                pin.handleChange(evt.currentTarget.value);
              }}
              onBlur={() => {
                pin.onBlur();
              }}
            />
          </CardBody>

          <CardFooter className="flex justify-between">
            <Button
              size="sm"
              variant="outline"
              disabled={reset.isPending}
              onClick={() => {
                reset
                  .mutateAsync({ body: { email: reset_session.email } })
                  .then(() => {
                    notify(
                      "success",
                      "We've sent a new code to your email address",
                    );
                  })
                  .catch(() =>
                    notifyApiError(
                      ApiError({
                        message: "Unable to resend verification token",
                      }),
                    ),
                  );
              }}
            >
              <LoadingButtonContent loading={reset.isPending}>
                Didn't get code?
              </LoadingButtonContent>
            </Button>
            <Button
              size="sm"
              variant="default"
              className="gap-2"
              disabled={!pin.isFilled}
              onClick={() => {
                const payload = { ...reset_session, code: pin.value };
                setSession(payload);
                if (!isValidResetSession(payload)) {
                  return notify(
                    "error",
                    "We couldn't verify. Please resend email address",
                  );
                }
                return navigate({ to: "/auth/new-password" });
              }}
            >
              Continue <ArrowRight size="0.874rem" />
            </Button>
          </CardFooter>
        </CardRoot>
      </div>
    </>
  );
}
