import { getAuthData } from "~/libs";
import { request } from ".";

enum endpoints {
  REVENUE = "/business/revenue/transactions",
  CREATE_REVENUE_STREAM = "/business/revenue/streams/create",
  GET_REVENUE_STREAMS = "/business/revenue/streams",
}

export const getRevenueStreams = async (businessId: string) => {
  const body = {};
  const authData = getAuthData();
  return await request(
    `${endpoints.GET_REVENUE_STREAMS}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "GET",
  );
};

export const createRevenueStream = async (
  businessId: string,
  name: string,
  revenueType: string,
) => {
  const body = { name, revenueType };
  const authData = getAuthData();
  return await request(
    `${endpoints.CREATE_REVENUE_STREAM}/${businessId}`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};
