import { FiatCurrencyI } from "~/constants/currencies";

export function CurrencyFactory(data: Partial<FiatCurrencyI>): FiatCurrencyI {
  return {
    label: data?.label ?? null,
    name: data?.name ?? "--",
    symbol: data?.symbol ?? "--",
    code: data?.code ?? null,
    decimal: data?.decimal ?? 2,
    flagUrl: data?.flagUrl ?? "",
    balance: data?.balance ?? 0,
  };
}
