import React from "react";
import { Badge } from "~/components/atoms/Badge";
import { ValueMap } from "~/general/interfaces";
import { safeStr } from "~/libs/data.helper";
import { BalanceMap } from "~/libs/factories/balance-map";
import { shortenId } from "~/libs/string.helper";
import { safeParseISO } from "../date.helpers";

export type CentralUserStatus = "DORMANT" | "ACTIVE" | "BLOCKED" | "DISABLED";

export const BasicUser = (data: {
  external_id: string;
  id: string;
  firstname: string;
  email: string;
  lastname: string;
  role: string;
  status: string;
}) => {
  const email = data?.email ?? "";
  const [first_name, last_name] = email.split("@")?.[0]?.split?.(".") ?? "";

  return {
    external_id: data?.external_id,
    user_id: data?.id ?? "--",
    firstname: data?.firstname ? data.firstname : first_name ?? "-",
    lastname: data?.lastname ? data.lastname : last_name ?? "-",
    status: (data?.status ?? "DORMANT") as CentralUserStatus,
    role: data?.status ?? "--",
    email: data?.email ?? "--",
    get full_name() {
      return `${this.firstname} ${this.lastname}`;
    },
    $original: data,
  };
};

export function userBadgeStatus(status: CentralUserStatus) {
  const map = {
    ACTIVE: "success",
    DORMANT: "default",
    BLOCKED: "error",
    // BLOCKED: "warn",
    DISABLED: "error",
  } satisfies Record<
    CentralUserStatus,
    React.ComponentProps<typeof Badge>["type"]
  >;

  return status in map ? map[status] : "default";
}

export interface CentralWalletUser {
  id: string;
  firstname: string;
  lastname: string;
  username: string;
  accountId: string;
  email: string;
  created_at: Date;
  last_active: Date;
  status: CentralUserStatus;
  balances: {
    total: ValueMap;
    wallet: ValueMap;
    vault: ValueMap;
  };
}

export const CentralWalletUser = (data: {
  id: string;
  name: unknown;
  total_balance: Record<string, unknown>;
  sub_wallet_balance: Record<string, unknown>;
  vault_balance: Record<string, unknown>;
  status: unknown;
  created_at: string;
  last_active: string;
  external_id: unknown;
  email: unknown;
}): CentralWalletUser => {
  const [firstname, lastname] = safeStr(data?.name || "Anonymous").split(
    /_|\s/g,
  );

  return {
    id: safeStr(data.id, "no_id"),
    firstname: firstname,
    lastname,
    created_at: safeParseISO(data?.created_at),
    last_active: safeParseISO(data?.last_active),
    get username() {
      return `${safeStr(firstname)} ${safeStr(lastname)}`.trim();
    },
    accountId: <string>data?.external_id,
    balances: {
      // @ts-expect-error
      total: BalanceMap.create(data?.total_balance),
      // @ts-expect-error
      wallet: BalanceMap.create(data?.sub_wallet_balance),
      // @ts-expect-error
      vault: BalanceMap.create(data?.vault_balance),
    },
    status: safeStr(data?.status, "UNKNOWN") as CentralUserStatus,
    email: safeStr(data?.email),
  } as const;
};

type User = ReturnType<typeof BasicUser>;

export const UserFactoryImpl = {
  shortId(user: User) {
    return shortenId(user.user_id);
  },
};
