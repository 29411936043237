import { noop } from "lodash";
import React from "react";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import { SafeWallet, Wallet } from "~/libs/factories/wallet-factory";

const NO_WALLET = {
  kind: "NONE",
} as const;

const BusinessContext = React.createContext<{
  current_wallet: SafeWallet;
  switchWallet: (wallet: SafeWallet) => void;
  wallets: Wallet[];
}>({
  current_wallet: { kind: "NONE" },
  switchWallet: noop,
  wallets: [],
});

export const useBusinessCtx = () => React.useContext(BusinessContext);

export const BusinessProvider = (props: { children: React.ReactNode }) => {
  const [current_wallet, switchWallet] = React.useState<SafeWallet>(NO_WALLET);
  const { data } = useWallets();
  const wallets = Array.from(
    WalletHolderImpl.toList(data, (wallet) => wallet.kind === "CORPORATE"),
  );

  return (
    <BusinessContext.Provider
      value={{
        wallets,
        current_wallet:
          current_wallet.kind === "NONE"
            ? wallets[0] ?? NO_WALLET
            : current_wallet,
        switchWallet,
      }}
    >
      {props.children}
    </BusinessContext.Provider>
  );
};
