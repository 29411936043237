import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/@/components/ui/button";
import { Calendar } from "~/@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/@/components/ui/form";
import { FormItemSplit } from "~/@/components/ui/form-layout";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/@/components/ui/popover";
import { AppEditIcon } from "~/assets/icons";
import { FieldShowBalanceIn } from "~/components/Inputs/fields";
import { Alert } from "~/components/atoms/Alert";
import { CardBody, CardFooter, CardRoot } from "~/components/layouts/Card";

export const Route = createFileRoute("/settings/wallets/revenue-wallet")({
  component: Component,
});

function Component() {
  const form = useForm();

  return (
    <CardRoot className="mt-6">
      <CardBody className="flex flex-col gap-y-6 pt-6">
        <Alert type={"info"}>
          Funds will be automatically sent to the corporate wallet
        </Alert>

        <Form {...form}>
          <FormField
            control={form.control}
            name="show_balance_in"
            render={() => <FieldShowBalanceIn />}
          />

          <FormField
            control={form.control}
            name="payout_date"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <FormLabel>Payout Date</FormLabel>
                    <FormControl>
                      {/* @ts-expect-error */}
                      <DatePicker {...field} />
                    </FormControl>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />
        </Form>
      </CardBody>
      <CardFooter>
        <Button>Save changes</Button>
      </CardFooter>
    </CardRoot>
  );
}

function DatePicker() {
  const [date, setDate] = React.useState(() => new Date());
  // Modifiers to apply styles to selected days
  const modifiers = {
    selected: date,
  };

  const modifiersClassNames = {
    today:
      "bg-primary text-primary-foreground hover:bg-primary/80 hover:text-primary-foreground  ", // Style for the current day
    selected: "bg-primary/50 text-primary-foreground", // Highlight the selected date
    hover: "hover:bg-primary text-primary-foreground", // Hover effect
  };

  const handleThisMonthClick = () => {
    const formateDate = format(new Date(), "dd/MM/yy");
    setDate(new Date());
  };

  const handleLastMonthClick = () => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const formateDate = format(lastMonth, "dd/MM/yy");
    setDate(lastMonth);
  };

  const handleLast7DaysClick = () => {
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7);
    const formateDate = format(last7Days, "dd/MM/yy");
    setDate(last7Days);
  };

  const handleLast6MonthsClick = () => {
    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    const formateDate = format(last6Months, "dd/MM/yy");
    setDate(last6Months);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={"flex justify-between px-3 text-gray-700"}
        >
          <span className={"text-sm font-normal text-gray-700"}>
            {format(date, "E do, MMM yyy")}
          </span>
          <AppEditIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <Calendar
          mode="single"
          selected={new Date()}
          onSelect={setDate}
          modifiers={modifiers}
          modifiersClassNames={modifiersClassNames}
          handleThisMonthClick={handleThisMonthClick}
          handleLastMonthClick={handleLastMonthClick}
          handleLast7DaysClick={handleLast7DaysClick}
          handleLast6MonthsClick={handleLast6MonthsClick}
        />
      </PopoverContent>
    </Popover>
  );
}
