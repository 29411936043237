import React from "react";
import { FullscreenLoader } from "~/components/Modal";
import { useAccountData } from "~/hooks/use-business";
import { getAuthData } from "~/libs";
import { useUserData } from "./Redux/state/user/hooks";

export function ProtectedRoute(props: {
  children: React.ReactNode;
  onAuthStateChange: (state: { kind: "error"; value: Error }) => void;
}) {
  const { children } = props;

  const { setUserDetails } = useUserData();
  const authData = getAuthData();
  const token = authData?.accessToken;

  const request = useAccountData();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (request.status === "success") {
      const account = request?.data;

      if (!(token && account)) {
        props.onAuthStateChange({
          kind: "error",
          value: new Error("Access denied"),
        });
        localStorage.removeItem("authentication");
        localStorage.removeItem("persist:wallet_os_8");
        return;
      }

      setUserDetails(account);
    }

    if (request.status === "error") {
      props.onAuthStateChange({
        kind: "error",
        value: request.error as unknown as Error,
      });
    }
  }, [token, request.status]);

  if (request.isLoading) {
    return <FullscreenLoader />;
  }

  if (request.status !== "success") {
    return null;
  }

  return children;
}
