import { useState } from "react";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { PlusIcon } from "~/assets/icons/PlusIcon";
import { DefaultButton, DefaultButtonOutline } from "~/components/Buttons";
import { OnboardingBackButton } from "~/components/Buttons/back-buttons";
import { OTPInput } from "~/components/Inputs";
import { useLoaders } from "~/components/Redux/state/loader/hooks";
import { Header4Medium, TitleRegular } from "~/components/Typography";
import UserProgress from "../../components/UserProgress";
import { useAccountData } from "~/hooks/use-business";
import {
  fetchEnableMfa,
  fetchVerifyMfa,
} from "~/api/codegen/walletosComponents";
import { FullscreenLoader } from "~/components/Modal";
import { notify, notifyApiError } from "~/components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { verify } from "crypto";
import { ArrowRight } from "~/assets/icons";
import { useNavigate } from "@tanstack/react-router";

interface MfaData {
  secretCode?: string;
  backupCodes?: string[];
}

export default function TwoFA({
  prev,
  next,
}: {
  prev: () => void;
  next: () => void;
}) {
  const { showEllipsisLoader, closeEllipsisLoader } = useLoaders();
  const [view, switchView] = useState<"2fa" | "otp" | "2fa-codes">("2fa");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [otpUrl, setOtpUrl] = useState("");
  const [mfaData, setMfaData] = useState<MfaData | null>(null);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);

  const { data: userDetails } = useAccountData();
  const navigate = useNavigate();

  useEffect(() => {
    enableTwoFA();
    setBackupCodes(userDetails?.backupCodes);
  }, [userDetails]);

  async function enableTwoFA() {
    const data = {
      email: userDetails.email,
      action: "generate_secret",
    };
    if (userDetails.mfaEnabled === false) {
      try {
        setIsLoading(true);
        // @ts-expect-error
        const res = await fetchEnableMfa({ body: data });
        setMfaData(res);
        // @ts-expect-error
        setBackupCodes(res.backupCodes);
        setOtpUrl(
          // @ts-expect-error
          `otpauth://totp/CentryOS:${userDetails.email}?secret=${res.secretCode}&issuer=GradientFi&algorithm=SHA1&digits=6&period=30`,
        );
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  function _prev() {
    if (view === "2fa") {
      prev();
    } else if (view === "otp") {
      switchView("2fa");
    } else if (view === "2fa-codes") {
      switchView("otp");
    }
  }

  function _next() {
    if (view === "2fa") {
      switchView("otp");
    } else if (view === "otp") {
      showEllipsisLoader();
      setTimeout(() => {
        closeEllipsisLoader();
      }, 1500);
      switchView("2fa-codes");
    } else {
      next();
    }
  }

  async function verifyTOTP() {
    if (otp.length !== 6) {
      notify("error", "Invalid OTP");
    } else {
      showEllipsisLoader();
      try {
        const res = await fetchVerifyMfa({
          body: {
            email: userDetails.email,
            code: otp,
          },
        });
        if (res.status === "SUCCESS") {
          await fetchEnableMfa({
            // @ts-expect-error
            body: {
              email: userDetails.email,
              action: "enable",
            },
          });
          closeEllipsisLoader();
          notify("success", "2FA enabled successfully");
          _next();
        } else {
          notify("error", res.message);
          closeEllipsisLoader();
        }
      } catch (err) {
        notifyApiError(err);
        closeEllipsisLoader();
      }
    }
  }

  const downloadBackupCodes = () => {
    const element = document.createElement("a");
    const file = new Blob([backupCodes.join("\n")], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "backup_codes.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      {isLoading ? <FullscreenLoader /> : null}
      <OnboardingBackButton onClick={() => _prev()} />

      <div className="inline-flex h-[auto] w-[90%] flex-col items-center justify-start lg:w-[730px]">
        <UserProgress stages={3} progress={3} />

        <div className="flex flex-col items-center justify-start gap-1">
          <Header4Medium className="mt-[35px] text-center">
            {view !== "2fa-codes"
              ? "Enable Two Factor Authentication"
              : "Two Factor Authentication Enabled!"}
          </Header4Medium>
        </div>
        {view === "2fa" && (
          <>
            <div className="flex flex-col justify-start gap-2">
              <TitleRegular>
                1. Install an authenticator app like Authy or Google
                Authenticator to use 2FA
              </TitleRegular>
              <TitleRegular>
                2. Scan the QR code or use the authentication key to generate a
                code from your authenticator app
              </TitleRegular>

              <div className="mb-[50px] mt-[30px] flex h-[300px] w-[100%] flex-col items-center justify-center lg:gap-2">
                <img
                  alt={"QR Code"}
                  className="mb-[30px] h-[250px] w-[250px]"
                  src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                    otpUrl,
                  )}&size=150x150`}
                />
                <div className="ml-[5px] flex items-center justify-start gap-4 rounded-2xl bg-sky-100 p-2">
                  <div className="font-body text-sm font-bold leading-normal tracking-wide text-[#0094FF] opacity-80">
                    {mfaData?.secretCode?.slice(0, 10)}****{" "}
                    {mfaData?.secretCode?.slice(40)}
                  </div>
                  <CopyToClipboard
                    text={`${mfaData?.secretCode}`}
                    onCopy={() => notify("success", "MFA Secret Key copied")}
                  >
                    <span className="cursor-pointer">
                      <CopyIcon h="16" w="16" color="#0094FF" />
                    </span>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <DefaultButton
              label="Next"
              onClick={() => _next()}
              className="w-[390px]"
            />
            <DefaultButtonOutline
              label="Skip"
              onClick={() => navigate({ to: "/dashboard" })}
              className="mt-4 w-[390px]"
            />
          </>
        )}
        {view === "otp" && (
          <>
            <div className="flex flex-col items-center justify-center gap-2">
              <TitleRegular className="my-[20px]">
                Enter 6 digit code from your auth app
              </TitleRegular>
              <OTPInput value={otp} setValue={setOtp} />
              <div className="mb-[40px] mt-[50px] inline-flex h-5 w-[118px] items-center gap-0.5">
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <div
                  className="font-body text-xs font-bold leading-tight hover:text-blue-500"
                  onClick={() => _prev()}
                >
                  Didn’t get a code?
                </div>
                <div className="flex h-3 w-3 items-center justify-center p-[0.38px]">
                  <div className="relative h-[11.25px] w-[11.25px]" />
                </div>
              </div>
            </div>
            <DefaultButton
              label="Next"
              disabled={otp.length !== 6}
              onClick={() => {
                verifyTOTP();
              }}
              className="w-[390px]"
            />
          </>
        )}
        {view === "2fa-codes" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TitleRegular className="my-[10px] text-center">
              Download and store these backup codes somewhere safe. If you
              cannot access to your authentication device, you can use any of
              these codes to login to your account
            </TitleRegular>
            <div className="mb-[60px] mt-[50px] inline-flex h-64 w-[324px] justify-between rounded-2xl bg-emerald-200 bg-opacity-30 px-6 py-4">
              <div className="inline-flex flex-col items-start justify-center gap-4">
                {backupCodes.slice(0, 5).map((code, index) => (
                  <div
                    key={index + 1}
                    className="text-base font-bold leading-normal text-sky-800"
                  >
                    {code}
                  </div>
                ))}
              </div>
              <div className="inline-flex flex-col items-start justify-center gap-4">
                {backupCodes.slice(5).map((code, index) => (
                  <div
                    key={index + 1}
                    className="text-base font-bold leading-normal text-sky-800"
                  >
                    {code}
                  </div>
                ))}
              </div>
            </div>
            <DefaultButton
              textClassName="text-gray-600"
              className="mb-[30px] w-[390px]"
              icon={<PlusIcon color="white" size="16" />}
              label="Download backup codes"
              onClick={() => {
                downloadBackupCodes();
              }}
            />
            <DefaultButtonOutline
              textClassName="text-gray-600"
              className="mb-[40px] w-[390px]"
              label="Launch CentryOS"
              icon={<ArrowRight size="16" />}
              onClick={() => navigate({ to: "/dashboard" })}
            />
          </div>
        )}

        {/* <DefaultButton label="Next" onClick={() => showModal(true)} className="w-[390px]" /> */}
      </div>
    </>
  );
}
