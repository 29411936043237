import { Menu, Transition } from "@headlessui/react";
import { Checkbox, capitalize } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { isEmpty, toLower, toUpper } from "ramda";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
  EmptyStateTitle,
} from "~/@/components/custom/empty-state";
import { DialogRemoteTrigger } from "~/@/components/ui/dialog";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { approveAdminRequest, getAdminActivities } from "~/api/admin";
import { useGetWalletTransactionHistories } from "~/api/codegen/liquidityComponents";
import { useGetAssociatedUserAccountsBalances } from "~/api/codegen/walletosComponents";
import { empty_state_default } from "~/assets";
import { CaretDown } from "~/assets/icons/CaretDown";
import { CaretDownFilled } from "~/assets/icons/CaretDownFilled";
import { CaretRight } from "~/assets/icons/CaretRight";
import { CheckCircleIcon } from "~/assets/icons/CheckCircleIcon";
import { CloseIcon } from "~/assets/icons/CloseIcon";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { EyeCloseIcon } from "~/assets/icons/EyeCloseIcon";
import { EyeOpenIcon } from "~/assets/icons/EyeOpenIcon";
import { InfoIcon } from "~/assets/icons/InfoIcon";
import { MinusIconCircle } from "~/assets/icons/MinusIconCircle";
import { PayIcon } from "~/assets/icons/PayIcon";
import { Badge } from "~/components/atoms/Badge";
import { mockValues } from "~/constants";
import { currencies } from "~/constants/currencies";
import {
  TitleHeading,
  TitleRoot,
} from "~/containers/Dashboard/Pages/Settings/Title";
import { useSharedCurrency } from "~/contexts/currency";
import {
  useAllBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import { displayMockUI } from "~/libs";
import CurrencyFormatter from "~/libs/CurrencyFormatter";
import { getDateFormat } from "~/libs/date.helpers";
import { BalanceMap } from "~/libs/factories/balance-map";
import { PaginationFactory } from "~/libs/factories/pagination";
import { TrxFactory } from "~/libs/factories/transaction";
import { CentralWalletUser, userBadgeStatus } from "~/libs/factories/user";
import styles from "../../general/styles.module.css";
import { NumberBadge, StatusBadge } from "../Badges";
import { DefaultButtonOutline } from "../Buttons";
import { DefaultInputField } from "../Inputs";
import { WalletCurrencyDropdown } from "../Menu";
import { DefaultDialog } from "../Modal/Dialog";
import { useGeneralState } from "../Redux/state/general/hooks";
import { useUserData } from "../Redux/state/user/hooks";
import { DefaultTabs, TabButton } from "../Tabs";
import ShowToast, { notifyApiError } from "../Toast";
import { WalletTransactionModal } from "../TransactionDetails";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardRoot,
  CardTitle,
} from "../layouts/Card";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "../molecules/empty-state";
import { TablePagination } from "../organisms/table/table-pagination";
import { TableSkeleton } from "../organisms/table/table-skeleton";
import { SectionHeading } from "../atoms/heading";
import {
  nameColumn,
  userIdColumn,
  walletBalanceColumn,
  vaultBalanceColumn,
  totalBalanceColumn,
  createdAtColumn,
  statusColumn,
} from "~/components/table/users";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import { AppTable } from "../table/table";
import { Divider } from "../Divider";
import useSearch from "~/hooks";

export function HistoricBalance() {
  const [currency, setCurrency] = useState(currencies[0]);
  const [hideBalance, changeBalanceVisibility] = useState(false);
  const balance = displayMockUI() ? mockValues.revenueBalance : 0;

  return (
    <>
      <div className="mt-[10px] inline-flex w-full flex-col items-start justify-start gap-4 rounded-lg bg-white p-[20px] shadow">
        <div className="flex w-full items-center justify-between">
          <span className="text-lg font-bold leading-7 text-gray-800">
            Historic Balance
          </span>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="">
              <div className="flex items-center justify-center gap-2 rounded-[16px] border border-[#DEE5E7] px-3 py-1.5">
                <span className="flex items-center text-[12px] font-bold leading-tight text-gray-600">
                  All Time
                </span>
                <CaretDownFilled />
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="flex max-h-[300px] flex-col py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => {}}
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        className={`cursor-pointer px-[5px] font-normal ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        Last week
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type={"button"}
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        className={`cursor-pointer px-[5px] font-normal ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        Last month
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <WalletCurrencyDropdown
              currency={currency}
              setCurrency={setCurrency}
            />
            <span className="text-base font-normal leading-normal text-slate-500">
              {currency.name}
            </span>
          </div>
          <div className="">
            <span className="flex items-center">
              <span className="text-[60px] leading-[72px] text-cyan-950">
                {hideBalance
                  ? "****"
                  : `${currency?.symbol}${CurrencyFormatter.formatCurrency(
                      balance,
                    )}`}
              </span>
              <button
                type={"button"}
                className="ml-[15px] cursor-pointer"
                onClick={() => changeBalanceVisibility(!hideBalance)}
              >
                {!hideBalance ? (
                  <EyeCloseIcon size="24" color="#CCD1D6" />
                ) : (
                  <EyeOpenIcon size="24" color="#CCD1D6" />
                )}
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

const refetchInterval = 30 * 60 * 1000;

export function UserTable({ showSearchBar }: { showSearchBar?: boolean }) {
  const user_columns = useMemo(
    () => [
      nameColumn,
      userIdColumn,
      createdAtColumn,
      walletBalanceColumn,
      vaultBalanceColumn,
      totalBalanceColumn,
      statusColumn,
    ],
    [],
  );

  const tabs = [
    { label: "All", value: "all", isActive: true },
    { label: "Active", value: "active", isActive: true },
    { label: "Inactive", value: "inactive", isActive: true },
    { label: "Disabled", value: "disabled", isActive: true },
    { label: "Blocked", value: "blocked", isActive: true },
  ];

  const navigate = useNavigate();
  const businessId = useGetBusinessId();
  const [page, setPage] = useState<number>(1);
  const [currentStatus, setCurrentStatus] = useState(() => tabs[0]);
  const [currentTab, setCurrentTab] = useState("all");

  const { data: res, isLoading } =
    useGetAssociatedUserAccountsBalances<unknown>(
      {
        pathParams: { businessId },
        queryParams: {
          status: toUpper(safeStr(currentTab)),
          page: page,
          limit: 10,
        },
      },
      {
        refetchInterval,
      },
    );

  const data = PaginationFactory(res);
  const logs = data.data.map((x) => {
    return CentralWalletUser({
      // @ts-expect-error
      id: x?.id,
      // @ts-expect-error
      status: x?.status,
      // @ts-expect-error
      external_id: x?.externalId,
      // @ts-expect-error
      name: x?.name,
      // @ts-expect-error
      created_at: x?.createdAt,
      // @ts-expect-error
      last_active: x?.lastActive,
      // @ts-expect-error
      email: x?.email,
      // @ts-expect-error
      total_balance: x?.totalBalance,
      // @ts-expect-error
      vault_balance: x?.totalVaultBalance,
      // @ts-expect-error
      sub_wallet_balance: x?.totalWalletBalance,
    });
  });
  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(logs, "username", "email");

  return (
    <>
      <SectionHeading>
        <span className="py-3 text-gray-500">Users Accounts</span>
        <NumberBadge label="pending" count={logs.length ?? 0} />
      </SectionHeading>
      <Tabs defaultValue={"all"} className="mb-5">
        <CardRoot>
          <div
            className={
              "no-scrollbar flex w-full flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
            }
          >
            <TabsList className={"gap-x-5 bg-transparent"}>
              {tabs.map((e) => (
                <TabsTrigger
                  key={e.label}
                  value={e.value}
                  disabled={!e.isActive}
                  onClick={() => setCurrentTab(e.value)}
                  asChild
                >
                  <TabButton>{e.label}</TabButton>
                </TabsTrigger>
              ))}
            </TabsList>
            <div>
              <DefaultInputField
                placeholder="Search"
                value={searchTerm}
                setValue={handleSearchChange}
                disabled={logs.length === 0}
              />
            </div>
          </div>

          <Divider className="my-[2px]" />

          <EmptyStateOverlay
            isEmpty={logs.length === 0 && !isLoading}
            content={
              <EmptyStateDescription>
                Users will appear here
              </EmptyStateDescription>
            }
          >
            <div className="no-scrollbar overflow-auto">
              <TabsContent value={"all"}>
                <AppTable
                  data={filteredList ?? []}
                  isLoading={isLoading}
                  columns={user_columns}
                  onRowClick={(row) => {
                    navigate({
                      to: `/dashboard/wallet/users/${logs[row.index].username}`,
                      search: {
                        user_id: logs[row.index].id,
                      },
                    });
                  }}
                />
              </TabsContent>
              <TabsContent value={"active"}>
                <AppTable
                  data={filteredList ?? []}
                  isLoading={isLoading}
                  columns={user_columns}
                  onRowClick={(row) => {
                    navigate({
                      to: `/dashboard/wallet/users/${logs[row.index].username}`,
                      search: {
                        user_id: logs[row.index].id,
                      },
                    });
                  }}
                />
              </TabsContent>
              <TabsContent value={"inactive"}>
                <AppTable
                  data={filteredList ?? []}
                  isLoading={isLoading}
                  columns={user_columns}
                  onRowClick={(row) => {
                    navigate({
                      to: `/dashboard/wallet/users/${logs[row.index].username}`,
                      search: {
                        user_id: logs[row.index].id,
                      },
                    });
                  }}
                />
              </TabsContent>
              <TabsContent value={"disabled"}>
                <AppTable
                  data={filteredList ?? []}
                  isLoading={isLoading}
                  columns={user_columns}
                  onRowClick={(row) => {
                    navigate({
                      to: `/dashboard/wallet/users/${logs[row.index].username}`,
                      search: {
                        user_id: logs[row.index].id,
                      },
                    });
                  }}
                />
              </TabsContent>
              <TabsContent value={"blocked"}>
                <AppTable
                  data={filteredList ?? []}
                  isLoading={isLoading}
                  columns={user_columns}
                  onRowClick={(row) => {
                    navigate({
                      to: `/dashboard/wallet/users/${logs[row.index].username}`,
                      search: {
                        user_id: logs[row.index].id,
                      },
                    });
                  }}
                />
              </TabsContent>
            </div>
          </EmptyStateOverlay>

          {data.meta.total !== 0 && (
            <CardFooter className="py-3">
              <TablePagination
                data={{
                  page: data.meta.page,
                  pageCount: data.meta.pageCount,
                  pageSize: data.meta.pageSize,
                  total: data.meta.total,
                  setPage: setPage,
                }}
              />
            </CardFooter>
          )}

          <WalletTransactionModal />
        </CardRoot>
      </Tabs>
    </>
  );
}

export function VaultTable({ showSearchBar }: { showSearchBar?: boolean }) {
  const user_columns = useMemo(
    () => [
      nameColumn,
      userIdColumn,
      walletBalanceColumn,
      vaultBalanceColumn,
      totalBalanceColumn,
      createdAtColumn,
      statusColumn,
    ],
    [],
  );

  const tabs = [
    { label: "All", value: "all", isActive: true },
    { label: "Active", value: "active", isActive: false },
    { label: "Suspended", value: "suspended", isActive: false },
    { label: "Blocked", value: "blocked", isActive: false },
    { label: "Whatchlist", value: "whatchlist", isActive: false },
    { label: "Banned", value: "banned", isActive: false },
  ];

  const navigate = useNavigate();
  const businessId = useGetBusinessId();
  const [page, setPage] = useState<number>(1);
  const [currentStatus, setCurrentStatus] = useState(() => tabs[0]);
  const [currentTab, setCurrentTab] = useState(undefined);

  const { data: res, isLoading } =
    useGetAssociatedUserAccountsBalances<unknown>(
      {
        pathParams: { businessId },
        queryParams: {
          status: toUpper(safeStr(currentStatus.label)),
          page: page,
          limit: 10,
        },
      },
      {
        refetchInterval,
      },
    );

  const data = PaginationFactory(res);
  const logs = data.data.map((x) => {
    return CentralWalletUser({
      // @ts-expect-error
      id: x?.id,
      // @ts-expect-error
      status: x?.status,
      // @ts-expect-error
      external_id: x?.externalId,
      // @ts-expect-error
      name: x?.name,
      // @ts-expect-error
      created_at: x?.createdAt,
      // @ts-expect-error
      last_active: x?.lastActive,
      // @ts-expect-error
      email: x?.email,
      // @ts-expect-error
      total_balance: x?.totalBalance,
      // @ts-expect-error
      vault_balance: x?.totalVaultBalance,
      // @ts-expect-error
      sub_wallet_balance: x?.totalWalletBalance,
    });
  });
  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(logs, "username", "email");

  return (
    <>
      <SectionHeading>
        <span className="py-3 text-gray-500">Users Accounts</span>
        <NumberBadge label="pending" count={logs.length ?? 0} />
      </SectionHeading>
      <Tabs defaultValue={"all"} className="">
        <CardRoot>
          <div
            className={
              "no-scrollbar flex w-full flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
            }
          >
            <TabsList className={"gap-x-5 bg-transparent"}>
              {tabs.map((e) => (
                <TabsTrigger
                  key={e.label}
                  value={e.value}
                  disabled={!e.isActive}
                  onClick={() => setCurrentTab(e.value)}
                  asChild
                >
                  <TabButton>{e.label}</TabButton>
                </TabsTrigger>
              ))}
            </TabsList>
            <div>
              <DefaultInputField
                placeholder="Search"
                value={searchTerm}
                setValue={handleSearchChange}
                disabled={logs.length === 0}
              />
            </div>
          </div>

          <Divider className="my-[2px]" />
          <EmptyStateOverlay
            isEmpty={logs.length === 0 && !isLoading}
            content={
              <EmptyStateDescription>
                Users will appear here
              </EmptyStateDescription>
            }
          >
            <div className="no-scrollbar overflow-auto">
              <TabsContent value={"all"}>
                <AppTable
                  data={filteredList ?? []}
                  isLoading={isLoading}
                  columns={user_columns}
                  onRowClick={(row) => {
                    navigate({
                      to: `/dashboard/wallet/users/${logs[row.index].username}`,
                      search: {
                        user_id: logs[row.index].id,
                      },
                    });
                  }}
                />
              </TabsContent>
            </div>
          </EmptyStateOverlay>

          {data.meta.total !== 0 && (
            <CardFooter className="py-3">
              <TablePagination
                data={{
                  page: data.meta.page,
                  pageCount: data.meta.pageCount,
                  pageSize: data.meta.pageSize,
                  total: data.meta.total,
                  setPage: setPage,
                }}
              />
            </CardFooter>
          )}

          <WalletTransactionModal />
        </CardRoot>
      </Tabs>
    </>
  );
}

type TxFlowType =
  | "FUNDING"
  | "WITHDRAWAL"
  | "PAYOUT"
  | "BUSINESS_FUNDING_FEE_COLLECTION"
  | "PLATFORM_FUNDING_FEE_COLLECTION"
  | "PAYMENT";

export function UserWalletLog(props: { walletId?: string }) {
  const { walletId } = props;
  const tabs = React.useMemo(
    (): { label: string; value: TxFlowType | "" }[] => [
      { label: "All", value: "" },
      { label: "Funding", value: "FUNDING" },
      { label: "Payout", value: "PAYOUT" },
      { label: "Withdrawal", value: "WITHDRAWAL" },
      { label: "Payments", value: "PAYMENT" },
    ],
    [],
  );
  const [currentTab, setCurrentTab] = React.useState(() => tabs[0]);

  const filteredTab = React.useMemo(() => {
    let tab = "";

    if (currentTab.value === "") {
      tab = "";
    }

    if (currentTab.value === "FUNDING") {
      tab = "FUNDING";
    }
    if (currentTab.value === "PAYMENT") {
      tab = "PAYMENT";
    }
    if (currentTab.value === "WITHDRAWAL") {
      tab = "WITHDRAWAL";
    }

    return tab;
  }, [currentTab?.value]);

  const businessId = useGetBusinessId();

  const res = useGetWalletTransactionHistories(
    {
      pathParams: { businessId, walletId },
      queryParams: {
        limit: 10,
        page: 1, // TODO: Set page value
        ...(currentTab.value
          ? { flowType: currentTab.value }
          : safeObj(undefined)),
      },
    },
    {
      enabled: !isEmpty(walletId),
    },
  );

  const { isLoading, data, refetch } = useAllBusinessTransactions(
    {
      businessId,
      page: 1,
    },
    {
      enabled: !!businessId,
    },
  );

  // console.log(res?.data);
  // console.log("walletId", walletId);

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(data.data, "flow_type", "method");

  const pagination = PaginationFactory(res?.data);

  const logs = React.useMemo(
    () => pagination.data.map(TrxFactory),
    [pagination.data],
  );

  const menuTabs = [
    { label: "Transactions", value: "transactions", isActive: true },
    { label: "Admin Activity", value: "admin", isActive: false },
  ];

  return (
    <>
      <Tabs defaultValue={"transactions"} className="">
        <div className="flex items-center justify-between">
          <SectionHeading className="pb-5 pl-1">
            <span className="py-1 text-gray-500">History</span>
            <NumberBadge label="pending" count={logs.length ?? 0} />
          </SectionHeading>

          <TabsList className={"-mt-5 gap-x-5 bg-transparent"}>
            {menuTabs.map((e) => (
              <TabsTrigger
                key={e.label}
                value={e.value}
                disabled={!e.isActive}
                asChild
                // onClick={() => setCurrentTab(e.value)}
              >
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>
        </div>

        <TabsContent value={"transactions"}>
          <CardRoot>
            <CardHeader className="flex flex-col gap-2">
              <div
                className={
                  "no-scrollbar flex w-full flex-col items-start justify-between gap-4 overflow-x-auto md:flex-row"
                }
              >
                <DefaultTabs
                  tabs={tabs}
                  activeTab={currentTab}
                  setActiveTab={(next_tab) => setCurrentTab(next_tab)}
                />

                {/* <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
                  <div>
                    <DefaultInputField
                      placeholder="Search"
                      value={searchTerm}
                      setValue={handleSearchChange}
                      disabled={data.data.length === 0}
                    />
                  </div>
                </div> */}
              </div>
            </CardHeader>

            <CardBody className="px-0 py-0 pt-4">
              <EmptyStateRoot isEmpty={!logs.length}>
                <EmptyStateContent>
                  <img
                    alt="A confused emoji with an empty sticky note"
                    src={empty_state_default}
                    className="aspect-square w-[280px] object-contain"
                  />
                  <div className="mb-10 flex  flex-col">
                    <EmptyStateTitle>No transactions</EmptyStateTitle>
                    <EmptyStateDescription>
                      User transaction log will display here
                    </EmptyStateDescription>
                  </div>
                </EmptyStateContent>

                <EmptyStateConceal>
                  <table className="w-full">
                    <thead className="h-[56px] min-h-[56px] bg-[#F2F6F8]">
                      <tr>
                        <th className="w-[44px] ">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="h-full"
                          >
                            <div className="h-2 w-2 rounded-lg bg-[#3BB75E]" />
                          </div>
                        </th>

                        <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                          Date
                        </th>

                        <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                          Transaction Type
                        </th>

                        <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                          From
                        </th>

                        <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                          To
                        </th>

                        <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                          Amount
                        </th>
                        <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                          Status
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {logs.map((log) => (
                        <DialogRemoteTrigger
                          key={log.externalTransactionId}
                          modalId={"transaction_details"}
                          params={log}
                        >
                          <tr
                            key={log.externalTransactionId}
                            className={
                              "h-[63px] cursor-pointer border-b-[.5px] border-zinc-100 hover:bg-zinc-100"
                            }
                          >
                            <td>
                              <div className="flex h-full w-full items-center justify-center">
                                <div
                                  className={"h-2 w-2 rounded-lg bg-[#3BB75E]"}
                                />
                              </div>
                            </td>

                            <td>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                                className="font-body font-bold text-gray-600"
                              >
                                {getDateFormat(log.created_at).dateFormat}{" "}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="font-body  font-normal text-gray-400"
                                >
                                  {getDateFormat(log.created_at).timeFormat}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                                className="font-body font-bold text-gray-600"
                              >
                                {log.flow_type}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="font-body font-normal text-gray-400"
                                >
                                  {log.method_for_human}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                                className="font-body font-bold text-gray-600"
                              >
                                {log.source.main}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="font-body font-normal text-gray-400"
                                >
                                  {log.source.sub}
                                </span>
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                                className="font-body font-bold text-gray-600"
                              >
                                {log.destination.main}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="font-body font-normal text-gray-400"
                                >
                                  {log.destination.sub}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                                className="font-body font-bold text-gray-600"
                              >
                                {CurrencyFormatter.formatCurrency(
                                  log.amount.value,
                                )}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="font-body font-normal text-gray-400"
                                >
                                  {log.amount.currency}
                                </span>
                              </span>
                            </td>
                            <td>
                              <StatusBadge label={log.status} />
                            </td>
                          </tr>
                        </DialogRemoteTrigger>
                      ))}
                    </tbody>
                  </table>
                </EmptyStateConceal>
              </EmptyStateRoot>
            </CardBody>

            {logs.length > 0 ? (
              <CardFooter>
                <TablePagination data={pagination.meta} />
              </CardFooter>
            ) : null}
          </CardRoot>
          <WalletTransactionModal />
        </TabsContent>
      </Tabs>
    </>
  );
}

function EscrowStatus({ status }: { status: string }) {
  const getColors = () => {
    switch (status) {
      case "pending":
        return { bg: "#FFF9EB", text: "#FFAB00" };
      case "in-progress":
        return { bg: "#FFF9EB", text: "#FFAB00" };
      case "successful":
        return { bg: "#EDFDF0", text: "#3BB75E" };
    }
  };

  const isPending = status === "pending";

  const menuItems = [
    {
      label: "Freeze Escrow",
      icon: <MinusIconCircle />,
    },
    {
      label: "Close Escrow",
      icon: <CloseIcon />,
    },
    {
      label: "Payout Escrow",
      icon: <PayIcon />,
    },
  ];

  return (
    <>
      {isPending ? (
        <Menu as="div" className=" inline-block">
          <Menu.Button className="">
            <div
              style={{ backgroundColor: getColors().bg }}
              className="flex items-center justify-center gap-2 rounded-[16px] px-2 py-1"
            >
              <InfoIcon color={getColors().text} />
              <span className="text-center text-xs font-bold leading-tight text-amber-500">
                In progress
              </span>
              <CaretDown />
            </div>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-[70px] z-10 mt-2 w-[160px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col p-[10px] py-1">
                {menuItems.map((item, i) => (
                  <Menu.Item>
                    {({ active }) => (
                      <div className="flex items-center py-[10px]">
                        {item.icon}
                        <span className="ml-[5px] text-sm font-bold leading-tight text-stone-600">
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <Menu>
          <Menu.Button className="">
            <div
              style={{ backgroundColor: getColors().bg }}
              className="flex w-[auto] items-center gap-2 rounded-[16px] px-2 py-1"
            >
              <CheckCircleIcon />
              <span
                style={{ color: getColors().text }}
                className="ml-[5px] text-center text-xs font-bold leading-tight text-amber-500"
              >
                Successful
              </span>
            </div>
          </Menu.Button>
        </Menu>
      )}
    </>
  );
}

function PayoutStatus({ status }: { status: string }) {
  const getColors = () => {
    switch (status) {
      case "pending":
        return { bg: "#FFF9EB", text: "#FFAB00" };
      case "in-progress":
        return { bg: "#FFF9EB", text: "#FFAB00" };
      case "successful":
        return { bg: "#EDFDF0", text: "#3BB75E" };
      case "ready":
        return { bg: "#FFEBEB", text: "#F05050" };
    }
  };

  const isPending = status === "pending" ? true : false;

  const menuItems = [
    {
      label: "Close Escrow",
      icon: <CloseIcon />,
    },
    {
      label: "Payout Escrow",
      icon: <PayIcon />,
    },
  ];

  return (
    <>
      {status === "pending" && (
        <Menu as="div" className=" inline-block">
          <Menu.Button className="">
            <div className="flex items-center justify-center rounded-[16px] border-[1px] border-[#DEE5E7] px-2 py-1">
              <InfoIcon color={"#CCD1D6"} />
              <span className="ml-[4px] text-center text-xs font-bold leading-tight text-[grey]">
                Not Ready
              </span>
              {/* <CaretDown /> */}
            </div>
          </Menu.Button>
        </Menu>
      )}
      {status === "successful" && (
        <Menu>
          <Menu.Button className="">
            <div
              style={{ backgroundColor: getColors().bg }}
              className="ml-[4px] flex w-[auto] items-center rounded-[16px] px-2 py-1"
            >
              <CheckCircleIcon />
              <span
                style={{ color: getColors().text }}
                className="ml-[5px] text-center text-xs font-bold leading-tight text-amber-500"
              >
                Paid out
              </span>
            </div>
          </Menu.Button>
        </Menu>
      )}
      {status === "ready" && (
        <Menu as="div" className=" inline-block">
          <Menu.Button className="">
            <div
              style={{ backgroundColor: getColors().bg }}
              className="flex items-center justify-center rounded-[16px] px-2 py-1"
            >
              <span className="mr-[4px] text-center text-xs font-bold leading-tight text-[#F05050]">
                Ready
              </span>
              <CaretRight color="#002C3D" />
            </div>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-[70px] z-10 mt-2 w-[160px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col p-[10px] py-1">
                {menuItems.map((item, i) => (
                  <Menu.Item>
                    {({ active }) => (
                      <div className="flex items-center py-[10px]">
                        {item.icon}
                        <span className="ml-[5px] text-sm font-bold leading-tight text-stone-600">
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  );
}

export function EscrowWalletsTable({
  setWalletDetails,
}: {
  setWalletDetails: Dispatch<SetStateAction<unknown>>;
}) {
  const tabs = [
    { label: "All", isActive: true },
    { label: "Completed", isActive: true },
    { label: "Suspended", isActive: true },
    { label: "Closed", isActive: true },
  ];
  const [searchVal, setSearchVal] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const logs = displayMockUI()
    ? [
        {
          transactionType: "funding",
          transactionId: "GMR34fgse",
          revenueStream: "Stream 1",
          amount: 10000,
          date: new Date("2023-01-01"),
          approvedBy: "John Doe",
          initiatedBy: "Alice Smith",
          status: "successful",
          adminRole: "admin",
          method: "Bank Transfer",
          address: "xweirjwdfskdnfs",
        },
        {
          transactionType: "withdrawal",
          transactionId: "GMR34fgse",
          revenueStream: "Stream 1",
          amount: 50000,
          date: new Date("2023-02-15"),
          approvedBy: "Jane Doe",
          initiatedBy: "Bob Johnson",
          status: "successful",
          adminRole: "manager",
          method: "Bank Transfer",
          address: "xweirjwdfskdnfs",
        },
        // {
        //   transactionType: "transfer",
        //   transactionId: 'GMR34fgse',
        //   revenueStream: 'Stream 1',
        //   amount: 750000,
        //   date: new Date("2023-03-20"),
        //   approvedBy: "Eve Doe",
        //   initiatedBy: "Charlie Brown",
        //   status: "pending",
        //   adminRole: "support",
        //   method: 'Bank Transfer',
        //   address: "xweirjwdfskdnfs"

        // },
        // {
        //   transactionType: "funding",
        //   transactionId: 'GMR34fgse',
        //   revenueStream: 'Stream 1',
        //   amount: 1200000,
        //   date: new Date("2023-04-10"),
        //   approvedBy: "Samuel White",
        //   initiatedBy: "Emily Green",
        //   status: "pending",
        //   adminRole: "admin",
        //   method: 'Bank Transfer',
        //   address: "xweirjwdfskdnfs"

        // },
      ]
    : [];

  return (
    <>
      <div className=" mt-[20px] w-full rounded-lg bg-white shadow">
        <div className="mb-[0px] flex w-full items-center justify-between p-[10px]">
          <div className="flex h-[40px] items-center">
            <DefaultTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={(v) => setActiveTab(v)}
            />
          </div>
          <div className="flex items-center">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="">
                <div className="flex items-center justify-center gap-2 rounded px-2 py-2">
                  <span className="flex items-center text-sm font-bold leading-tight text-gray-600">
                    Select
                  </span>
                  <CaretDown />
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex max-h-[300px] flex-col py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => {
                            setSearchVal("");
                          }}
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          className={`cursor-pointer px-[5px] font-normal ${
                            active ? "bg-gray-200" : ""
                          }`}
                        >
                          option
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="">
                <div className="flex items-center justify-center gap-2 rounded px-2 py-2">
                  <span className="flex items-center text-sm font-bold leading-tight text-gray-600">
                    Filter
                  </span>
                  <CaretDown />
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex max-h-[300px] flex-col py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => {
                            setSearchVal("");
                          }}
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          className={`cursor-pointer px-[5px] font-normal ${
                            active ? "bg-gray-200" : ""
                          }`}
                        >
                          option
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <DefaultInputField
              placeholder="Search"
              value={searchVal}
              setValue={setSearchVal}
              containerStyle={{ width: "160px", height: "36px" }}
            />
          </div>
        </div>
        <div className={`${styles.custom_scrollbar} w-full overflow-x-scroll`}>
          <table className="w-full min-w-[1000px]">
            <thead className="h-[56px] min-h-[56px] bg-[#F2F6F8]">
              <tr>
                <th className="w-[44px] ">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="h-full"
                  >
                    <div className="h-2 w-2 rounded-lg bg-[#3BB75E]" />
                  </div>
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Escrow Name
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Date
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Escrow Address
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Escrow Balance
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Escrow Status
                </th>
                <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                  Payout Status
                </th>
              </tr>
            </thead>
            <tbody className="">
              {logs.map((log, i) => (
                // biome-ignore lint/a11y/useKeyWithClickEvents: REWRITE
                <tr
                  key={log.transactionId}
                  onClick={() => setWalletDetails(log)}
                  className={
                    "h-[63px] cursor-pointer border-b-[.5px] border-zinc-100 hover:bg-zinc-100"
                  }
                >
                  <td>
                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: REWRITE */}
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="flex h-full w-full items-center justify-center"
                    >
                      <Checkbox size="small" />
                    </div>
                  </td>
                  <td>
                    <span
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                      className="font-body font-bold text-gray-600"
                    >
                      {log.initiatedBy}
                      <br />
                      <span
                        style={{ fontSize: "12px", fontWeight: "normal" }}
                        className="font-body font-normal text-gray-400"
                      >
                        {log.method}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                      className="font-body font-bold text-gray-600"
                    >
                      {getDateFormat(log.date).dateFormat} <br />
                      <span
                        style={{ fontSize: "12px", fontWeight: "normal" }}
                        className="font-body  font-normal text-gray-400"
                      >
                        {getDateFormat(log.date).timeFormat}
                      </span>
                    </span>
                  </td>
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: REWRITE */}
                  <td onClick={(e) => e.stopPropagation()}>
                    <div title="Copy address" className="flex items-center">
                      <span
                        style={{ fontSize: "14px", fontWeight: "bolder" }}
                        className="mr-[3px] font-body font-bold text-gray-600"
                      >
                        {log.address}
                      </span>
                      <CopyIcon h="12" w="12" color="#909090" />
                    </div>
                  </td>
                  <td>
                    <span
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                      className="font-body font-bold text-gray-600"
                    >
                      {CurrencyFormatter.formatCurrency(log.amount)}
                      <br />
                      <span
                        style={{ fontSize: "12px", fontWeight: "normal" }}
                        className="font-body font-normal text-gray-400"
                      >
                        USD
                      </span>
                    </span>
                  </td>
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                  <td onClick={(e) => e.stopPropagation()}>
                    <EscrowStatus status={log.status} />
                  </td>
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                  <td onClick={(e) => e.stopPropagation()}>
                    <PayoutStatus status={"ready"} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex w-full items-center justify-between px-[20px] py-[10px]">
          <span className="font-body text-gray-600">1 - 50</span>
          <DefaultButtonOutline
            onClick={() => {}}
            label="Load more"
            className="h-[34px] w-[106px]"
          />
          <span className="font-body text-gray-600">{logs.length} records</span>
        </div>
      </div>
    </>
  );
}

export function EscrowWalletLog() {
  const tabs = [
    { label: "Transactions", isActive: true },
    { label: "Admin Activity", isActive: true },
  ];
  const [searchVal, setSearchVal] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const logs = displayMockUI()
    ? [
        {
          transactionType: "funding",
          transactionId: "GMR34fgse",
          revenueStream: "Stream 1",
          amount: 10000,
          date: new Date("2023-01-01"),
          approvedBy: "John Doe",
          initiatedBy: "Alice Smith",
          status: "active",
          adminRole: "admin",
          method: "Bank Transfer",
          address: "tldbduuysdvljnsdvs",
        },
        {
          transactionType: "withdrawal",
          transactionId: "GMR34fgse",
          revenueStream: "Stream 1",
          amount: 50000,
          date: new Date("2023-02-15"),
          approvedBy: "Jane Doe",
          initiatedBy: "Bob Johnson",
          status: "active",
          adminRole: "manager",
          method: "Bank Transfer",
          address: "tldbduuysdvljnsdvs",
        },
        // {
        //   transactionType: "funding",
        //   transactionId: 'GMR34fgse',
        //   revenueStream: 'Stream 1',
        //   amount: 1200000,
        //   date: new Date("2023-04-10"),
        //   approvedBy: "Samuel White",
        //   initiatedBy: "Emily Green",
        //   status: "active",
        //   adminRole: "admin",
        //   method: 'Bank Transfer',
        //   address: 'tldbduuysdvljnsdvs'
        // },
      ]
    : [];

  return (
    <>
      <div className=" mt-[20px] w-full rounded-lg bg-white shadow">
        <div className="mb-[0px] flex w-full items-center justify-between p-[10px]">
          <div className="flex h-[40px] items-center">
            <DefaultTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={(v) => setActiveTab(v)}
            />
          </div>

          <div className="flex items-center">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="">
                <div className="flex items-center justify-center gap-2 rounded px-2 py-2">
                  <span className="flex items-center text-sm font-bold leading-tight text-gray-600">
                    Select
                  </span>
                  <CaretDown />
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex max-h-[300px] flex-col py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => {
                            setSearchVal("");
                          }}
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          className={`cursor-pointer px-[5px] font-normal ${
                            active ? "bg-gray-200" : ""
                          }`}
                        >
                          option
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="">
                <div className="flex items-center justify-center gap-2 rounded px-2 py-2">
                  <span className="flex items-center text-sm font-bold leading-tight text-gray-600">
                    Filter
                  </span>
                  <CaretDown />
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex max-h-[300px] flex-col py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => {
                            setSearchVal("");
                          }}
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          className={`cursor-pointer px-[5px] font-normal ${
                            active ? "bg-gray-200" : ""
                          }`}
                        >
                          option
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <DefaultInputField
              placeholder="Search"
              value={searchVal}
              setValue={setSearchVal}
              containerStyle={{ width: "160px", height: "36px" }}
            />
          </div>
        </div>
        <div className={`${styles.custom_scrollbar} w-full overflow-x-scroll`}>
          {activeTab.label === "Transactions" && (
            <table className="w-full min-w-[1000px]">
              <thead className="h-[56px] min-h-[56px] bg-[#F2F6F8]">
                <tr>
                  <th className="w-[44px] ">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="h-full"
                    >
                      <div className="h-2 w-2 rounded-lg bg-[#3BB75E]" />
                    </div>
                  </th>
                  <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                    Date
                  </th>
                  <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                    From
                  </th>
                  <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                    Wallet Address
                  </th>
                  <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                    Amount
                  </th>
                  <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {logs.map((log) => (
                  <tr
                    key={log.transactionId}
                    className={
                      "h-[63px] cursor-pointer border-b-[.5px] border-zinc-100 hover:bg-zinc-100"
                    }
                  >
                    <td>
                      <div className="flex h-full w-full items-center justify-center">
                        <div
                          style={{ backgroundColor: "#3BB75E" }}
                          className={"h-2 w-2 rounded-lg"}
                        />
                      </div>
                    </td>
                    <td>
                      <span
                        style={{ fontSize: "14px", fontWeight: "bolder" }}
                        className="font-body font-bold text-gray-600"
                      >
                        {getDateFormat(log.date).dateFormat} <br />
                        <span
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                          className="font-body  font-normal text-gray-400"
                        >
                          {getDateFormat(log.date).timeFormat}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span
                        style={{ fontSize: "14px", fontWeight: "bolder" }}
                        className="font-body font-bold text-gray-600"
                      >
                        {log.initiatedBy}
                        <br />
                        <span
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                          className="font-body font-normal text-gray-400"
                        >
                          {log.method}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span title="Copy Address" className="flex items-center">
                        <span
                          style={{ fontSize: "14px", fontWeight: "bolder" }}
                          className="mr-[6px] font-body font-bold text-gray-600"
                        >
                          {log.address}
                        </span>
                        <CopyIcon h="12" w="12" color="#909090" />
                      </span>
                    </td>
                    <td>
                      <span
                        style={{ fontSize: "14px", fontWeight: "bolder" }}
                        className="font-body font-bold text-gray-600"
                      >
                        {CurrencyFormatter.formatCurrency(log.amount)}
                        <br />
                        <span
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                          className="font-body font-normal text-gray-400"
                        >
                          USD
                        </span>
                      </span>
                    </td>
                    <td>
                      <StatusBadge label={log.status} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {activeTab.label === "Admin Activity" && <AdminActivity />}
        </div>
        <div className="flex w-full items-center justify-between px-[20px] py-[10px]">
          <span className="font-body text-gray-600">1 - 50</span>
          <DefaultButtonOutline
            onClick={() => {}}
            label="Load more"
            className="h-[34px] w-[106px]"
          />
          <span className="font-body text-gray-600">{logs.length} records</span>
        </div>
      </div>
    </>
  );
}

function AdminApprovalStatus({
  status,
  showDenyDialog,
  showApprovalDialog,
  onSelect,
}: {
  onSelect: () => void;
  status: string;
  showDenyDialog: Dispatch<SetStateAction<boolean>>;
  showApprovalDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { businessDetails } = useUserData();
  const getColors = () => {
    switch (status) {
      case "pending":
        return { bg: "#FFF9EB", text: "#FFAB00" };
      case "in-progress":
        return { bg: "#FFF9EB", text: "#FFAB00" };
      case "successful":
        return { bg: "#EDFDF0", text: "#3BB75E" };
      case "success":
        return { bg: "#EDFDF0", text: "#3BB75E" };
      case "denied":
        return { bg: "#FFEBEB", text: "#F05050" };
    }
  };

  const isPending = status === "pending";

  const menuItems = [
    {
      label: "Approve",
      icon: <CheckCircleIcon />,
      onClick: () => {
        onSelect();
        showApprovalDialog(true);
      },
    },
    {
      label: "Deny",
      icon: <CloseIcon />,
      onClick: () => {
        onSelect();
        showDenyDialog(true);
      },
    },
  ];

  return (
    <>
      {status === "pending" && (
        <Menu as="div" className="relative inline-block">
          <Menu.Button className="">
            <StatusBadge
              label="pending"
              leftIcon={<InfoIcon color={getColors().text} />}
              rightIcon={<CaretRight color="black" />}
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 mt-2 w-[160px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col">
                {menuItems.map((item, i) => (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type={"button"}
                        onClick={() => item.onClick()}
                        className={
                          "flex items-center p-[10px] hover:bg-[#F2F6F8]"
                        }
                      >
                        {item.icon}
                        <span className="ml-[5px] text-sm font-bold leading-tight text-stone-600">
                          {item.label}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      {status === "successful" && (
        <Menu>
          <Menu.Button className="">
            <StatusBadge label="successful" leftIcon={<CheckCircleIcon />} />
          </Menu.Button>
        </Menu>
      )}

      {status === "denied" && (
        <Menu>
          <Menu.Button className="">
            <StatusBadge label="denied" leftIcon={<CloseIcon size="16" />} />
          </Menu.Button>
        </Menu>
      )}
    </>
  );
}

export function AdminActivity() {
  const { adminActivities, setAdminActivities } = useGeneralState();
  const [loading, showLoading] = useState(false);
  const [approvalDialog, showApprovalDialog] = useState(false);
  const [denyDialog, showDenyDialog] = useState(false);
  const { businessDetails } = useUserData();
  const [selected, setSelected] = useState(null);

  async function approve() {
    try {
      const res = await approveAdminRequest(
        businessDetails?.id,
        selected?.id,
        "approve",
      );
      if (res.status === 200) {
        ShowToast(res.message, "success");
        setAdminActivities(res.activities);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      showApprovalDialog(false);
    }
  }

  async function deny() {
    try {
      const res = await approveAdminRequest(
        businessDetails?.id,
        selected?.id,
        "deny",
      );
      if (res.status === 200) {
        ShowToast(res.message, "success");
        setAdminActivities(res.activities);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      showDenyDialog(false);
    }
  }

  async function getTransactions() {
    try {
      showLoading(true);
      const res = await getAdminActivities(businessDetails?.id);
      if (res.status === 200) {
        setAdminActivities(res.activities);
      }
    } catch (err) {
    } finally {
      showLoading(false);
    }
  }

  useEffect(() => {
    if (displayMockUI) {
      getTransactions();
    }
  }, []);

  return (
    <>
      <table className="w-full min-w-[1000px]">
        <thead className="h-[56px] min-h-[56px] bg-[#F2F6F8]">
          <tr>
            <th className="w-[44px] ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="h-full"
              >
                <div className="h-2 w-2 rounded-lg bg-[#3BB75E]" />
              </div>
            </th>
            <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
              <div className="flex flex-col">
                <span className="text-sm font-bold leading-tight text-gray-600">
                  Date
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginTop: "2px",
                  }}
                  className="font-body font-normal text-gray-400"
                >
                  Time
                </span>
              </div>
            </th>
            <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
              Activity
            </th>
            <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
              <div className="flex flex-col">
                <span className="text-sm font-bold leading-tight text-gray-600">
                  Amount
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginTop: "2px",
                  }}
                  className="font-body font-normal text-gray-400"
                >
                  Currency
                </span>
              </div>
            </th>
            <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
              Initiator
            </th>
            <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
              Approval Status
            </th>
            <th className="w-[154px] text-left text-sm font-bold leading-tight text-gray-600">
              Approved/Denied by
            </th>
          </tr>
        </thead>

        {loading ? (
          <TableSkeleton columns={6} />
        ) : (
          <tbody className="">
            {adminActivities?.map((log) => (
              <tr
                key={log.id}
                className={
                  "h-[63px] cursor-pointer border-b-[.5px] border-zinc-100 hover:bg-zinc-100"
                }
              >
                <td>
                  <div className="flex h-full w-full items-center justify-center">
                    <div className="h-2 w-2 rounded-lg bg-[#3BB75E]" />
                  </div>
                </td>
                <td>
                  <span
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                    className="font-body font-bold text-gray-600"
                  >
                    {getDateFormat(log.date).dateFormat} <br />
                    <span
                      style={{ fontSize: "12px", fontWeight: "normal" }}
                      className="font-body  font-normal text-gray-400"
                    >
                      {getDateFormat(log.date).timeFormat}
                    </span>
                  </span>
                </td>
                <td>
                  <div className="flex items-center ">
                    <span
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                      className="mr-[6px] font-body font-bold capitalize text-gray-600"
                    >
                      {log.activity}
                    </span>
                    <CaretRight />
                  </div>
                </td>
                <td>
                  <span
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                    className="font-body font-bold text-gray-600"
                  >
                    {CurrencyFormatter.formatCurrency(log.amount)}
                    <br />
                    <span
                      style={{ fontSize: "12px", fontWeight: "normal" }}
                      className="font-body font-normal text-gray-400"
                    >
                      USD
                    </span>
                  </span>
                </td>
                <td>
                  <div className="flex items-center ">
                    <span
                      style={{ fontSize: "14px", fontWeight: "bolder" }}
                      className="mr-[6px] font-body font-bold capitalize text-gray-600"
                    >
                      {log.initiatedBy}
                    </span>
                    <CaretRight />
                  </div>
                </td>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <td onClick={(e) => e.stopPropagation()}>
                  <AdminApprovalStatus
                    status={log.status}
                    onSelect={() => setSelected(log)}
                    showDenyDialog={showDenyDialog}
                    showApprovalDialog={showApprovalDialog}
                  />
                </td>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <td onClick={(e) => e.stopPropagation()}>
                  <span
                    style={{ fontSize: "14px", fontWeight: "bolder" }}
                    className="font-body font-bold text-gray-600"
                  >
                    {log.resolvedBy}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <DefaultDialog
        modal={approvalDialog}
        showModal={showApprovalDialog}
        leftButtonOptions={{ label: "Cancel" }}
        rightButtonOptions={{
          width: "130px",
          label: "Approve",
          bgColor: "#3BB75E",
          onClick: () => approve(),
        }}
        title="Approve Request?"
        message="Do you want to authorize this admin action?"
      />
      <DefaultDialog
        modal={denyDialog}
        showModal={showDenyDialog}
        leftButtonOptions={{ label: "Cancel" }}
        rightButtonOptions={{
          width: "130px",
          label: "Deny",
          bgColor: "#F05050",
          onClick: () => deny(),
        }}
        title="Deny Request?"
        message="Do you want to deny this admin action?"
      />
    </>
  );
}
