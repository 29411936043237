export function CheckIcon() {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0.5C2.24 0.5 0 2.74 0 5.5C0 8.26 2.24 10.5 5 10.5C7.76 10.5 10 8.26 10 5.5C10 2.74 7.76 0.5 5 0.5ZM4 8.00004L1.5 5.50004L2.205 4.79504L4 6.58504L7.795 2.79004L8.5 3.50004L4 8.00004Z"
        fill="#226835"
      />
    </svg>
  );
}
