import { toLower } from "lodash";
import React from "react";
import { Button } from "~/@/components/ui/button";
import { Switch } from "~/@/components/ui/switch";
import { DropdownRadioSelectorInput } from "~/components/Inputs";
import { CardBody, CardFooter, CardRoot } from "~/components/layouts/Card";
import { safeArray } from "~/libs";

const notifications = [
  {
    type: "toggle",
    id: "email",
    label: "Email",
    description: "You'll get a notification for every activity your carry out",
  },
  {
    type: "toggle",
    id: "send_sms",
    label: "Send as SMS",
    description: "Carrier rates may apply",
  },
  {
    type: "special",
    id: "incoming_transaction_exceeds",
    label: "Incoming transaction exceeds specified amount",
  },
  {
    type: "multiple",
    id: "outgoing_transaction_exceeds",
    label: "Outgoing transaction exceeds specified amount",
    valid_options: ["Email", "In-app"],
  },
  {
    type: "toggle",
    id: "receive_approval_notifications",
    label: "Notifications regarding approvals assigned to you on CentryOS",
  },
  {
    type: "toggle",
    id: "trx_fails",
    label: "Transaction fails",
  },
  {
    type: "toggle",
    id: "tx_rule_modified",
    label: "Auto transfer rule is created or modified",
  },
  {
    type: "toggle",
    id: "email_customers_for_every_transaction",
    label: "Email my customers for every transaction",
  },
  {
    type: "multiple",
    id: "user_act_approval",
    label: "Notifications regarding approvals assigned to you on CentryOS",
    valid_options: ["Email", "In-app"],
  },
  {
    type: "multiple",
    id: "get_notified_on_creation",
    label: "Get notified when a user wallet is created",
    valid_options: ["Email", "In-app"],
  },
];

export function PersonalPreferences() {
  const options = React.useMemo(
    () => notifications.filter((e) => ["email", "send_sms"].includes(e.id)),
    [],
  );

  return (
    <CardRoot className="mx-auto mt-6">
      <CardBody className="flex flex-col gap-y-4 !pt-8 pb-6">
        <p className="font-bold text-gray-800">How you get notifications</p>

        <ul className={"flex flex-col divide-y"}>
          {options.map(renderNotificationOption)}
        </ul>
      </CardBody>
      <CardFooter>
        <SaveButton />
      </CardFooter>
    </CardRoot>
  );
}

function SaveButton(props: React.ComponentProps<typeof Button>) {
  return (
    <Button {...props} variant={"default"}>
      Save changes
    </Button>
  );
}

export function MyActivities() {
  const options = notifications.filter((e) =>
    [
      "incoming_transaction_exceeds",
      "outgoing_transaction_exceeds",
      "receive_approval_notifications",
      "trx_fails",
      "tx_rule_modified",
      "email_customers_for_every_transaction",
    ].includes(e.id),
  );

  return (
    <CardRoot className="mx-auto mt-6">
      <CardBody className="flex flex-col gap-y-4 !pt-8 pb-6">
        <p className="font-bold text-gray-800">Transaction notifications</p>

        <ul className={"flex flex-col divide-y"}>
          {options.map(renderNotificationOption)}
        </ul>
      </CardBody>
      <CardFooter>
        <SaveButton />
      </CardFooter>
    </CardRoot>
  );
}

function renderNotificationOption(item: (typeof notifications)[0]) {
  if (item.type === "toggle") {
    return (
      <ToggleSwitch key={item.label}>
        <ToggleContent>
          <p>{item.label}</p>
          <p>{item.description}</p>
        </ToggleContent>
      </ToggleSwitch>
    );
  }

  if (item.type === "special") {
    return (
      <div
        key={item.label}
        className="flex items-center justify-between px-6 py-4"
      >
        <ToggleContent>
          <p>{item.label}</p>
          <p className="flex items-center gap-x-2">
            <span>Send when an incoming transaction exceeds: </span>
            <input
              placeholder={"$0.00"}
              className={"min-w-[80px] border p-1"}
            />
          </p>
        </ToggleContent>

        <div className="flex min-w-[200px]">
          <DropdownRadioSelectorInput
            list={safeArray(item?.valid_options).map((e) => ({
              value: toLower(e),
              label: e,
            }))}
            value={{
              value: "",
              label: "",
            }}
            setValue={(value) => {}}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      key={item.label}
      className="flex items-center justify-between px-6 py-4"
    >
      <ToggleContent>
        <p>{item.label}</p>
        <p>{item.description}</p>
      </ToggleContent>

      <div className="flex min-w-[200px]">
        <DropdownRadioSelectorInput
          list={safeArray(item?.valid_options).map((e) => ({
            value: toLower(e),
            label: e,
          }))}
          value={{
            value: "",
            label: "",
          }}
          setValue={(value) => {}}
        />
      </div>
    </div>
  );
}

function ToggleContent(props: { children?: React.ReactNode }) {
  const [first_child, second_child] = React.Children.toArray(props.children);

  return (
    <div className="flex flex-col">
      <div className="text-[14px] font-semibold text-gray-600">
        {first_child}
      </div>
      <div className="text-[12px] text-xs text-muted-foreground">
        {second_child}
      </div>
    </div>
  );
}

function ToggleSwitch(props: { children?: React.ReactNode }) {
  return (
    <li className={"flex items-center justify-between px-6 py-4"}>
      {props.children}
      <Switch />
    </li>
  );
}

export function PlatformActivities() {
  const options = notifications.filter((e) =>
    ["user_act_approval", "get_notified_on_creation"].includes(e.id),
  );

  return (
    <CardRoot className="mx-auto mt-6">
      <CardBody className="flex flex-col gap-y-4 !pt-8 pb-6">
        <p className="font-bold text-gray-800">Users Activities</p>
        <ul className={"flex flex-col divide-y"}>
          {options.map(renderNotificationOption)}
        </ul>
      </CardBody>
      <CardFooter>
        <SaveButton />
      </CardFooter>
    </CardRoot>
  );
}
