import React, { useState } from "react";
import useEffectEvent from "react-use-event-hook";

export const useChecklist = ({ length }) => {
  const [checks, setChecks] = React.useState(() => new Set<string>());
  const [isSelectAll, setIsSelectAll] = useState(false);

  const toggle = useEffectEvent((productId: string) => {
    setIsSelectAll(false);
    checks.has(productId) ? checks.delete(productId) : checks.add(productId);
    setChecks(new Set(checks.values()));
  });

  const toggleAll = useEffectEvent(<A>(arr: A[], prop: keyof A) => {
    const newSet = new Set(checks.values());
    for (const item of arr) {
      const key = String(item[prop]);
      isSelectAll ? newSet.delete(key) : newSet.add(key);
    }
    setChecks(newSet);
    setIsSelectAll((e) => !e);
  });

  const checkAll = useEffectEvent(
    <A extends Record<string, unknown>>(arr: A[], prop: keyof A) => {
      setChecks(new Set(arr.map((e) => String(e[prop]))));
      setIsSelectAll(true);
    },
  );

  const getList = React.useCallback(
    () => Array.from(checks.values()),
    [checks],
  );

  return {
    getList,
    checks,
    toggle: toggle,
    checkAll,
    toggleAll,
    allChecked: length === checks.size,
    reset: () => setChecks(new Set()),
  };
};
