import { useState } from "react";
import useSWR from "swr";
import { walletOSFetcher } from "~/api/codegen/walletosFetcher";
import { UserAccountI } from "~/components/Redux/state/user";
import { useUserData } from "~/components/Redux/state/user/hooks";
import { getAuthData } from "~/libs";
import { fetcher, request } from ".";

enum endpoints {
  CREATE_BUSINESS = "/v1/business/create",
  USER_SIGNIN = "/v1/user/signin",
  UPDATE_TEMP_PASS = "/v1/account/update-temporary-password",
  CREATE_ACCOUNT = "/v1/account/create",
  LOGIN = "/v1/authentication/login",
  BUSINESS_INVITE_JOIN = "/v1/business/invitation-join",
  BUSINESS = "/v1/business",
  ACCOUNT = "/v1/account",
  LOGOUT = "/v1/authentication/logout",
}

interface BusinessI {
  business: {
    slug: string;
    legalName: string;
    website: string;
    address: string;
    country: string;
    kyb: {
      registrationNumber: string;
      industry: string;
    };
  };
  owner: {
    email: string;
  };
}

export const createBusiness = async (details: BusinessI) => {
  const body = { ...details };
  return await request(endpoints.CREATE_BUSINESS, { body }, "POST");
};

export const useUserSignin = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const userSignin = async ({ name }: { name: string }) => {
    try {
      setLoading(true);
      const body = { name };
      const result = await request(
        endpoints.USER_SIGNIN,
        { body, token: "token", headers: {} },
        "POST",
      );
      setData(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, data, error, userSignin };
};

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return await walletOSFetcher({
    url: endpoints.LOGIN,
    method: "post",
    body: { email, password },
    headers: {
      Authorization: undefined,
    },
  });
};

export const updateTempPassword = async (
  email: string,
  temporaryPassword: string,
  password: string,
) => {
  const body = { email, temporaryPassword, password };
  return await request(
    endpoints.UPDATE_TEMP_PASS,
    { body, token: "", headers: {} },
    "POST",
  );
};

export const createAccount = async (
  firstName: string,
  lastName: string,
  email: string,
) => {
  const body = { firstName, lastName, email };
  return await request(endpoints.CREATE_ACCOUNT, { body, headers: {} }, "POST");
};

export const acceptBusinessInvite = async (session: string, token: string) => {
  const body = { session };
  return await request(
    endpoints.BUSINESS_INVITE_JOIN,
    { body, token: token, headers: {} },
    "POST",
  );
};

export const sendAdminInvite = async (businessId: string, email: string) => {
  const body = {
    email,
    role: "ADMIN",
  };
  const authData = getAuthData();
  return await request(
    `${endpoints.BUSINESS}/${businessId}/invite`,
    { body, token: authData.accessToken, headers: {} },
    "POST",
  );
};

export const generateBusinessApiKey = async (
  businessId: string,
  accessToken: string,
) => {
  const body = {};
  return await request(
    `${endpoints.BUSINESS}/${businessId}/api-key`,
    { body, token: accessToken, headers: {} },
    "POST",
  );
};

export const getSessionInfo = async (session: string) => {
  const body = {};
  const authData = getAuthData();
  return await request(
    `${endpoints.BUSINESS}/invite/${session}`,
    { body, token: "", headers: {} },
    "GET",
  );
};

export const getAdminInfo = async (): Promise<{
  status: unknown;
  account: UserAccountI;
}> => {
  const body = {};
  const authData = getAuthData();
  return await request(
    endpoints.ACCOUNT,
    { body, token: authData?.accessToken, headers: {} },
    "GET",
  );
};

export const useBusiness = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setBusinessDetails, setCredentials } = useUserData();

  const getBusinessInfo = async (businessId: string) => {
    const body = {};
    const authData = getAuthData();
    return await request(
      `${endpoints.BUSINESS}/${businessId}`,
      { body, token: authData?.accessToken, headers: {} },
      "GET",
    );
  };

  const getBusinessMetadata = async (businessInfo: string) => {
    try {
      setIsLoading(true);
      const res = await getBusinessInfo(businessInfo);
      if (res.status === 200) {
        if (res.business) {
          setBusinessDetails(res.business);
          setCredentials(res.business?.BusinessAPIKey);
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getBusinessMetadata };
};

export const getBusinessInfo = async (businessId: string) => {
  const body = {};
  const authData = getAuthData();
  return await request(
    `${endpoints.BUSINESS}/${businessId}`,
    { body, token: authData?.accessToken, headers: {} },
    "GET",
  );
};

export const invalidateAccessToken = async () => {
  const authData = getAuthData();
  const body = {};
  return await request(
    `${endpoints.LOGOUT}`,
    { body, token: authData?.accessToken, headers: {} },
    "POST",
  );
};

export const useFetchUserData = () => {
  const { isLoading, data, error, mutate } = useSWR("/details", fetcher);
  return { data, error, refresh: mutate };
};
