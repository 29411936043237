export function CaretDownFilled({
  color,
  h,
  w,
}: {
  color?: string;
  h?: string;
  w?: string;
}) {
  return (
    <svg
      width={w || "8"}
      height={h || "4"}
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20157 0.583496H0.798435C0.521425 0.583496 0.392412 0.926856 0.600883 1.10927L3.80245 3.91064C3.91556 4.00961 4.08444 4.00961 4.19755 3.91064L7.39912 1.10927C7.60759 0.926857 7.47858 0.583496 7.20157 0.583496Z"
        fill={color || "#57584E"}
      />
    </svg>
  );
}
