import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { formatNumber } from "~/libs/currency.helpers";
import { getDateFormat } from "~/libs/date.helpers";
import { StatusBadge, StatusDot } from "../Badges";
import { ApplicationTokenTypes } from "~/libs/types.helper";
import { truncateString } from "~/libs/string.helper";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/@/components/ui/tooltip";
import { notify } from "../Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyIcon } from "~/assets/icons";
import { LinkDropdown } from "~/routes/dashboard/wallet/spend";
import { useModal } from "~/hooks/use-modal";

const columns = createColumnHelper<ApplicationTokenTypes>();

export const statusDotColumn = columns.accessor(
  (log) => {
    return (
      <>
        {log?.application?.valid ? (
          <StatusDot label={"success"} />
        ) : (
          <StatusDot label={"failed"} />
        )}
      </>
    );
  },
  {
    id: "tx-status-dot",
    header: () => (
      <div className={"flex items-center self-stretch"}>
        <StatusDot label={"success"} />
      </div>
    ),
    cell: (row) => row.getValue(),
  },
);

export const nameColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "normal" }}
        className="font-body font-medium text-gray-800"
      >
        {truncateString(
          `${
            data?.application?.metadata?.extra?.slug ?? data?.application?.token
          }`,
          100,
        )}{" "}
      </span>
    );
  },
  {
    id: "link-name",
    header: tableHeader("Payment Link Name"),
    cell: (row) => row.getValue(),
  },
);

export const urlColumn = columns.accessor(
  (data) => {
    return (
      <div
        style={{ fontSize: "14px", fontWeight: "normal" }}
        className="flex items-center"
      >
        <span
          style={{ fontSize: "14px", fontWeight: "normal" }}
          className="font-body font-medium text-gray-800"
        >
          {truncateString(`${data?.url}`, 20)} <br />
        </span>
        <div className="z-2 pl-2">
          <CopyToClipboard
            text={`${data?.url}`}
            onCopy={() => {
              notify("success", "Checkout link copied");
            }}
          >
            <button type="button" className={""}>
              <CopyIcon h="14" w="14" color="#4084B5" />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    );
  },
  {
    id: "link-url",
    header: tableHeader("URL"),
    cell: (row) => (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {}}
        onKeyUp={(e) => {}}
      >
        {row.getValue()}
      </div>
    ),
  },
);

export const amountColumn = columns.accessor(
  (data) => {
    return (
      <div
        style={{ fontSize: "14px", fontWeight: "normal" }}
        className="flex items-center"
      >
        <span
          style={{ fontSize: "14px", fontWeight: "bolder" }}
          className="font-body font-bold text-gray-600"
        >
          {data?.application?.metadata?.extra?.amount
            ? formatNumber(data?.application?.metadata?.extra?.amount, {
                decimal: true,
              })
            : "-"}
          <br />
          <span
            style={{ fontSize: "12px", fontWeight: "normal" }}
            className="font-body  font-normal text-gray-400"
          >
            {data?.application?.metadata?.currency}
          </span>
        </span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="-mt-3 pl-3">
              {data?.application?.metadata?.extra?.amountLocked ? (
                <img
                  src="/lock.svg"
                  alt="Lock"
                  className="absolute h-[16px] w-[16px]"
                />
              ) : (
                <img
                  src="/unlock.svg"
                  alt="Lock"
                  className="absolute h-[16px] w-[16px]"
                />
              )}
            </TooltipTrigger>
            <TooltipContent>
              <p>
                {data?.application?.metadata?.extra?.amountLocked
                  ? "Amount locked"
                  : "Amount unlocked"}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  },
  {
    id: "amount-details",
    header: tableHeader("Amount", "Currency"),
    cell: (row) => row.getValue(),
  },
);

export const dateColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "14px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {getDateFormat(data?.application?.createdAt).dateFormat} <br />
        <span
          style={{ fontSize: "13px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {getDateFormat(data?.application?.expiredAt).dateFormat}
        </span>
      </span>
    );
  },
  {
    id: "date-time",
    header: tableHeader("Created Date", "Expiration Date"),
    cell: (row) => row.getValue(),
  },
);

export const statusColumn = columns.accessor(
  (data) => {
    return (
      <>
        {data?.application?.valid ? (
          <StatusBadge label={"Active"} />
        ) : (
          <StatusBadge label={"Inactive"} />
        )}
      </>
    );
  },
  {
    id: "status",
    header: tableHeader("Status"),
    cell: (row) => row.getValue(),
  },
);
export const actionColumn = columns.accessor(
  (data) => {
    const modal = useModal();
    return (
      <LinkDropdown
        handleEdit={() => {
          // modal.show("invite_user");
          // console.log(data);
        }}
      />
    );
  },
  {
    id: "cat",
    header: tableHeader(""),
    cell: (row) => row.getValue(),
  },
);

function tableHeader(
  mainHeading: string,
  subHeading?: string,
): () => React.JSX.Element {
  return () => {
    return (
      <div className="flex flex-col">
        <span
          className={`text-sm font-bold leading-tight text-gray-600 ${
            !subHeading ? "py-5" : ""
          }`}
        >
          {mainHeading}
        </span>
        {!subHeading ? null : (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              marginTop: "2px",
            }}
            className="font-body font-normal text-gray-400"
          >
            {subHeading}
          </span>
        )}
      </div>
    );
  };
}
