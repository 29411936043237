import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Plus } from "lucide-react";
import React from "react";
import { useGetLinkedAccounts } from "~/api/codegen/liquidityComponents";
import { WalletTransactionModal } from "~/components/TransactionDetails";
import { DefaultInputField } from "~/components/Inputs";
import { CaretDown, EllipsisVertical } from "~/assets/icons";
import { PencilEditIcon } from "~/assets/icons/PencilEditIcon";
import { TrashBinIcon } from "~/assets/icons/TrashBinIcon";
import { AppTable } from "~/components/table/table";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { TabButton } from "~/components/Tabs";
import { CardFooter, CardRoot } from "~/components/layouts/Card";
import { NumberBadge } from "~/components/Badges";
import { useGetBusinessId } from "~/hooks/use-business";
import {
  statusDotColumn,
  sourceDetails,
  dateColumn,
  rateDetails,
  destinationDetails,
  statusColumn,
  actionColumn,
} from "~/components/table/marketplace";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { Button } from "~/@/components/ui/button";
import { Divider } from "~/components/Divider";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import { TitleSubHeading } from "~/containers/Dashboard/Pages/Settings/Title";

export const Route = createFileRoute("/dashboard/wallet/spend/marketplace")({
  component: () => <Page />,
});

function Page() {
  return (
    <section className="w-full px-3 py-10">
      <MerketPlace />
    </section>
  );
}

function MerketPlace() {
  // const router = useRouter();

  const businessId = useGetBusinessId();
  const { data: wallet_holder } = useWallets();

  const default_wallet = WalletHolderImpl.getDefaultWallet(
    wallet_holder,
    "CORPORATE",
  );
  const { data: accountData, isLoading } = useGetLinkedAccounts(
    {
      pathParams: {
        businessId,
        currency: "USD",
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const recipientAccount = useMemo(() => {
    return accountData?.data ?? [];
  }, [accountData]);

  const [searchVal, setSearchVal] = useState("");
  const table_columns = useMemo(
    () => [
      statusDotColumn,
      dateColumn,
      sourceDetails,
      rateDetails,
      destinationDetails,
      statusColumn,
      actionColumn,
    ],
    [],
  );

  const marketOrders = [
    {
      date: "Aug 29, 2022",
      time: "04:50 PM",
      convertCurrency: "USD",
      convertAmount: "10000",
      exchangeRate: "1000",
      exchangeCurrency: "NGN",
      receivingCurrency: "NGN",
      receivingAmount: "10000000",
      status: "Pending",
    },
    {
      date: "Aug 29, 2022",
      time: "04:50 PM",
      convertCurrency: "USD",
      convertAmount: "10000",
      exchangeRate: "1000",
      exchangeCurrency: "NGN",
      receivingCurrency: "NGN",
      receivingAmount: "10000000",
      status: "Failed",
    },
    {
      date: "Aug 29, 2022",
      time: "04:50 PM",
      convertCurrency: "USD",
      convertAmount: "10000",
      exchangeRate: "1000",
      exchangeCurrency: "NGN",
      receivingCurrency: "NGN",
      receivingAmount: "10000000",
      status: "Pending",
    },
    {
      date: "Aug 29, 2022",
      time: "04:50 PM",
      convertCurrency: "USD",
      convertAmount: "10000",
      exchangeRate: "1000",
      exchangeCurrency: "NGN",
      receivingCurrency: "NGN",
      receivingAmount: "10000000",
      status: "Pending",
    },
  ];

  const tabs = [
    { label: "All", value: "all", isActive: true },
    { label: "Pending", value: "pending", isActive: false },
    { label: "Expired", value: "expired", isActive: false },
  ];
  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <TitleSubHeading className="pr-2 text-gray-800">
            Orders
          </TitleSubHeading>
          <NumberBadge label="pending" count={marketOrders.length} />
        </div>
        <Link to="/convert" className="">
          <Button>
            {" "}
            <Plus size={"1rem"} color="#fff" />{" "}
            <span className="px-2"> Add Order</span>
          </Button>
        </Link>
      </div>

      <div className="mt-5">
        <Tabs defaultValue={"all"} className="">
          <CardRoot>
            <div className={"flex justify-between px-4 pb-2 pt-5"}>
              <TabsList className={"gap-x-5 bg-transparent"}>
                {tabs.map((e) => (
                  <TabsTrigger
                    key={e.label}
                    value={e.value}
                    disabled={!e.isActive}
                    asChild
                  >
                    <TabButton>{e.label}</TabButton>
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>

            <Divider className="my-[5px]" />

            <EmptyStateOverlay
              isEmpty={false}
              content={
                <EmptyStateDescription>
                  Marketplace orders will appear here
                </EmptyStateDescription>
              }
            >
              <ScrollArea className={"min-h-[400px] w-full"}>
                <TabsContent value={"all"}>
                  <AppTable
                    data={marketOrders}
                    isLoading={isLoading}
                    columns={table_columns}
                    onRowClick={(row) => {}}
                  />
                </TabsContent>
              </ScrollArea>
            </EmptyStateOverlay>

            {recipientAccount.length !== 0 && (
              <CardFooter>
                <TablePagination
                  data={{
                    page: 1,
                    pageCount: 1,
                    pageSize: 1,
                    total: marketOrders.length,
                  }}
                />
              </CardFooter>
            )}

            <WalletTransactionModal />
          </CardRoot>
        </Tabs>
      </div>
    </>
  );
}

export function CTADropdown({
  handleEdit,
  handleDelete,
}: {
  handleEdit?: () => void;
  handleDelete?: () => void;
}) {
  const filterItems = React.useMemo(
    () => [
      // {
      //   label: "Edit",
      //   icon: <PencilEditIcon size="1rem" />,
      //   link: "/settings/profile",
      //   fun: handleEdit,
      // },
      {
        label: "Delete",
        icon: <TrashBinIcon size={"1rem"} />,
        link: "/settings/profile",
        fun: handleDelete,
      },
    ],
    [handleDelete],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="px-5">
        <div className="flex cursor-pointer items-center text-sm text-[#909090]">
          <div className="ml-[5px]">
            <EllipsisVertical />
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={10}
        align="end"
        className="min-w-[150px]"
      >
        <DropdownMenuGroup>
          {filterItems.map((item, i) => (
            <DropdownMenuItem
              key={item.label}
              className="space-x-2 text-gray-700"
              onClick={() => {
                item.fun();
                // navigate({
                //   to: "/dashboard/wallet/spend/setup",
                // });
              }}
            >
              {item.icon}
              <span className={"text-base font-normal"}>{item.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
