import { createFileRoute } from "@tanstack/react-router";
import { Tabs } from "~/@/components/ui/tabs";
import {
  SecondaryContent,
  SecondaryNav,
  SecondaryRoot,
  SecondaryTab,
} from "~/components/layouts/SecondaryNav";
import {
  BusinessProfile,
  Security,
  UserProfile,
  TwoFA,
  Backup,
} from "~/containers/Dashboard/Pages/Settings/ProfileAndSecurity";
import { useBusiness } from "~/hooks/use-business";
import { useState, useEffect } from "react";

export const Route = createFileRoute("/settings/profile")({
  component: ProfileAndSecurity,
});

function ProfileAndSecurity() {
  const associatedBusiness = useBusiness();
  const hasBusiness = !!associatedBusiness;
  const [showTwoFA, setShowTwoFA] = useState(false);
  const [showBackup, setShowBackup] = useState(false);
  const [activeTab, setActiveTab] = useState("Profile");

  useEffect(() => {
    if (showTwoFA) {
      setActiveTab("2FA");
    }
  }, [showTwoFA]);

  useEffect(() => {
    if (showBackup) {
      setActiveTab("Backup Codes");
    }
  }, [showBackup]);

  const tabs = [
    { label: "Profile", isActive: !showTwoFA && !showBackup },
    { label: "Business", isActive: hasBusiness && !showTwoFA && !showBackup },
    { label: "Security", isActive: !showTwoFA && !showBackup },
    { label: "2FA", isActive: showTwoFA && !showBackup },
    { label: "Backup Codes", isActive: showBackup && !showTwoFA },
  ];

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <SecondaryRoot>
        <SecondaryNav>
          <SecondaryTab
            tabs={tabs
              .filter((e) => e.isActive)
              .map((e) => ({
                key: e.label,
                ...e,
              }))}
          />
        </SecondaryNav>

        <SecondaryContent value="Profile">
          <UserProfile />
        </SecondaryContent>

        <SecondaryContent value="Business">
          <BusinessProfile />
        </SecondaryContent>

        <SecondaryContent value="Security">
          <Security
            onToggleTwoFA={() => setShowTwoFA(true)}
            onToggleBackup={() => setShowBackup(true)}
          />
        </SecondaryContent>

        <SecondaryContent value="2FA">
          <TwoFA
            onBack={() => {
              setShowTwoFA(false);
              setActiveTab("Security");
            }}
          />
        </SecondaryContent>
        <SecondaryContent value="Backup Codes">
          <Backup
            onBack={() => {
              setShowBackup(false);
              setActiveTab("Security");
            }}
          />
        </SecondaryContent>
      </SecondaryRoot>
    </Tabs>
  );
}
