export function CaretRight({ color }: { color?: string }) {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33336 1.25L5.90669 4.82333C5.92991 4.84652 5.94834 4.87406 5.9609 4.90437C5.97347 4.93469 5.97994 4.96718 5.97994 5C5.97994 5.03282 5.97347 5.06531 5.9609 5.09563C5.94834 5.12594 5.92991 5.15348 5.90669 5.17667L2.33336 8.75"
        stroke={color || "#919191"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
