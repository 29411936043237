import { capitalize } from "@mui/material";
import { Data } from "effect";
import { BusinessMemberI, Invitation } from "~/general/interfaces";
import { safeStr } from "~/libs/data.helper";

interface TeamMember extends BusinessMemberI {
  readonly _tag: "TeamMember";
}

type InvitedMember = Invitation & {
  readonly _tag: "InvitedMember";
};

interface Member {
  readonly _tag: "Member";
  name: string;
  status: "active" | "invited";
  role: string;
  email: string;
  lastLogin: Date;
  mfaEnabled: boolean;
  isChecked: boolean;
}

export const InvitedMember = Data.tagged<InvitedMember>("InvitedMember");
export const TeamMember = Data.tagged<TeamMember>("TeamMember");
export const Member = Data.tagged<Member>("Member");

export function MemberFactory(member: TeamMember | InvitedMember): Member {
  if (member._tag === "InvitedMember") {
    const [first_name, last_name] = safeStr(member.email.split("@")[0]).split(
      ".",
    );

    return Member({
      status: "invited",
      mfaEnabled: false,
      isChecked: false,
      lastLogin: undefined,
      email: member.email,
      role: member.role,
      get name() {
        return `${capitalize(first_name ?? "")} ${capitalize(last_name ?? "")}`;
      },
    });
  }

  return Member({
    status: "active",
    role: member?.assignedRole?.role,
    lastLogin: member.createdAt,
    mfaEnabled: member?.mfaEnabled,
    email: member.email,
    isChecked: false,
    get name() {
      return `${capitalize(member.firstName ?? "")} ${capitalize(
        member.lastName ?? "",
      )}`;
    },
  });
}
