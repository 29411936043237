import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "~/@/lib/utils";
import { safeArray } from "~/libs";

const cardVariants = cva("", {
  variants: {
    variant: {
      default: "border-[#0094FF] bg-[#0094FF] text-[white]",
      darkshade: "bg-[#005695] text-[white]",
      outline:
        "border bg-white/[0.5] border-[#7F8C95]/[0.30] text-[#1C1C1C]/[0.7]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

type StatsProps = React.ComponentProps<"div"> & {
  data: { value: React.ReactNode; title: React.ReactNode }[];
  caption: string;
  Icon: React.JSX.Element;
  variant?: "darkshade" | "default" | "outline";
};

export function MetricsCard(props: StatsProps) {
  const { className, data: _data, caption, Icon } = props;

  const data = safeArray(_data);

  return (
    <div className={cn("flex flex-col gap-y-2", className)}>
      {!Icon ? null : (
        <div className={"flex items-center gap-x-2"}>
          {React.cloneElement(Icon, {
            height: "0.8rem",
            width: "0.8rem",
            color: "#002C3D",
          })}
          <span className={"text-[#797D81]"}>{caption}</span>
        </div>
      )}

      <div
        className={cn(
          cardVariants({ variant: props.variant ?? "default" }),
          "flex items-center justify-between rounded-lg px-6 py-6 shadow",
        )}
      >
        <div className="flex flex-1 flex-col text-left">
          <span className="block text-[28px] leading-[48px] md:text-[32px]">
            {data[0].value}
          </span>
          <span className="block text-sm font-semibold leading-tight md:font-bold">
            {data[0].title}
          </span>
        </div>

        <div className="mx-6 w-[1px] self-stretch bg-[currentColor] opacity-50" />

        <div className="flex flex-1 flex-col text-left">
          <span className="block text-[28px] leading-[48px] md:text-[32px]">
            {data[1].value}
          </span>
          <span className="block text-sm font-semibold leading-tight md:font-bold">
            {data[1].title}
          </span>
        </div>
      </div>
    </div>
  );
}
