import { createFileRoute } from "@tanstack/react-router";
import { Tabs } from "~/@/components/ui/tabs";
import {
  SecondaryContent,
  SecondaryNav,
  SecondaryRoot,
  SecondaryTab,
} from "~/components/layouts/SecondaryNav";
import {
  BusinessProfile,
  Security,
  UserProfile,
  TwoFA,
  Backup,
} from "~/containers/Dashboard/Pages/Settings/ProfileAndSecurity";
import {
  Banks,
  Cards,
} from "~/containers/Dashboard/Pages/Settings/LinkedAccounts";
import { useBusiness } from "~/hooks/use-business";
import { useState, useEffect } from "react";

export const Route = createFileRoute("/settings/accounts")({
  component: ProfileAndSecurity,
});

function ProfileAndSecurity() {
  const associatedBusiness = useBusiness();
  const hasBusiness = !!associatedBusiness;
  const [showTwoFA, setShowTwoFA] = useState(false);
  const [showBackup, setShowBackup] = useState(false);
  const [activeTab, setActiveTab] = useState("Banks");

  useEffect(() => {
    if (showTwoFA) {
      setActiveTab("2FA");
    }
  }, [showTwoFA]);

  useEffect(() => {
    if (showBackup) {
      setActiveTab("Backup Codes");
    }
  }, [showBackup]);

  const tabs = [
    { label: "Banks", isActive: true },
    {
      label: "Cards",
      isActive: true,
    },
  ];

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <SecondaryRoot>
        <SecondaryNav>
          <SecondaryTab
            tabs={tabs
              .filter((e) => e.isActive)
              .map((e) => ({
                key: e.label,
                ...e,
              }))}
          />
        </SecondaryNav>

        <SecondaryContent value="Banks">
          <Banks />
        </SecondaryContent>

        <SecondaryContent value="Cards">
          <Cards />
        </SecondaryContent>
      </SecondaryRoot>
    </Tabs>
  );
}
