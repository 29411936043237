export function ArrowLeft({
  w,
  h,
  color,
}: {
  w?: string;
  h?: string;
  color?: string;
}) {
  return (
    <svg
      width={w || "14"}
      height={h || "14"}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.25 13H0.75"
        stroke={color || "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 2.5L0.75 13L11.25 23.5"
        stroke={color || "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
