// src/context/NavbarContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface NavbarContextType {
  isOpen: boolean;
  toggleNavbar: () => void;
  closeNavbar: () => void;
}

const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

export const useNavbar = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error("useNavbar must be used within a NavbarProvider");
  }
  return context;
};

interface NavbarProviderProps {
  children: ReactNode;
}

export const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => setIsOpen((prev) => !prev);
  const closeNavbar = () => setIsOpen(false);

  return (
    <NavbarContext.Provider value={{ isOpen, toggleNavbar, closeNavbar }}>
      {children}
    </NavbarContext.Provider>
  );
};
