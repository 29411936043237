import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActivityLog } from "~/general/interfaces";

interface initialStateProps {
  adminActivities: ActivityLog[];
}

const initialState: initialStateProps = {
  adminActivities: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    _setAdminActivities(state, action: PayloadAction<ActivityLog[]>) {
      state.adminActivities = action.payload;
    },
  },
});

export default generalSlice.reducer;

export const { _setAdminActivities } = generalSlice.actions;
