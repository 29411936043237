import React from "react";
import { ChevronRight } from "lucide-react";
import {
  useCreateApplicationTokenAccount,
  useGetTokens,
  useOptionsMetadata,
} from "~/api/codegen/liquidityComponents";
import { CardsIcon, BankFundingIcon } from "~/assets/icons";
import { useAccountData, useGetBusinessId } from "~/hooks/use-business";
import { ClickableCard } from "~/components/ClickableCard";
import * as Address from "~/components/Inputs/CountryAddressFields";
import { empty_state_default } from "~/assets";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { BankIcon } from "~/assets/icons";
import { EmptyStateDescription } from "~/components/molecules/empty-state";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { getFiatCurrency } from "~/libs/currency.helpers";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { CardLikeButtonSkeleton } from "../Withdrawal/StepSource";
import { PayoutCtx } from ".";
import { Alert } from "~/components/atoms/Alert";
import { useGetPlatformMetadata } from "~/api/codegen/walletosComponents";
import { FiatCurrency } from "../Withdrawal/AddDestination/Countries";
import { useNavigate } from "@tanstack/react-router";
import { ApplicationTokenTypes, PickOneOptionType } from "~/libs/types.helper";
import { useCountries } from "~/hooks";
import { notify } from "~/components/Toast";
import { useWallets, WalletHolderImpl } from "~/hooks/use-wallet";

export interface LinkedAccountType {
  id: string;
  currency: string;
  optionType: string;
  createdAt: string;
  account: {
    label: string;
    lastFourDigits: string;
  };
  wallet: {
    id: string;
    balance: string;
    disabled: boolean;
  };
  entity: {
    id: string;
    email: string;
    externalIdentifier: string;
    entityType: string;
  };
  application: {
    id: string;
    externalId: string;
    token: string;
    businessId: string;
  };
}

const StepPaymnetMethod = ({ completeStep, setCurrentStep, currentStep }) => {
  const businessId = useGetBusinessId();
  const { data: userDetails } = useAccountData();
  const { data: countries } = useCountries();
  const { updateState } = useContext(PayoutCtx);
  const [countryCode, setcountryCode] = React.useState("US");
  const navigate = useNavigate();
  const { data: wallet } = useWallets();

  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const {
    data: appTokens,
    refetch,
    isLoading,
  } = useGetTokens(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        type: "ACCOUNT_WIDGET",
        page: 1,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const selectedCountry = React.useMemo(() => {
    return countries?.filter((country) => country.iso2 === countryCode) ?? [];
  }, [countries, countryCode]);

  const mutation = useCreateApplicationTokenAccount();

  const filteredTokens = useMemo(() => {
    return (
      appTokens?.data?.filter(
        (token: ApplicationTokenTypes) =>
          token?.application?.externalId === businessId &&
          token?.application?.metadata?.currency ===
            selectedCountry[0]?.currency &&
          new Date(token?.application?.expiredAt) > new Date(),
      ) ?? []
    );
  }, [appTokens]);

  const createAppTokens = async () => {
    try {
      await Promise.all(
        wallets?.map((currency) => {
          return mutation.mutateAsync({
            pathParams: { businessId },
            body: {
              externalId: businessId,
              expiredAt: "2044-08-12T13:41:35.162Z",
              tokenType: "ACCOUNT_WIDGET",
              currency: currency.currency,
            },
          });
        }),
      );
      await refetch();
    } catch (error) {
      console.log("error", error);
    }
  };

  const {
    data: options,
    isLoading: optionsLoading,
    isError,
    error,
  } = useOptionsMetadata(
    {
      pathParams: {
        token: filteredTokens[0]?.application?.token,
        currency: selectedCountry[0]?.currency,
      },
    },
    {
      staleTime: Infinity,
      enabled:
        !!selectedCountry[0]?.currency &&
        !!filteredTokens[0]?.application?.token,
    },
  );

  const optionsMetadata = useMemo(() => {
    return options?.data?.options ?? [];
  }, [options?.data]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === "Enter") {
      // selectedAccounts(account);
    }
  };

  useEffect(() => {
    if (filteredTokens.length === 0 && !isLoading) {
      createAppTokens();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isError && !optionsLoading) {
      // @ts-expect-error
      notify("error", `${error?.message}`);
    }
  }, [optionsLoading]);

  const optionIcon = (option: string) => {
    switch (option) {
      case "Bank":
      case "Manual Bank":
        return <BankFundingIcon />;
      case "Card":
        return <CardsIcon />;
      default:
        return <BankFundingIcon />;
    }
  };

  return (
    <>
      {
        <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10">
          <div className="mb-5  flex items-center justify-between">
            <h2 className="py-5 text-xl font-normal text-muted-foreground">
              Select Payment Method
            </h2>
            <div className="w-[45%]">
              <h2 className="pb-[2px] text-left text-xs font-normal text-muted-foreground">
                Destination country
              </h2>
              <Address.Root defaultValue={countryCode}>
                <Address.CountryField
                  value={countryCode}
                  onChange={(value) => {
                    setcountryCode(value);
                  }}
                  disabled={!userDetails?.mfaEnabled}
                />
              </Address.Root>
            </div>
          </div>
          {!userDetails?.mfaEnabled && (
            <Alert type={"info"} className="my-5">
              2FA is required to complete this transaction.{" "}
              <span
                className="cursor-pointer font-medium"
                onClick={() => {
                  navigate({ to: "/settings/profile" });
                }}
                onKeyDown={handleKeyDown}
              >
                Set up
              </span>
            </Alert>
          )}
          <EmptyStateRoot
            isEmpty={optionsMetadata?.length === 0 && !optionsLoading}
          >
            <EmptyStateContent>
              <div className="flex flex-col items-center">
                <img
                  src={empty_state_default}
                  alt="Nope!"
                  className="aspect-[3/2] max-w-[35ch] object-contain"
                />

                <div className="flex flex-col items-center gap-2">
                  <EmptyStateDescription className="py-2 text-gray-200">
                    No payment method found for the selected country
                  </EmptyStateDescription>
                </div>
              </div>
            </EmptyStateContent>
            <EmptyStateConceal>
              <div className="flex flex-col gap-3">
                <SkeletonContent
                  isLoading={optionsLoading || isLoading}
                  Component={CardLikeButtonSkeleton}
                >
                  <ScrollArea className={"flex max-h-[500px] w-full flex-col"}>
                    {optionsMetadata?.map((method: PickOneOptionType) => (
                      <ClickableCard
                        key={method?.label}
                        className={
                          "mb-3 flex w-full items-center justify-between px-4 py-5"
                        }
                        disabled={!userDetails?.mfaEnabled}
                        onClick={() => {
                          updateState("paymentMethod", method);
                          updateState("country", selectedCountry[0]);
                          updateState("tokendetails", filteredTokens[0]);
                          setCurrentStep(currentStep + 1);
                          completeStep(currentStep);
                        }}
                      >
                        <div className="flex items-center">
                          <div className="mr-5 flex">
                            {optionIcon(method.label)}
                          </div>
                          <div className="flex flex-col text-left">
                            <span className="text-[15px] font-semibold text-gray-600">
                              {method.label}
                            </span>
                            <span className="text-[15px] font-normal text-gray-500">
                              {method.title}
                            </span>
                          </div>
                        </div>

                        <div className="text-[32px] font-semibold text-[#002C3D]">
                          <ChevronRight
                            size={"1.5rem"}
                            className="text-gray-400"
                          />
                        </div>
                      </ClickableCard>
                    ))}
                  </ScrollArea>
                </SkeletonContent>
              </div>
            </EmptyStateConceal>
          </EmptyStateRoot>
        </div>
      }
    </>
  );
};

export default React.memo(StepPaymnetMethod);
