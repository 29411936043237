import React from "react";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { Button } from "~/@/components/ui/button";
import { ClickableCard } from "~/components/ClickableCard";
import { Skeleton } from "~/@/components/ui/skeleton";
import { CardSeparator, CardTitle } from "~/components/layouts/Card";
import { ConvertCtx } from ".";
import { useAccountData } from "~/hooks/use-business";
import { notify } from "~/components/Toast";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { ChevronUp, UserCircle } from "lucide-react";
import { FlatImage } from "../Withdrawal/StepDestination";

const StepReview = ({ completeStep, setCurrentStep, currentStep }) => {
  const { data: user } = useAccountData();
  const { swapState, updateState } = useContext(ConvertCtx);

  const swapDetails = swapState?.swapDetails;

  const currency = getFiatCurrency(swapDetails?.sourceWallet?.code);
  const recipientCurrency = getFiatCurrency(swapDetails?.receiverWallet?.code);
  const handleReview = async () => {
    try {
      setCurrentStep(currentStep + 1);
      completeStep(currentStep);
    } catch (error) {
      notify("error", `${error?.message}`);
    }
  };

  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10 pt-5">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl font-normal text-gray-800">Review Details</h2>
      </div>

      <div className="flex flex-col gap-4">
        <div className="rounded border">
          <div className="flex items-center justify-between px-5 py-2">
            <h3 className="text-base font-normal text-gray-500">
              Convert details
            </h3>
            <div className="cursor-pointer text-[32px] font-semibold text-[#002C3D]">
              {/* <ChevronUp size={"1rem"} className="text-gray-400" /> */}
            </div>
          </div>

          <div className="border-[0.5px]" />

          <div className="px-5 py-5">
            <div className="flex items-center justify-between pb-8 pt-2">
              <h3 className="text-sm font-normal text-gray-800">Paying</h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(
                  swapDetails?.sourceAmount,
                )}  ${swapDetails?.sourceWallet?.code}`}
              </p>
            </div>
            <div className="flex items-center justify-between pb-8">
              <h3 className="text-sm font-normal text-gray-600">Rate</h3>
              <div className="inline-flex cursor-pointer items-center text-gray-800">
                <span className="mr-1 text-sm font-semibold">
                  {formatNumber(1, {
                    decimal: true,
                  })}
                </span>
                <span className="text-sm font-semibold">
                  {swapDetails?.sourceWallet?.code}
                </span>
                <span className="ml-0.5 text-sm font-semibold">
                  {"  =  "}
                  {formatNumber(
                    swapDetails?.isCustomeRate && swapDetails?.customeRate
                      ? swapDetails?.customeRate
                      : 1300,
                    {
                      decimal: true,
                    },
                  )}
                </span>
                <span className="ml-1 text-sm font-semibold">
                  {swapDetails?.receiverWallet?.code}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between pb-8">
              <h3 className="text-sm font-normal text-gray-600">Receiving</h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${recipientCurrency?.symbol}${formatNumber(
                  swapDetails?.customeRate && swapDetails?.isCustomeRate
                    ? swapDetails?.customeRate * swapDetails?.sourceAmount
                    : 1300 * swapDetails?.sourceAmount,
                )}  ${swapDetails?.receiverWallet?.code}`}
              </p>
            </div>
            <div className="flex items-center justify-between pb-8">
              <h3 className="text-sm font-normal text-gray-600">Fees</h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(0)} ${
                  swapDetails?.sourceWallet?.code
                }`}
              </p>
            </div>
            <div className="flex items-center justify-between pb-2">
              <h3 className="text-sm font-normal text-gray-600">
                Total Amount{" "}
              </h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(
                  swapDetails?.sourceAmount,
                )}  ${swapDetails?.sourceWallet?.code}`}
              </p>
            </div>
          </div>
        </div>
        <CardSeparator className="border-dashed" />
        <div className="rounded border">
          <div className="flex items-center justify-between px-5 py-2">
            <h3 className="text-base font-normal text-gray-500">
              Method details
            </h3>
            <div className="text-[32px] font-semibold text-[#002C3D]">
              {/* <ChevronUp size={"1.5rem"} className="text-gray-400" /> */}
            </div>
          </div>

          <div className="border-[0.5px]" />

          <div className="px-5 py-5">
            <div className="flex items-center justify-between pb-8 pt-2">
              <h3 className="text-sm font-normal text-gray-600">Paying From</h3>
              {swapDetails?.sourceWallet?.code && (
                <div className="flex items-center">
                  <FlatImage
                    alt={currency?.code}
                    src={currency.flagUrl}
                    className="aspect-[20/13.3] w-[20px] object-contain"
                  />
                  <p className="pl-2 text-sm font-semibold text-gray-800">
                    {currency?.code}
                  </p>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between pb-2 pt-2">
              <h3 className="text-sm font-normal text-gray-600">Receiving</h3>
              {swapDetails?.receiverWallet?.code && (
                <div className="flex items-center">
                  <FlatImage
                    alt={recipientCurrency?.code}
                    src={recipientCurrency.flagUrl}
                    className="aspect-[20/13.3] w-[20px] object-contain"
                  />
                  <p className="pl-2 text-sm font-semibold text-gray-800">
                    {recipientCurrency?.code}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <CardSeparator className="border-dashed" />
        <div className="rounded border">
          <div className="flex items-center justify-between px-5 py-2">
            <h3 className="text-base text-gray-500">Schedule details</h3>
            <div className="text-[32px] font-semibold text-[#002C3D]">
              {/* <ChevronUp size={"1rem"} className="text-gray-400" /> */}
            </div>
          </div>

          <div className="border-[0.5px]" />

          <div className="px-5 py-5">
            <div className="flex items-center justify-between pb-8 pt-2">
              <h3 className="text-sm font-normal text-gray-600">Sending</h3>
              <p className="text-sm font-semibold text-gray-800">Now</p>
            </div>
            <div className="flex items-center justify-between pb-2">
              <h3 className="text-sm font-normal text-gray-600">
                Delivery Time
              </h3>
              <p className="text-sm font-semibold text-gray-800">Varies</p>
            </div>
          </div>
        </div>
        <CardSeparator className=" my-2 border-dashed" />

        <div className="text-left">
          <CardTitle className="mb-2 text-base font-semibold text-gray-700">
            Admin
          </CardTitle>
          <ClickableCard
            className={
              "flex w-full cursor-default items-center bg-[#F2F6F8] px-4 py-2 hover:border-gray-100"
            }
          >
            <UserCircle size={"1.2rem"} className="text-gray-500" />
            <CardTitle className="px-2 text-sm font-normal text-gray-700">
              {`${user?.firstName} ${user?.lastName}`}
            </CardTitle>
          </ClickableCard>
        </div>
      </div>

      <div className="mt-5 flex items-center justify-between">
        <Button
          size="lg"
          variant="outline"
          className="mt-5 w-[15%]"
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          Back
        </Button>
        <Button
          size="lg"
          variant="default"
          className="mt-5 w-[15%]"
          // disabled={!swapDetails?.sourceAmount}
          onClick={() => {
            handleReview();
          }}
        >
          <LoadingButtonContent loading={false}>Next</LoadingButtonContent>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(StepReview);
