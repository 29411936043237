import * as BalanceCard from "~/components/organisms/BalanceCard";
import { setCurrency, useSharedCurrency } from "~/contexts/currency";
import { useUserMetrics } from "~/hooks/use-user-metrics";

import { useConvert } from "~/hooks/use-currency-rates";
import { useSharedFormatter } from "~/hooks/use-shared-formatter";
import {
  BalanceMap,
  getOptionsFromValueMap,
} from "~/libs/factories/balance-map";

export function TotalUserWalletBalance() {
  const currency = useSharedCurrency();
  const { data: metrics } = useUserMetrics();

  const balance_map = useMemo(() => {
    return BalanceMap.reduce(
      BalanceMap.create(metrics?.transactions?.volume),
      BalanceMap.create(metrics?.activeVaults?.volume),
    );
  }, [metrics]);

  const { isLoading, data: balance } = useConvert(currency.code, balance_map);

  return (
    <BalanceCard.Card>
      <BalanceCard.Header headingText={"Aggregated Balance"} />
      <BalanceCard.Content>
        <BalanceCard.CurrencySwitcher
          currency={currency}
          setCurrency={setCurrency}
          options={getOptionsFromValueMap(metrics?.totalBalance?.volume)}
          readOnly={true}
        />
        <BalanceCard.Balance
          size={"lg"}
          isLoading={isLoading}
          balance={balance.amount}
          currency={currency}
        />
      </BalanceCard.Content>
    </BalanceCard.Card>
  );
}

export function CentralUserSubWalletBalanceCard(props: {
  title?: string;
  className?: string;
  activeCard?: string;
  setActiveCard?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { formatter, currency } = useSharedFormatter();
  const { data: metrics } = useUserMetrics();

  return (
    <BalanceCard.Card
      className={`cursor-pointer ${
        props.activeCard === "All User Accounts" ? "bg-primary/10" : ""
      }  ${props.className}`}
      onClick={() => props.setActiveCard("All User Accounts")}
    >
      <BalanceCard.Header headingText={props.title ?? "All User Accounts"} />
      <BalanceCard.Content className="py-5">
        <BalanceCard.CurrencySwitcher
          readOnly={true}
          currency={currency}
          options={getOptionsFromValueMap(metrics?.subwalletsTotal?.volume)}
          setCurrency={(val) => setCurrency(val)}
        />
        <BalanceCard.Balance
          balance={formatter.getValue(metrics?.subwalletsTotal?.volume)}
          currency={currency}
          showIcon={false}
        />
      </BalanceCard.Content>
    </BalanceCard.Card>
  );
}

export function CentralUserWalletVaultBalanceCard(props: {
  title?: string;
  className?: string;
  activeCard?: string;
  setActiveCard?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const currency = useSharedCurrency();
  const { data: metrics } = useUserMetrics();

  const { isLoading, data } = useConvert(
    currency.code,
    BalanceMap.create(metrics?.vaultsWallet?.volume),
  );

  return (
    <BalanceCard.Card
      className={`cursor-pointer ${
        props?.activeCard === "All User Vaults" ? "bg-primary/10" : ""
      }  ${props.className}`}
      onClick={() => props?.setActiveCard("All User Accounts")}
    >
      <BalanceCard.Header headingText={props.title ?? "All User Vaults"} />
      <BalanceCard.Content className="py-5">
        <BalanceCard.CurrencySwitcher
          currency={currency}
          readOnly={true}
          options={getOptionsFromValueMap(metrics?.vaultsWallet?.volume)}
          setCurrency={setCurrency}
        />
        <BalanceCard.Balance
          isLoading={isLoading}
          balance={data.amount}
          currency={currency}
          showIcon={false}
        />
      </BalanceCard.Content>
    </BalanceCard.Card>
  );
}
