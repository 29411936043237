import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface GlobalModalStateI {
  revenueStreamsModal?: boolean;
  escrowWalletsModal?: boolean;
  userTableModal?: boolean;
  corporatePayoutModal?: boolean;
  revenueLogModal?: boolean;
  corporateWalletLogModal?: boolean;
}

const initialState: GlobalModalStateI = {
  revenueStreamsModal: false,
  escrowWalletsModal: false,
  userTableModal: false,
  corporatePayoutModal: false,
  revenueLogModal: false,
  corporateWalletLogModal: false,
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    _updateGlobalModalState(state, action: PayloadAction<GlobalModalStateI>) {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    removeModalGlobalState: () => {
      return initialState;
    },
  },
});

export default modalSlice.reducer;

export const { _updateGlobalModalState } = modalSlice.actions;
