export function CardIcon({
  w,
  h,
  color,
}: {
  w?: string;
  h?: string;
  color?: string;
}) {
  return (
    <svg
      width={w || "25"}
      height={h || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6861_26472)">
        <path
          d="M18.5 4H6.5C4.29 4 2.5 5.79 2.5 8V16C2.5 18.21 4.29 20 6.5 20H18.5C20.71 20 22.5 18.21 22.5 16V8C22.5 5.79 20.71 4 18.5 4ZM16.64 13.77C16.4 13.97 16.07 14.05 15.76 13.97L4.65 11.25C4.95 10.52 5.66 10 6.5 10H18.5C19.17 10 19.76 10.34 20.13 10.84L16.64 13.77ZM6.5 6H18.5C19.6 6 20.5 6.9 20.5 8V8.55C19.91 8.21 19.23 8 18.5 8H6.5C5.77 8 5.09 8.21 4.5 8.55V8C4.5 6.9 5.4 6 6.5 6Z"
          fill={color || "#002C3D"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6861_26472">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
