import { mergeDeep } from "immutable";
import { is, isNotNil } from "ramda";
import { identity } from "rxjs";
import { z } from "zod";
import { createSlug } from "~/libs";
import { addressSchema, businessFormSchema } from "~/libs/validators";

export type FormState = Record<string, unknown>;

export type OnboardingFormProps = {
  prev: () => void;
  next: (data: Record<string, unknown>) => void;
  initialFormState: Record<string, unknown>;
  isLoading: boolean;
};

export const form_one_schema = businessFormSchema.merge(addressSchema);

export const form_two_schema = z.object({
  website: z.string().url("Please provide a valid URL"),
  reg_no: z.string(),
  industry: z.string(),
  description: z.string().max(500, "Maximum number of words reached"),
  // cert_of_inc: file_base64_validator,
  // memorandum: file_base64_validator,
  // address_doc: file_base64_validator,
});

export function createFormOneBody(data: z.infer<typeof form_one_schema>) {
  return {
    business: {
      legalName: data.business_name,
      slug: createSlug(data.business_name),
      address: [data.address, data.state, data.zipCode]
        .filter(isNotNil)
        .join(", "),
      country: data.selectedCountry,
    },
    owner: {
      email: data.business_email,
    },
  };
}

export function createFormTwoBody(data: z.infer<typeof form_two_schema>) {
  return {
    business: {
      website: data.website,
      kyb: {
        registrationNumber: data.reg_no,
        industry: data.industry,
      },
    },
  };
}

const onboarding_transformers = [createFormOneBody, createFormTwoBody];

export function mergeStateWithTransformers(
  state: Record<string, unknown>[],
  transformers = onboarding_transformers,
) {
  return state.reduce((accum, obj, index) => {
    const fn = transformers[index] ?? identity;
    const value = fn(obj);

    if (!is(Object, value)) return accum;

    return mergeDeep(accum, value);
  }, {});
}
