import React from "react";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { Button } from "~/@/components/ui/button";
import { ClickableCard } from "~/components/ClickableCard";
import { Skeleton } from "~/@/components/ui/skeleton";
import { CardSeparator, CardTitle } from "~/components/layouts/Card";
import { Input } from "~/@/components/ui/input";
import { BankIcon } from "~/assets/icons";
import { CardIcon, BankFundingIcon } from "~/assets/icons";
import { WithdrawCtx } from ".";
import { FlatImage } from "./StepDestination";
import { useAccountData } from "~/hooks/use-business";
import { notify } from "~/components/Toast";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { ChevronUp, UserCircle } from "lucide-react";

const StepReview = ({ completeStep, setCurrentStep, currentStep }) => {
  const { data: user } = useAccountData();
  const { withdrawState, updateState } = useContext(WithdrawCtx);
  const [comment, setComment] = React.useState<string>("");

  const recipientDetails = withdrawState?.destination;
  const amountState = withdrawState?.withdarwAmount;
  const currency = getFiatCurrency(recipientDetails?.currency);

  const handleReview = async () => {
    try {
      updateState(
        "reason",
        comment
          ? comment
          : `Withdrawal to ${recipientDetails?.account?.label} account`,
      );
      setCurrentStep(currentStep + 1);
      completeStep(currentStep);
    } catch (error) {
      notify("error", `${error?.message}`);
    }
  };

  const optionIcon = (option: string) => {
    switch (option.toLowerCase()) {
      case "bank":
      case "manual bank":
        return <BankFundingIcon color="#002C3D" w="20" h="19" />;
      case "card":
        return <CardIcon color="#002C3D" w="20" h="19" />;
      default:
        return <BankFundingIcon color="#002C3D" w="20" h="19" />;
    }
  };

  const deliveryTime = (method: string, currency: string) => {
    switch (true) {
      case method.toLowerCase() === "bank" && currency.toLowerCase() === "ngn":
      case method.toLowerCase() === "card" && currency.toLowerCase() === "ngn":
      case method.toLowerCase() === "card" && currency.toLowerCase() === "usd":
        return "Instantaneous";
      case method.toLowerCase() === "wire" && currency.toLowerCase() === "usd":
      case method.toLowerCase() === "bank" && currency.toLowerCase() === "usd":
        return "0 - 1 business day";
      default:
        return "Instantaneous";
    }
  };

  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10 pt-5">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl font-normal text-gray-800">Review Details</h2>
      </div>

      <div className="flex flex-col gap-4">
        <div className="rounded border">
          <div className="flex items-center justify-between px-5 py-2">
            <h3 className="text-base font-normal text-gray-500">
              Withdrawal details
            </h3>
            <div className="cursor-pointer text-[32px] font-semibold text-[#002C3D]">
              {/* <ChevronUp size={"1rem"} className="text-gray-400" /> */}
            </div>
          </div>

          <div className="border-[0.5px]" />

          <div className="px-5 py-5">
            <div className="flex items-center justify-between pb-8 pt-2">
              <h3 className="text-sm font-normal text-gray-800">Amount</h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(amountState?.amount)}  ${
                  amountState?.currency
                }`}
              </p>
            </div>
            <div className="flex items-center justify-between pb-8">
              <h3 className="text-sm font-normal text-gray-600">Fees</h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(amountState?.fee)} ${
                  amountState?.currency
                }`}
              </p>
            </div>
            <div className="flex items-center justify-between pb-8">
              <h3 className="text-sm font-normal text-gray-600">
                Total Amount{" "}
              </h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(
                  amountState?.amount - amountState?.fee,
                )}  ${amountState?.currency}`}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-normal text-gray-600">
                Recipient Gets{" "}
              </h3>
              <p className="text-sm font-semibold text-gray-800">
                {`${currency?.symbol}${formatNumber(
                  amountState?.amount - amountState?.fee,
                )}  ${amountState?.currency}`}
              </p>
            </div>
          </div>
        </div>
        <CardSeparator className="border-dashed" />
        <div className="rounded border">
          <div className="flex items-center justify-between px-5 py-2">
            <h3 className="text-base font-normal text-gray-500">
              Recipient details
            </h3>
            <div className="text-[32px] font-semibold text-[#002C3D]">
              {/* <ChevronUp size={"1.5rem"} className="text-gray-400" /> */}
            </div>
          </div>

          <div className="border-[0.5px]" />

          {recipientDetails?.optionType === "bank" && (
            <div className="px-5 py-5">
              <div className="flex items-center justify-between pb-8 pt-2">
                <h3 className="text-sm font-normal text-gray-600">
                  Account Name
                </h3>
                <p className="text-sm font-semibold text-gray-800">
                  {recipientDetails?.account?.label ?? "-"}
                </p>
              </div>
              <div className="flex items-center justify-between pb-8">
                <h3 className="text-sm font-normal text-gray-600">
                  Account Number
                </h3>
                <p className="text-sm font-semibold text-gray-800">
                  {`**** ${recipientDetails?.account?.lastFourDigits}`}
                </p>
              </div>
              <div className="flex items-center justify-between pb-8">
                <h3 className="text-sm font-normal text-gray-600">Bank Name</h3>
                <p className="text-sm font-semibold text-gray-800">-</p>
              </div>
              {recipientDetails?.currency !== "NGN" && (
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">
                    Routing Number
                  </h3>
                  <p className="text-sm font-semibold text-gray-800">-</p>
                </div>
              )}
              <div className="flex items-center justify-between pb-8">
                <h3 className="text-sm font-normal text-gray-600">Currency</h3>
                {recipientDetails?.currency && (
                  <div className="flex items-center">
                    <FlatImage
                      alt={currency.code}
                      src={currency.flagUrl}
                      className="aspect-[20/13.3] w-[20px] object-contain"
                    />
                    <p className="pl-2 text-sm font-semibold text-gray-800">
                      {recipientDetails?.currency}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between pb-5">
                <h3 className="text-sm font-normal text-gray-600">Type</h3>
                {recipientDetails?.optionType && (
                  <div className="flex items-center">
                    {optionIcon(recipientDetails?.optionType)}
                    <p className="pl-2 text-sm font-semibold capitalize text-gray-800">
                      {recipientDetails?.optionType}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {recipientDetails?.optionType === "card" && (
            <div className="px-5 py-5">
              <div className="flex items-center justify-between pb-8 pt-2">
                <h3 className="text-sm font-normal text-gray-600">Card Name</h3>
                <p className="text-sm font-semibold text-gray-800">
                  {recipientDetails?.account?.label ?? "-"}
                </p>
              </div>
              <div className="flex items-center justify-between pb-8">
                <h3 className="text-sm font-normal text-gray-600">
                  Card Number
                </h3>
                <p className="text-sm font-semibold text-gray-800">
                  {`**** ${recipientDetails?.account?.lastFourDigits}`}
                </p>
              </div>
              {/* <div className="flex items-center justify-between pb-8">
                <h3 className="text-sm font-normal text-gray-600">Bank Name</h3>
                <p className="text-sm font-semibold text-gray-800">-</p>
              </div> */}
              {/* {recipientDetails?.currency !== "NGN" && (
                <div className="flex items-center justify-between pb-8">
                  <h3 className="text-sm font-normal text-gray-600">
                    Routing Number
                  </h3>
                  <p className="text-sm font-semibold text-gray-800">-</p>
                </div>
              )} */}
              <div className="flex items-center justify-between pb-8">
                <h3 className="text-sm font-normal text-gray-600">Currency</h3>
                {recipientDetails?.currency && (
                  <div className="flex items-center">
                    <FlatImage
                      alt={currency.code}
                      src={currency.flagUrl}
                      className="aspect-[20/13.3] w-[20px] object-contain"
                    />
                    <p className="pl-2 text-sm font-semibold text-gray-800">
                      {recipientDetails?.currency}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between pb-5">
                <h3 className="text-sm font-normal text-gray-600">Type</h3>
                {recipientDetails?.optionType && (
                  <div className="flex items-center">
                    {optionIcon(recipientDetails?.optionType)}
                    <p className="pl-2 text-sm font-semibold capitalize text-gray-800">
                      {recipientDetails?.optionType}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <CardSeparator className="border-dashed" />
        <div className="rounded border">
          <div className="flex items-center justify-between px-5 py-2">
            <h3 className="text-base text-gray-500">Schedule details</h3>
            <div className="text-[32px] font-semibold text-[#002C3D]">
              {/* <ChevronUp size={"1rem"} className="text-gray-400" /> */}
            </div>
          </div>

          <div className="border-[0.5px]" />

          <div className="px-5 py-5">
            <div className="flex items-center justify-between pb-8 pt-2">
              <h3 className="text-sm font-normal text-gray-600">Sending</h3>
              <p className="text-sm font-semibold text-gray-800">Now</p>
            </div>
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-normal text-gray-600">
                Delivery Time
              </h3>
              <p className="text-sm font-semibold text-gray-800">
                {deliveryTime(
                  recipientDetails?.optionType,
                  recipientDetails?.currency,
                )}
              </p>
            </div>
          </div>
        </div>
        <CardSeparator className=" my-2 border-dashed" />
        <div className="text-left">
          <CardTitle className="mb-2 text-xl font-semibold text-gray-800">
            Narration{" "}
            <span className="text-base font-normal text-gray-500">
              (optional)
            </span>
          </CardTitle>
          <Input
            className="h-[64px] w-full border-gray-800 text-xl font-normal text-gray-800 outline-none placeholder:text-xl placeholder:font-normal placeholder:text-gray-200 focus:outline-none disabled:cursor-default disabled:opacity-[1]"
            placeholder=""
            value={comment}
            onChange={(evt) => setComment(evt.target.value)}
          />
        </div>
        <CardSeparator className="mt-2 border-dashed" />
        <div className="text-left">
          <CardTitle className="mb-2 text-base font-semibold text-gray-700">
            Admin
          </CardTitle>
          <ClickableCard
            className={
              "flex w-full cursor-default items-center bg-[#F2F6F8] px-4 py-2 hover:border-gray-100"
            }
          >
            <UserCircle size={"1.2rem"} className="text-gray-500" />
            <CardTitle className="px-2 text-sm font-normal text-gray-700">
              {`${user?.firstName} ${user?.lastName}`}
            </CardTitle>
          </ClickableCard>
        </div>
      </div>

      <div className="mt-5 flex items-center justify-between">
        <Button
          size="lg"
          variant="outline"
          className="mt-5 w-[15%]"
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          Back
        </Button>
        <Button
          size="lg"
          variant="default"
          className="mt-5 w-[15%]"
          disabled={!amountState?.amount}
          onClick={() => {
            handleReview();
          }}
        >
          <LoadingButtonContent loading={false}>Next</LoadingButtonContent>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(StepReview);

export function CardLikeButtonSkeleton() {
  return (
    <ClickableCard className="my-2 w-full justify-between" disabled>
      <div className="flex basis-1/2 flex-col space-y-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-12" />
          <Skeleton className="h-4 basis-7/12 rounded" />
        </div>
        <Skeleton className="h-3 w-full rounded" />
      </div>

      <Skeleton className="h-7 w-24 basis-4/12 rounded" />
    </ClickableCard>
  );
}
