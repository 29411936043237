import { Button } from "~/@/components/ui/button";
import { useUpdateAnAccount } from "~/api/codegen/walletosComponents";
import { Plus } from "lucide-react";
import { DEFAULT_COUNTRY } from "~/constants";
import { notifyApiError, notifySuccess } from "~/components/Toast";
import { CardRoot } from "~/components/layouts/Card";
import { TitleSubHeading } from "~/containers/Dashboard/Pages/Settings/Title";
import { useAccountData, useBusinessMetadata } from "~/hooks/use-business";
import { NumberBadge } from "~/components/Badges";
import { Link } from "@tanstack/react-router";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { AppTable } from "~/components/table/table";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import {
  accountNumber,
  nameColumn,
  bankName,
  accountType,
  countryCurrency,
  statusColumn,
  actionColumn,
} from "~/components/table/banks";

export function Banks() {
  const table_columns = useMemo(
    () => [
      nameColumn,
      accountNumber,
      bankName,
      accountType,
      countryCurrency,
      statusColumn,
      actionColumn,
    ],
    [],
  );

  return (
    <>
      <div className="w-full px-1 pb-5 pt-5">
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <TitleSubHeading className="pr-2 text-gray-500">
              Banks
            </TitleSubHeading>
            <NumberBadge label="pending" count={0} />
          </div>
          <Link to="/convert" className="" disabled>
            <Button>
              {" "}
              <Plus size={"1rem"} color="#fff" />{" "}
              <span className="px-2"> Add Bank</span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="">
        <CardRoot>
          <EmptyStateOverlay
            isEmpty={false}
            content={
              <EmptyStateDescription>
                You have no bank account added
              </EmptyStateDescription>
            }
          >
            <ScrollArea className={"min-h-[400px] w-full"}>
              <AppTable
                data={[]}
                isLoading={false}
                columns={table_columns}
                onRowClick={(row) => {}}
              />
            </ScrollArea>
          </EmptyStateOverlay>

          {/* {recipientAccount.length !== 0 && (
              <CardFooter>
                <TablePagination
                  data={{
                    page: 1,
                    pageCount: 1,
                    pageSize: 1,
                    total: marketOrders.length,
                  }}
                />
              </CardFooter>
            )} */}

          {/* <WalletTransactionModal /> */}
        </CardRoot>
      </div>
    </>
  );
}

export function Cards() {
  const table_columns = useMemo(
    () => [
      nameColumn,
      accountNumber,
      bankName,
      accountType,
      countryCurrency,
      statusColumn,
      actionColumn,
    ],
    [],
  );

  return (
    <>
      <div className="w-full px-1 pb-5 pt-5">
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <TitleSubHeading className="pr-2 text-gray-500">
              Cards
            </TitleSubHeading>
            <NumberBadge label="pending" count={0} />
          </div>
          <Link to="/convert" className="" disabled>
            <Button>
              {" "}
              <Plus size={"1rem"} color="#fff" />{" "}
              <span className="px-2"> Add Card</span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="">
        <CardRoot>
          <EmptyStateOverlay
            isEmpty={false}
            content={
              <EmptyStateDescription>
                You have no bank account added
              </EmptyStateDescription>
            }
          >
            <ScrollArea className={"min-h-[400px] w-full"}>
              <AppTable
                data={[]}
                isLoading={false}
                columns={table_columns}
                onRowClick={(row) => {}}
              />
            </ScrollArea>
          </EmptyStateOverlay>

          {/* {recipientAccount.length !== 0 && (
              <CardFooter>
                <TablePagination
                  data={{
                    page: 1,
                    pageCount: 1,
                    pageSize: 1,
                    total: marketOrders.length,
                  }}
                />
              </CardFooter>
            )} */}

          {/* <WalletTransactionModal /> */}
        </CardRoot>
      </div>
    </>
  );
}
