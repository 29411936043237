import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/@/components/ui/button";
import { Checkbox } from "~/@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/@/components/ui/form";
import { FormItemSplit } from "~/@/components/ui/form-layout";
import { Switch } from "~/@/components/ui/switch";
import { cn } from "~/@/lib/utils";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { Divider } from "~/components/Divider";
import { FieldShowBalanceIn } from "~/components/Inputs/fields";
import { CardBody, CardFooter, CardRoot } from "~/components/layouts/Card";
import {
  CorporateWalletFactory,
  CorporateWalletSchema,
  FormStruct,
  toFormStruct,
} from "~/libs/factories/corporate-wallet";

export const Route = createFileRoute("/settings/wallets/")({
  loader: () => ({
    externalUserId: "",
    entityId: "",
    whoCanFund: [],
    requireApproval: false,
    approvalThreshold: 1000,
    fundWalletVia: ["NGN", "USD"],
    allowFunding: ["NGN", "USD"],
    allowWithdrawal: ["NGN", "USD"],
  }),
  component: CorporateWalletSettings,
});

function CorporateWalletSettings() {
  const data: CorporateWalletSchema = CorporateWalletFactory(
    useLoaderData({ strict: false }),
  );

  const form = useForm<FormStruct>({
    defaultValues: React.useMemo(() => toFormStruct(data), [data]),
  });

  const onSubmit = form.handleSubmit(async (values) => {
    await new Promise((res) => {
      setTimeout(res, 2000);
    });

    console.log("Data", values);
  });

  return (
    <Form {...form}>
      <CardRoot className="mt-6">
        <CardBody className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="balance"
            render={() => <FieldShowBalanceIn />}
          />

          <Divider />

          <FormField
            control={form.control}
            name="who_can_fund"
            render={() => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <span className={"text-sm"}>
                      Who can <b>Fund</b>?
                    </span>
                    <FormLabel
                      className={cn("flex items-center space-x-2", {
                        "opacity-50": true,
                      })}
                    >
                      <FormControl>
                        <Checkbox checked={true} disabled />
                      </FormControl>
                      <span className={"font-normal"}>Business Owner</span>
                    </FormLabel>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="who_can_withdraw"
            render={() => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <span className={"text-sm"}>
                      Who can <b>Withdraw</b>?
                    </span>
                    <FormLabel
                      className={cn("flex items-center space-x-2", {
                        "opacity-50": true,
                      })}
                    >
                      <FormControl>
                        <Checkbox checked={true} disabled />
                      </FormControl>
                      <span className={"font-normal"}>Business Owner</span>
                    </FormLabel>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />

          <Divider />

          <FormField
            control={form.control}
            name="require_approval"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <FormLabel>Require Approval</FormLabel>
                    <FormControl className="self-end">
                      <Switch
                        checked={Boolean(field.value)}
                        onCheckedChange={(value) =>
                          form.setValue(field.name, value)
                        }
                      />
                    </FormControl>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />

          <Divider />

          <FormItemSplit>
            <FormLabel>Fund Wallet via</FormLabel>
            <ul className="w-full space-y-2 font-body">
              <FormControl>
                <ul className="flex w-full flex-col gap-y-4">
                  {[
                    {
                      id: "bank_transfer",
                      title: "Bank Transfer",
                      disabled: false,
                    },
                    {
                      id: "debit_credit_card",
                      title: "Debt/Credit Card",
                      disabled: true,
                    },
                    {
                      id: "subWallet",
                      title: "subWallet Transfer",
                      disabled: true,
                    },
                    {
                      id: "crypto_transfer",
                      title: "Crypto Transfer",
                      disabled: true,
                    },
                    {
                      id: "mobile_wallet",
                      title: "Mobile Wallet",
                      disabled: true,
                    },
                  ].map((option) => {
                    return (
                      <FormField
                        key={option.title}
                        // @ts-expect-error No validator for dynamic values
                        name={`allow_funding_in.${option.id}`}
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <FormItem
                              className={"flex items-center gap-x-2 text-sm"}
                            >
                              <FormControl>
                                <Checkbox
                                  name={field.name}
                                  disabled={option.disabled}
                                  checked={Boolean(field.value)}
                                  onCheckedChange={(value) => {
                                    form.setValue(field.name, value);
                                  }}
                                />
                              </FormControl>

                              <FormLabel className="!mt-0 font-normal text-gray-600">
                                {option.title}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    );
                  })}
                </ul>
              </FormControl>
            </ul>
          </FormItemSplit>
        </CardBody>

        <CardFooter>
          <Button onClick={onSubmit}>
            <LoadingButtonContent loading={form.formState.isSubmitting}>
              Save changes
            </LoadingButtonContent>
          </Button>
        </CardFooter>
      </CardRoot>
    </Form>
  );
}
