import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { createRevenueStream, getRevenueStreams } from "~/api/revenue";
import { USDCIcon } from "~/assets/assetIcons";
import { ArrowLeft } from "~/assets/icons/ArrowLeft";
import { ArrowRight } from "~/assets/icons/ArrowRight";
import { ArrowTopRight } from "~/assets/icons/ArrowTopRight";
import { CaretDownFilled } from "~/assets/icons/CaretDownFilled";
import { InfoIcon } from "~/assets/icons/InfoIcon";
import { PlusIcon } from "~/assets/icons/PlusIcon";
import { currencies } from "~/constants/currencies";
import { StreamDetails } from "~/general/interfaces";
import { displayMockUI } from "~/libs";
import CurrencyFormatter from "~/libs/CurrencyFormatter";
import { getDateFormat } from "~/libs/date.helpers";
import Modal from ".";
import styles from "../../general/styles.module.css";
import { DefaultButton, DefaultButtonOutline } from "../Buttons";
import TransactionPerformance from "../Charts";
import { Divider } from "../Divider";
import {
  CurrencySelectorInput,
  DatePickerInput,
  DefaultInputField,
  DropdownRadioSelectorInput,
  TimePickerInput,
} from "../Inputs";
import { useLoaders } from "../Redux/state/loader/hooks";
import { useModals } from "../Redux/state/modals/hooks";
import { useRevenueStates } from "../Redux/state/revenue/hooks";
import { useUserData } from "../Redux/state/user/hooks";
import { StreamListSkeleton } from "../Skeletons";
import ShowToast from "../Toast";
import Authenticator from "./2FaAuth";

function RevenuStreamList({
  prev,
  next,
}: {
  prev: () => void;
  next: () => void;
}) {
  const [loading, showLoading] = useState(false);
  const { businessDetails } = useUserData();
  const { revenueStates, updateGlobalRevenueStates } = useRevenueStates();
  const [streamDetails, setStreamDetails] = useState<StreamDetails | null>(
    null,
  );
  const [streamModal, showStreamModal] = useState(false);

  async function fetchRevenueStreams() {
    try {
      showLoading(true);
      const res = await getRevenueStreams(businessDetails?.id);
      if (res.status === 200) {
        updateGlobalRevenueStates({ revenue_streams: res.streams });
      }
    } catch (err) {
    } finally {
      showLoading(false);
    }
  }

  useEffect(() => {
    if (displayMockUI()) {
      fetchRevenueStreams();
    }
  }, []);

  function viewStream(details: StreamDetails) {
    setStreamDetails(details);
    showStreamModal(true);
  }

  return (
    <>
      <div className="max-h-[80vh] min-h-[80vh] w-[550px] rounded bg-[white] pb-[20px]">
        <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type="button"
            onClick={() => prev()}
            className="cursor-pointer"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
          </button>

          <span
            className={"text-[20px] font-bold leading-tight text-[#0094FF]"}
          >
            Revenue Streams
          </span>
        </div>
        <div
          className={`${styles.custom_scrollbar2} max-h-[600px] w-[100%] overflow-y-scroll bg-[white] px-[20px] py-[0px]`}
        >
          <div className="flex items-center justify-between p-[20px]">
            <div className="inline-flex h-6 items-center justify-start">
              <span className="mr-[5px] text-base font-normal text-zinc-500">
                Revenue Streams
              </span>
              <span className="flex w-8 items-center justify-start rounded-2xl bg-amber-50 px-3 py-2">
                <span className="text-center text-xs font-bold leading-tight text-amber-500">
                  {revenueStates.revenue_streams
                    ? revenueStates.revenue_streams.length
                    : 0}
                </span>
              </span>
            </div>
            <DefaultButton
              label="Create new stream"
              icon={<PlusIcon color="white" />}
              onClick={() => next()}
              className="h-[33px] w-[182px] bg-[#3BB75E]"
            />
          </div>
          {loading ? (
            <div>
              <StreamListSkeleton />
              <StreamListSkeleton />
              <StreamListSkeleton />
            </div>
          ) : (
            <div>
              {revenueStates.revenue_streams?.map((stream, i) => (
                <button
                  type="button"
                  key={stream.name}
                  onClick={() => viewStream(stream)}
                  className="mb-[15px] w-[100%] cursor-pointer rounded-[10px] border-[1px] border-[#0094FF] p-[20px]"
                >
                  <div className="flex items-center justify-between">
                    <span className="text-xs font-normal leading-tight text-neutral-400">
                      Stream Name
                      <br />{" "}
                      <span className="text-sm font-bold leading-tight text-stone-600">
                        {stream?.name}
                      </span>
                    </span>
                    <ArrowRight color="grey" size="24" />
                  </div>
                  <Divider />
                  <div className="flex items-center justify-between">
                    <span className="text-xs font-normal leading-tight text-neutral-400">
                      Trigger
                      <br />{" "}
                      <span className="mr-[10px] flex items-center text-sm font-bold capitalize leading-tight text-stone-600">
                        {stream?.trigger.replace("-", " ")}
                        <span className="ml-[8px]">
                          <ArrowTopRight />
                        </span>
                      </span>
                    </span>
                    <span className="text-[12px] font-normal leading-tight text-green-500">
                      Rates
                      <span className="ml-[5px] text-[12px] font-bold leading-tight text-green-800">
                        0.4% / 100 Trigger
                      </span>
                    </span>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {streamDetails && (
        <ViewRevenuStream
          modal={streamModal}
          showModal={showStreamModal}
          details={streamDetails}
          onClose={() => setStreamDetails(null)}
        />
      )}
    </>
  );
}

function CreateNewStream({ prev, showAuth }) {
  const revenueTypes = [
    { label: "Select Option", value: "null" },
    { label: "Trigger Based", value: "trigger-based" },
    { label: "Fixed Charge", value: "fixed-charge" },
  ];

  const triggers = [
    { label: "Select Option", value: "null" },
    { label: "All Transactions", value: "transactions" },
    { label: "Account Funding", value: "account-funding" },
    { label: "Withdrawal", value: "withdrawal" },
    { label: "Transfers", value: "transfers" },
  ];

  const chargeSytems = [
    { label: "Select Option", value: "null" },
    { label: "Amount Percentage", value: "amount-percentage" },
    { label: "Set Fixed Amount", value: "fixed-amount" },
    { label: "Charge/Volume", value: "charge-volume" },
  ];

  const frequencyList = [
    { label: "Select Option", value: "null" },
    { label: "One Time Charge", value: "one-time" },
    { label: "Annual Charge", value: "annual-charge" },
    { label: "Monthly Charge", value: "annual-charge" },
    { label: "Weekly Charge", value: "annual-charge" },
    { label: "Daily Charge", value: "annual-charge" },
  ];
  const { revenueStates, updateGlobalRevenueStates } = useRevenueStates();
  const { showEllipsisLoader, closeEllipsisLoader } = useLoaders();
  const { businessDetails } = useUserData();
  const [loading, showLoading] = useState(false);
  const [streamName, setStreamName] = useState("");
  const [revenueType, setRevenueType] = useState(revenueTypes[0]);
  const [trigger, setTrigger] = useState(triggers[0]);
  const [chargeSystem, setChargeSystem] = useState(chargeSytems[0]);
  const [rate, setRate] = useState("");
  const [rateInt, setRateInt] = useState(0);
  const [volume, setVolume] = useState("0");
  const [frequency, setFrequency] = useState(frequencyList[0]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [fixedAmount, setFixedAmount] = useState("");
  const [fixedAmountInt, setFixedAmountInt] = useState(0);
  const [fixedAmountCurrency, setFixedAmountCurrency] = useState(currencies[0]);
  const [rateCurrency, setRateCurrency] = useState(currencies[0]);
  const [chargeRate, setChargeRate] = useState("");
  const [chargeRateInt, setChargeRateInt] = useState(0);
  const [chargeVolume, setChargeVolume] = useState("");

  function reset() {
    setStreamName("");
    setRevenueType(revenueTypes[0]);
    setChargeSystem(chargeSytems[0]);
    setTrigger(triggers[0]);
    setFrequency(frequencyList[0]);
  }

  async function createStream() {
    try {
      showEllipsisLoader();
      const res = await createRevenueStream(
        businessDetails?.id,
        streamName,
        revenueType.value,
      );
      if (res.status === 200) {
        updateGlobalRevenueStates({
          revenue_streams: [...revenueStates.revenue_streams, res.stream],
        });
        ShowToast(res.message, "success");
        reset();
      }
    } catch (err) {
    } finally {
      closeEllipsisLoader();
    }
  }

  const isBtnDisabled = () => {
    if (displayMockUI()) {
      if (
        revenueType.value === "null" ||
        trigger.value === "null" ||
        chargeSystem.value === "null" ||
        frequency.value === "null"
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <div className="max-h-[80vh] w-[550px] rounded bg-[white] pb-[20px]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button type="button" onClick={() => prev()} className="cursor-pointer">
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>

        <span className={"text-[20px] font-bold leading-tight text-[#0094FF]"}>
          Create Revenue Stream
        </span>
      </div>

      <div
        className={
          "mb-[20px] max-h-[700px] w-[100%] bg-[white] px-[20px] py-[0px]"
        }
      >
        <DropdownRadioSelectorInput
          list={revenueTypes}
          value={revenueType}
          setValue={setRevenueType}
          label="Revenue Type"
          containerStyle={{ marginTop: "25px" }}
        />
        {revenueType.value === "trigger-based" && (
          <div>
            <DropdownRadioSelectorInput
              list={triggers}
              value={trigger}
              setValue={setTrigger}
              label="Trigger"
              containerStyle={{ marginTop: "25px" }}
            />
            <DropdownRadioSelectorInput
              list={chargeSytems}
              value={chargeSystem}
              setValue={setChargeSystem}
              label="Charge System"
              containerStyle={{ marginTop: "25px" }}
            />
            {chargeSystem.value === "amount-percentage" && (
              <div className="mt-[25px] flex items-end">
                <div className="flex items-center">
                  <DefaultInputField
                    label="Rate"
                    value={rate}
                    setValue={setRate}
                    forceNumbers
                    containerStyle={{ width: "150px" }}
                  />
                  <span className="mx-[10px] mt-[20px] text-sm font-bold text-stone-600">
                    %
                  </span>
                </div>
                <span className="w-[18px] font-body text-[32px] font-bold leading-tight text-gray-300">
                  /
                </span>
                <div className="item-center flex">
                  <DefaultInputField
                    label=""
                    value={volume}
                    setValue={setVolume}
                    forceNumbers
                    containerStyle={{ width: "150px" }}
                  />
                  <span className="ml-[8px] mt-[10px] text-sm font-bold leading-tight text-stone-600">
                    {trigger.label}
                  </span>
                </div>
              </div>
            )}
            {chargeSystem.value === "charge-volume" && (
              <div className="mt-[25px] flex items-end">
                <div className="flex items-center">
                  <DefaultInputField
                    label="Rate"
                    value={chargeRate}
                    setValue={setChargeRate}
                    forceNumbers
                    containerStyle={{ width: "150px" }}
                  />
                  <span className="mx-[10px] mt-[20px] text-sm font-bold text-stone-600">
                    Charge
                  </span>
                </div>
                <span className="w-[18px] font-body text-[32px] font-bold leading-tight text-gray-300">
                  /
                </span>
                <div className="item-center flex">
                  <DefaultInputField
                    label=""
                    value={chargeVolume}
                    setValue={setChargeVolume}
                    forceNumbers
                    containerStyle={{ width: "150px" }}
                  />
                  <span className="ml-[8px] mt-[10px] text-sm font-bold leading-tight text-stone-600">
                    Volume
                  </span>
                </div>
              </div>
            )}
            {chargeSystem.value === "fixed-amount" && (
              <div className="mt-[20px] flex w-[100%] flex-col">
                <span className="mb-[8px] text-sm font-bold leading-tight text-gray-800">
                  Rate
                </span>
                <CurrencySelectorInput
                  setIntValue={setRateInt}
                  currency={rateCurrency}
                  setCurrency={setRateCurrency}
                  label="Rate"
                  currencies={currencies}
                  value={rate}
                  setValue={setRate}
                />
              </div>
            )}
          </div>
        )}
        {revenueType.value === "fixed-charge" && (
          <div>
            <DropdownRadioSelectorInput
              list={frequencyList}
              value={frequency}
              setValue={setFrequency}
              label="Frequency"
              containerStyle={{ marginTop: "25px" }}
            />
            <div className="mt-[20px]">
              <span className="mb-[8px] text-sm font-bold leading-tight text-gray-800">
                Date
                <span className="font-normal"> (Start date - End date)</span>
              </span>
              <div className="flex items-end justify-between">
                <DatePickerInput
                  label=""
                  value={dateFrom}
                  setValue={setDateFrom}
                  containerStyle={{}}
                />
                <DatePickerInput
                  label=""
                  value={dateTo}
                  setValue={setDateTo}
                  containerStyle={{}}
                />
              </div>
            </div>

            <TimePickerInput
              label="Time"
              value={time}
              setValue={setTime}
              containerStyle={{ marginTop: "25px" }}
            />
            <div className="mt-[20px] flex w-[100%] flex-col">
              <span className="mb-[8px] text-sm font-bold leading-tight text-gray-800">
                Amount
              </span>
              <CurrencySelectorInput
                setIntValue={setFixedAmountInt}
                currency={fixedAmountCurrency}
                setCurrency={setFixedAmountCurrency}
                label="Amount"
                currencies={currencies}
                value={fixedAmount}
                setValue={setFixedAmount}
              />
            </div>
            <div className="mt-[6px] flex items-start">
              <span className="mt-[4px]">
                <InfoIcon color="#FFAA22" />
              </span>
              <span>
                <span className="text-base font-bold leading-tight text-zinc-500">
                  {CurrencyFormatter.formatCurrency(fixedAmountInt)}
                  {fixedAmountCurrency.code}{" "}
                </span>
                <span className="text-sm font-normal leading-tight text-zinc-500">
                  will be charged for monthly at{" "}
                  {getDateFormat(time).timeFormat}
                </span>
              </span>
            </div>
          </div>
        )}
        <Divider />
        <DefaultInputField
          label="Stream name"
          value={streamName}
          setValue={setStreamName}
          containerStyle={{}}
        />
      </div>
      <div className="w-[100%] border-t-[.5px] border-zinc-200 px-[20px]">
        <div className="mt-[20px] flex items-center justify-between">
          <DefaultButtonOutline
            label="Back"
            onClick={() => prev()}
            styles={{ height: "40px" }}
            className="h-[33px] w-[61px]"
          />
          <DefaultButton
            label="Create"
            rtl
            disabled={!isBtnDisabled()}
            icon={<ArrowRight color="white" />}
            styles={{ height: "40px" }}
            // onClick={() => showAuth()}
            onClick={() => createStream()}
            className="h-[33px] w-[auto] bg-[#3BB75E] px-[10px]"
          />
        </div>
      </div>
      <div className="h-[25px]" />
    </div>
  );
}

function ViewRevenuStream({
  modal,
  showModal,
  onClose,
  details,
}: {
  modal: boolean;
  showModal: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  details: StreamDetails; //update type
}) {
  const [active, setActive] = useState(false);
  const triggerList = [
    { label: "Marketplace checkout", value: "Marketplace checkout" },
  ];

  const [streamName, setStreamName] = useState(details?.name);
  const [trigger, setTrigger] = useState(details?.trigger);

  const dates = [];
  const today = moment();

  dates.push(today.format("MM/DD/YYYY"));
  Array(10)
    .fill("1")
    .map(() => dates.push(today.subtract(1, "M").format("MM/DD/YYYY")));

  const _trigger = details.trigger.replace("-", " ");

  return (
    <Modal
      modal={modal}
      showModal={showModal}
      renderHeader={
        <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type="button"
            onClick={() => {
              showModal(false);
              onClose();
            }}
            className="flex cursor-pointer items-center"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
            <span
              className={
                "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
              }
            >
              {details?.name}
            </span>
          </button>
          {/* <div className="flex items-center justify-between">
            <span className="flex items-center">
              <ToggleInput
                isToggled={active}
                setIsToggled={setActive}
                onToggle={() => {}}
              />
              <span
                className={`ml-[5px] font-bold ${
                  !active ? "text-[lightgrey]" : "text-[#3BB75E]"
                }`}
              >
                Active
              </span>
            </span>
            <span className="mx-[20px]">
              <SettingsIcon />
            </span>
            <span>
              <DeleteIcon />
            </span>
          </div> */}
        </div>
      }
    >
      <div className="w-[1000px] max-w-[85vw] rounded bg-[white]">
        <div
          className={`${styles.custom_scrollbar2} h-[90%] overflow-y-scroll p-[15px]`}
        >
          <div className="rounded p-[15px] shadow">
            <span className="font-normal leading-tight text-zinc-500">
              Stream Details
            </span>
            <div className="mt-[15px] flex items-center justify-between">
              <span className="text-sm font-bold leading-tight text-stone-600">
                Stream Name
              </span>
              <DefaultInputField
                label=""
                value={details?.name}
                setValue={setStreamName}
                disabled
                containerStyle={{ width: "280px" }}
              />
            </div>
            <div className="mt-[15px] flex items-center justify-between">
              <span className="text-sm font-bold leading-tight text-stone-600">
                Trigger
              </span>
              <DefaultInputField
                label=""
                value={_trigger}
                setValue={setTrigger}
                disabled
                containerStyle={{ width: "280px", textTransform: "capitalize" }}
              />
            </div>

            <div className="mt-[15px] flex items-center justify-between">
              <span className="text-sm font-bold leading-tight text-stone-600">
                Rate
              </span>

              <div className="flex items-end">
                <div className="flex items-center">
                  <DefaultInputField
                    value={String(details?.amount)}
                    setValue={setStreamName}
                    containerStyle={{ width: "70px" }}
                  />
                  <span className="mx-[10px] mt-[0px] text-sm font-bold text-stone-600">
                    %
                  </span>
                </div>
                <span className="w-[18px] font-body text-[32px] font-bold leading-tight text-gray-300">
                  /
                </span>
                <div className="item-center flex">
                  <DefaultInputField
                    label=""
                    value={streamName}
                    setValue={setStreamName}
                    containerStyle={{ width: "70px" }}
                  />
                  <span className="ml-[8px] mt-[10px] text-xs font-bold leading-tight text-stone-600">
                    Trigger Action
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[25px]">
            <div className="px-[20px]">
              <div className="flex items-center justify-between">
                <span className="font-normal leading-tight text-zinc-500">
                  Stream Earning
                </span>
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="">
                    <div className="flex items-center justify-center gap-2 rounded-[16px] border border-[#DEE5E7] px-3 py-1.5">
                      <span className="flex items-center text-[12px] font-bold leading-tight text-gray-600">
                        This week
                      </span>
                      <CaretDownFilled />
                    </div>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="flex max-h-[300px] flex-col py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => {}}
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                cursor: "pointer",
                              }}
                              className={`cursor-pointer px-[5px] font-normal ${
                                active ? "bg-gray-200" : ""
                              }`}
                            >
                              Last week
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={() => {}}
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                cursor: "pointer",
                              }}
                              className={`cursor-pointer px-[5px] font-normal ${
                                active ? "bg-gray-200" : ""
                              }`}
                            >
                              Last month
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="mt-[15px] flex items-center justify-between">
                <div className="flex items-center justify-end gap-2">
                  <USDCIcon />
                  <span className="text-xl font-bold leading-tight text-stone-600">
                    USD
                  </span>
                </div>
                <span className="text-[40px] font-bold leading-[72px] text-cyan-950">
                  {CurrencyFormatter.formatCurrency(details?.amount)}
                </span>
              </div>
              <Divider className="my-[5px]" />
              <div className="mt-[25px]">
                <span className="inline-flex h-5 items-end justify-start gap-1">
                  <span className="text-sm font-normal leading-tight text-[#596A7A]">
                    Next payout
                  </span>
                  <span className="text-base font-normal leading-tight text-green-800">
                    {parseFloat((details?.amount * 1.023).toString()).toFixed(
                      2,
                    )}{" "}
                    USDC
                  </span>
                  <span className="text-xs font-normal leading-tight text-green-500">
                    /
                  </span>
                  <span className="text-base font-normal leading-tight text-green-800">
                    00
                  </span>
                  <span className="text-sm font-normal leading-tight text-green-500">
                    %
                  </span>
                </span>
                <div className="mt-[15px] flex items-center justify-end">
                  <DefaultButtonOutline
                    textStyle={{ color: "#57584E" }}
                    label="Duplicate stream"
                    onClick={() => {}}
                    styles={{ height: "33px" }}
                    className="h-[33px] w-[150px] bg-[#F4F6F8]"
                  />
                  {/* <DefaultButtonOutline
                    textStyle={{ color: "#57584E" }}
                    label="Freeze stream"
                    onClick={() => {}}
                    styles={{ height: "33px" }}
                    className="h-[33px] bg-[#F4F6F8] w-[150px] mx-[30px]"
                  /> */}
                  <DefaultButton
                    label="Freeze stream"
                    onClick={() => {}}
                    styles={{ height: "33px" }}
                    className="ml-[30px] h-[33px] w-[150px] bg-[#F05051]"
                  />
                </div>
              </div>
              <Divider />
              <TransactionPerformance
                hideManage={true}
                title="Stream performance"
                subheader=""
                chartLabels={dates.reverse()}
                chartData={[
                  {
                    name: "Stream 1",
                    type: "line",
                    fill: "solid",
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function RevenueStreams() {
  const { globalModalState, updateGlobalModalState } = useModals();
  const [step, setStep] = useState(1);
  const [auth, showAuth] = useState(false);
  const [streamDetails, showStreamDetails] = useState(false);

  function prev() {
    if (step === 1) {
      return updateGlobalModalState({ revenueStreamsModal: false });
    }
    setStep(step - 1);
  }

  function next() {
    setStep(step + 1);
  }

  function authSuccess() {
    updateGlobalModalState({ revenueStreamsModal: false });
    showStreamDetails(true);
    setStep(1);
  }

  function renderStep() {
    if (step === 1) {
      return <RevenuStreamList prev={() => prev()} next={() => next()} />;
    }
    if (step === 2) {
      return (
        <CreateNewStream prev={() => prev()} showAuth={() => showAuth(true)} />
      );
    }
  }

  return (
    <>
      <Modal modal={globalModalState.revenueStreamsModal} disableBackdropClose>
        {renderStep()}
      </Modal>
      {/* <ViewRevenuStream modal={streamDetails} showModal={showStreamDetails} /> */}
      <Authenticator
        modal={auth}
        showModal={showAuth}
        onAuthSuccess={() => authSuccess()}
      />
    </>
  );
}
