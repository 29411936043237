import { Menu, Transition } from "@headlessui/react";
import { FiatCurrencyI, currencies } from "~/constants/currencies";

import { CaretDownFilled } from "~/assets/icons/CaretDownFilled";

import { Fragment } from "react";
import { safeArray } from "~/libs/data.helper";

export function WalletCurrencyDropdown(props: {
  options?: FiatCurrencyI[];
  currency: FiatCurrencyI;
  setCurrency: (value: FiatCurrencyI) => void;
  disabled?: boolean;
}) {
  const { currency, setCurrency, disabled = true } = props;
  const options = safeArray(props.options ?? currencies);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="" disabled={!disabled}>
        <span className="flex items-center">
          <img
            src={currency?.flagUrl}
            className="h-[25px] w-[28px]"
            alt={currency?.flagName}
          />
          <span className="mx-[6px] text-lg font-bold leading-tight text-stone-600">
            {currency?.code}
          </span>
          {disabled ? <CaretDownFilled w="12" h="10" /> : null}
        </span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-[90px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex max-h-[300px] flex-col py-1">
            {options.map((currency: FiatCurrencyI) => (
              <Menu.Item key={currency.code}>
                {({ active }) => (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <div
                    onClick={() => setCurrency(currency)}
                    className={`flex cursor-pointer items-center px-[10px] py-1  ${
                      active ? "bg-gray-200" : ""
                    }`}
                  >
                    <img
                      src={currency.flagUrl}
                      alt={currency?.flagName}
                      className="w-[32px]"
                    />
                    <span
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      className={"ml-[7px] cursor-pointer font-normal"}
                    >
                      {currency.code}
                    </span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
