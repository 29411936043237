export function MinusIconCircle() {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5155" cy="10.4761" r="10" fill="#002C3D" />
      <g clipPath="url(#clip0_4798_11143)">
        <path
          d="M6.453 10.4761H14.453"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4798_11143">
          <rect
            width="11"
            height="4"
            fill="white"
            transform="translate(4.953 8.47607)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
