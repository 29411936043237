import moment from "moment";
import TransactionPerformance from "~/components/Charts";
import {
  RevenueBalanceOverview,
  RevenueWalletLog,
  RevenueQuickActions,
} from "~/components/DashboardComponents/revenuewallet";
import { HistoricBalance } from "~/components/DashboardComponents/shared";
import { WalletLog } from "~/components/DashboardComponents/wallet-logs";
import { Divider } from "~/components/Divider";
import { displayMockUI } from "~/libs";

export function RevenueOverviewContent() {
  const dates = [];
  const today = moment();

  dates.push(today.format("MM/DD/YYYY"));
  Array(10)
    .fill("1")
    .map(() => dates.push(today.subtract(1, "M").format("MM/DD/YYYY")));

  return (
    <>
      <section className="flex flex-col gap-2 pb-4 pt-8">
        <RevenueBalanceOverview />
        <Divider />
        {/* <TransactionPerformance
          title="Revenue Performance"
          hideManage={false}
          subheader=""
          chartLabels={dates.reverse()}
          chartData={
            displayMockUI()
              ? [
                  {
                    name: "Stream 1",
                    type: "line",
                    fill: "solid",
                    data: [14, 23, 23, 35, 28, 19, 26, 34, 46, 54],
                  },
                  {
                    name: "Stream 2",
                    type: "area",
                    fill: "gradient",
                    data: [65, 44, 32, 24, 36, 8, 56, 4, 27, 18, 23],
                  },
                  {
                    name: "Stream 3",
                    type: "line",
                    fill: "solid",
                    data: [16, 24, 12, 34, 26, 38, 76, 24, 37, 28],
                  },
                  {
                    name: "Stream 4",
                    type: "line",
                    fill: "solid",
                    data: [41, 54, 24, 63, 14, 7, 8, 45, 40, 43, 56],
                  },
                ]
              : []
          }
        /> */}

        <RevenueQuickActions />
        <Divider />
        <RevenueWalletLog />
      </section>
    </>
  );
}
