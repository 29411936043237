import Lottie from "lottie-react";
import { XIcon } from "lucide-react";
import React, { Dispatch, SetStateAction } from "react";
import { createPortal } from "react-dom";
import ellipsisAnimation from "../../assets/animations/ellipsis.lottie.json";
import styles from "../../general/styles.module.css";
import { useLoaders } from "../Redux/state/loader/hooks";
import { CaptionMedium } from "../Typography";

/** @deprecated use Dialog instead **/
export default function Modal({
  modal,
  showModal,
  disableBackdropClose,
  children,
  renderHeader,
}: {
  modal: boolean;
  children: React.ReactNode;
  disableBackdropClose?: boolean;
  showModal?: Dispatch<SetStateAction<boolean>>;
  renderHeader?: React.JSX.Element;
}) {
  return (
    <>
      {modal ? (
        <>
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div
            onClick={() => (disableBackdropClose ? null : showModal(false))}
            style={{ zIndex: 1000 }}
            className="fixed inset-0 z-50 flex items-start justify-center overflow-x-hidden overflow-y-hidden bg-[rgba(0,0,0,.7)] outline-none focus:outline-none"
          >
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <main
              className={" mt-[4%] rounded-lg bg-[white]"}
              onClick={(e) => e.stopPropagation()}
            >
              {renderHeader && renderHeader}
              <div
                className={`${styles.custom_scrollbar} max-h-[80vh] max-w-[90vw] overflow-y-scroll rounded-lg`}
              >
                {children}
              </div>
            </main>
          </div>
          {/* <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 999 }} className="inset-0 z-40 bg-[rgba(0,0,0,.7)]"></div> */}
        </>
      ) : null}
    </>
  );
}

/** @deprecated use Dialog instead **/
export function DefaultModal({
  modal,
  showModal,
  disableBackdropClose,
  children,
}: {
  modal: boolean;
  children: React.ReactNode;
  disableBackdropClose?: boolean;
  showModal?: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      {modal ? (
        <>
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div
            onClick={() => (disableBackdropClose ? null : showModal(false))}
            style={{ zIndex: 1000 }}
            //auto
            className="z-100 fixed inset-0 flex items-center justify-center overflow-x-hidden overflow-y-hidden bg-[rgba(0,0,0,.7)] outline-none focus:outline-none"
          >
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <main onClick={(e) => e.stopPropagation()}>{children}</main>
          </div>
          {/* <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 999 }} className="inset-0 z-40 bg-[rgba(0,0,0,.7)]"></div> */}
        </>
      ) : null}
    </>
  );
}

export function EllipsisLoader() {
  const { isLoaderOpen, closeEllipsisLoader } = useLoaders();

  return (
    <DefaultModal modal={isLoaderOpen}>
      <FullscreenLoader />
    </DefaultModal>
  );
}

export function FullscreenLoader() {
  return createPortal(
    <div
      className="pointer-events-none fixed inset-0 flex items-center justify-center backdrop-blur-sm"
      style={{ backgroundColor: "rgba(256, 256, 256, .9)" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Lottie
          animationData={ellipsisAnimation}
          loop={true}
          style={{ height: "100px", width: "100px" }}
        />
        <CaptionMedium>Processing...</CaptionMedium>
      </div>
    </div>,
    document.body,
  );
}

export function DialogModalHeader({
  title,
  onBackArrowClick,
}: {
  title: string;
  onBackArrowClick: () => void;
}) {
  return (
    <div className="flex items-start justify-between">
      <span className={"text-lg font-bold"}>{title}</span>
      <button
        type={"button"}
        onClick={() => onBackArrowClick()}
        className="flex cursor-pointer items-center"
      >
        <XIcon />
      </button>
    </div>
  );
}
