import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { Tabs } from "~/@/components/ui/tabs";
import { useUserData } from "~/components/Redux/state/user/hooks";
import {
  SecondaryContent,
  SecondaryNav,
  SecondaryRoot,
  SecondaryTab,
} from "~/components/layouts/SecondaryNav";
import {
  MyActivities,
  PersonalPreferences,
  PlatformActivities,
} from "~/components/notification-page";

export const Route = createFileRoute("/settings/notifications")({
  component: Tabbed,
});

export function Tabbed() {
  const { associatedBusiness: hasBusiness } = useUserData();

  const tabs = React.useMemo(
    () => [
      { key: "preferences", label: "Preferences", isActive: true },
      {
        key: "activities",
        label: "Account Activities",
        isActive: Boolean(hasBusiness),
      },
      { key: "platform", label: "Platform Activities", isActive: true },
    ],
    [hasBusiness],
  );

  return (
    <Tabs defaultValue={tabs[0].key}>
      <SecondaryRoot>
        <SecondaryNav>
          <SecondaryTab tabs={tabs} />
        </SecondaryNav>

        <SecondaryContent value={"preferences"}>
          <PersonalPreferences />
        </SecondaryContent>

        <SecondaryContent value="activities">
          <MyActivities />
        </SecondaryContent>

        <SecondaryContent value="platform">
          <PlatformActivities />
        </SecondaryContent>
      </SecondaryRoot>
    </Tabs>
  );
}

export function Plain() {
  return (
    <>
      <PersonalPreferences />
      <MyActivities />
      <PlatformActivities />
    </>
  );
}
