import { GlobalModalStateI, _updateGlobalModalState } from ".";
import { useAppDispatch, useAppSelector } from "../hooks";

// hook to easily interact with products state
export const useModals = () => {
  const dispatch = useAppDispatch();

  const updateGlobalModalState = (payload: GlobalModalStateI) =>
    dispatch(_updateGlobalModalState(payload));
  const globalModalState = useAppSelector((state) => state.modals);

  return {
    globalModalState,
    updateGlobalModalState,
  };
};
