import React from "react";
import Layout from "~/containers/Withdrawal/Layout";
import StepSource from "./StepSource";
import SideNav from "./SideNav";
import StepDestination from "./StepDestination";
import StepAmount from "./StepAmount";
import StepAuthorize from "./StepAuthorize";
import StepReview from "./StepReview";
import StepSuccess from "./StepSuccess";
import { RecipientAccountTypes } from "~/libs/types.helper";

interface WithdrawDefaultCtx {
  withdrawState: {
    destination: RecipientAccountTypes;
    reason: string;
    withdarwAmount: {
      amount: number;
      fee: number;
      currency: string;
      recipientCurrency: string;
    };
  };
  updateState: (name: string, newState: unknown) => void;
  resetState: () => void;
}

export const WithdrawCtx = React.createContext({} as WithdrawDefaultCtx);

export default function Withdrawal() {
  const [withdrawState, setWithdrawState] = React.useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
  ]);

  const completeStep = (index: number) => {
    const newCompletedSteps = [...completedSteps];
    newCompletedSteps[index] = true;
    setCompletedSteps(newCompletedSteps);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      // case 0:
      //   return (
      //     <StepSource
      //       completeStep={() => completeStep(0)}
      //       setCurrentStep={setCurrentStep}
      //       currentStep={currentStep}
      //     />
      //   );
      case 0:
        return (
          <StepDestination
            completeStep={() => completeStep(0)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 1:
        return (
          <StepAmount
            completeStep={() => completeStep(1)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 2:
        return (
          <StepReview
            completeStep={() => completeStep(2)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 3:
        return (
          <StepAuthorize
            completeStep={() => completeStep(3)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 4:
        return (
          <StepSuccess
            completeStep={() => completeStep(4)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      default:
        return (
          <StepDestination
            completeStep={() => completeStep(0)}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
          // <StepSource
          //   completeStep={() => completeStep(0)}
          //   setCurrentStep={setCurrentStep}
          //   currentStep={currentStep}
          // />
        );
    }
  };

  const updateState = (name: string, newState: unknown) => {
    setWithdrawState((e) => ({ ...e, [name]: newState }));
  };
  const resetState = () => {
    setWithdrawState({});
  };

  const ctxValue = {
    withdrawState,
    updateState,
    resetState,
  };
  return (
    <Layout>
      <WithdrawCtx.Provider value={ctxValue as null}>
        <div className={"container mx-auto flex flex-col px-12 pt-10"}>
          <div className="flex">
            <div className="">
              <SideNav
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                completedSteps={completedSteps}
              />
            </div>
            <div className="w-full grow px-[20px] text-center">
              {renderStepContent()}
            </div>
          </div>
        </div>
      </WithdrawCtx.Provider>
    </Layout>
  );
}
