import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  isWelcomeModalViewed: boolean;
  isOnboardModalViewed: boolean;
}

const initialState: initialStateProps = {
  isWelcomeModalViewed: false,
  isOnboardModalViewed: false,
};

const persistentSlice = createSlice({
  name: "persistent",
  initialState,
  reducers: {
    _setWelcomeModalViewed(state) {
      state.isWelcomeModalViewed = true;
    },
    _setOnboardModalViewed(state) {
      state.isOnboardModalViewed = true;
    },
  },
});

export default persistentSlice.reducer;

export const { _setWelcomeModalViewed, _setOnboardModalViewed } =
  persistentSlice.actions;
