import { ArrowRight } from "~/assets/icons/ArrowRight";
import styles from "../../src/general/styles.module.css";
import { Divider } from "./Divider";

export function StreamListSkeleton() {
  return (
    <div className="mb-[15px] w-[100%] rounded-[10px] border-[1px] border-[grey] p-[20px]">
      <div className="flex items-center justify-between">
        <span className="text-xs font-normal leading-tight text-neutral-400">
          Stream Name
          <br /> <span className={`${styles.skeleton_loader} w-[140px]`} />
        </span>
        <ArrowRight color="grey" size="24" />
      </div>
      <Divider />
      <div className="flex items-center justify-between">
        <span className="text-xs font-normal leading-tight text-neutral-400">
          Trigger
          <br />{" "}
          <span className="mr-[10px] flex items-center text-sm font-bold leading-tight text-stone-600">
            <span className={`${styles.skeleton_loader} w-[120px]`} />
          </span>
        </span>
        <span className="text-[12px] font-normal leading-tight text-green-500">
          Rates
          <div className="text-[12px] font-bold leading-tight text-green-800">
            <span className={`${styles.skeleton_loader} w-[100px]`} />
          </div>
        </span>
      </div>
    </div>
  );
}
