import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { Textarea } from "~/@/components/ui/textarea";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import {
  OnboardingFormProps,
  form_two_schema,
} from "~/containers/Onboarding/helpers";
import { FormGrid } from "./FormGrid";
import { OnboardingHeading, OnboardingSubHeading } from "./Layout";

export function OnboardingTwo(props: OnboardingFormProps) {
  const { next, initialFormState } = props;

  const form = useForm<z.infer<typeof form_two_schema>>({
    defaultValues: initialFormState ?? {},
    resolver: zodResolver(form_two_schema),
    mode: "all",
  });

  const onSubmit = form.handleSubmit(function create(data_) {
    next(data_);
  });

  return (
    <form className={"flex flex-col items-center gap-y-8"} onSubmit={onSubmit}>
      <OnboardingHeading.Slot>
        Let’s verify your business
      </OnboardingHeading.Slot>
      <OnboardingSubHeading.Slot>
        This information will be used to verify your business
      </OnboardingSubHeading.Slot>

      <Form {...form}>
        <FormGrid className="grid gap-4 gap-x-20" style={{ "--rows": 3 }}>
          <FormField
            control={form.control}
            name={"website"}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Website/Social media</FormLabel>
                  <FormControl>
                    <Input placeholder={"https://"} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name={"reg_no"}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Business registration number *</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"Enter Business Registration Number"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name={"industry"}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Industry *</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={"Select Industry"} />
                      </SelectTrigger>

                      <SelectContent>
                        {industries.map((industry) => {
                          return (
                            <SelectItem
                              value={industry.value}
                              key={industry.value}
                            >
                              {industry.label}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className={"row-span-3"}>
            <FormField
              control={form.control}
              name={"description"}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Product description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
        </FormGrid>

        {/* TODO: Enable when needed */}
        {/* <div className={"space-y-8"}>
          <div className={"border-b pb-[1rem] pt-[1rem]"}>
            <h2 className={"font-body text-base font-normal text-gray-800"}>
              Please upload the valid documents
            </h2>
          </div>

          <div className={"grid grid-cols-2 gap-8"}>
            <FormField
              name={"cert_of_inc"}
              control={form.control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <DocumentPicker
                        label="Certificate of Incorporation"
                        // @ts-expect-error
                        value={field.value}
                        onFileChange={(val) => {
                          form.setValue(field.name, val);
                        }}
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />

            <FormField
              name={"memorandum"}
              control={form.control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <DocumentPicker
                        label="Memorandum & Article of Association"
                        name={field.name}
                        // @ts-expect-error
                        value={field.value}
                        onFileChange={(val) => {
                          form.setValue(field.name, val);
                        }}
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />

            <FormField
              name={"address_doc"}
              control={form.control}
              render={({ field }) => {
                return (
                  <FormItem className={"text-start"}>
                    <FormControl>
                      <DocumentPicker
                        label={
                          <div>
                            Any Means of Address Verification
                            <span
                              className={"text-muted-foreground font-normal"}
                            >
                              &nbsp;—&nbsp;Utility bill, Bank statement
                            </span>
                          </div>
                        }
                        // @ts-expect-error
                        value={field.value}
                        onFileChange={(val) => {
                          form.setValue(field.name, val);
                        }}
                      />
                    </FormControl>

                    <p className="text-xs text-gray-300">
                      Document must be dated no more than 3 months prior
                    </p>
                  </FormItem>
                );
              }}
            />
          </div>
        </div> */}

        <Button
          type={"submit"}
          size={"lg"}
          disabled={!form.formState.isValid}
          className={"mt-12 w-full md:w-1/2"}
        >
          <LoadingButtonContent loading={props.isLoading}>
            Continue
          </LoadingButtonContent>
        </Button>
      </Form>
    </form>
  );
}

const industries = [
  { value: "null", label: "Select option" },
  { value: "Agriculture and Farming", label: "Agriculture and Farming" },
  { value: "Automotive", label: "Automotive" },
  { value: "Banking and Finance", label: "Banking and Finance" },
  { value: "Construction", label: "Construction" },
  { value: "Consulting", label: "Consulting" },
  { value: "Education", label: "Education" },
  { value: "Energy and Utilities", label: "Energy and Utilities" },
  { value: "Entertainment and Media", label: "Entertainment and Media" },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Health and Wellness", label: "Health and Wellness" },
  { value: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { value: "Information Technology", label: "Information Technology" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketing and Advertising", label: "Marketing and Advertising" },
  {
    value: "Nonprofit and Social Services",
    label: "Nonprofit and Social Services",
  },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Retail", label: "Retail" },
  { value: "Telecommunications", label: "Telecommunications" },
  {
    value: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
];
