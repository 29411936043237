import React, { ComponentProps } from "react";
import { Search, ChevronRight } from "lucide-react";
import { DefaultInputField } from "~/components/Inputs";
import { ClickableCard } from "~/components/ClickableCard";
import { cn } from "~/@/lib/utils";
import { CardSeparator } from "~/components/layouts/Card";
import { getFlagFromRegionCode } from "~/libs/currency.helpers";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { DestinationCtx } from ".";
import { useCountries } from "~/hooks";
import { CountryI } from "~/general/interfaces";
import { useGetPlatformMetadata } from "~/api/codegen/walletosComponents";
import {
  useCreateApplicationTokenAccount,
  useGetLinkedAccounts,
  useGetTokens,
} from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { ApplicationTokenTypes } from "~/libs/types.helper";
import { notify } from "~/components/Toast";
import { useWallets, WalletHolderImpl } from "~/hooks/use-wallet";

export type FiatCurrency = {
  decimal: number;
  code: string;
  country: string;
  symbol: string;
};

const Countries = ({ currentMove, setCurrentMove }) => {
  const { updateState } = useContext(DestinationCtx);
  const businessId = useGetBusinessId();
  const [searchVal, setSearchVal] = React.useState("");
  const { data: countries } = useCountries();

  // const supportedFiatCurrencies = useGetPlatformMetadata(
  //   {},
  //   {
  //     select: React.useCallback(
  //       (data) => safeArray(data?.supportedFiatCurrencies) as FiatCurrency[],
  //       []
  //     ),
  //   }
  // );

  const { data: wallet } = useWallets();

  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const supportedCountires = React.useMemo(() => {
    return (
      countries?.filter((country) =>
        wallets?.some((currency) => currency?.currency === country?.currency),
      ) ?? []
    );
  }, [wallets, countries]);

  const filteredCountries = React.useMemo(() => {
    if (searchVal === "") {
      return supportedCountires;
    }
    return supportedCountires?.filter((item: CountryI) =>
      item?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()),
    );
  }, [searchVal, supportedCountires]);

  const {
    data: appTokens,
    refetch,
    isLoading,
  } = useGetTokens(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        type: "ACCOUNT_WIDGET",
        page: 1,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const mutation = useCreateApplicationTokenAccount();

  const filteredTokens = appTokens?.data?.filter(
    (token: ApplicationTokenTypes) =>
      token?.application?.externalId === businessId,
  );

  const createAppTokens = async () => {
    try {
      await Promise.all(
        wallets?.map((currency) => {
          return mutation.mutateAsync({
            pathParams: { businessId },
            body: {
              externalId: businessId,
              expiredAt: "2044-08-12T13:41:35.162Z",
              tokenType: "ACCOUNT_WIDGET",
              currency: currency.currency,
            },
          });
        }),
      );
      await refetch();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (filteredTokens?.length === 0) {
      createAppTokens();
    }
  }, [isLoading]);

  return (
    <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10">
      <div className="mb-5 px-2 text-left">
        <h2 className="py-5 text-xl font-normal text-muted-foreground">
          Enter Destination Country
        </h2>
        <div className="flex items-center">
          <DefaultInputField
            placeholder="Search for country"
            value={searchVal}
            setValue={setSearchVal}
            icon={<Search size={"1rem"} className="text-gray-300" />}
            rtl={false}
            style={{ height: "50px" }}
          />
        </div>
      </div>
      <CardSeparator className="mx-2 my-2 mb-5" />
      <ScrollArea className={"flex max-h-[500px] w-full flex-col px-2"}>
        {filteredCountries?.map((country) => {
          const flag = getFlagFromRegionCode(country?.iso2);
          return (
            <ClickableCard
              key={country?.name}
              className={
                "mb-3 flex w-full items-center justify-between px-4 py-4"
              }
              onClick={() => {
                const isFound = filteredTokens?.some(
                  (token: ApplicationTokenTypes) =>
                    token.application.metadata.currency === country.currency,
                );

                if (!isFound) {
                  notify("error", "Country currency not supported");
                  return;
                }
                updateState("country", country);
                setCurrentMove(currentMove + 1);
              }}
            >
              <div className="flex items-center">
                <div className="mr-5 flex">
                  <FlatImage alt={country.name} src={flag ?? ""} />
                </div>
                <div className="flex flex-col text-left">
                  <span className="text-[15px] font-semibold text-gray-600">
                    {country.name}
                  </span>
                </div>
              </div>

              <div className="text-[32px] font-semibold text-[#002C3D]">
                <ChevronRight size={"1.5rem"} className="text-gray-400" />
              </div>
            </ClickableCard>
          );
        })}
      </ScrollArea>
    </div>
  );
};

export default React.memo(Countries);

function FlatImage(props: ComponentProps<"img">) {
  return (
    <img
      {...props}
      src={props.src}
      className={cn("aspect-[10/5] w-[28px]", props.className)}
      alt={props.alt ?? "Flag image"}
    />
  );
}
