import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";

export function DefaultTabs<
  T extends {
    label: string;
    /** @deprecated rename to `disabled`. **/
    isActive?: boolean;
    disabled?: boolean;
  },
>({
  tabs,
  activeTab,
  setActiveTab,
}: {
  tabs: T[];
  activeTab: T;
  setActiveTab: (tab: T) => void;
}) {
  return (
    <div className="flex h-[100%] items-center gap-6">
      {tabs.map((tab) => {
        const isActive = tab.label === activeTab.label;
        return (
          <TabButton
            key={tab.label}
            data-state={isActive ? "active" : "inactive"}
            onClick={() => (!isActive ? setActiveTab(tab) : null)}
          >
            {tab.label}
          </TabButton>
        );
      })}
    </div>
  );
}

interface TabButtonProps extends ComponentProps<"button"> {}

export const TabButton = React.forwardRef<HTMLButtonElement, TabButtonProps>(
  function TabButton(props, ref) {
    const { children, className, ...PROPS } = props;

    return (
      <button
        ref={ref}
        type={"button"}
        {...PROPS}
        className={cn(
          className,
          "flex cursor-pointer items-center justify-center rounded-none border-b-[2px] border-transparent px-0 py-2 font-body text-gray-700 !shadow-none",
          "data-[state=active]:border-[#0094FF]",
          "disabled:cursor-not-allowed data-[state=inactive]:border-b-2 data-[state=inactive]:border-transparent",
          "text-sm font-normal text-gray-700 data-[state=active]:text-[#0094FF]",
        )}
      >
        {children}
      </button>
    );
  },
);
