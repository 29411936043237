import { default as numeral } from "numbro";
import { curry, memoizeWith, toLower, toUpper } from "ramda";
import { FiatCurrencyI, SymbolCurrencyMap } from "~/constants/currencies";

export const formatNumber = (
  value: string | number,
  config?: { decimal: true },
) => {
  const val = Object.is(NaN, +value) ? value : value.toString();

  if (config?.decimal) {
    return numeral(val).format({
      thousandSeparated: true,
      mantissa: 2,
    });
  }

  return numeral(val).format({
    thousandSeparated: true,
    mantissa: 2,
    // optionalMantissa: true,
  });
};

export const InputFormatNumber = (input: string) => {
  // Remove non-digit characters
  const numericValue = input.replace(/[^\d]/g, "");

  // If input has fewer than 3 digits, return it as is
  if (numericValue.length < 3) {
    return numericValue;
  }

  // Split the input into integer and decimal parts
  let integerPart = numericValue.slice(0, -2);
  const decimalPart = numericValue.slice(-2);

  // Format integer part with commas
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Join the formatted parts
  const formattedNumber = `${integerPart}.${decimalPart}`;

  return formattedNumber;
};

export const abbrevCurrencyValue =
  (currency_code: string) =>
  (num: number): string =>
    addCurrencySymbol(currency_code, abbrevLongNumber(num));

export const simpleCurrencyFormatter = memoizeWith(
  (selected_currency: string) => selected_currency,
  function simpleCurrencyFormatter(selected_currency: string) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: selected_currency,
      currencySign: "accounting",
      currencyDisplay: "narrowSymbol",
    });
  },
);
export const addCurrencySymbol = curry(function addCurrencySymbol(
  currency_code: string,
  formatted_number: string,
) {
  if (!formatted_number) return formatted_number;

  const symbol = simpleCurrencyFormatter(currency_code)
    .formatToParts(0)
    .find((e) => e.type === "currency")?.value;

  if (!symbol) return formatted_number;

  return [symbol, formatted_number].join("");
});

export function abbrevLongNumber(value: number) {
  const string =
    String(value).length >= 7
      ? numeral(value).format({
          average: true,
          mantissa: 2,
          trimMantissa: true,
        })
      : formatNumber(value);
  // const string = formatNumber(value);

  return toUpper(string);
}

export function removeCommas(inputString: string) {
  // Use the replace method with a regular expression to remove all commas
  return inputString.replace(/,/g, "");
}

const EmptyFiatCurrency: FiatCurrencyI = {
  decimal: 0,
  symbol: "--",
  // @ts-expect-error
  code: "--",
  name: "--",
  // @ts-expect-error
  label: "--",
  flagName: "--",
  flagUrl: "--",
};

export function getSafeFiatCurrency(symbol: string): FiatCurrencyI {
  const value = getFiatCurrency(symbol);
  return value ? value : EmptyFiatCurrency;
}

export function getFiatCurrency(symbol: string): FiatCurrencyI | null {
  return SymbolCurrencyMap[symbol] ?? null;
}

export function getFlagFromRegionCode(region: string) {
  const region_code = toLower(safeStr(region));
  return `https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/${region_code}.svg`;
}

/**
 * @param currencyRates eg. { NGN: 1541.25, USD: 1 }
 * @param fromCurrencySymbol
 * @param toCurrencySymbol
 * @param amount
 * */
export function convertCurrencyFrom(
  currencyRates: Record<string, number>,
  { from: fromCurrencySymbol, to: toCurrencySymbol, fromAmount: amount },
) {
  const fromValue = currencyRates[fromCurrencySymbol];
  const toValue = currencyRates[toCurrencySymbol];

  const rate = toValue / fromValue;
  return rate * amount;
}
