import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import * as Address from "~/components/Inputs/CountryAddressFields";
import { addressSchema } from "~/libs/validators";

export function BusinessFields() {
  const methods = useFormContext<z.infer<typeof addressSchema>>();

  return (
    <>
      <FormField
        name={"address"}
        control={methods.control}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Residential Address #1 *</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Address line 1" />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      {/* TODO: Re-enable when supported */}
      {/*<FormField*/}
      {/*  name={"address2"}*/}
      {/*  control={methods.control}*/}
      {/*  render={({ field }) => {*/}
      {/*    return (*/}
      {/*      <FormItem>*/}
      {/*        <FormLabel>Residential address #2</FormLabel>*/}
      {/*        <FormControl>*/}
      {/*          <Input {...field} placeholder="Address line 2" />*/}
      {/*        </FormControl>*/}
      {/*        <FormMessage />*/}
      {/*      </FormItem>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}

      <Address.Root defaultValue={methods.watch("selectedCountry")}>
        <FormField
          name={"selectedCountry"}
          control={methods.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Country</FormLabel>
                <FormControl>
                  <Address.CountryField {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription>Please use your HQ Location</FormDescription>
              </FormItem>
            );
          }}
        />

        <FormField
          name={"state"}
          control={methods.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>State</FormLabel>
                <FormControl>
                  <Address.StateField {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <FormField
          name={"city"}
          control={methods.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter city" />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </Address.Root>

      <FormField
        name={"zipCode"}
        control={methods.control}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Postal code</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Zip code" />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </>
  );
}

export function OwnerAddressFields() {
  const methods = useFormContext<z.infer<typeof addressSchema>>();

  return (
    <>
      <FormField
        name={"address"}
        control={methods.control}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Residential Address #1 *</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Address line 1" />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      {/* TODO: Re-enable when supported */}
      {/* <FormField
        name={"address2"}
        control={methods.control}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Residential address #2</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Address line 2" />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      /> */}

      <Address.Root>
        <FormField
          name={"selectedCountry"}
          control={methods.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Country</FormLabel>
                <FormControl>
                  <Address.CountryField {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <FormField
          name={"state"}
          control={methods.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>State</FormLabel>
                <FormControl>
                  <Address.StateField {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <FormField
          name={"city"}
          control={methods.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter city" />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </Address.Root>

      <FormField
        name={"zipCode"}
        control={methods.control}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Postal code</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Zip code" />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </>
  );
}
