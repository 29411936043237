import { cn } from "~/@/lib/utils";

export function ClickableCard(props: React.ComponentProps<"button">) {
  const { children, className, ...PROPS } = props;

  return (
    <button
      {...PROPS}
      type={"button"}
      className={cn(
        "relative flex cursor-pointer items-center rounded-lg border-[1px] px-[20px] py-6 hover:border-[#0094FF] disabled:pointer-events-none disabled:opacity-50",
        className,
      )}
    >
      {children}
    </button>
  );
}
