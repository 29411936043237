export function EyeCloseIcon({
  size,
  color,
}: {
  size?: string;
  color?: string;
}) {
  return (
    <svg
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3749_2448)">
        <path
          d="M11.9877 5.54334C13.8247 5.53527 15.6265 6.04715 17.1849 7.01983C18.7432 7.99252 19.9945 9.38629 20.7942 11.0401C20.2157 12.2338 19.3959 13.2944 18.3866 14.1551L19.8208 15.5634C21.2279 14.3245 22.3182 12.7668 23.0007 11.0206C21.8934 8.22422 19.7631 5.95421 17.0426 4.67169C14.322 3.38918 11.2153 3.19036 8.35352 4.11561L10.0019 5.76399C10.6549 5.62472 11.3201 5.5508 11.9877 5.54334Z"
          fill={color || "#002C3D"}
        />
        <path
          d="M10.9162 6.68555L12.9864 8.74926L14.2844 10.0472L16.3481 12.1174C16.5236 11.4546 16.5432 10.7601 16.4053 10.0884C16.2674 9.41672 15.9757 8.78612 15.5532 8.24606C15.1307 7.706 14.5889 7.27116 13.9701 6.97564C13.3514 6.68012 12.6726 6.53196 11.987 6.54277C11.6254 6.54427 11.2656 6.59226 10.9162 6.68555ZM2.0059 3.42124L4.68614 6.09499C3.03658 7.38335 1.76349 9.09127 1 11.0401C2.14469 13.954 4.39996 16.2938 7.26972 17.5449C10.1395 18.796 13.3887 18.8559 16.3027 17.7115L19.7162 21.1251L21.118 19.7168L3.41416 2L2.0059 3.42124ZM6.10088 7.51622L7.8531 9.26195C7.61031 9.81403 7.48648 10.4111 7.48967 11.0142C7.48778 11.7528 7.66915 12.4803 8.01755 13.1317C8.36595 13.783 8.87049 14.3376 9.48596 14.746C10.1014 15.1544 10.8086 15.4037 11.5441 15.4716C12.2796 15.5395 13.0205 15.4239 13.7003 15.1351L13.7392 15.174L14.7192 16.154C13.8251 16.4015 12.9018 16.5281 11.974 16.5304C10.1398 16.5332 8.34183 16.0198 6.78564 15.0489C5.22945 14.078 3.97788 12.6888 3.17404 11.0401C3.86044 9.65262 4.86294 8.44562 6.10088 7.51622Z"
          fill={color || "#002C3D"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3749_2448">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
