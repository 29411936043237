import { cva } from "class-variance-authority";
import { ArrowLeft } from "lucide-react";
import React, { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";

const cardRootVariants = cva(
  "w-full rounded-lg border border-[#DEE5E7] bg-[white] mx-auto",
  {
    variants: {
      size: {
        full: "w-full",
        lg: "max-w-[613px]",
        half: "max-w-[35rem]",
      },
    },
    defaultVariants: {
      size: "full",
    },
  },
);

export const CardRoot = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div"> & { size?: "full" | "lg" | "half" }
>(function Card(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    // @ts-expect-error
    <div ref={ref} {...PROPS} className={cardRootVariants(props)}>
      {children}
    </div>
  );
});

export const CardBody = React.forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function CardBody(props, ref) {
    const { children, className, ...PROPS } = props;

    return (
      <div ref={ref} className={cn("px-6 py-12", className)} {...PROPS}>
        {children}
      </div>
    );
  },
);

export const CardFooter = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function Footer(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div
      ref={ref}
      {...PROPS}
      className={cn(
        "flex w-full items-center justify-end border-t border-[#DEE5E7] px-6 py-4",
        className,
      )}
    >
      {children}
    </div>
  );
});

export const CardHeader = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function CardHeader(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div
      ref={ref}
      className={cn("font-heading px-6 pb-4 pt-6", className)}
      {...PROPS}
    >
      {children}
    </div>
  );
});

export const CardTitle = React.forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>(function CardTitle(props, ref) {
  const { children, className, ...PROPS } = props;

  return (
    <div
      ref={ref}
      {...PROPS}
      className={cn("font-bold text-gray-800", className)}
    >
      {children}
    </div>
  );
});

export const CardSeparator = (props: { className?: string }) => {
  return (
    <div
      className={cn(
        "h-0 self-stretch border-t-[.5px] bg-[#EFF2F3]",
        props.className,
      )}
    />
  );
};

export function CardBackButton(props: React.ComponentProps<"button">) {
  const { className, ...PROPS } = props;

  return (
    <button
      {...PROPS}
      type={"button"}
      className={cn("flex cursor-pointer items-center", props.className)}
    >
      <ArrowLeft size={"1.2rem"} />
    </button>
  );
}
