export function DashboardUserIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4910_611"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <path
          d="M1 9C1 11.1217 1.84285 13.1566 3.34315 14.6569C4.84344 16.1571 6.87827 17 9 17C11.1217 17 13.1566 16.1571 14.6569 14.6569C16.1571 13.1566 17 11.1217 17 9C17 6.87827 16.1571 4.84344 14.6569 3.34315C13.1566 1.84285 11.1217 1 9 1C6.87827 1 4.84344 1.84285 3.34315 3.34315C1.84285 4.84344 1 6.87827 1 9Z"
          fill="#D9D9D9"
          stroke="#919191"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_4910_611)">
        <path
          d="M6 6.5C6 7.42826 6.31607 8.3185 6.87868 8.97487C7.44129 9.63125 8.20435 10 9 10C9.79565 10 10.5587 9.63125 11.1213 8.97487C11.6839 8.3185 12 7.42826 12 6.5C12 5.57174 11.6839 4.6815 11.1213 4.02513C10.5587 3.36875 9.79565 3 9 3C8.20435 3 7.44129 3.36875 6.87868 4.02513C6.31607 4.6815 6 5.57174 6 6.5Z"
          fill="#002C3D"
        />
        <path
          d="M2 16.5C2 18.2239 2.7375 19.8772 4.05025 21.0962C5.36301 22.3152 7.14348 23 9 23C10.8565 23 12.637 22.3152 13.9497 21.0962C15.2625 19.8772 16 18.2239 16 16.5C16 14.7761 15.2625 13.1228 13.9497 11.9038C12.637 10.6848 10.8565 10 9 10C7.14348 10 5.36301 10.6848 4.05025 11.9038C2.7375 13.1228 2 14.7761 2 16.5Z"
          fill="#002C3D"
        />
        <path
          d="M0.75 9C0.75 11.188 1.61919 13.2865 3.16637 14.8336C4.71354 16.3808 6.81196 17.25 9 17.25C11.188 17.25 13.2865 16.3808 14.8336 14.8336C16.3808 13.2865 17.25 11.188 17.25 9C17.25 6.81196 16.3808 4.71354 14.8336 3.16637C13.2865 1.61919 11.188 0.75 9 0.75C6.81196 0.75 4.71354 1.61919 3.16637 3.16637C1.61919 4.71354 0.75 6.81196 0.75 9Z"
          stroke="#7F8C95"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
