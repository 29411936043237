import { useSyncExternalStore } from "react";
import { FiatCurrencyI, currencies } from "~/constants/currencies";

const subs = new Set<() => void>();
function emitChanges() {
  for (const fn of subs) {
    fn();
  }
}

let currency = currencies[0];

const store = {
  subscribe(callback: () => void) {
    subs.add(callback);
    return () => {
      subs.delete(callback);
    };
  },
};

export function getSnapshot() {
  return currency;
}

export function setCurrency(val: FiatCurrencyI) {
  currency = val;
  emitChanges();
}

export const useSharedCurrency = () => {
  return useSyncExternalStore(store.subscribe, getSnapshot);
};
