import React, { CSSProperties } from "react";
import { MoonLoader } from "react-spinners";

export function DefaultButton({
  onClick,
  disabled,
  styles,
  label,
  rtl,
  icon,
  className,
  textClassName,
  loading,
  textStyle,
}: {
  onClick: () => void;
  label: string;
  rtl?: boolean;
  styles?: CSSProperties;
  textClassName?: string;
  icon?: React.JSX.Element;
  className?: string;
  textStyle?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
}) {
  const hasIcon = !!icon;
  const isBtnDisabled = disabled || loading;
  return (
    <button
      type={"button"}
      onClick={() => onClick()}
      disabled={isBtnDisabled}
      className={`inline-flex h-[48px] w-[100%] items-center justify-center gap-2 rounded bg-[#008EF4] px-4 py-1 ${className}`}
      style={{
        ...styles,
        cursor: isBtnDisabled ? "not-allowed" : "pointer",
        opacity: isBtnDisabled ? 0.5 : 1,
      }}
    >
      {loading ? (
        <div className="flex items-center">
          <MoonLoader size={20} />
          <span className="ml-[6px] text-center text-sm font-bold text-white">
            Loading ..
          </span>
        </div>
      ) : (
        <>
          {hasIcon && !rtl && icon}
          <span
            style={textStyle}
            className={`${textClassName} text-center text-sm font-bold text-white`}
          >
            {label}
          </span>
          {hasIcon && rtl && icon}
        </>
      )}
    </button>
  );
}

export function DefaultButtonOutline({
  onClick,
  styles,
  label,
  rtl,
  icon,
  className,
  textStyle,
  textClassName,
  disabled,
}: {
  onClick: () => void;
  label: string;
  rtl?: boolean;
  textStyle?: CSSProperties;
  styles?: CSSProperties;
  icon?: React.JSX.Element;
  textClassName?: string;
  className?: string;
  disabled?: boolean;
}) {
  const hasIcon = !!icon;
  const isBtnDisabled = disabled;

  return (
    <button
      type={"button"}
      onClick={() => onClick()}
      disabled={isBtnDisabled}
      className={`inline-flex h-[48px] w-[100%] items-center justify-center gap-2 rounded border border-zinc-200 bg-white px-4 py-1 ${className}`}
      style={{
        ...styles,
        cursor: isBtnDisabled ? "not-allowed" : "pointer",
        opacity: isBtnDisabled ? 0.5 : 1,
      }}
    >
      <>
        {hasIcon && !rtl && icon}
        <span
          style={textStyle}
          className={`text-center text-sm font-bold ${textClassName}`}
        >
          {label}
        </span>
        {hasIcon && rtl && icon}
      </>
    </button>
  );
}
