import { getFlagFromRegionCode } from "~/libs/currency.helpers";

export interface FiatCurrencyI {
  id?: string;
  symbol: string;
  // @deprecated Use data.code instead of data.label
  label: "NGN" | "USD";
  flagUrl: string;
  code: "NGN" | "USD";
  name: string;
  flagName?: string;
  decimal: number;
  balance?: number;
}

export interface CryptoAssetI {
  code: "ETH";
  name: string;
  logoUrl: string;
}

export const SymbolCurrencyMap = {
  USD: <FiatCurrencyI>{
    symbol: "$",
    label: "USD",
    flagUrl: getFlagFromRegionCode("US"),
    code: "USD",
    name: "United States Dollar",
    flagName: "usd-flag",
  },
  NGN: <FiatCurrencyI>{
    symbol: "₦",
    label: "NGN",
    flagUrl: getFlagFromRegionCode("NG"),
    code: "NGN",
    name: "Nigerian Naira",
    flagName: "ngn-flag",
  },
};

export const currencies: FiatCurrencyI[] = [
  SymbolCurrencyMap.USD,
  SymbolCurrencyMap.NGN,
];

export const cryptoAssets: CryptoAssetI[] = [
  {
    logoUrl:
      "https://res.cloudinary.com/dgm7hpcmb/image/upload/v1703954381/eth-image_dtnszd.png",
    code: "ETH",
    name: "Ethereum",
  },
];
