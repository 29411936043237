export function EllipsisVertical() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 10.8335C10.4602 10.8335 10.8333 10.4604 10.8333 10.0002C10.8333 9.53992 10.4602 9.16683 9.99999 9.16683C9.53975 9.16683 9.16666 9.53992 9.16666 10.0002C9.16666 10.4604 9.53975 10.8335 9.99999 10.8335Z"
        stroke="#4F5056"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 5.00016C10.4602 5.00016 10.8333 4.62707 10.8333 4.16683C10.8333 3.70659 10.4602 3.3335 9.99999 3.3335C9.53975 3.3335 9.16666 3.70659 9.16666 4.16683C9.16666 4.62707 9.53975 5.00016 9.99999 5.00016Z"
        stroke="#4F5056"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 16.6668C10.4602 16.6668 10.8333 16.2937 10.8333 15.8335C10.8333 15.3733 10.4602 15.0002 9.99999 15.0002C9.53975 15.0002 9.16666 15.3733 9.16666 15.8335C9.16666 16.2937 9.53975 16.6668 9.99999 16.6668Z"
        stroke="#4F5056"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
