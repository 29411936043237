import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "@tanstack/react-router";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/@/components/ui/form";
import {
  useAcceptInvite,
  useChangeTemporaryPassword,
} from "~/api/codegen/walletosComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { OnboardingBackButton } from "~/components/Buttons/back-buttons";
import { PasswordInput } from "~/components/Inputs/password";
import { PasswordValidator } from "~/components/Inputs/password-validator";
import { useVerifyCredentials } from "~/components/Modal/VerifyAccountModal";
import { notify, notifyApiError } from "~/components/Toast";
import { Header4Medium, TitleRegular } from "~/components/Typography";
import { useAuthSession } from "~/hooks/use-auth";
import { password_validator } from "~/libs/validators";
import UserProgress from "../../components/UserProgress";

const schema = z
  .object({
    password: password_validator,
    confirm_password: password_validator,
  })
  .superRefine((val, ctx) => {
    if (val.password === val.confirm_password) {
      return z.NEVER;
    }

    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Passwords don't match",
    });
  });

export default function Password({
  prev,
  next,
}: {
  prev: () => void;
  next: () => void;
}) {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof schema>>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const update_password_req = useChangeTemporaryPassword();
  const accept_req = useAcceptInvite();

  const { sessionId: session } = useParams({ strict: false });

  const [{ email, pass }] = useVerifyCredentials();
  const [, setAuthentication] = useAuthSession();

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await update_password_req.mutateAsync({
        body: { email, temporaryPassword: pass, password: data.password },
      });
      setAuthentication(res.authentication);
      const inviteRes = await accept_req.mutateAsync({
        headers: { Authorization: `Bearer ${res.authentication.accessToken}` },
        body: { session },
      });
      notify(inviteRes.message, "success");
      next();
      //navigate({ to: "/dashboard" });
    } catch (err) {
      notifyApiError(err);
    }
  });

  const isLoading = accept_req.isPending || update_password_req.isPending;

  return (
    <div className="flex w-full max-w-[430px] flex-col">
      <div className="self-center">
        <UserProgress stages={3} progress={2} />
        <OnboardingBackButton onClick={() => prev()} />
      </div>

      <div className="mt-[40px] text-center">
        <Header4Medium>Set a Password</Header4Medium>
        <TitleRegular>Choose a secure password for your account</TitleRegular>
      </div>

      <Form {...form}>
        <form className="mt-[20px]" onSubmit={onSubmit}>
          <FormField
            name="password"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <PasswordValidator password={field.value} />
                </FormItem>
              );
            }}
          />

          <FormField
            name="confirm_password"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Re-enter password</FormLabel>
                  <FormControl>
                    <PasswordInput {...field} hideToggle />
                  </FormControl>
                  {"password" in form.formState.errors ? null : field.value ===
                    form.watch("password") ? null : (
                    <p className="text-sm font-medium text-destructive">
                      Passwords don't match
                    </p>
                  )}
                </FormItem>
              );
            }}
          />

          <Button
            size={"lg"}
            className="mt-12 w-full"
            disabled={isLoading || !form.formState.isValid}
          >
            <LoadingButtonContent loading={isLoading}>
              Continue
            </LoadingButtonContent>
          </Button>
        </form>
      </Form>
    </div>
  );
}
