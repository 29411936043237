import { BusinessAPICredentials, BusinessMetadata } from "~/general/interfaces";
import {
  UserAccountI,
  _setBusinessDetails,
  _setCredentials,
  _setUserDetails,
} from ".";
import { useAppDispatch, useAppSelector } from "../hooks";

// hook to easily interact with products state
/** @deprecated **/
export const useUserData = () => {
  const dispatch = useAppDispatch();

  const setUserDetails = (data: UserAccountI) =>
    dispatch(_setUserDetails(data));

  /** @deprecated **/
  const setBusinessDetails = (data: BusinessMetadata) =>
    dispatch(_setBusinessDetails(data));

  /** @deprecated **/
  const setCredentials = (data: BusinessAPICredentials) =>
    dispatch(_setCredentials(data));

  const userDetails = useAppSelector((state) => state.user.userDetails);
  const businessDetails = useAppSelector((state) => state.user.businessDetails);
  const credentials = useAppSelector((state) => state.user.credentials);

  const _returnAssociatedBusiness = () => {
    if (
      userDetails?.associatedBusinesses &&
      userDetails?.associatedBusinesses.length > 0
    )
      return userDetails.associatedBusinesses[0];
    return null;
  };

  return {
    userDetails,
    setUserDetails,
    setBusinessDetails,
    setCredentials,
    businessDetails,
    credentials,
    associatedBusiness: _returnAssociatedBusiness(), // remove this
  };
};

export function getRoleInfo(user_details: UserAccountI) {
  return user_details?.associatedBusinesses?.[0]?.assignedRole?.role ?? "--";
}
