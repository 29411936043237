import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from ".";
import styles from "../../general/styles.module.css";
import { useModals } from "../Redux/state/modals/hooks";

import { Checkbox } from "@mui/material";
import moment from "moment";
import { ArrowLeft } from "~/assets/icons/ArrowLeft";
import { ArrowRight } from "~/assets/icons/ArrowRight";
import { ArrowTopRight } from "~/assets/icons/ArrowTopRight";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { GridIcon } from "~/assets/icons/GridIcon";
import { PlusIcon } from "~/assets/icons/PlusIcon";
import { UserRoleIcon } from "~/assets/icons/UserRoleIcon";
import { Recipient } from "~/general/interfaces";
import { displayMockUI } from "~/libs";
import CurrencyFormatter from "~/libs/CurrencyFormatter";
import { formatNumber, getFlagFromRegionCode } from "~/libs/currency.helpers";
import { DefaultButton, DefaultButtonOutline } from "../Buttons";
import { CentralUserWalletVaultBalanceCard } from "../DashboardComponents/centraluserwallet";
import {
  EscrowWalletLog,
  EscrowWalletsTable,
} from "../DashboardComponents/shared";
import { Divider } from "../Divider";
import { DefaultInputField, EscrowPayoutInput } from "../Inputs";
import ShowToast from "../Toast";
import Authenticator from "./2FaAuth";
import { DefaultDialog } from "./Dialog";

function Wallets({
  setWalletDetails,
}: {
  setWalletDetails: Dispatch<SetStateAction<unknown>>;
}) {
  const { globalModalState, updateGlobalModalState } = useModals();

  return (
    <div className=" w-[1000px] max-w-[85vw] rounded bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type={"button"}
          onClick={() => updateGlobalModalState({ escrowWalletsModal: false })}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "text-[#0094FF]text-[20px] ml-[15px] font-bold leading-tight"
          }
        >
          Vault Wallets
        </span>
      </div>
      <div
        className={`${styles.custom_scrollbar2} relative h-[90%] overflow-y-scroll p-[15px]`}
      >
        <CentralUserWalletVaultBalanceCard />
        <div className="h-[30px]" />
        <EscrowWalletsTable setWalletDetails={setWalletDetails} />
      </div>
    </div>
  );
}

function ViewWallet({
  goBack,
  walletDetails,
}: {
  goBack: () => void;
  walletDetails?: { address: string; amount: number };
}) {
  const [payout, showPayout] = useState(false);
  const [freezeDialog, showFreezeDialog] = useState(false);
  const [closeEscrowDialog, showCloseEscrowDialog] = useState(false);

  return (
    <div className=" w-[1000px] max-w-[85vw] rounded bg-[white]">
      {/* {JSON.stringify(walletDetails)} */}
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type={"button"}
          onClick={() => goBack()}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Wallet name
        </span>
      </div>
      <div
        className={`${styles.custom_scrollbar2} relative h-[90%] overflow-y-scroll p-[15px]`}
      >
        <div className="mt-[10px] flex items-center justify-between p-[10px]">
          <span className="mr-[7px] text-sm font-normal leading-tight text-zinc-700">
            Wallet Address
          </span>
          <div className="mr-[8px] flex items-center">
            <span className="mr-[8px] text-base font-bold leading-normal text-zinc-700">
              {walletDetails?.address}
            </span>
            <div className="flex cursor-pointer items-center justify-center rounded-[16px] bg-[#E2F1FC] px-[10px] py-[6px]">
              <span className="mr-[5px] text-center text-xs font-bold leading-tight text-[#0094FF]">
                Copy Address
              </span>
              <CopyIcon color="#0094FF" h="13" w="13" />
            </div>
          </div>
        </div>
        <div className="mt-[20px] rounded-lg py-[10px] shadow">
          <CentralUserWalletVaultBalanceCard />
          <div className="mt-[20px] flex w-[100%] items-center justify-end px-[20px]">
            <DefaultButtonOutline
              textStyle={{ color: "#57584E" }}
              label="Payout Tournament"
              onClick={() => showPayout(true)}
              styles={{ height: "40px" }}
              disabled={displayMockUI() ? false : true}
              className="h-[32px] w-[190px] bg-[#f7f7f7]"
            />
            <DefaultButtonOutline
              textStyle={{ color: "#57584E" }}
              label="Freeze"
              onClick={() => showFreezeDialog(true)}
              styles={{ height: "40px" }}
              disabled={displayMockUI() ? false : true}
              className="mx-[25px] h-[32px] w-[110px] bg-[#f7f7f7]"
            />
            <DefaultButton
              label="Close Escrow"
              onClick={() => showCloseEscrowDialog(true)}
              styles={{ height: "40px" }}
              disabled={displayMockUI() ? false : true}
              className="h-[40px] w-[165px] bg-[#F05051]"
            />
          </div>
        </div>
        <EscrowWalletLog />
        <div className="h-[30px]" />
      </div>
      <DefaultDialog
        modal={freezeDialog}
        showModal={showFreezeDialog}
        leftButtonOptions={{ label: "Cancel" }}
        rightButtonOptions={{
          width: "130px",
          label: "Freeze Escrow",
          bgColor: "#3BB75E",
        }}
        title="Confirm Wallet Freeze"
        message="Freezing temporarily suspends transactions, ensuring fund security. Reversible anytime. While frozen, no transactions or event participation. Proceed to freeze your wallet?"
      />
      <DefaultDialog
        modal={closeEscrowDialog}
        showModal={showCloseEscrowDialog}
        leftButtonOptions={{ label: "Cancel" }}
        rightButtonOptions={{
          width: "130px",
          label: "Close Escrow",
          bgColor: "#F05050",
        }}
        title="Close Escrow Wallet"
        message="Closing is irreversible. All transactions cease, and funds follow closure policies. Ensure completion of necessary transactions. Confirm closure?"
      />
      <EscrowPayout
        modal={payout}
        showModal={showPayout}
        amount={walletDetails?.amount}
      />
    </div>
  );
}

function PayoutIntro({
  close,
  setDisplay,
  narration,
  setNarration,
  recipients,
  setRecipients,
  amount,
}: {
  close: () => void;
  setDisplay: Dispatch<SetStateAction<unknown>>;
  narration: string;
  setNarration: Dispatch<SetStateAction<string>>;
  recipients: Recipient[];
  setRecipients: (a: Recipient[]) => void;
  amount: number;
}) {
  return (
    <div className="w-[610px] rounded-lg bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type="button"
          onClick={() => close()}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Escrow payout
        </span>
      </div>
      <div className="p-[15px]">
        <div className="rounded p-[20px] shadow">
          <span className="text-center text-base font-normal leading-loose text-stone-600">
            Escrow Total Payout
          </span>
          <div className="flex items-center justify-between">
            <span className="flex items-center">
              <img src={getFlagFromRegionCode("NG")} alt="NIGERIAN FLAG" />
              <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                NGN
              </span>
            </span>
            <span className="text-[32px] font-bold leading-10 text-cyan-950">
              {CurrencyFormatter.formatCurrency(amount)}
            </span>
          </div>
          <Divider className="my-[5px]" />
          <span className="inline-flex w-[100%] items-center justify-start gap-1">
            <span className="text-xs font-light leading-tight text-zinc-700">
              Revenue Earning
            </span>
            <span className="text-xs font-bold leading-tight text-sky-500">
              {(amount * 1.13).toFixed(2)}
            </span>
            <span className="text-xs font-light leading-tight text-sky-800">
              USDC
            </span>
          </span>
        </div>
        <div
          className={`${styles.custom_scrollbar2} max-h-[270px] overflow-y-scroll p-[20px]`}
        >
          <div className="mb-[20px] flex items-center">
            <span className="text-base font-normal leading-tight text-zinc-500">
              Recipients
            </span>
            <div className="ml-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-[32px] bg-[#FFF9EB]">
              <span className="text-center text-xs font-bold leading-tight text-amber-500">
                {recipients.length}
              </span>
            </div>
          </div>
          {recipients.length > 0 ? (
            recipients.map((recipient) => (
              <div key={recipient.id}>
                <UserItem
                  user={recipient}
                  users={recipients}
                  setUsers={setRecipients}
                  showAmount
                />
              </div>
            ))
          ) : (
            <button
              type={"button"}
              onClick={() => setDisplay("add-recipients")}
              className="flex h-[110px] w-[100%] cursor-pointer items-center justify-between rounded-lg border-[1px] border-[#7F8C95] px-[25px]"
            >
              <div className="flex items-center">
                <GridIcon size="40" />
                <div className="ml-[15px] flex flex-col">
                  <span className="text-2xl font-normal leading-tight text-stone-600">
                    Add Recipients
                  </span>
                  <span className="text-sm font-normal leading-tight text-stone-600">
                    Connect and send USDC to a crypto wallet
                  </span>
                </div>
              </div>
              <ArrowRight color="grey" size="24" />
            </button>
          )}
        </div>

        <Divider />
        <div className="flex items-center justify-end">
          <button
            type={"button"}
            onClick={() => setDisplay("edit-amount")}
            className="flex cursor-pointer items-center"
          >
            <span className="mr-[5px] mt-[3px] text-xs font-bold leading-tight text-[#0094FF]">
              Customize Amount
            </span>
            <ArrowTopRight />
          </button>
        </div>
        <div className="mb-[30px] flex-col px-[20px]">
          <span className="h-5 text-2xl font-bold leading-tight text-zinc-700">
            Narration
          </span>
          <input
            placeholder="Narration"
            value={narration}
            onChange={(e) => setNarration(e.target.value)}
            className="mt-[10px] inline-flex h-[72px] w-[100%] items-center justify-center gap-2 rounded border border-slate-500 bg-white px-4 py-2"
          />
        </div>
        {recipients.length > 0 && (
          <div className="flex w-[100] flex-col items-center">
            <DefaultButton
              onClick={() => setDisplay("summary")}
              styles={{ width: "420px" }}
              label="Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

function UserItem({
  user,
  users,
  setUsers,
  showAmount,
}: {
  user: Recipient;
  users: Recipient[];
  setUsers: Dispatch<SetStateAction<Recipient[]>>;
  showAmount?: boolean;
}) {
  function isSelected() {
    const _ = users.find((s) => s.id === user.id);
    if (_) return true;
    return false;
  }

  function selectedUser() {
    if (isSelected()) {
      const filtered = users.filter((s) => s.id !== user.id);
      setUsers(filtered);
    } else {
      setUsers((prev) => {
        return [...prev, user];
      });
    }
  }

  return (
    <button
      type={"button"}
      onClick={() => selectedUser()}
      className="my-[10px] flex cursor-pointer items-center justify-between py-[10px] pr-[10px] hover:bg-zinc-100"
    >
      <div className="flex items-center">
        <Checkbox checked={isSelected()} />
        <div className="ml-[4px] flex items-center">
          <img src="/user.png" alt="Unkown user" />
          <div className="ml-[6px] flex flex-col">
            <span className="text-sm font-normal leading-tight text-gray-600">
              {user.firstname} {user.lastname}
            </span>
            <span className="mt-[5px] text-sm font-bold leading-tight text-gray-600">
              @{user.username}
            </span>
          </div>
        </div>
      </div>
      <div>
        {showAmount && (
          <span className="text-right text-2xl font-bold leading-loose text-zinc-500">
            {CurrencyFormatter.formatCurrency(user.amount)} NGN
          </span>
        )}
      </div>
    </button>
  );
}

function AddEscrowPayoutRecipients({
  setDisplay,
  recipients,
  setRecipients,
}: {
  setDisplay: Dispatch<SetStateAction<unknown>>;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
}) {
  const [searchVal, setSearchVal] = useState("");

  const users = [
    {
      id: 1,
      firstname: "Chijioke",
      lastname: "Okonkwo",
      username: "chijioke_okonkwo",
      amount: 700,
    },
    {
      id: 2,
      firstname: "Ngozi",
      lastname: "Adekunle",
      username: "ngozi_adekunle",
      amount: 1200,
    },
    {
      id: 3,
      firstname: "Yusuf",
      lastname: "Ahmed",
      username: "yusuf_ahmed",
      amount: 1650,
    },
  ];

  const [filtered, setFiltered] = useState<Recipient[]>([]);

  useEffect(() => {
    const filter = users.filter((user) => {
      return (
        `${user.firstname} ${user.lastname}`
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1
      );
    });
    setFiltered(filter);
  }, [searchVal]);

  const result = filtered.length > 0 ? filtered : users;

  function isSelected(user) {
    const _ = recipients.find((s) => s.id === user.id);
    if (_) return true;
    return false;
  }

  function selectedUser(user) {
    if (isSelected(user)) {
      const filtered = recipients.filter((s) => s.id !== user.id);
      setRecipients(filtered);
    } else {
      setRecipients((prev) => {
        return [...prev, user];
      });
    }
  }

  return (
    <div className="w-[610px] rounded-lg bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type={"button"}
          onClick={() => setDisplay("intro")}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Add Recipients
        </span>
      </div>
      <div className="p-[15px]">
        <DefaultInputField
          placeholder="Search users"
          value={searchVal}
          setValue={setSearchVal}
        />
        <Divider className="my-[15px]" />
        <div
          className={`${styles.custom_scrollbar2} h-[290px] overflow-y-scroll`}
        >
          {result.map((user) => (
            <div key={user.id}>
              <UserItem
                user={user}
                users={recipients}
                setUsers={setRecipients}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function EditableUserItem({
  recipient,
  recipients,
  setRecipients,
  index,
}: {
  index: number;
  recipient: Recipient;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [toEdit, setToEdit] = useState(null);
  const [amount, setAmount] = useState(recipient.amount);
  const [mask, setMask] = useState(formatNumber(recipient.amount.toString()));

  function updateAmounts(val: number) {
    const updated = recipients.map((item) => {
      if (item.id === recipient.id) {
        return { ...recipient, amount };
      }
      return item;
    });
    if (!updated.some((item) => item.id === recipient.id)) {
      updated.push({ ...recipient });
    }
    setRecipients(updated);
  }

  useEffect(() => {
    if (amount) {
      updateAmounts(amount);
    }
  }, [amount]);

  return (
    <div
      className={`my-[10px] flex cursor-pointer items-center justify-between py-[10px] pr-[10px] ${
        recipients.length !== index + 1 && "border-b-[1px] border-[#DEE5E7]"
      }`}
    >
      <div className="flex items-center">
        <div className="ml-[4px] flex items-center">
          <img src="/user.png" alt="Unknown user" />
          <div className="ml-[6px] flex flex-col">
            <span className="text-sm font-normal leading-tight text-gray-600">
              {recipient.firstname} {recipient.lastname}
            </span>
            <span className="mt-[5px] text-sm font-bold leading-tight text-gray-600">
              @{recipient.username}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        {/* <div
          className={`${
            isFocused ? "border border-[#0094FF]" : "border border-zinc-200"
          } p-[10px] w-[200px] h-[70px] rounded-[4px]`}
        >
          <input
            value={amount}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => handleChange(e.target.value)}
            className={`text-right text-[black] text-2xl focus:outline-none font-normal leading-loose h-[100%] w-[100%]`}
          />
        </div> */}
        <EscrowPayoutInput
          value={mask}
          setValue={setMask}
          setIntValue={setAmount}
        />
        <span className="ml-[10px] text-right text-2xl font-normal leading-loose text-gray-700">
          NGN
        </span>
      </div>
    </div>
  );
}

function CustomizeEscrowPayout({
  setDisplay,
  recipients,
  setRecipients,
  amount,
}: {
  setDisplay: Dispatch<SetStateAction<unknown>>;
  recipients: Recipient[];
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
  amount: number;
}) {
  return (
    <div className="w-[610px] rounded-lg bg-[white]">
      <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
        <button
          type="button"
          onClick={() => setDisplay("intro")}
          className="flex cursor-pointer items-center"
        >
          <ArrowLeft color="#0094FF" h="25" w="26" />
        </button>
        <span
          className={
            "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
          }
        >
          Customize Amount
        </span>
      </div>
      <div className="p-[15px]">
        <div className="mb-[20px]">
          <span className="text-xl font-bold leading-loose text-stone-600">
            How much should each recipient get?
          </span>
        </div>
        <div className="mb-[20px] flex items-center">
          <span className="text-base font-normal leading-tight text-zinc-500">
            Recipients
          </span>
          <div className="ml-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-[32px] bg-[#FFF9EB]">
            <span className="text-center text-xs font-bold leading-tight text-amber-500">
              {recipients.length}
            </span>
          </div>
        </div>

        <div className="rounded p-[10px] shadow">
          {recipients.map((recipient, i) => (
            <div>
              <EditableUserItem
                index={i}
                recipient={recipient}
                recipients={recipients}
                setRecipients={setRecipients}
              />
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={() => setDisplay("add-recipients")}
          className="item-center my-[20px] flex cursor-pointer justify-center"
        >
          <span className="mr-[5px] text-xs font-bold leading-tight text-[#0094FF]">
            Add another Recipient
          </span>
          <PlusIcon color="#0094FF" />
        </button>
        <Divider className="my-[20px]" />
        <div className="mt-[20px] p-[20px]">
          <span className="text-center text-base font-normal leading-loose text-stone-600">
            Escrow Total Payout
          </span>
          <div className="flex items-center justify-between">
            <span className="flex items-center">
              <img src={getFlagFromRegionCode("NG")} alt="Nigerian flag" />
              <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                NGN
              </span>
            </span>
            <span className="text-[32px] font-bold leading-10 text-cyan-950">
              {CurrencyFormatter.formatCurrency(amount)}
            </span>
          </div>
          <Divider className="my-[5px]" />
          <span className="inline-flex w-[100%] items-center justify-start gap-1">
            <span className="text-xs font-light leading-tight text-zinc-700">
              Revenue Earning
            </span>
            <span className="text-xs font-bold leading-tight text-sky-500">
              {(amount * 1.13).toFixed(2)}
            </span>
            <span className="text-xs font-light leading-tight text-sky-800">
              USDC
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

function EscrowPayoutSummary({
  setDisplay,
  recipients,
  narration,
  setNarration,
  setRecipients,
  close,
  amount,
}: {
  setDisplay: Dispatch<SetStateAction<unknown>>;
  recipients: Recipient[];
  narration: string;
  setNarration: Dispatch<SetStateAction<string>>;
  setRecipients: Dispatch<SetStateAction<Recipient[]>>;
  close: () => void;
  amount: number;
}) {
  const [auth, setAuth] = useState(false);

  function success() {
    setDisplay("intro");
    setRecipients([]);
    setNarration("");
    ShowToast("Success message", "success");
    close();
  }

  return (
    <>
      <div className="w-[610px] rounded-lg bg-[white]">
        <div className="flex h-[54px] w-[100%] items-center justify-between border-b-[.5px] border-zinc-200 px-[20px]">
          <button
            type="button"
            onClick={() => setDisplay("intro")}
            className="flex cursor-pointer items-center"
          >
            <ArrowLeft color="#0094FF" h="25" w="26" />
          </button>
          <span
            className={
              "ml-[15px] text-[20px] font-bold leading-tight text-[#0094FF]"
            }
          >
            Summary
          </span>
        </div>
        <div className="p-[15px]">
          <div className="mt-[20px] p-[10px]">
            <span className="text-center text-base font-normal leading-loose text-stone-600">
              You Transfer
            </span>
            <div className="flex items-center justify-between ">
              <span className="flex items-center">
                <img src={getFlagFromRegionCode("NG")} alt="Nigerian flag" />
                <span className="ml-[8px] text-xl font-bold leading-tight text-stone-600">
                  NGN
                </span>
              </span>
              <span className="text-[32px] font-bold leading-10 text-cyan-950">
                {CurrencyFormatter.formatCurrency(amount)}
              </span>
            </div>
            <span className="mb-[20px] mt-[10px] inline-flex w-[100%] items-center justify-start gap-1">
              <span className="text-xs font-light leading-tight text-[#F05050]">
                Fees Applied
              </span>
              <span className="text-xs font-bold leading-tight text-[#CA1111]">
                100 USDC /
              </span>
              <span className="text-xs font-light leading-tight text-[#CA1111]">
                0.25 %
              </span>
            </span>
          </div>
          <div className="mb-[20px] flex items-center pl-[10px]">
            <span className="text-base font-normal leading-tight text-zinc-500">
              Recipients
            </span>
            <div className="ml-[20px] flex h-[32px] w-[32px] items-center justify-center rounded-[32px] bg-[#FFF9EB]">
              <span className="text-center text-xs font-bold leading-tight text-amber-500">
                {recipients.length}
              </span>
            </div>
          </div>

          <div
            className={`${styles.custom_scrollbar2} max-h-[220px] overflow-y-scroll rounded p-[10px]`}
          >
            {recipients.map((recipient, i) => (
              <div
                key={recipient.id}
                className={
                  "my-[10px] flex items-center justify-between py-[10px] pr-[10px]"
                }
              >
                <div className="flex items-center">
                  <div className="ml-[4px] flex items-center">
                    <img src="/user.png" alt="Anonymous user" />
                    <div className="ml-[6px] flex flex-col">
                      <span className="text-sm font-normal leading-tight text-gray-600">
                        {recipient.firstname} {recipient.lastname}
                      </span>
                      <span className="mt-[5px] text-sm font-bold leading-tight text-gray-600">
                        @{recipient.username}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="text-right text-2xl font-bold leading-loose text-zinc-500">
                    {CurrencyFormatter.formatCurrency(recipient.amount)}
                  </span>
                  <span className="ml-[10px] text-right text-2xl font-normal leading-loose text-gray-700">
                    NGN
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="my-[30px] flex-col px-[20px]">
            <span className="h-5 text-2xl font-bold leading-tight text-zinc-700">
              Narration
            </span>
            <input
              placeholder="Narration"
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
              className="mb-[15px] mt-[10px] inline-flex h-[72px] w-[100%] items-center justify-center gap-2 rounded border border-slate-500 bg-white px-4 py-2"
            />
            <DefaultInputField
              label="Admin"
              value={"Adetobi Adeobi"}
              setValue={setNarration}
              disabled
              icon={<UserRoleIcon />}
            />
          </div>
          <Divider className="my-[20px]" />
          <div className="mb-[20px] flex w-[100%] items-center justify-center">
            <DefaultButton
              label="Continue"
              onClick={() => setAuth(true)}
              className="w-[80%]"
            />
          </div>
        </div>
      </div>
      <Authenticator
        modal={auth}
        showModal={setAuth}
        onAuthSuccess={() => success()}
      />
    </>
  );
}

export function EscrowPayout({
  modal,
  showModal,
  amount,
}: {
  modal: boolean;
  showModal: Dispatch<SetStateAction<boolean>>;
  amount: number;
}) {
  const [recipients, setRecipients] = useState([]);
  const [narration, setNarration] = useState("");
  const [display, setDisplay] = useState<
    "intro" | "add-recipients" | "edit-amount" | "summary"
  >("intro");

  const close = () => {
    showModal(false);
  };

  return (
    <Modal modal={modal} showModal={showModal}>
      {display === "intro" && (
        <PayoutIntro
          close={() => showModal(false)}
          setDisplay={setDisplay}
          narration={narration}
          setNarration={setNarration}
          recipients={recipients}
          setRecipients={setRecipients}
          amount={amount}
        />
      )}
      {display === "add-recipients" && (
        <AddEscrowPayoutRecipients
          setDisplay={setDisplay}
          recipients={recipients}
          setRecipients={setRecipients}
        />
      )}
      {display === "edit-amount" && (
        <CustomizeEscrowPayout
          setDisplay={setDisplay}
          recipients={recipients}
          setRecipients={setRecipients}
          amount={amount}
        />
      )}
      {display === "summary" && (
        <EscrowPayoutSummary
          amount={amount}
          setDisplay={setDisplay}
          recipients={recipients}
          narration={narration}
          setNarration={setNarration}
          setRecipients={setRecipients}
          close={close}
        />
      )}
    </Modal>
  );
}
export function Escrow() {
  const [view, setView] = useState<"wallets" | "view-wallets" | "payout">(
    "wallets",
  );
  const { globalModalState, updateGlobalModalState } = useModals();
  const [walletDetails, setWalletDetails] = useState(null);

  const triggerList = [
    { label: "Marketplace checkout", value: "Marketplace checkout" },
  ];

  const dates = [];
  const today = moment();

  dates.push(today.format("MM/DD/YYYY"));
  Array(10)
    .fill("1")
    .map(() => dates.push(today.subtract(1, "M").format("MM/DD/YYYY")));

  function viewWallet() {
    setView("view-wallets");
  }

  function goBack() {
    setWalletDetails(null);
    setView("wallets");
  }

  useEffect(() => {
    if (walletDetails) {
      viewWallet();
    }
  }, [walletDetails]);

  return (
    <Modal modal={globalModalState.escrowWalletsModal} disableBackdropClose>
      {view === "wallets" && <Wallets setWalletDetails={setWalletDetails} />}
      {view === "view-wallets" && (
        <ViewWallet goBack={goBack} walletDetails={walletDetails} />
      )}
    </Modal>
  );
}
