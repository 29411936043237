import { Slot } from "@radix-ui/react-slot";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Balancer from "react-wrap-balancer";
import { Button } from "~/@/components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { Input } from "~/@/components/ui/input";
import { Label } from "~/@/components/ui/label";
import { Skeleton } from "~/@/components/ui/skeleton";
import { cn } from "~/@/lib/utils";
import {
  useCreateApiClientAndSecret,
  useRotateCredentialsSecret,
} from "~/api/codegen/walletosComponents";
import { ApiError } from "~/api/response-types";
import { ArrowTopRight } from "~/assets/icons";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { CopyButton } from "~/components/Buttons/copy-button";
import { notify, notifyApiError } from "~/components/Toast";
import { Badge } from "~/components/atoms/Badge";
import { SkeletonContent } from "~/components/atoms/skeleton";
import {
  CardBody,
  CardHeader,
  CardRoot,
  CardTitle,
} from "~/components/layouts/Card";
import { BusinessAPIKeyCreated } from "~/general/interfaces";
import { useBusinessMetadata, useGetBusinessId } from "~/hooks/use-business";
import { ModalKeys, useModal, useModalHandle } from "~/hooks/use-modal";
import { safeStr } from "~/libs/data.helper";
import { TitleHeading, TitleRoot, TitleSubHeading } from "./Title";
import { ManageWebhooks } from "./manage-webhooks";
import { PasswordInput } from "~/components/Inputs/password";
import { APIConfigIcon } from "~/assets/navIcons";
import { Link } from "@tanstack/react-router";

export function ApiConfigScreen() {
  const { data, isLoading } = useBusinessMetadata();

  const public_key = safeStr(data?.business.credentials?.clientId);
  const hasCredentials = Boolean(public_key);

  return (
    <>
      <div className="mt-6 flex flex-col gap-y-12">
        <CardRoot>
          <CardHeader className={"border-b pb-0 pt-0"}>
            <TitleRoot>
              <CardTitle>API Key</CardTitle>
            </TitleRoot>
          </CardHeader>

          <CardBody>
            <SkeletonContent
              isLoading={isLoading}
              count={1}
              Component={LayoutSkeleton}
            >
              <div className="flex w-full flex-col gap-8 md:flex-row">
                <div className="w-full flex-1">
                  {hasCredentials ? (
                    <div className="items-start justify-center">
                      <Label
                        htmlFor={"public_key"}
                        className=" text-sm font-bold leading-tight text-stone-600"
                      >
                        Client ID
                      </Label>

                      <div className={"mt-3 flex flex-col"}>
                        <div className="w-full">
                          <PasswordInput
                            value={public_key}
                            readOnly={true}
                            id={"public_key"}
                            type="password"
                            placeholder="test_pk_***********************"
                          />
                        </div>
                        <div className="flex w-full justify-between">
                          <div className="text-end">
                            <RotateClientSecretKeyButton>
                              {({ isLoading: isRotating }) => {
                                return (
                                  <button
                                    type={"button"}
                                    className={cn(
                                      "inline-flex cursor-pointer items-center text-[#0094FF]",
                                      {
                                        "animate-pulse grayscale": isRotating,
                                      },
                                    )}
                                    disabled={isRotating}
                                  >
                                    <span className="mr-[6px] text-xs">
                                      Generate New Secret Key
                                    </span>
                                    <ArrowTopRight size="0.8rem" />
                                  </button>
                                );
                              }}
                            </RotateClientSecretKeyButton>
                          </div>

                          <div className="text-end">
                            <CopyToClipboard
                              text={public_key}
                              onCopy={() =>
                                public_key
                                  ? notify("success", "Public API Key copied")
                                  : null
                              }
                            >
                              <CopyButton />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        "mx-auto flex max-w-sm flex-col gap-y-4 text-center"
                      }
                    >
                      <CardTitle>No API Key</CardTitle>
                      <p className={"text-sm text-gray-500"}>
                        <Balancer>
                          This business has not API Key. Create one from the
                          button below.
                        </Balancer>
                      </p>
                      <GenerateKeyButton>
                        {({ isLoading }) => (
                          <Button className={"!mt-6 self-center"}>
                            <LoadingButtonContent loading={isLoading}>
                              Generate Key
                            </LoadingButtonContent>
                          </Button>
                        )}
                      </GenerateKeyButton>
                    </div>
                  )}
                </div>
                <div className="w-full flex-1">
                  <div className="mb-2 flex items-center">
                    <APIConfigIcon />
                    <p className="px-2 text-sm font-bold leading-tight text-stone-600">
                      API documentation
                    </p>
                  </div>
                  <Link
                    to={
                      "https://documenter.getpostman.com/view/31918091/2s9YymHQXm#ac22ae2b-e5aa-4028-9271-7536593efd00"
                    }
                  >
                    <button
                      type={"button"}
                      className={
                        "inline-flex cursor-pointer items-center text-[#0094FF]"
                      }
                    >
                      <span className="cursor-pointer px-[2px] text-xs">
                        Click here to check out CentryOS API docs
                      </span>
                      <ArrowTopRight size="0.8rem" />
                    </button>
                  </Link>
                </div>
              </div>
            </SkeletonContent>
          </CardBody>
        </CardRoot>

        <ManageWebhooks />
      </div>

      <GenerateApiKeyModal />
    </>
  );
}

function GenerateApiKeyModal() {
  const modal = useModal();
  const { data } = useModalHandle("create_api_key");
  const [isCopied, setIsCopied] = useState(false);

  const secret = data?.client_secret ?? "";

  return (
    <DialogRemote
      id={"create_api_key"}
      onOpenChange={() => {
        setIsCopied(false);
      }}
    >
      <DialogContent
        showClose={false}
        onInteractOutside={(evt) => {
          evt.preventDefault();
        }}
      >
        <DialogHeader className={"space-y-4"}>
          <DialogTitle>New API Key and Secret</DialogTitle>
          <DialogDescription>
            Copy the{" "}
            <b className={"font-bold text-foreground"}>Client Secret</b> below
            and keep it secure as this will be the only time you'll see this
          </DialogDescription>
        </DialogHeader>

        <div className={"relative !my-6 flex items-center"}>
          <Input
            id="secret"
            type={"text"}
            readOnly
            value={secret}
            className="pr-24"
          />
          <div className={"absolute right-0 px-4"}>
            <CopyToClipboard
              text={secret}
              onCopy={() => {
                setIsCopied(true);
                secret ? notify("success", "Secret key copied!") : null;
              }}
            >
              <CopyButton />
            </CopyToClipboard>
          </div>
        </div>

        <Button
          size={"lg"}
          disabled={!isCopied}
          onClick={() => {
            modal.hide("create_api_key");
            setIsCopied(false);
          }}
        >
          I've secured the Secret token
        </Button>
      </DialogContent>
    </DialogRemote>
  );
}

function GenerateKeyButton(props: {
  children: (props: { isLoading: boolean }) => React.JSX.Element;
}) {
  const businessId = useGetBusinessId();
  const { refetch } = useBusinessMetadata();
  const { isPending, mutateAsync } = useCreateApiClientAndSecret({});
  const modal = useModal();

  return (
    <Slot
      onClick={() =>
        mutateAsync({ pathParams: { businessId } })
          .then(async (response: { credentials: BusinessAPIKeyCreated }) => {
            const secret = response?.credentials?.clientSecret;
            const clientId = response?.credentials?.clientId;

            if (!(secret && clientId))
              throw ApiError({ message: "Error generating secret" });

            modal.show("create_api_key", {
              client_id: clientId,
              client_secret: secret,
              mode: "fresh",
            });

            return refetch();
          })
          .catch(notifyApiError)
      }
    >
      {props.children({ isLoading: isPending })}
    </Slot>
  );
}

function RotateClientSecretKeyButton(props: {
  children: (props: { isLoading: boolean }) => React.JSX.Element;
}) {
  const modal = useModal();
  const businessId = useGetBusinessId();
  const { refetch } = useBusinessMetadata();
  const { isPending, mutateAsync } = useRotateCredentialsSecret({});

  return (
    <>
      <Slot
        onClick={() => {
          modal.show("prompt:rotate");
        }}
      >
        {props.children({ isLoading: isPending })}
      </Slot>

      <Prompt id="rotate" heading="Disclaimer" isLoading={isPending}>
        <p className="flex-1 text-muted-foreground">
          Your current{" "}
          <span className="font-medium text-foreground">API secret</span> will
          be <span className="font-medium text-foreground">replaced</span> with
          a new one. If wish to continue, click on the button below to proceed
        </p>

        <DialogFooter>
          <DialogRemoteTrigger modalId="prompt:rotate" params={undefined}>
            <Button variant="outline">Cancel</Button>
          </DialogRemoteTrigger>

          <Button
            onClick={() => {
              mutateAsync({ pathParams: { businessId } })
                .then(
                  async (response: { credentials: BusinessAPIKeyCreated }) => {
                    modal.hide("prompt:rotate");

                    const secret = response?.credentials?.clientSecret;
                    const clientId = response?.credentials?.clientId;

                    if (!(secret && clientId))
                      throw ApiError({ message: "Error generating secret" });

                    modal.show("create_api_key", {
                      client_id: clientId,
                      client_secret: secret,
                      mode: "rotate",
                    });

                    return refetch();
                  },
                )
                .catch(notifyApiError);
            }}
          >
            <LoadingButtonContent loading={isPending}>
              Rotate API Key
            </LoadingButtonContent>
          </Button>
        </DialogFooter>
      </Prompt>
    </>
  );
}

function LayoutSkeleton() {
  return (
    <div className={"flex flex-col items-center gap-y-2 py-12"}>
      <Skeleton className={"h-4 w-[100px]"} />
      <Skeleton className={"h-8 w-[200px]"} />
      <Skeleton className={"h-4 w-[220px]"} />
      <Skeleton className={"h-4 w-[180px]"} />
    </div>
  );
}

const Prompt = function Prompt(props: {
  id: string;
  heading: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
}) {
  const id = `prompt:${props.id}` as ModalKeys;

  return (
    <DialogRemote id={id}>
      <DialogContent
        showClose={false}
        className="flex aspect-[4/3.5] max-w-[400px] flex-col"
      >
        <DialogHeader>
          <div className="mb-4 flex flex-col">
            <Badge type="error" showIcon={false}>
              Action Irreversible
            </Badge>
          </div>

          <DialogTitle className="tracking-wide">{props.heading}</DialogTitle>
        </DialogHeader>

        {props.children}
      </DialogContent>
    </DialogRemote>
  );
};
