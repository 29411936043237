import { cn } from "~/@/lib/utils";

export function Divider({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "my-[16px] self-stretch border-b-[0.5px] border-zinc-200",
        className,
      )}
    />
  );
}
