export function GridIcon({ size }: { size?: string }) {
  return (
    <svg
      width={size || "14"}
      height={size || "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.125 1.37305H2.125C1.71079 1.37305 1.375 1.70883 1.375 2.12305V5.12305C1.375 5.53726 1.71079 5.87305 2.125 5.87305H5.125C5.53921 5.87305 5.875 5.53726 5.875 5.12305V2.12305C5.875 1.70883 5.53921 1.37305 5.125 1.37305Z"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.125 8.12305H2.125C1.71079 8.12305 1.375 8.45883 1.375 8.87305V11.873C1.375 12.2873 1.71079 12.623 2.125 12.623H5.125C5.53921 12.623 5.875 12.2873 5.875 11.873V8.87305C5.875 8.45883 5.53921 8.12305 5.125 8.12305Z"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 1.37305H8.875C8.46079 1.37305 8.125 1.70883 8.125 2.12305V5.12305C8.125 5.53726 8.46079 5.87305 8.875 5.87305H11.875C12.2892 5.87305 12.625 5.53726 12.625 5.12305V2.12305C12.625 1.70883 12.2892 1.37305 11.875 1.37305Z"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 8.12305H8.875C8.46079 8.12305 8.125 8.45883 8.125 8.87305V11.873C8.125 12.2873 8.46079 12.623 8.875 12.623H11.875C12.2892 12.623 12.625 12.2873 12.625 11.873V8.87305C12.625 8.45883 12.2892 8.12305 11.875 8.12305Z"
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
