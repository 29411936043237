import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { SignInBase } from "~/containers/Signin";

export const Route = createFileRoute("/signin")({
  loader: () => Promise.resolve({}),
  component: function SignInPage() {
    const navigate = useNavigate();

    return (
      <SignInBase
        onSuccess={() => {
          return navigate({ to: "/dashboard/overview" });
        }}
      />
    );
  },
});
