import React from "react";
import { DollarSignIcon, Users } from "lucide-react";
import {
  CentralUserSubWalletBalanceCard,
  CentralUserWalletVaultBalanceCard,
  TotalUserWalletBalance,
} from "~/components/DashboardComponents/centraluserwallet";
import { UserTable, VaultTable } from "~/components/DashboardComponents/shared";
import { Divider } from "~/components/Divider";
import { MetricsCard } from "~/components/molecules/MetricsCard";
import { useUserMetrics } from "~/hooks/use-user-metrics";
import { safeNum } from "~/libs/data.helper";

import { useSharedCurrency } from "~/contexts/currency";
import { useConvert } from "~/hooks/use-currency-rates";

import { BalanceMap } from "~/libs/factories/balance-map";
import { abbrevCurrencyValue } from "~/libs/currency.helpers";
import { ActiveEscrowsIcon } from "~/assets/navIcons";

export function CentralUserWalletContent() {
  const [activeCard, setActiveCard] =
    React.useState<string>("All User Accounts");
  return (
    <div className={"mt-10 flex flex-col gap-y-4"}>
      <TotalUserWalletBalance />

      <CentralUserWalletMetrics />

      <div className="flex flex-col items-center justify-between gap-x-4 gap-y-4 md:flex-row">
        <div className={"w-full flex-1"}>
          <CentralUserSubWalletBalanceCard
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />
          {/* <WalletCard /> */}
        </div>

        <div className={"w-full flex-1"}>
          <CentralUserWalletVaultBalanceCard
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />
        </div>
      </div>

      <Divider />

      {activeCard === "All User Accounts" && <UserTable showSearchBar />}

      {activeCard === "All User Vaults" && <VaultTable showSearchBar />}
    </div>
  );
}

export function CentralUserWalletMetrics() {
  const { data: metrics } = useUserMetrics();
  const currency = useSharedCurrency();
  const formatter = abbrevCurrencyValue(currency.code);

  const transaction = useConvert(
    currency.code,
    useMemo(
      () => BalanceMap.create(metrics?.transactions?.volume),
      [metrics?.transactions?.volume],
    ),
  );

  const active_vault = useConvert(
    currency.code,
    useMemo(
      () => BalanceMap.create(metrics?.activeVaults?.volume),
      [metrics?.activeVaults?.volume],
    ),
  );

  return (
    <div className="mb-[10px] flex flex-col items-center justify-between gap-6 xl:flex-row">
      <MetricsCard
        className={"w-full"}
        Icon={<Users />}
        caption={"Total Users"}
        data={[
          {
            value: safeNum(metrics?.totalUsers?.registered),
            title: "Registered",
          },
          {
            value: safeNum(metrics?.totalUsers?.active),
            title: "Active",
          },
        ]}
      />

      <MetricsCard
        className={"w-full"}
        Icon={<DollarSignIcon size={"0.8rem"} />}
        caption={"Transactions"}
        variant="outline"
        data={[
          { value: safeNum(metrics?.transactions?.count), title: "Count" },
          {
            value: formatter(transaction.data.amount),
            title: "Volume",
          },
        ]}
      />

      <MetricsCard
        className={"w-full"}
        Icon={<ActiveEscrowsIcon />}
        caption={"Active Vaults"}
        variant="outline"
        data={[
          { value: safeNum(metrics?.activeVaults?.count), title: "Count" },
          {
            value: formatter(active_vault.data.amount),
            title: "Volume",
          },
        ]}
      />
    </div>
  );
}
