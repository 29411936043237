export function PeopleIcon({
  height,
  width,
  color,
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10449_42072)">
        <path
          d="M3.33301 4.66667C3.33301 5.37391 3.61396 6.05219 4.11406 6.55229C4.61415 7.05238 5.29243 7.33333 5.99967 7.33333C6.70692 7.33333 7.3852 7.05238 7.88529 6.55229C8.38539 6.05219 8.66634 5.37391 8.66634 4.66667C8.66634 3.95942 8.38539 3.28115 7.88529 2.78105C7.3852 2.28095 6.70692 2 5.99967 2C5.29243 2 4.61415 2.28095 4.11406 2.78105C3.61396 3.28115 3.33301 3.95942 3.33301 4.66667Z"
          stroke={color || "#303940"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 14V12.6667C2 11.9594 2.28095 11.2811 2.78105 10.781C3.28115 10.281 3.95942 10 4.66667 10H7.33333C8.04058 10 8.71885 10.281 9.21895 10.781C9.71905 11.2811 10 11.9594 10 12.6667V14"
          stroke={color || "#303940"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.667 2.08667C11.2406 2.23354 11.749 2.56714 12.1121 3.03488C12.4752 3.50262 12.6722 4.07789 12.6722 4.67C12.6722 5.26212 12.4752 5.83739 12.1121 6.30513C11.749 6.77287 11.2406 7.10647 10.667 7.25334"
          stroke={color || "#303940"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 14.0001V12.6668C13.9966 12.0782 13.7986 11.5073 13.4368 11.043C13.0751 10.5788 12.5699 10.2472 12 10.1001"
          stroke={color || "#303940"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10449_42072">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
