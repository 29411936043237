import React from "react";
import { CheckIcon } from "~/assets/icons/CheckIcon";
import { safeStr } from "~/libs/data.helper";

export function PasswordValidator({ password = "" }: { password: string }) {
  const validations = React.useMemo(
    () => verifyPassword(safeStr(password)),
    [password],
  );

  return (
    <div className="flex flex-col py-2">
      {validations.map(({ message, is_valid }) => (
        <div
          className="mb-[4px] flex items-center gap-x-2 text-[#002C3D]"
          key={message}
        >
          {is_valid ? (
            <CheckIcon />
          ) : (
            <span className="aspect-square h-[10px] w-[10px] rounded-full border border-[currentColor]" />
          )}
          <p className="font-body text-sm">{message}</p>
        </div>
      ))}
    </div>
  );
}

function verifyPassword(password: string) {
  type ValidationRule = { check: (v: string) => boolean; message: string };

  const validators: ValidationRule[] = [
    // Check minimum length
    { check: (value) => value.length >= 8, message: "Min 8 characters long" },
    // Check for at least one uppercase letter
    {
      check: (value) => /[A-Z]/.test(value),
      message: "At least one UPPERCASE letter",
    },
    // Check for at least one special character
    {
      check: (value) => /[!@#$%^&*?]/.test(value),
      message: "One unique character (e.g: !@#$%^&*?)",
    },
    // Check for at least one digit
    { check: (value) => /\d/.test(value), message: "At least one number" },
  ];

  return validators.map((e): ValidationRule & { is_valid: boolean } => {
    const is_valid = e.check(password);
    return { ...e, is_valid };
  });
}
