import React from "react";
import { InitalScaleHelmet } from "~/components/Helmet";
import { AuthScreenNav } from "~/components/Navbars";
import { cn } from "~/@/lib/utils";
import { X } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";

export default function Layout({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <>
      <InitalScaleHelmet />
      <header className="inline-flex h-[9vh] w-full items-center justify-between border-b px-4 py-0 md:px-12">
        <div className="inline-flex items-center">
          <AuthScreenNav path="/dashboard/overview" />
          <div className="border-l border-gray-200 pl-10">
            <h1 className="text-lg font-normal text-gray-500">Payout</h1>
          </div>
        </div>
        <PayoutClose
          onClick={() => {
            navigate({ to: "/dashboard/wallet/spend" });
          }}
          className="px-4 py-0 md:px-14"
        />
      </header>

      <main className="flex min-h-[60vh] w-full items-start justify-center pb-4">
        {children}
      </main>
    </>
  );
}

export function PayoutClose(props: React.ComponentProps<"button">) {
  return (
    <button
      type={"button"}
      {...props}
      className={cn("cursor-pointer ", props.className)}
    >
      <X />
    </button>
  );
}
