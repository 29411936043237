import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WalletLog } from "~/general/interfaces";

interface initialStateProps {
  corporateWalletLogs: WalletLog[];
}

const initialState: initialStateProps = {
  corporateWalletLogs: [],
};

const walletsSlice = createSlice({
  name: "wallets",
  initialState,
  reducers: {
    _setCorporateWalletLogs(state, action: PayloadAction<WalletLog[]>) {
      state.corporateWalletLogs = action.payload;
    },
  },
});

export default walletsSlice.reducer;

export const { _setCorporateWalletLogs } = walletsSlice.actions;
