import { createFileRoute } from "@tanstack/react-router";
import { ArrowLeft } from "lucide-react";
import React from "react";
import { Tabs } from "~/@/components/ui/tabs";
import {
  SecondaryContent,
  SecondaryNav,
  SecondaryRoot,
  SecondaryTab,
} from "~/components/layouts/SecondaryNav";
import { MainContainer } from "~/components/layouts/Shared";
import { ManageRoles } from "~/containers/Dashboard/Pages/Settings/ManageRoles";
import { TeamMembers } from "~/containers/Dashboard/Pages/Settings/TeamManagement";
import { RoleInfo } from "~/hooks/use-app-config";

export function TeamPage() {
  const [role, setRole] = React.useState<RoleInfo | null>();
  return (
    <Tabs defaultValue={"members"}>
      {role ? (
        <SecondaryRoot>
          <div className=" bg-white ">
            <MainContainer className="flex items-center justify-between py-4 text-gray-500">
              <div
                className="flex cursor-pointer items-center gap-3"
                onClick={() => setRole(null)}
                onKeyPress={() => setRole(null)}
              >
                <ArrowLeft />{" "}
                <span className="text-sm font-medium tracking-normal">
                  Manage Roles
                </span>
              </div>
              <div>{role.label}</div>
            </MainContainer>
          </div>

          <SecondaryContent value={"members"}>
            <TeamMembers />
          </SecondaryContent>
          <SecondaryContent value={"roles"}>
            <ManageRoles role={role} setRole={setRole} />
          </SecondaryContent>
        </SecondaryRoot>
      ) : (
        <SecondaryRoot>
          <SecondaryNav>
            <SecondaryTab
              tabs={[
                { label: "Members", key: "members", isActive: false },
                { label: "Manage Roles", key: "roles", isActive: false },
              ]}
            />
          </SecondaryNav>
          <SecondaryContent value={"members"}>
            <TeamMembers />
          </SecondaryContent>
          <SecondaryContent value={"roles"}>
            <ManageRoles role={role} setRole={setRole} />
          </SecondaryContent>
        </SecondaryRoot>
      )}
    </Tabs>
  );
}

export const Route = createFileRoute("/settings/team")({
  component: TeamPage,
});
