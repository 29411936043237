export function RadioSelected() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="5"
        fill="#4576C2"
        stroke="#4576C2"
        strokeWidth="6"
      />
      <circle cx="8" cy="8" r="5.5" stroke="white" strokeWidth="3" />
    </svg>
  );
}
