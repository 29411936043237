import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { Button } from "~/@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/@/components/ui/form";
import { FormItemSplit } from "~/@/components/ui/form-layout";
import { Input } from "~/@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { ChipSelector } from "~/components/Inputs";
import { FieldShowBalanceIn } from "~/components/Inputs/fields";
import { CardBody, CardFooter, CardRoot } from "~/components/layouts/Card";

export const Route = createFileRoute("/settings/wallets/vault-wallet")({
  component: Component,
});

function Component() {
  const form = useForm();

  return (
    <Form {...form}>
      <CardRoot className="mt-6">
        <CardBody className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="show_balance_in"
            render={() => <FieldShowBalanceIn />}
          />

          <FormField
            control={form.control}
            name="who_can_fund"
            render={() => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <FormLabel>Who can Fund?</FormLabel>
                    <FormControl>
                      <Select>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="User Wallets" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="wallet-1">Wallet #1</SelectItem>
                          <SelectItem value="wallet-2">Wallet #2</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="payout_type"
            render={() => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <FormLabel>Payout Type</FormLabel>
                    <FormControl>
                      <Select>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Manual Payout" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="light">Yes</SelectItem>
                          <SelectItem value="dark">No</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="who_can_payout"
            render={() => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <FormLabel>Who can Payout?</FormLabel>
                    <FormControl>
                      <ChipSelector
                        list={["Owner", "Admin"]}
                        selected={["Admin"]}
                        setSelected={() => {}}
                      />
                    </FormControl>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="failsafe_destination"
            render={() => {
              return (
                <FormItem>
                  <FormItemSplit>
                    <FormLabel>Fail-safe Destination</FormLabel>
                    <FormControl>
                      <Input />
                    </FormControl>
                  </FormItemSplit>
                </FormItem>
              );
            }}
          />
        </CardBody>

        <CardFooter>
          <Button>Save changes</Button>
        </CardFooter>
      </CardRoot>
    </Form>
  );
}
