import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { cn } from "~/@/lib/utils";
import {
  ArrowTop,
  CaretDownFilled,
  CaretDown,
  EyeCloseIcon,
  EyeOpenIcon,
} from "~/assets/icons";
import { WalletCurrencyDropdown } from "~/components/Menu";
import { SkeletonText } from "~/components/atoms/skeleton";
import { CardRoot, CardSeparator } from "~/components/layouts/Card";
import { FiatCurrencyI } from "~/constants/currencies";
import CurrencyFormatter from "~/libs/CurrencyFormatter";

export function Card(props: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <CardRoot
      className={`flex-col items-start justify-start gap-4 rounded-lg p-[20px] font-body ${props.className}`}
      onClick={() => (props.onClick ? props.onClick() : {})}
    >
      {props.children}
    </CardRoot>
  );
}

export function Header(props: {
  headingText: string;
  trendDifference?: string;
}) {
  const { headingText, trendDifference } = props;

  return (
    <>
      <div>
        <div className="flex w-full items-center justify-between">
          <span className="text-base font-semibold leading-7 text-gray-800">
            {headingText}
          </span>

          <Menu as="div" className="relative hidden text-left">
            <Menu.Button>
              <div className="flex items-center justify-center gap-2 rounded-[16px] border-[1px] border-[#DEE5E7] px-3 py-1">
                <span className="flex items-center text-sm font-bold leading-tight text-gray-600">
                  Current
                </span>
                <CaretDown color="#57584E" />
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="flex max-h-[300px] flex-col py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        className={`cursor-pointer px-[5px] font-normal ${
                          active ? "bg-gray-200" : ""
                        }`}
                      >
                        Last week
                      </span>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        {trendDifference ? (
          <span className="flex items-center">
            <ArrowTop />
            <span className="ml-[8px] text-sm font-normal leading-snug text-gray-600">
              ({trendDifference}) than last Week
            </span>
          </span>
        ) : null}
      </div>

      <CardSeparator className="my-5" />
    </>
  );
}

export function Content(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`flex w-full flex-col items-start justify-between gap-4 md:flex-row md:items-center  ${props.className}`}
    >
      {props.children}
    </div>
  );
}

export function Balance(props: {
  currency: FiatCurrencyI;
  balance: number;
  size?: "lg" | "base";
  isLoading?: boolean;
  showIcon?: boolean;
}) {
  const {
    currency,
    balance,
    isLoading,
    size = "base",
    showIcon = true,
  } = props;

  const [hideBalance, setHideBalance] = useState(false);

  return (
    <div className="">
      <span className="flex items-center">
        <SkeletonText
          isLoading={isLoading}
          className={cn("leading-[72px] text-[#083344]", {
            "text-[30px] md:text-[60px]": size === "lg",
            "text-[30px] leading-[1.2]": size === "base",
          })}
        >
          {hideBalance ? (
            "****"
          ) : (
            <>
              <span>{`${currency?.symbol}`}</span>
              <span className="font-medium">
                {`${CurrencyFormatter.formatCurrency(balance).split(".")[0]}.`}
              </span>
              <span className="text-[30px] text-gray-500">
                {CurrencyFormatter.formatCurrency(balance).split(".")[1]}
              </span>
            </>
          )}
        </SkeletonText>

        {showIcon && (
          <button
            type="button"
            className="ml-[15px] cursor-pointer"
            onClick={() => setHideBalance(!hideBalance)}
          >
            {!hideBalance ? (
              <EyeCloseIcon size="24" color="#CCD1D6" />
            ) : (
              <EyeOpenIcon size="24" color="#CCD1D6" />
            )}
          </button>
        )}
      </span>
    </div>
  );
}

export function CurrencySwitcher(
  props: Omit<
    React.ComponentProps<typeof WalletCurrencyDropdown>,
    "disabled"
  > & {
    readOnly?: boolean;
    showName?: boolean;
  },
) {
  const { currency, showName = true, readOnly = false, ...PROPS } = props;

  return (
    <div className="flex flex-col">
      <WalletCurrencyDropdown
        currency={currency}
        {...PROPS}
        disabled={!readOnly}
      />
      <span className="text-base font-normal leading-normal text-slate-500">
        {showName && currency.name}
      </span>
    </div>
  );
}

function Filter() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="">
        <div className="flex items-center justify-center gap-2 rounded-[16px] border-[1px] border-[#DEE5E7] px-3 py-1">
          <span className="flex items-center text-sm font-bold leading-tight text-gray-600">
            All time
          </span>
          <CaretDownFilled color="#DEE5E7" />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex max-h-[300px] flex-col py-1 ">
            <Menu.Item>
              {({ active }) => (
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  className={`cursor-pointer px-[5px] font-normal ${
                    active ? "bg-gray-200" : ""
                  }`}
                >
                  Last week
                </span>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export const BalanceRoot = Card;
export const BalanceHeader = Header;
export const BalanceContent = Content;
export const BalanceCurrencyOptions = CurrencySwitcher;
export const BalanceValue = Balance;
