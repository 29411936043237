import {
  __,
  compose,
  ifElse,
  isNil,
  map,
  prop,
  range,
  replace,
  test,
} from "ramda";
import { useCallback, useRef, useState } from "react";
import useEffectEvent from "react-use-event-hook";

const PLACEHOLDER = "";

export function usePIN({ length: MAX }: { length: number }) {
  const mainInput = useRef(null);

  const isPin = useEffectEvent((text: string) => {
    return test(RegExp(`^\d{${length}}`), text);
  });

  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");
  const [digits, setDigits] = useState(Array(MAX).fill(PLACEHOLDER));

  const focus = useEffectEvent(() => mainInput.current?.focus?.());

  const autoUpdate = useCallback(() => {
    console.info("Added pin directly from clipboard");
  }, []);

  const focusAndUpdate = useEffectEvent(() => {
    focus();
    setFocused(true);
    setTimeout(() => autoUpdate(), 1000);
  });

  const handleChange = (text: string) => {
    if (!(test(/^\d+$/, text) || text === "")) return;

    if (text.length > MAX) return;

    const splitText = replace(/\s/g, "")(text);
    setValue(splitText);
    setDigits((_digits: number[]) => {
      return compose(
        map(
          ifElse(
            compose(isNil, prop(__, splitText)),
            (num: number) => (num > splitText.length - 1 ? "" : _digits[num]),
            (num: number) => text[num],
          ),
        ),
      )(range(0, MAX));
    });
  };

  const register = useCallback(
    ({ index }: { index: number }) => ({
      inputMode: "numeric" as const,
      onFocus: focusAndUpdate,
    }),
    [focusAndUpdate],
  );

  function onBlur() {
    return setFocused(false);
  }

  return {
    mainInput,
    value,
    digits,
    focused,
    isFilled: value.length === MAX,
    isEmpty: value.length === 0,
    position: value.length,
    clear: useEffect(() => handleChange(""), []),
    focus,
    focusAndUpdate,
    handleChange,
    onBlur,
    register,
  };
}
