import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import React from "react";
import { useGetBusinessAuditLog } from "~/api/codegen/liquidityComponents";
import { DefaultInputField } from "~/components/Inputs";
import { AppTable } from "~/components/table/table";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import { TabButton } from "~/components/Tabs";
import { CardFooter, CardRoot } from "~/components/layouts/Card";
import { NumberBadge } from "~/components/Badges";
import {
  statusDotColumn,
  category,
  dateColumn,
  sourceIP,
  adminRole,
  description,
  statusColumn,
} from "~/components/table/audit";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { Divider } from "~/components/Divider";
import { TitleSubHeading } from "~/containers/Dashboard/Pages/Settings/Title";
import useSearch from "~/hooks";
import { useGetBusinessId } from "~/hooks/use-business";
import { AuditLogDetails } from "./Detail";
import { useModal } from "~/hooks/use-modal";

type SortableColumns =
  | "createdAt"
  | "action"
  | "entity"
  | "sourceIP"
  | "adminRole"
  | "status";

export function AuditLogScreen() {
  const businessId = useGetBusinessId();
  const modal = useModal();
  const [auditPage, setAuditPage] = useState<number>(1);
  const [sortBy, setSortBy] = React.useState<SortableColumns>("createdAt");
  const [orderBy, setOrderby] = React.useState<"ASC" | "DESC">("DESC");
  const [currentTab, setCurrentTab] = useState(undefined);
  const [selectedData, setSelectedData] = useState(null);

  const filteredTab = useMemo(() => {
    const tab = "";

    // if (currentTab === "all") {
    //   tab = "";
    // }

    // if (currentTab === "account") {
    //   tab = "account";
    // }
    // if (currentTab === "transfer") {
    //   tab = "payment";
    // }
    // if (currentTab === "earnings") {
    //   tab = "earnings";
    // }
    // if (currentTab === "withdrawal") {
    //   tab = "withdrawal";
    // }
    return tab;
  }, [currentTab]);

  const { data, isLoading } = useGetBusinessAuditLog(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        page: auditPage,
        limit: 10,
        sortBy: sortBy,
        sortOrder: orderBy,
        entity: filteredTab,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const auditLog = useMemo(() => {
    return data?.data ?? [];
  }, [data?.data]);

  const auditMeta = useMemo(() => {
    return (
      data?.meta ?? {
        page: 0,
        pageCount: 0,
        pageSize: 0,
        total: 0,
      }
    );
  }, [data?.meta]);

  const handleRowClick = (data, tableName) => {
    if (tableName) {
      modal.show("create_modal");
      setSelectedData(data);
    }
  };

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(auditLog, "entity", "action");

  const table_columns = useMemo(
    () => [
      statusDotColumn,
      dateColumn,
      description,
      adminRole,
      category,
      sourceIP,
      statusColumn,
    ],
    [],
  );

  const tabs = [
    { label: "All", value: "All", isActive: true },
    { label: "Account Management", value: "account", isActive: false },
    { label: "API Management", value: "api", isActive: false },
    { label: "Data Security", value: "security", isActive: false },
    { label: "Financial", value: "financial", isActive: false },
  ];
  return (
    <>
      <div className="w-full px-3 py-8">
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <TitleSubHeading className="pr-2 text-gray-500">
              Logs
            </TitleSubHeading>
            <NumberBadge label="pending" count={auditMeta?.total} />
          </div>
        </div>

        <div className="mt-5">
          <Tabs defaultValue={"all"} className="">
            <CardRoot>
              <div className={"flex justify-between px-4 pb-2 pt-5"}>
                <TabsList className={"gap-x-5 bg-transparent"}>
                  {tabs.map((e) => (
                    <TabsTrigger
                      key={e.label}
                      value={e.value}
                      disabled={!e.isActive}
                      onClick={() => setCurrentTab(e.value)}
                      asChild
                    >
                      <TabButton>{e.label}</TabButton>
                    </TabsTrigger>
                  ))}
                </TabsList>
                <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
                  <DefaultInputField
                    placeholder="Search"
                    value={searchTerm}
                    setValue={handleSearchChange}
                    disabled={auditLog?.length === 0}
                  />
                </div>
              </div>

              <Divider className="my-[5px]" />

              <EmptyStateOverlay
                isEmpty={isLoading}
                content={
                  <EmptyStateDescription>
                    Marketplace orders will appear here
                  </EmptyStateDescription>
                }
              >
                <ScrollArea className={"min-h-[400px] w-full"}>
                  <TabsContent value={"all"}>
                    <AppTable
                      data={auditLog ?? []}
                      isLoading={false}
                      columns={table_columns}
                      onRowClick={(row) => {
                        handleRowClick(
                          data?.data[row.index],
                          "Payment Link Details",
                        );
                      }}
                    />
                  </TabsContent>
                </ScrollArea>
              </EmptyStateOverlay>

              {auditLog?.length !== 0 && (
                <CardFooter>
                  <TablePagination
                    data={{
                      page: auditMeta.page,
                      pageCount: auditMeta.pageCount,
                      pageSize: auditMeta.pageSize,
                      total: auditMeta.total,
                      setPage: setAuditPage,
                    }}
                  />
                </CardFooter>
              )}
              <AuditLogDetails selectedData={selectedData} />
            </CardRoot>
          </Tabs>
        </div>
      </div>
    </>
  );
}
