import React from "react";
import { CardSeparator } from "~/components/layouts/Card";
import { WithdrawCtx } from ".";
import { Button } from "~/@/components/ui/button";
import { OTPInput } from "~/components/Inputs";
import { useAccountData, useGetBusinessId } from "~/hooks/use-business";
import { notify } from "~/components/Toast";
import { useNavigate } from "@tanstack/react-router";

const StepSuccess = ({ completeStep, setCurrentStep, currentStep }) => {
  const { withdrawState, updateState } = useContext(WithdrawCtx);
  const { data: userDetails } = useAccountData();
  const navigate = useNavigate();

  return (
    <div className="mx-auto mt-5 w-full max-w-[613px] rounded-lg bg-[white]">
      <div className="flex w-full  items-center justify-center rounded-lg border py-5">
        <div className="flex w-full flex-col">
          <p className="pb-5 font-semibold text-primary">Stay Tuned!</p>
          <CardSeparator className="mb-10 w-full" />
          <div className="flex h-[30vh] flex-col items-center">
            {/* <OTPInput value={otp} setValue={setOtp} /> */}
          </div>
          <div className="mb-5 mt-10">
            <Button
              size="lg"
              variant="default"
              className="mt-5 w-[60%]"
              onClick={() => {
                navigate({ to: "/dashboard/wallet/spend" });
              }}
            >
              Return to Dashboard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StepSuccess);
