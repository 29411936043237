import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers

import general from "./general";
import ellipsisLoader from "./loader";
import modals from "./modals";
import persistent from "./persistent";
import revenue from "./revenue";
import user from "./user";
import wallets from "./wallets";

// Persist saves state to the localstorage. Adding a state to the blacklist means you dont want that state to be persisted. It is very useful for some states
const persistConfig = {
  key: "wallet_os_8",
  storage,
  version: 1,
  blacklist: [
    "ellipsisLoader",
    "modals",
    "user",
    "wallets",
    "general",
    "revenue",
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ellipsisLoader: ellipsisLoader,
    modals: modals,
    persistent,
    user,
    wallets,
    general,
    revenue,
  }),
);

let store: ReturnType<typeof makeStore>;

export function makeStore(preloadedState = undefined) {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools: import.meta.env.NODE_ENV === "development",
    preloadedState,
  });
}

export const initializeStore = (preloadedState = undefined) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

store = initializeStore();

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;

export const persistor = persistStore(store);

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
