import React from "react";
import { EmptyStateDescription as Description } from "~/@/components/custom/empty-state";
import { cn } from "~/@/lib/utils";
import emptyState from "~/assets/images/empty-state.webp";

export function EmptyStateOverlay(
  props: Pick<React.ComponentProps<"div">, "className" | "children"> & {
    content?: React.ReactNode;
    isEmpty?: boolean;
  },
) {
  const { isEmpty = true } = props;

  return (
    <div className={cn("relative", props.className)}>
      {isEmpty && (
        <>
          <div className="absolute inset-0 z-10 bg-white/[0.9] backdrop-blur-sm" />
          <div className="absolute inset-0 z-20 flex flex-col items-center justify-center py-4">
            <img
              src={emptyState}
              className="h-full object-contain"
              alt={"Empty state"}
            />
            {props?.content}
          </div>
        </>
      )}
      {props.children}
    </div>
  );
}

export function EmptyStateDescription(props: React.ComponentProps<"p">) {
  const { className, ...PROPS } = props;

  return (
    <Description
      {...(PROPS as Record<string, string>)}
      className={cn(
        "max-w-[50ch] text-balance text-sm text-gray-700",
        className,
      )}
    >
      {props.children}
    </Description>
  );
}
