import React from "react";
import { InitalScaleHelmet } from "~/components/Helmet";
import { AuthScreenNav } from "~/components/Navbars";
import { makePortal } from "~/libs/make-portal";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <InitalScaleHelmet />
      <AuthScreenNav />

      <main className="flex min-h-[60vh] w-full items-start justify-center pb-4">
        {children}
      </main>
    </>
  );
}

export const OnboardingHeading = makePortal("onboarding-heading");
export const OnboardingSubHeading = makePortal("onboarding-sub-heading");
