import { Button } from "~/@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { useEnforceActionType } from "~/api/codegen/walletosComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { notifyApiError, notifySuccess } from "~/components/Toast";
import { useGetBusinessId } from "~/hooks/use-business";
import { useModalHandle } from "~/hooks/use-modal";
import { Divider } from "../Divider";

type ModalProps = { onSuccess?: () => void };

export function RequestBlockModal(props: ModalProps) {
  const modal = useModalHandle("suspend_user");
  const businessId = useGetBusinessId();
  const mutation = useEnforceActionType();

  return (
    <DialogRemote id={"suspend_user"}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm User Account Suspension</DialogTitle>
        </DialogHeader>

        <div>
          <span className="mt-[15px]">
            <span className="text-sm font-normal leading-tight text-stone-600">
              Suspension temporarily stops transactions and activity on this
              user account and wallet, reversible anytime. While frozen, no
              transactions or event participation.{" "}
            </span>
            <span className="text-sm font-bold leading-tight text-stone-600">
              Proceed to freeze User Account?
            </span>
          </span>
        </div>

        <Divider className="my-[1px]" />

        <DialogFooter>
          <Button variant={"outline"} onClick={() => modal.hide()}>
            Cancel
          </Button>
          <Button
            variant={"default"}
            onClick={() => {
              mutation
                .mutateAsync({
                  pathParams: { businessId, accountId: modal?.data?.user_id },
                  queryParams: {
                    actionType: "STATUS_CHANGE",
                    action: "BLOCK",
                  },
                })
                .then((res) => {
                  modal.hide();
                  notifySuccess(res);
                })
                .then(props.onSuccess)
                .catch(notifyApiError);
            }}
          >
            <LoadingButtonContent loading={mutation.isPending}>
              Freeze User
            </LoadingButtonContent>
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRemote>
  );
}

export function RequestBanModal(props: ModalProps) {
  const modal = useModalHandle("ban_user");
  const businessId = useGetBusinessId();
  const mutation = useEnforceActionType();

  return (
    <DialogRemote id="ban_user">
      <DialogContent className={"font-body"}>
        <DialogHeader>
          <DialogTitle>Confirm Block User Account</DialogTitle>
        </DialogHeader>

        <div className="h-[auto] w-[450px] rounded bg-[white]">
          <div>
            <span className="mt-[15px]">
              <span className="text-sm font-normal leading-tight text-stone-600">
                Closure is irreversible. All transactions cease, and funds
                follow closure policies. This profile will be blocked from
                creating future accounts and wallets.
              </span>
              <span className="text-sm font-bold leading-tight text-stone-600">
                Confirm closure?
              </span>
            </span>
          </div>

          <Divider className="my-[1px]" />

          <DialogFooter>
            <Button variant={"outline"} onClick={() => modal.hide()}>
              Cancel
            </Button>
            <Button
              variant={"destructive"}
              onClick={() => {
                mutation
                  .mutateAsync({
                    pathParams: { businessId, accountId: modal?.data?.user_id },
                    queryParams: {
                      actionType: "STATUS_CHANGE",
                      action: "BAN",
                    },
                  })
                  .then((res) => {
                    notifySuccess(res);
                    modal.hide();
                  })
                  .then(props.onSuccess)
                  .catch(notifyApiError);
              }}
            >
              <LoadingButtonContent loading={mutation.isPending}>
                Block User
              </LoadingButtonContent>
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </DialogRemote>
  );
}
