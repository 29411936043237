import {
  useGetWalletTransactionHistories,
  useGetAllAssociatedWalletsTransactions,
} from "~/api/codegen/liquidityComponents";
import {
  useGetAccountMetadata,
  useGetBusinessMetadata,
} from "~/api/codegen/walletosComponents";
import { UserAccountI } from "~/components/Redux/state/user";
import { BusinessMetadata } from "~/general/interfaces";
import { getAuthData } from "~/libs";
import { BusinessMetaFactory } from "~/libs/factories/business";
import { PaginationFactory } from "~/libs/factories/pagination";
import { TrxFactory } from "~/libs/factories/transaction";

export function useAccountData() {
  const auth = getAuthData();
  const res = useGetAccountMetadata(
    {},
    {
      retry: 0,
      staleTime: 0,
      queryHash: auth?.accessToken,
    },
  );

  return {
    ...res,
    data: res?.data?.account as UserAccountI,
  };
}

export function useGetBusinessId() {
  const { data: businessDetails } = useAccountData();

  return businessDetails?.associatedBusinesses?.[0]?.id ?? null;
}

export function useBusiness() {
  return useAccountData().data?.associatedBusinesses?.[0];
}

export function useBusinessMetadata() {
  const businessId = useGetBusinessId();
  const res = useGetBusinessMetadata<
    { business: BusinessMetadata } | undefined
  >(
    {
      pathParams: { businessId },
    },
    { enabled: Boolean(businessId) },
  );

  return {
    ...res,
    data: useMemo(() => BusinessMetaFactory(res.data), [res.data]),
  };
}

export function useBusinessTransactions(
  {
    walletId,
    businessId,
    page,
    flowType = undefined,
    startDate = undefined,
    endDate = undefined,
    amount = undefined,
    method = undefined,
    status = undefined,
    currency = undefined,
  },
  options?: Parameters<typeof useGetWalletTransactionHistories>[1],
) {
  const res = useGetWalletTransactionHistories({
    pathParams: {
      businessId,
      walletId,
    },
    queryParams: {
      page: page,
      limit: 10,
      startDate: startDate,
      endDate: endDate,
      amount: amount,
      flowType: flowType,
      status: status,
      currency: currency,
    },
  });

  const pagination = useMemo(
    () => PaginationFactory(res.data, TrxFactory),
    [res.data],
  );

  return { ...res, data: pagination };
}

export function useAllBusinessTransactions(
  {
    businessId,
    page,
    wallets = undefined,
    flowType = undefined,
    startDate = undefined,
    endDate = undefined,
    amount = undefined,
    method = undefined,
    status = undefined,
    currency = undefined,
  },
  options?: Parameters<typeof useGetAllAssociatedWalletsTransactions>[1],
) {
  const res = useGetAllAssociatedWalletsTransactions({
    pathParams: {
      businessId,
    },
    queryParams: {
      wallets: wallets,
      page: page,
      limit: 10,
      startDate: startDate,
      endDate: endDate,
      amount: amount,
      flowType: flowType,
      status: status,
      currency: currency,
    },
  });

  const pagination = useMemo(
    () => PaginationFactory(res.data, TrxFactory),
    [res.data],
  );

  return { ...res, data: pagination };
}
