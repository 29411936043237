import React from "react";
import { CountryI } from "~/general/interfaces";
import Countries from "./Countries";
import PaymentMethod from "./PaymentMethod";
import AccountDetail from "./AccountDetail";
import { ApplicationTokenTypes, PickOneOptionType } from "~/libs/types.helper";

interface DestinationDefaultCtx {
  destinationState: {
    country: CountryI;
    method: PickOneOptionType;
    tokendetails: ApplicationTokenTypes;
  };
  updateState: (name: string, newState: unknown) => void;
  resetState: () => void;
}

export const DestinationCtx = React.createContext({} as DestinationDefaultCtx);

const AddDestination = ({ setCurrentStep, currentStep }) => {
  const [destinationState, setDestinationState] = React.useState({});
  const [currentMove, setCurrentMove] = useState(0);

  const updateState = (name: string, newState: unknown) => {
    setDestinationState((e) => ({ ...e, [name]: newState }));
  };
  const resetState = () => {
    setDestinationState({});
  };

  const renderStepContent = () => {
    switch (currentMove) {
      case 0:
        return (
          <Countries
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
          />
        );
      case 1:
        return (
          <PaymentMethod
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
          />
        );
      case 2:
        return (
          <AccountDetail
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      default:
        return (
          <Countries
            setCurrentMove={setCurrentMove}
            currentMove={currentMove}
          />
        );
    }
  };

  const ctxValue = {
    destinationState,
    updateState,
    resetState,
  };
  return (
    <DestinationCtx.Provider value={ctxValue as null}>
      <div className="mx-auto w-full">
        <div className="w-full grow">{renderStepContent()}</div>
      </div>
    </DestinationCtx.Provider>
  );
};

export default React.memo(AddDestination);
