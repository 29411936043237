import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { getDateFormat } from "~/libs/date.helpers";
import { StatusBadge, StatusDot } from "../Badges";
import { AuditLog } from "~/libs/factories/audit-log";
import { da } from "date-fns/locale";

const columns = createColumnHelper<AuditLog>();

const status_color_map = {
  critical: { bg: "#FFEBEB", text: "#F05050", border: "transparent" },
  important: { bg: "#FFF9EB", text: "#FFAB00", border: "transparent" },
  informational: { bg: "#FFFFFF", text: "#57584E", border: "#DEE5E7" },
  success: { bg: "#EDFDF0", text: "#3BB75E", border: "transparent" },
  get: { bg: "#FFFFFF", text: "#57584E", border: "#DEE5E7" },
};

export const statusDotColumn = columns.accessor(
  (log) => {
    return <StatusDot label={log.status} />;
  },
  {
    id: "tx-status-dot",
    header: () => (
      <div className={"flex items-center self-stretch"}>
        <StatusDot label={"success"} />
      </div>
    ),
    cell: (row) => row.getValue(),
  },
);

export const dateColumn = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "13px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {getDateFormat(new Date(data?.createdAt)).dateFormat} <br />
        <span
          style={{ fontSize: "12px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {getDateFormat(new Date(data?.createdAt)).timeFormat}
        </span>
      </span>
    );
  },
  {
    id: "date-time",
    header: tableHeader("Date", "Time"),
    cell: (row) => row.getValue(),
  },
);

export const description = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "13px", fontWeight: "normal" }}
        className="font-body font-normal text-gray-500"
      >
        {data?.description}
      </span>
    );
  },
  {
    id: "description",
    header: tableHeader("Narration"),
    cell: (row) => row.getValue(),
  },
);

export const adminRole = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "13px", fontWeight: "bolder" }}
        className="font-body font-bold text-gray-600"
      >
        {"-"} <br />
        <span
          style={{ fontSize: "13px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {"-"}
        </span>
      </span>
    );
  },
  {
    id: "admin-role",
    header: tableHeader("Admin", " Role"),
    cell: (row) => row.getValue(),
  },
);

export const category = columns.accessor(
  (data) => {
    return (
      <span
        style={{ fontSize: "13px", fontWeight: "normal" }}
        className="font-body font-normal text-gray-500"
      >
        {data?.entity}
      </span>
    );
  },
  {
    id: "category",
    header: tableHeader("Category"),
    cell: (row) => row.getValue(),
  },
);

export const sourceIP = columns.accessor(
  (data) => {
    // console.log("data", typeof data?.details);
    // const parsed = !!data?.details ? JSON.parse(data?.details) : data?.details;

    return (
      <span
        style={{ fontSize: "13px", fontWeight: "bolder" }}
        className="font-body font-normal text-gray-500"
      >
        {/* {typeof parsed === "object" && parsed !== null
          ? parsed?.deviceInfo
          : !!data?.details
          ? data?.details
          : "-"} */}
        <br />
        <span
          style={{ fontSize: "13px", fontWeight: "normal" }}
          className="font-body  font-normal text-gray-400"
        >
          {/* {typeof parsed === "object" && parsed !== null
            ? parsed?.ipAddress
            : !!data?.details
            ? data?.details
            : "-"} */}
        </span>
      </span>
    );
  },
  {
    id: "audit-action",
    header: tableHeader("Source", "IP Address"),
    cell: (row) => row.getValue(),
  },
);

export const statusColumn = columns.accessor(
  (data) => {
    const match = status_color_map[data?.action.toLowerCase()] ?? {};
    return (
      <div
        style={{
          backgroundColor: match.bg,
          border: `1px solid ${match.border}`,
        }}
        className={
          "inline-flex h-[30px] items-center gap-1 rounded-2xl px-3 py-[10px]"
        }
      >
        <span
          style={{ color: match.text }}
          className="mx-[5px] text-center text-[12px] font-bold capitalize"
        >
          {data?.action}
        </span>
      </div>
    );
  },
  {
    id: "priority",
    header: tableHeader("Priority"),
    cell: (row) => row.getValue(),
  },
);

function tableHeader(
  mainHeading: string,
  subHeading?: string,
): () => React.JSX.Element {
  return () => {
    return (
      <div className="flex items-center justify-between py-1">
        <div className="flex flex-col">
          <span
            className={`text-sm font-bold leading-tight text-gray-600 ${
              !subHeading ? "pt-1" : ""
            }`}
          >
            {mainHeading}
          </span>
          {!subHeading ? null : (
            <span
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                marginTop: "2px",
              }}
              className="font-body font-normal text-gray-400"
            >
              {subHeading}
            </span>
          )}
        </div>
      </div>
    );
  };
}
