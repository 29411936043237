import { FileRoutesByPath } from "@tanstack/react-router";
import * as React from "react";
import { ComponentProps } from "react";
import { cn } from "~/@/lib/utils";
import { useNavbar } from "~/contexts/NavbarContext";

export type RouteGroup = {
  groupHeading?: string;
  items: RouteLink[];
};

export type RouteLink = {
  label: string;
  icon: React.JSX.Element;
  link: keyof FileRoutesByPath | "";
  isDisabled?: boolean;
  onClick?: () => void;
};

export function NavGroup(props: {
  group: RouteGroup;
  isMatch: (item: RouteLink) => boolean;
  onItemClick: (item: RouteLink) => void;
}) {
  const { group, isMatch } = props;
  const { isOpen } = useNavbar();

  return (
    <div className={"group"}>
      <div className="my-4 ml-[15px] h-[1px] w-[20px] bg-[#303940]/[0.3] group-first:hidden" />

      {group.groupHeading && isOpen ? (
        <div
          className={
            "px-4 font-body text-[12px] font-normal uppercase tracking-wider text-gray-800/[0.5]"
          }
          style={{
            fontFeatureSettings: `'clig' off, 'liga' off`,
          }}
        >
          {group.groupHeading}
        </div>
      ) : null}

      {group.items.map((item) => {
        const key = item.link as string;

        return (
          <NavItem
            key={key}
            data={item}
            disabled={item.isDisabled}
            data-state={
              !item?.link ? "inactive" : isMatch(item) ? "active" : "inactive"
            }
            onClick={() => props.onItemClick(item)}
          />
        );
      })}
    </div>
  );
}

export function NavItem(props: { data: RouteLink } & ComponentProps<"button">) {
  const { data: nav, ...PROPS } = props;
  const [displayData, setDisplayData] = React.useState("");
  const { isOpen } = useNavbar();

  // console.log(displayData);

  const clickDisplayData = () => {
    if (displayData === nav.label) {
      setDisplayData("");
    } else {
      setDisplayData(nav.label);
    }
  };

  return (
    <div className="flex">
      <button
        {...PROPS}
        type={"button"}
        onMouseOver={() => setDisplayData(nav.label)}
        onFocus={() => {}}
        onMouseOut={() => setDisplayData("")}
        onBlur={() => {}}
        className={cn(
          `flex h-[42px] ${
            isOpen ? "w-full pl-[15px]" : "w-max px-[22px]"
          } cursor-pointer items-center border-transparent  disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50`,
          "data-[state=active]:border-r-[3px] data-[state=active]:border-[#0094FF] data-[state=active]:bg-[#E2F1FC] data-[state=active]:text-gray-800 data-[state=active]:hover:border-[#0094FF] data-[state=active]:hover:bg-[#E2F1FC] data-[state=active]:hover:text-[#4084B5]",
        )}
      >
        {isOpen && (
          <>
            <span className={"text-[#005695]"}>{nav.icon}</span>
            <span
              className={
                "ml-[10px] mt-[4px] text-[14px] leading-tight text-[#4084B5]"
              }
            >
              {nav.label}
            </span>
          </>
        )}
        {!isOpen && (
          <>
            <span className={"text-[#005695]"}>{nav.icon}</span>
          </>
        )}
      </button>
      {displayData === nav.label && !isOpen && (
        <div className="absolute left-[80px] z-[100] rounded-lg bg-[#E2F1FC] p-2 pr-4 shadow-sm">
          <div className="inline-flex flex-col items-start justify-start gap-1.5">
            <div className="font-body text-sm font-normal leading-[1] text-[#4084B5]">
              {nav.label}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
