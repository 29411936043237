import { FooterBackArrow } from "~/assets/icons/FooterBackArrow";

export function OnboardingFooter({ prev }: { prev: () => void }) {
  return (
    <div
      style={{ position: "fixed", zIndex: 99, bottom: 0, left: 0 }}
      className="inline-flex h-20 w-[100%] items-center justify-start gap-[968px] bg-[transparent] px-5 py-6 lg:px-10"
    >
      <button type="button" onClick={() => prev()}>
        <FooterBackArrow />
      </button>
    </div>
  );
}

export function LinksFooter() {
  return (
    <div className="inline-flex  h-10 w-[100%] items-center justify-center gap-6 p-2.5">
      <a
        href={"/terms"}
        className="cursor-pointer text-center text-sm font-bold leading-tight text-zinc-700 hover:underline"
      >
        Terms of use
      </a>
      <a
        href={"/policy"}
        className="cursor-pointer text-center text-sm font-bold leading-tight text-zinc-700 hover:underline"
      >
        Privacy policy
      </a>
    </div>
  );
}
