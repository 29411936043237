import { zodResolver } from "@hookform/resolvers/zod";
import { EditIcon, Loader2, PlusIcon } from "lucide-react";
import { omit } from "ramda";
import React from "react";
import { useForm } from "react-hook-form";
import { parseURL } from "ufo";
import { z } from "zod";
import { Button } from "~/@/components/ui/button";
import { Checkbox } from "~/@/components/ui/checkbox";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components/ui/form";
import { Input } from "~/@/components/ui/input";
import { useConfigureWebhook } from "~/api/codegen/walletosComponents";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { notifyApiError } from "~/components/Toast";
import {
  CardBody,
  CardHeader,
  CardRoot,
  CardTitle,
} from "~/components/layouts/Card";
import { useBusinessMetadata, useGetBusinessId } from "~/hooks/use-business";
import { useModalHandle } from "~/hooks/use-modal";
import { serialNo } from "~/libs";
import { safeArray } from "~/libs/data.helper";
import { safeFormatDistance, safeParseISO } from "~/libs/date.helpers";
import { TitleRoot } from "./Title";

const WebhookSchema = z.object({
  id: z.string().uuid(),
  url: z.string().url(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

type Webhook = z.infer<typeof WebhookSchema>;

const schema = z.object({
  id: z.string().optional(),
  hash: z.string().min(6, "Minimum of 6 chars required"),
  url: z
    .string()
    .url("Invalid callback url")
    .refine(
      (val) => {
        const { protocol } = parseURL(val);
        return protocol === "https:";
      },
      { message: "Only `https` protocol allowed" },
    ),
  send_event: z.boolean().default(false),
});

export function ManageWebhooks() {
  const { data, isLoading } = useBusinessMetadata();
  const modal = useModalHandle("add_web_hook");

  // @ts-expect-error
  const hooks = safeArray<Webhook>(data?.business?.webhooks);

  return (
    <CardRoot>
      <CardHeader
        className={"flex items-center justify-between border-b pb-0 pt-0"}
      >
        <TitleRoot>
          <CardTitle>Webhooks</CardTitle>
        </TitleRoot>
        <DialogRemoteTrigger
          modalId={"add_web_hook"}
          params={{
            mode: "create",
          }}
        >
          <Button size="sm">
            {" "}
            <PlusIcon size="0.9rem" />
            <span className="px-2">Add Webhook</span>
          </Button>
        </DialogRemoteTrigger>
      </CardHeader>

      <CardBody className="w-full overflow-x-auto py-6">
        {hooks.length > 0 ? null : (
          <p className={"text-center text-muted-foreground"}>
            No webhook record
          </p>
        )}

        {hooks.length > 0 ? (
          <div className="w-full flex-1 p-2">
            <table className="w-full min-w-[750px] font-body">
              <thead>
                <tr className="border-b text-sm text-gray-600">
                  <th className="px-2 py-2 text-left">#</th>
                  <th className="px-2 text-left">URL</th>
                  <th className="px-2 text-left">Secret</th>
                  <th className="px-2 text-left">Last updated</th>
                  <th className="px-2 text-right">
                    <div className="flex justify-end">
                      {isLoading ? (
                        <Loader2 className={"animate-spin text-blue-600"} />
                      ) : null}
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {hooks.map((record, index) => {
                  return (
                    <tr className="text-sm text-gray-600" key={record.id}>
                      <td className="px-2 py-1 text-left">
                        {serialNo(index + 1)}.
                      </td>
                      <td className="px-2 py-1">{record.url}</td>
                      <td className="px-2 py-1">****************</td>
                      <td className="px-2 py-1">
                        {safeFormatDistance(
                          safeParseISO(record?.updatedAt ?? record.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                          },
                        ) ?? "--"}
                      </td>
                      <th className="px-2 py-1 text-right">
                        <Button
                          variant="outline"
                          size="icon"
                          onClick={() => {
                            modal.show({
                              mode: "edit",
                              data: {
                                id: record.id,
                                url: record.url,
                              },
                            });
                          }}
                        >
                          <EditIcon size="1rem" />
                        </Button>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}

        <WebhookDialog />
      </CardBody>
    </CardRoot>
  );
}

function WebhookDialog() {
  const { refetch } = useBusinessMetadata();
  const configure_hook = useConfigureWebhook();
  const modal = useModalHandle("add_web_hook");

  const business_id = useGetBusinessId();
  const { data: modal_params } = useModalHandle("add_web_hook");
  const mode = modal_params?.mode ?? "create";

  const form = useForm<z.infer<typeof schema>>({
    defaultValues: {
      hash: "",
      url: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const payload =
        mode === "edit"
          ? {
              queryParams: { id: data.id },
              pathParams: { businessId: business_id },
              body: /\*+/.test(data.hash) ? omit(["hash"], data) : data,
            }
          : {
              pathParams: { businessId: business_id },
              body: data,
            };

      await configure_hook.mutateAsync(payload);
      form.reset();
      modal.hide();
      refetch();
    } catch (err) {
      notifyApiError(err);
    }
  });

  React.useEffect(() => {
    if (modal_params?.mode === "edit") {
      const config = { shouldDirty: false };
      form.setValue("url", modal_params.data.url, config);
      form.setValue("id", modal_params.data.id, config);
      form.setValue("hash", "*****************", config);
    } else {
      form.reset();
    }
  }, [form, modal_params]);

  return (
    <DialogRemote id="add_web_hook">
      <Form {...form}>
        <DialogContent className={"font-body"}>
          <DialogHeader>
            <DialogTitle>
              {mode === "edit" ? "Update" : "Register"} Webhook
            </DialogTitle>
            <DialogDescription>
              Listen for all events from CentryOS backend
            </DialogDescription>
          </DialogHeader>

          <div className="flex flex-col gap-y-[24px] p-4">
            <div className="flex items-start justify-center">
              <span className="mr-[20px] mt-[10px] text-sm font-bold leading-tight text-stone-600">
                Webhook URL
              </span>

              <FormField
                control={form.control}
                name="url"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="text"
                          {...field}
                          placeholder="https://"
                          className="min-w-[300px]"
                        />
                      </FormControl>
                      <FormMessage />
                      <FormItem className="flex gap-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={form.watch("send_event")}
                            onCheckedChange={(value) => {
                              if (value !== "indeterminate")
                                form.setValue("send_event", value);
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-body text-xs font-normal text-neutral-600">
                          Send a test webhook event
                        </FormLabel>
                      </FormItem>
                    </FormItem>
                  );
                }}
              />
            </div>

            <div className="flex items-start justify-center">
              <span className="mr-[30px] mt-[10px] text-sm font-bold leading-tight text-stone-600">
                Secret Hash
              </span>
              <FormField
                control={form.control}
                name="hash"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="text"
                          {...field}
                          placeholder=""
                          className="min-w-[300px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </div>

          <DialogFooter>
            <Button onClick={onSubmit} disabled={!form.formState.isDirty}>
              <LoadingButtonContent loading={configure_hook.isPending}>
                Save changes
              </LoadingButtonContent>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Form>
    </DialogRemote>
  );
}
