import React from "react";
import { cn } from "~/@/lib/utils";
import { InfoIcon } from "~/assets/icons";

export function Alert(props: React.ComponentProps<"div"> & { type: "info" }) {
  const { className, ...PROPS } = props;

  return (
    <div
      {...PROPS}
      className={cn(
        "flex select-none items-center justify-center gap-x-2 rounded-md border border-[#3EAEFF] bg-[#F2F6F8] p-2 px-4 py-4 text-[#4084B5]",
        className,
      )}
    >
      <InfoIcon color="#FFAA22" />
      <p className="text-xs">{props.children}</p>
    </div>
  );
}
