import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import Withdrawal from "~/containers/Withdrawal";

export const Route = createFileRoute("/withdraw")({
  // component: () => <Withdrawal />,
  component: WithdrawLayoutComponent,
});

function WithdrawLayoutComponent() {
  const navigate = useNavigate();
  return (
    <ProtectedRoute
      onAuthStateChange={(state) => {
        if (state.kind === "error") {
          return navigate({ to: "/signin" });
        }
      }}
    >
      <Withdrawal />
    </ProtectedRoute>
  );
}
