import { Divider } from "../Divider";
import { DefaultInputField } from "../Inputs";
import { WalletTransactionModal } from "../TransactionDetails";

import { ScrollArea } from "~/@/components/ui/scroll-area";
import { AppTable } from "~/components/table/table";
import {
  amountColumn,
  createdAtColumn,
  receiverColumn,
  senderColumn,
  statusColumn,
  statusDotColumn,
  transactionTypeColumn,
} from "~/components/table/transaction";
import {
  useBusinessTransactions,
  useGetBusinessId,
} from "~/hooks/use-business";
import { useModal } from "~/hooks/use-modal";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import { WalletImpl } from "~/libs/factories/wallet-factory";
import { CardFooter, CardHeader, CardRoot, CardTitle } from "../layouts/Card";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "../molecules/empty-state";
import { TablePagination } from "../organisms/table/table-pagination";

export function WalletLog({ title }: { title?: string }) {
  const table_columns = useMemo(
    () => [
      statusDotColumn,
      createdAtColumn,
      transactionTypeColumn,
      receiverColumn,
      senderColumn,
      amountColumn,
      statusColumn,
    ],
    [],
  );

  const [searchVal, setSearchVal] = useState("");
  const modal = useModal();

  const businessId = useGetBusinessId();
  const { data: wallet_holder } = useWallets();

  const default_wallet = WalletHolderImpl.getDefaultWallet(
    wallet_holder,
    "CORPORATE",
  );

  const { isLoading, data } = useBusinessTransactions(
    {
      businessId,
      walletId: WalletImpl.getId(default_wallet),
      page: 1,
      flowType: undefined,
    },
    {
      enabled: default_wallet.kind !== "NONE",
    },
  );

  return (
    <CardRoot>
      <CardHeader className="mb-[0px] flex justify-between px-[20px]">
        <CardTitle>{title || "Wallet Log"}</CardTitle>

        <div className="flex items-center">
          <DefaultInputField
            placeholder="Search"
            value={searchVal}
            setValue={setSearchVal}
          />
        </div>
      </CardHeader>

      <Divider className="my-[5px]" />

      <EmptyStateOverlay
        isEmpty={data.data.length === 0 && !isLoading}
        content={
          <EmptyStateDescription>
            Actions and Transactions will appear here
          </EmptyStateDescription>
        }
      >
        <ScrollArea className={"min-h-[400px] w-full"}>
          <AppTable
            data={data.data}
            isLoading={isLoading}
            columns={table_columns}
            onRowClick={(row) => {
              modal.show("transaction_details", data.data[row.index]);
            }}
          />
        </ScrollArea>
      </EmptyStateOverlay>

      <CardFooter>
        <TablePagination
          data={{ page: 0, pageCount: 0, pageSize: 0, total: 0 }}
        />
      </CardFooter>

      <WalletTransactionModal />
    </CardRoot>
  );
}
