export function PayIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9493 3.82912L4.6704 7.29241C4.5671 7.32155 4.47515 7.38152 4.40685 7.46431C4.33855 7.5471 4.29714 7.64877 4.28816 7.75572C4.27919 7.86268 4.30307 7.96982 4.35662 8.06284C4.41017 8.15586 4.49083 8.23032 4.58783 8.27626L10.2296 10.9487C10.3396 11.0008 10.4282 11.0894 10.4803 11.1994L13.1527 16.8411C13.1987 16.9381 13.2731 17.0188 13.3661 17.0724C13.4592 17.1259 13.5663 17.1498 13.6733 17.1408C13.7802 17.1318 13.8819 17.0904 13.9647 17.0221C14.0475 16.9538 14.1074 16.8619 14.1366 16.7586L17.5999 4.47964C17.6253 4.38944 17.6262 4.29409 17.6026 4.20342C17.5789 4.11274 17.5315 4.03001 17.4652 3.96374C17.399 3.89747 17.3162 3.85007 17.2256 3.82641C17.1349 3.80275 17.0395 3.80368 16.9493 3.82912Z"
        fill="#002C3D"
      />
    </svg>
  );
}
