import { useNavigate } from "@tanstack/react-router";
import { LogOut, SettingsIcon, User, MailIcon } from "lucide-react";
import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import {
  CaretDown,
  CaretRight,
  DashboardUserIcon,
  NotificationIcon,
} from "~/assets/icons";
import { Header6Medium } from "~/components/Typography";
import { useAuth } from "~/hooks/use-auth";
import { notifyApiError } from "../Toast";

export function DashboardNavbar(props: { children?: React.ReactNode }) {
  const pathname = window.location.pathname;
  const paths = React.useMemo(() => pathname.split("/").slice(1), [pathname]);

  const getPath = (path: string, index: number) => {
    // dashboard routes
    if (path === "overview" && index === 1) {
      return "dashboard".replaceAll("-", " ");
    }
    if (path === "spend" && index === 2) {
      return path.replaceAll("-", " ");
    }
    if (path === "revenue" && index === 2) {
      return path.replaceAll("-", " ");
    }
    if (path === "users" && index === 2) {
      return "accounts".replaceAll("-", " ");
    }
    if (path === "audit" && index === 1) {
      return "Audit Logs".replaceAll("-", " ");
    }

    // settings routes
    if (path === "profile" && index === 1) {
      return "Profile".replaceAll("-", " ");
    }
    if (path === "team" && index === 1) {
      return "Team Management".replaceAll("-", " ");
    }
    if (path === "wallets" && index === 1) {
      return path.replaceAll("-", " ");
    }
    if (path === "api" && index === 1) {
      return "Developer".replaceAll("-", " ");
    }

    return "";
  };
  return (
    <div
      style={{ gridArea: "header" }}
      className="flex h-[65px] w-full items-center justify-between border-b border-gray-120 bg-[white] px-4 md:px-8"
    >
      <div className="flex items-center">
        {paths
          .map((e) => decodeURI(e))
          .map((path, i) => (
            <div className="flex items-center" key={path}>
              <span className="flex items-center capitalize">
                <Header6Medium className="font-normal capitalize">
                  {getPath(path, i)}
                </Header6Medium>

                {/* {i === 0 && (
                    <Header6Medium className="font-normal capitalize">
                      {path.replaceAll("-", " ")}
                    </Header6Medium>
                  )} */}
              </span>
            </div>
          ))}
      </div>

      <div className="flex items-center justify-evenly gap-x-4">
        {props.children}
      </div>
    </div>
  );
}

export function AccountDropdownMenu() {
  const { destroySession } = useAuth();
  const navigate = useNavigate();

  const dropDownItems = React.useMemo(
    () => [
      // {
      //   label: "Profile",
      //   icon: (
      //     <User
      //       absoluteStrokeWidth={false}
      //       size={"1.2rem"}
      //       stroke={"#005695"}
      //       strokeWidth={1.8}
      //     />
      //   ),
      //   link: "/settings/profile",
      //       isActive: true,
      // },
      {
        label: "Settings",
        icon: (
          <SettingsIcon
            absoluteStrokeWidth={false}
            size={"1.1rem"}
            stroke={"#005695"}
            strokeWidth={1.8}
          />
        ),
        link: "/settings",
        isActive: true,
      },
      {
        label: "Support",
        icon: (
          <MailIcon
            absoluteStrokeWidth={false}
            size={"1.1rem"}
            stroke={"#005695"}
            strokeWidth={1.8}
          />
        ),
        link: "/settings",
        isActive: false,
      },
    ],
    [],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex cursor-pointer items-center">
          <DashboardUserIcon />
          <div className="ml-[8px]">
            <CaretDown />
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="min-w-[150px]">
        <DropdownMenuGroup>
          {dropDownItems.map((item, i) => (
            <div key={item.label}>
              <DropdownMenuItem
                className="space-x-2 text-gray-700"
                onClick={() => navigate({ to: item.link })}
                disabled={!item.isActive}
              >
                {item.icon}
                <span className={"text-base font-normal"}>{item.label}</span>
              </DropdownMenuItem>
              {dropDownItems?.slice(-1)[0].label !== item.label && (
                <hr className="-mx-2 my-1  border border-[#F4F5F6]" />
              )}
            </div>
          ))}
        </DropdownMenuGroup>
        <hr className="-mx-2 my-1  border border-[#F4F5F6]" />

        <DropdownMenuGroup>
          <DropdownMenuItem
            className="space-x-2 text-gray-700"
            onClick={() =>
              destroySession()
                .then(() => {
                  navigate({ to: "/signin" });
                  // window.location.reload();
                })
                .catch(notifyApiError)
            }
          >
            <LogOut
              strokeWidth={1.8}
              size={"1.2rem"}
              stroke={"#005695"}
              absoluteStrokeWidth={false}
            />
            <span className={"ml-[7px] text-base font-normal"}>Logout</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function NotificationtDropdownMenu() {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger onClick={() => {}}>
        <span className="flex cursor-not-allowed items-center">
          <NotificationIcon />
        </span>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
}
