import { fromPairs } from "ramda";
import { z } from "zod";
import { safeArray } from "../data.helper";

type Option = { id: string; label: string };

type TokenOption = string;

const Token = z.string();

const Option = z.object({ id: z.string(), label: z.string() });

const Structure = z.object({
  balance: z.string(),
  who_can_fund: z.array(z.string()),
  who_can_withdraw: z.array(z.string()),
  require_approval: z.boolean(),
  fund_wallet_via: z.array(Option),
  allow_funding: z.array(Token),
  allow_withdrawal: z.array(Token),
  //   approval_threshold: z.object({
  //     status: z.brand("none" | "some"),
  //     value: z.number()
  //   })
});

const FormStructure = Structure.omit({ fund_wallet_via: true }).extend({
  fund_wallet_via: z.record(z.boolean()),
});

export type CorporateWalletSchema = z.infer<typeof Structure>;
export type FormStruct = z.infer<typeof FormStructure>;

// biome-ignore lint/suspicious/noExplicitAny: Not certain of the structure
export function CorporateWalletFactory(data: any): CorporateWalletSchema {
  // const threshold = safeObj(data?.approval_threshold);

  return {
    balance: data?.balance ?? null,

    who_can_fund: safeArray(data?.whoCanFund),

    require_approval: Boolean(data?.requireApproval),

    // approval_threshold: {
    //   status: threshold?.status ?? "none",
    //   value: threshold?.value ?? 0,
    // },

    fund_wallet_via: safeArray(
      data?.fundWalletVia as unknown as Option[],
    ).filter((e: Option) => e?.label && e?.id),

    allow_funding: safeArray(data?.allowFunding as unknown as TokenOption[]),

    allow_withdrawal: safeArray(
      data?.allowWithdrawal as unknown as TokenOption[],
    ),
  };
}

export function toFormStruct(data: CorporateWalletSchema): FormStruct {
  return {
    ...data,
    fund_wallet_via: fromPairs(
      data.fund_wallet_via.map((e) => [e.id, true]),
    ) as Record<string, boolean>,
  };
}

export function beforeSave(data: FormStruct): FormStruct {
  return {
    ...data,
  };
}
