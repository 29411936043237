import { destr } from "destr";
import React from "react";
import { exitStates$ } from "~/libs/observables/document";

type FormKey = `form:${string}`;

function prefixKey(key: string): FormKey {
  return `form:${key}`;
}

export function useFormPersist<T>(key: string, getState: () => T) {
  const config = React.useRef({ cleared: false });
  const storage_key = prefixKey(key);

  const data = React.useMemo(
    () => destr(sessionStorage[storage_key]),
    [storage_key],
  );

  React.useEffect(() => {
    const handler = exitStates$.subscribe({
      next() {
        if (config.current.cleared) {
          return clearPersisted([storage_key]);
        }

        sessionStorage[storage_key] = JSON.stringify(getState());
      },
    });

    return () => handler.unsubscribe();
  }, [getState, storage_key]);

  return {
    data,
    clear() {
      config.current.cleared = true;
    },
  };
}

export function usePersistedState<T>(key: string, initialState: T | (() => T)) {
  const { data, clear } = useFormPersist(key, () => state);
  const [state, setData] = React.useState<T>(() => data ?? initialState);

  return {
    data: state,
    setData,
    clear,
  };
}

export function clearPersisted(keys: FormKey[]) {
  for (const key of keys) {
    sessionStorage.removeItem(key);
  }
}
