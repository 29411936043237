/* @deprecated Do not use the movement */
const CurrencyFormatter = {
  // accurately rounds any number to 2dp
  roundCurrency(num: number, toDigits?: number): number {
    if (!num) {
      return 0.0;
    }
    const multiplier = 10 ** 2;
    const num_ = parseFloat((num * multiplier).toFixed(2));
    const test = Math.round(num_) / multiplier;

    return +test.toFixed(toDigits ? toDigits : 2);
  },

  // should be used only when displaying the amount
  // converts number to currency format
  // by adding commas where needed
  formatCurrency(num: number): string {
    if (!num) {
      return "0.00";
    }
    const truncatedNumber = Math.floor(num * 100) / 100;
    return Number(truncatedNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  },
  /**
   * Formats any currency value
   *
   * e.g. 50 => 50.00
   * @author David Akin - let me know if you want to improve this :)
   * @param {{value: number|string, fractionDigits:number, commas:boolean}} args
   */
  format({
    value,
    currency = "",
    fractionDigits = 2,
    commas = true,
  }: {
    value: number | string;
    currency?: string;
    fractionDigits?: number;
    commas?: boolean;
  }): string {
    if (value === null || value === undefined) {
      throw new Error("expected type Number, but got null or undefined");
    }

    const valNum = Number(value);

    if (Number.isNaN(valNum)) {
      throw new Error(`expected type Number, but got ${value}`);
    }

    const negative = valNum < 0; // we should consider negative numbers

    // possible max digit before it breaks
    const digitsAggr =
      fractionDigits > 0 && fractionDigits <= 20 ? fractionDigits : 2;

    const floatingPoint = parseFloat(
      valNum.toString().replace(/[^\d.-]/g, ""),
    ).toFixed(digitsAggr);

    // determines if we should show commas
    if (commas) {
      let begin = floatingPoint.split(".")[0];
      const remaining = floatingPoint.split(".")[1];

      const lastIdx = begin.split("").reverse();
      begin = "";
      for (
        let i = 0;
        i < (negative ? lastIdx.length - 1 : lastIdx.length);
        i += 1
      ) {
        begin +=
          lastIdx[i] +
          ((i + 1) % 3 === 0 &&
          i + 1 !== (negative ? lastIdx.length - 1 : lastIdx.length) // don't go over again
            ? ","
            : "");
      }

      // render:
      // if negative number, add a -ve sign in front
      return `${currency}${negative ? "-" : ""}${begin
        .split("")
        .reverse()
        .join("")}.${remaining}`;
    }

    // render:
    return `${currency}${floatingPoint}`;
  },

  /**
   * @description converts any valid number to decimal
   * @returns {number} reformat with a set amount of decimal places
   */
  numToFixedDecimal(numeric: string | number, decimals = 2): number {
    const num = typeof numeric === "string" ? Number(numeric) : numeric;
    return numeric === null || numeric === undefined
      ? 0
      : parseFloat(num.toFixed(decimals));
  },

  /** Format a value in crypto or stable coin equivalent */
  cryptoFormat(numeric: string | number): number {
    try {
      const num = typeof numeric === "string" ? Number(numeric) : numeric;
      if (String(num) === "NaN") {
        throw new TypeError("Amount should be a number");
      }
      return parseFloat(String(num));
    } catch (e) {
      console.error(e);
      return 0;
    }
  },

  cryptoFormatStr(numeric: string | number, code = ""): string {
    const value = this.cryptoFormat(numeric)
      .toString()
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return `${value}${code ? ` ${code}` : ""}`;
  },
};

export default CurrencyFormatter;
