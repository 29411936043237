import { OrderedMap as IMap } from "immutable";
import React, { ComponentProps } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { CountryI } from "~/general/interfaces";
import { useCountries } from "~/hooks";
import { getFlagFromRegionCode } from "~/libs/currency.helpers";
import { safeArray } from "~/libs/data.helper";

const CACtx = React.createContext<{
  countries: IMap<string, CountryI>;
  country: CountryI | null;
  setCountry: (a: string) => void;
}>({
  countries: IMap(),
  country: null,
  setCountry: () => {},
});

export function Root(props: {
  defaultValue?: string;
  children?: React.ReactNode;
}) {
  const { data, isLoading } = useCountries();
  const [countryCode, setCountry] = React.useState(
    () => props?.defaultValue ?? null,
  );
  const countries = React.useMemo(() => {
    return IMap(data.map((e) => [e.iso2, e]));
  }, [data]);

  const country = React.useMemo(
    () => countries.get(countryCode),
    [countryCode, countries],
  );

  return (
    <CACtx.Provider
      value={{
        countries,
        country,
        setCountry,
      }}
    >
      {props.children}
    </CACtx.Provider>
  );
}

export const CountryField = React.forwardRef<
  HTMLSelectElement,
  {
    value: string;
    disabled?: boolean;
    onChange: ComponentProps<typeof Select>["onValueChange"];
    onBlur?: ComponentProps<"select">["onBlur"];
  }
>(function CountrySelector(props, ref) {
  const { countries, setCountry } = React.useContext(CACtx);

  return (
    <Select
      defaultValue={props.value}
      onValueChange={(value) => {
        props.onChange(value);
        setCountry(value);
      }}
      // disabled={props.disabled}
    >
      <SelectTrigger disabled={props.disabled}>
        <SelectValue ref={ref} placeholder={"Choose country"} />
      </SelectTrigger>

      <SelectContent>
        {countries
          .map((country) => (
            <SelectItem key={country.iso2} value={country.iso2}>
              <div className="flex items-center space-x-2">
                <img
                  src={getFlagFromRegionCode(country.iso2)}
                  className="aspect-[25/20] w-[25px] rounded object-contain"
                  alt={country.name}
                />
                <span className="font-normal leading-tight text-stone-600">
                  {country.name}
                </span>
              </div>
            </SelectItem>
          ))
          .toList()}
      </SelectContent>
    </Select>
  );
});

export const StateField = React.forwardRef<
  React.ElementRef<typeof SelectValue>,
  {
    value: string;
    onChange: ComponentProps<typeof Select>["onValueChange"];
    onBlur: ComponentProps<"select">["onBlur"];
  }
>(function StateField(props, ref) {
  const { country } = React.useContext(CACtx);
  const states = safeArray(country?.states);

  return (
    <Select
      onValueChange={props.onChange}
      defaultValue={props.value}
      disabled={states.length === 0}
    >
      <SelectTrigger className="w-[100%]">
        <SelectValue
          ref={ref}
          placeholder={"Choose state"}
          onBlur={props.onBlur}
        />
      </SelectTrigger>

      <SelectContent>
        {states.map((state) => (
          <SelectItem key={state.name} value={state.name}>
            <div className="flex items-center space-x-2">
              <span className="font-normal leading-tight text-stone-600">
                {state.name}
              </span>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});
