import debounce from "lodash/debounce";
import * as R from "ramda";
import { F } from "ts-toolbelt";

type GroupLogger = F.Curry<(group: string, x: unknown) => unknown>;
interface Logger {
  (x: unknown): unknown;
  group: GroupLogger;
}

export const loggerFactory = R.curry(
  (style: unknown) =>
    (namespace = "", config = { disabled: false }): Logger => {
      const logGroup: GroupLogger = R.curry((groupname: string, x: unknown) => {
        if (config.disabled) return;
        if (isDevelopment()) {
          const label =
            namespace && groupname
              ? `${namespace}/${groupname}`
              : groupname
                ? `${groupname}`
                : "";

          // eslint-disable-next-line
          if (isBrowser()) {
            console.info(`%c${label}`, style, x);
          } else {
            console.info(namespace, x);
          }
        }
        return x;
      });

      const logger: Logger = (x) => logGroup("LOG")(x);
      logger.group = logGroup;

      return logger as Logger;
    },
);

const infoLogger = loggerFactory(`
  background-color: dodgerblue;
  color: white;
  padding: 0 .5rem;
  border-radius: 3px;
`);

const errorLogger = loggerFactory(`
  background-color: crimson;
  color: white;
  padding: 0 .5rem;
  border-radius: 3px;
`);

export const isBrowser = () => typeof window !== "undefined";

// eslint-disable-next-line
export const isDevelopment = () =>
  ["development"].includes(import.meta.env.MODE);

export const isProd = () => process.env.NODE_ENV === "production";

export const log = R.curry((group: string, x: unknown) => infoLogger(group)(x));

export const logDebounce = (tag: string) => {
  const fn = debounce((k, v) => log(`${tag} - ${k}`, v), 2000);
  return (k: unknown, v: unknown) => {
    fn(k, v);
    return v;
  };
};

export const logError = R.curry((group: string, x: unknown) =>
  errorLogger(group)(x),
);

interface MakeTrace extends Array<Logger> {
  info: Logger;
  error: Logger;
}

export const makeTrace = (
  groupname: string,
  config = { disabled: false },
): MakeTrace => {
  const info = infoLogger(groupname, config);
  const error = errorLogger(groupname, config);

  const struct: MakeTrace = [info, error] as never;
  struct.info = info;
  struct.error = error;

  return struct;
};

export const PerformanceTest = {
  record: {} as Record<string, number>,
  start(key: string, logger: (tag: string) => (data: unknown) => void = log) {
    this.record[key] = performance.now();
    return () => {
      const value = performance.now() - this.record[key];

      logger(`Performance[${key}]`)(value);
    };
  },
};
